/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export function recursiveFilterIds(filters = []) {
  const ids = [];
  filters.forEach(filter => {
    ids.push(filter.id);
    if (Array.isArray(filter.children) && filter.children.length > 0) {
      ids.push(...recursiveFilterIds(filter.children));
    }
  });
  return ids;
}
