/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import Cookies from 'universal-cookie';

import { getChildAppApis, getFrameApi } from '../api';
import herems from '../herems';
import { getOktaAuthClient } from '../api/package/auth';
import { authApi } from '../AuthApi';

const cookies = new Cookies();

export function clearLoggedInUser() {
  return {
    type: 'CLEAR_LOGGED_IN_USER',
  };
}

export function loginFail() {
  return {
    type: 'LOGIN_FAILED',
  };
}

export function loggingIn() {
  return {
    type: 'LOGGING_IN',
  };
}

export function replaceLoggedInUser(entity) {
  const userId = entity.id;
  const api = getFrameApi();

  api.setAuth(userId);

  getChildAppApis().forEach((childAppApi) => {
    childAppApi.setAuth(userId);
  });

  return {
    type: 'REPLACE_LOGGED_IN_USER',
    entity,
  };
}
export function login(username, password) {
  const api = getFrameApi();

  return (dispatch) => {
    dispatch(loggingIn());

    api.user
      .login(username, password)
      .then((userEntity) => {
        const userId = userEntity.id;
        api.setAuth(userId);

        // Remove cookies
        cookies.set('userId', userId, { path: '/' });

        dispatch(replaceLoggedInUser(userEntity));
        authApi.loggedIn = true;
        herems.send('LOGGEDIN', { user: userEntity });
      })
      .catch(() => {
        dispatch(loginFail());
      });
  };
}

export function loginWithToken(token, loginWithAccessToken) {
  const api = getFrameApi();

  return async (dispatch) => {
    dispatch(loggingIn());

    try {
      const user = await loginWithAccessToken(token);
      const userId = user.id;
      cookies.set('userId', userId, { path: '/' });
      api.setAuth(userId);
      dispatch(replaceLoggedInUser(user));
      authApi.loggedIn = true;
      herems.send('LOGGEDIN', { user });
    } catch (e) {
      // console.error(e);
      const authClient = await getOktaAuthClient();
      await authClient.tokenManager.clear();
      dispatch(loginFail());
    }
  };
}

export function loginWithUserDetails(user) {
  const api = getFrameApi();

  return async (dispatch) => {
    dispatch(loggingIn());

    try {
      const userId = user.id;
      const email = user.username;
      cookies.set('userId', userId, { path: '/' });
      if (email !== undefined) {
        localStorage.setItem('altairone-email', email);
      }

      delete user.fromSession;

      // If from session then ignore the change
      api.setAuth(userId);
      dispatch(replaceLoggedInUser(user));
      authApi.loggedIn = true;
      herems.send('LOGGEDIN', { user });
    } catch (e) {
      // console.error(e);
      const authClient = await getOktaAuthClient();
      if (authClient) {
        authClient.tokenManager.clear();
      }
      dispatch(loginFail());
    }
  };
}

export function logout() {
  // return async dispatch => {
  //   const api = getFrameApi();
  //   await Promise.allSettled([apolloClient.clearStore(), licenseManager.checkinAll(), authApi.executeHandlers(AuthEventType.Logout)]);
  //   const reload = await api.auth.doLogout();
  //   cookies.remove('userId', { path: '/' });
  //   browserApi.cacheService.removeData('localStorage', { key: 'altairone-email' });
  //   browserApi.cacheService.removeDataOnLogOut(); // clearing saved cache
  //   if (!reload) {
  //     api.clearAuth();
  //     getChildAppApis().forEach(childAppApi => {
  //       childAppApi.setAuth();
  //     });
  //     dispatch({
  //       type: 'LOGOUT',
  //     });
  //   }
  // };
}

export function loginByUserId(userId, setUserInfo) {
  const api = getFrameApi();

  api.setAuth(userId);

  return (dispatch) => {
    api.user
      .get(userId)
      .then((userEntity) => {
        if (setUserInfo) {
          setUserInfo(userEntity);
        }
        dispatch(replaceLoggedInUser(userEntity));
      })
      .catch((err) => {
        console.error('Catch exp in login By UserId:', err);
        dispatch(logout());
      });
    dispatch({
      type: 'AUTO_LOGGING_IN',
    });
  };
}

/*
export function autoLogin() {
  const api = getFrameApi();

  return async (dispatch) => {
    try {
      dispatch({ type: 'AUTO_LOGGING_IN' });
      // const user = await api.user.whoAmI();
      const userId = cookies.userId;
      // cookies.set('userId', userId, { path: '/' });
      let user = {
        status: 'LOGGED_IN',
        id: userId,
      };
      api.setAuth(userId);
      dispatch(replaceLoggedInUser(user));
      authApi.loggedIn = true;
      herems.send('LOGGEDIN', { user });
    } catch (e) {
      console.error('Catch exp in loginByUserName:', e);
      dispatch(logout());
    }
  };
}*/

export function guestLogin() {
  return async (dispatch) => {
    //const api = getFrameApi();

    /*
    const userData = cookies.get('userData');
    let userId = userData ? userData.email : null;
    if (!userId) {
      userId = cookies.get('userId', { path: '/' });
    }

    let user;
    if (userData) {
      user = {
        status: 'LOGGED_IN',
        id: userId,
        attributes: {
          userName: userData.username,
          eulaAccepted: true,
        },
        ...userData,
      };
    }

    if (!userId) {
      cookies.set('userId', 'GUEST', { path: '/' });
      await api.auth.logout();
    }

    api.setAuth(userId);
    */

    try {
      const guestUser = {
        id: 'GUEST',
        attributes: {
          name: 'GUEST',
          userName: 'GUEST',
        },
      };
      dispatch(replaceLoggedInUser(guestUser));
    } catch (e) {
      // dispatch(logout());
    }
  };
}

export function signupFail() {
  return {
    type: 'SIGNUP_FAILED',
  };
}

export function signingUp() {
  return {
    type: 'SIGNING_UP',
  };
}

export function signup(displayName, username, password) {
  const cookies = new Cookies();
  const api = getFrameApi();

  return (dispatch) => {
    dispatch(signingUp());

    api.user
      .signup(displayName, username, password)
      .then((userEntity) => {
        dispatch(loggingIn());

        const userId = userEntity.id;

        dispatch(replaceLoggedInUser(userEntity));

        cookies.set('userId', userId, { path: '/' });
        api.setAuth(userId);
      })
      .catch(() => {
        dispatch(signupFail());
      });
  };
}
