/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

/* eslint-disable react/no-unused-state */

import React from 'react';
import PropTypes from 'prop-types';

import { AppFrameContext, DEFAULT_APP_FRAME_CONTEXT } from './AppFrameContext';
import { isEqual } from 'lodash';

export class AppFrameProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_APP_FRAME_CONTEXT,
      topBarHidden: true,
      footerHidden: true,
      notifications: [],
      notificationEnabled: props.notificationEnabled,
      onlineHelpUrl: props.onlineHelpUrl,
      panelPortalNode: document.body,
      dismissNotification: () => {},
      setConfig: this.setConfig,
      setTopBarHidden: this.setTopBarHidden,
      setFooterHidden: this.setFooterHidden,
      setWordmark: this.setWordmark,
      setTexts: this.setTexts,
      setAppContent: this.setAppContent,
      setWaffleMenuProps: this.setWaffleMenuProps,
      setOnlineHelpUrl: this.setOnlineHelpUrl,
      setNotifications: this.setNotifications,
      setDismissNotification: this.setDismissNotification,
      appVersion: props.appVersion,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      notificationEnabled: props.notificationEnabled,
      panelPortalNode: props.panelPortalNode,
    };
  }

  setConfig = (config) => {
    if (this.state.config !== config) {
      this.setState({ config });
    }
  };

  setTopBarHidden = (topBarHidden) => {
    if (this.state.topBarHidden !== topBarHidden) {
      this.setState({ topBarHidden });
    }
  };

  setFooterHidden = (footerHidden) => {
    if (this.state.footerHidden !== footerHidden) {
      this.setState({ footerHidden });
    }
  };

  setPanelPortalNode = (panelPortalNode) => {
    this.setState({ panelPortalNode });
  };

  setWordmark = (wordmark) => {
    this.setState({ wordmark });
  };

  setWaffleMenuProps = (waffleMenuProps) => {
    this.setState({ waffleMenuProps });
  };

  setTexts = (texts) => {
    if (this.state.texts !== texts) {
      this.setState({ texts });
    }
  };

  setAppContent = (appContent) => {
    if (!isEqual(this.state.appContent, appContent)) {
      this.setState({ appContent });
    }
  };

  setOnlineHelpUrl = (onlineHelpUrl) => {
    this.setState({ onlineHelpUrl });
  };

  setNotifications = (notifications) => {
    this.setState({ notifications });
  };

  setDismissNotification = (dismissNotification) => {
    this.setState({ dismissNotification });
  };

  render() {
    const { children } = this.props;

    return <AppFrameContext.Provider value={this.state}>{children}</AppFrameContext.Provider>;
  }
}

AppFrameProvider.propTypes = {
  appVersion: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  notificationEnabled: PropTypes.bool,
  panelPortalNode: PropTypes.instanceOf(Element),
};
