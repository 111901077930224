/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
/*
* @input
{
  "type": "object",
  "additionalProperties": true,
  "properties": {
    "attributes": {
      "type": "object",
      "additionalProperties": true,
      "properties": {
        "NVH_REPRESENTATION_NVHTYPE": {
          "type": "string",
          "displayName": "NVH Representation NVHTYPE"
        },
        "NODE_ID_RANGE": {
          "type": "array",
          "displayName": "Node ID Range",
          "items": {
            "type": "number"
          }
        }
      }
    }
  }
}
*
* @output
{
  "attributes": {
    "__settings__": { "title": "attributes" },
    "NVH_REPRESENTATION_NVHTYPE": {
      "isReadOnly": true,
      "sourcePath": "attributes.NVH_REPRESENTATION_NVHTYPE",
      "title": "NVH Representation NVHTYPE",
      "type": "string"
    },
    "NODE_ID_RANGE": {
      "items": {
        "type": "number"
      },
      "isReadOnly": true,
      "__settings__": { "title": "Node ID Range" },
      "sourcePath": "attributes.NODE_ID_RANGE",
      "type": "array"
    }
  }
}
* */

function jsonSchemaToNativeSchame(jsonSchema) {
  return buildSchema({ parentPath: '', properties: jsonSchema.properties });
}

function buildSchema({ parentPath, properties }) {
  const schema = {};
  const keys = Object.keys(properties);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const property = properties[key];
    const title = property.displayName || key;

    if (property.type === 'object') {
      schema[key] = buildSchema({ properties: property.properties, parentPath: `${key}.` });
      schema[key].__settings__ = { title };
    } else if (property.type === 'array') {
      schema[key] = {
        items: property.items,
        isReadOnly: true,
        __settings__: { title: property.displayName || key },
        sourcePath: `${parentPath}${key}`,
        type: property.type,
      };
    } else {
      schema[key] = {
        isReadOnly: true,
        sourcePath: `${parentPath}${key}`,
        title: property.displayName || key,
        type: property.type,
      };
    }
  }

  return schema;
}

export default jsonSchemaToNativeSchame;
