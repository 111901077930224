/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import AppFrame from './materials-ui/amdc-app-frame/App';

import Materials from './materials-ui/App';

function isEnabled(value) {
  return value === undefined || value === 'true';
}

const appNames = [];
const enableUsercentrics = true;

const materialsHelpUrl = process.env.REACT_APP_MATERIALS_HELP_URL;

const materialsEnabled = isEnabled(process.env.REACT_APP_MATERIALDB_ENABLED);
if (materialsEnabled) {
  appNames.push('Materials');
}

const notificationEnabled = true;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: localStorage.getItem('amdc-theme') || 'light',
      appContent: {},
    };
  }

  setTheme = (theme, appContent = null) => {
    this.setState({ theme: theme });
    localStorage.setItem('amdc-theme', theme);

    if (appContent && appContent.config && appContent.config.semanticcolors && theme && appContent.config.semanticcolors[theme]) {
      sessionStorage.setItem('semanticcolors', JSON.stringify(appContent.config.semanticcolors[theme]));
    }
  };

  setAppContent = (appContent) => {
    this.setState({ appContent: appContent });
  };

  render() {
    return (
      <AppFrame
        appContent={this.state.appContent}
        theme={this.state.theme}
        setTheme={this.setTheme}
        defaultAppName={'Materials'}
        appNames={appNames}
        config={{ notificationEnabled, appVersion: 'v2022', enableUsercentrics }}
      >
        {materialsEnabled && (
          <Materials
            appName="Materials"
            appVersion="4.0.0"
            onlineHelpUrl={materialsHelpUrl}
            theme={this.state.theme}
            setTheme={this.setTheme}
            setApplicationContent={this.setAppContent}
          />
        )}
      </AppFrame>
    );
  }
}

export default App;
