/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import * as React from 'react';

const NOOP = () => {};

const DEFAULT_ACCOUNT_PANEL_PROPS = {
  onAccountSettingsClick: NOOP,
  onLogoutClick: NOOP,
};

const DEFAULT_TOPBAR_PROPS = {
  controlProps: {
    isHidden: false,
    items: [],
  },
  signinButtonProps: {
    isHidden: true,
  },
  signupButtonProps: {
    isHidden: true,
  },
  waffleButtonProps: {
    isHidden: true,
  },
  wordmark: undefined,
};

const DEFAULT_WAFFLEMENU_PROPS = {
  dashboardButtonProps: {
    isHidden: false,
  },
  homeButtonProps: {
    isHidden: false,
  },
  items: [],
};

/**
 * If any new props added/removed here reevaluate ../topbar/hooks/useDeriveTopbarContextFromProps
 */

export const DEFAULT_TOPBAR_CONTEXT = {
  accountPanelProps: DEFAULT_ACCOUNT_PANEL_PROPS,
  activePanelId: null,
  altairAdminBaseUrl: '',
  altairOneBaseUrl: '',
  onlineHelpUrl: undefined,
  userProfile: null,
  accessToken: null,
  topbarProps: DEFAULT_TOPBAR_PROPS,
  waffleMenuProps: DEFAULT_WAFFLEMENU_PROPS,
  wordmark: undefined,
};

export const TopbarContext = React.createContext(DEFAULT_TOPBAR_CONTEXT);
