/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { TextField } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';
import ComboBox from '../../ComboBox';

function StringInput({ dataItem, errorMessage, onBlur, onChange, pathKeys }) {
  const { isSearchable, isReadOnly, selectOptions, value } = dataItem;
  const isDropdown = Array.isArray(selectOptions);
  const testId = `PropertyEditor-${pathKeys.join('-')}`;

  if (isDropdown) {
    const options = selectOptions.map(option => {
      if (
        typeof option === 'object' &&
        Object.prototype.hasOwnProperty.call(option, 'key') &&
        Object.prototype.hasOwnProperty.call(option, 'text')
      ) {
        return option;
      }
      return {
        key: option,
        text: option,
      };
    });

    return (
      <ComboBox
        data-testid={testId}
        defaultSelectedKey={value}
        disabled={isReadOnly || options.length === 0}
        dropDownItemWrapperMaxHeight={390}
        isSearchable={isSearchable}
        onBlur={onBlur}
        onChange={isReadOnly ? () => {} : (e, option) => onChange(option.key)}
        options={options}
      />
    );
  }

  return (
    <TextField
      data-testid={testId}
      disabled={isReadOnly}
      errorMessage={errorMessage}
      onBlur={isReadOnly ? () => {} : e => onBlur(e.target.value)}
      onChange={isReadOnly ? () => {} : e => onChange(e.target.value)}
      type="text"
      value={value}
    />
  );
}

StringInput.propTypes = {
  dataItem: PropTypes.shape({
    isSearchable: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    selectOptions: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ key: PropTypes.string, text: PropTypes.string })])
    ),
    value: PropTypes.any,
  }).isRequired,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  pathKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

StringInput.defaultProps = {
  errorMessage: null,
  onBlur: () => {},
  onChange: () => {},
};

export default StringInput;
