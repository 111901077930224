/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { ChoiceGroup } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import localPropTypes from '../../propTypes';
import EnumFilter from './EnumFilter';

function EnumFilterGroup(props) {
  const { group, onChange, selectedItems } = props;

  const handleChange = useCallback(
    (e, option) => {
      const newValue = option.key;

      onChange({ newValue });
    },
    [onChange]
  );

  const options = useMemo(() => {
    if (!group) {
      return [];
    }

    const styles = {
      root: {
        margin: 0,
      },
      choiceFieldWrapper: {
        width: '100%',
        selectors: {
          '.ms-ChoiceFieldLabel': {
            fontSize: '12px !important',
          },
        },
      },
    };

    let selectedKey;

    const optionsTemp = group.filters.map(filter => {
      const { id, name } = filter;
      if (selectedItems.includes(id)) {
        selectedKey = id;
      }
      return {
        key: id,
        onRenderField: (fieldProps, render) => {
          const newFieldProps = { fieldProps, render, filter };
          return EnumFilter(newFieldProps);
        },
        styles,
        text: name,
      };
    });

    return {
      options: optionsTemp,
      selectedKey,
    };
  }, [group, selectedItems]);

  if (!group) return null;

  return <ChoiceGroup className="Filter__Value" onChange={handleChange} {...options} />;
}

EnumFilterGroup.propTypes = {
  group: localPropTypes.group,
  selectedItems: PropTypes.arrayOf(PropTypes.string.isRequired),
  onChange: PropTypes.func,
};

EnumFilterGroup.defaultProps = {
  group: null,
  selectedItems: [],
  onChange: () => {},
};

export default EnumFilterGroup;
