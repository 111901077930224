/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, IconBase } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { DefaultButtonStyle, Divider, GroupExpanderRoot } from './styled';
import { onRenderGroupExpanderButtonText } from './onRenderGroupExpanderButtonText';

const NOOP = () => {};

function GroupExpander(props) {
  const { children, isActive, isExpanded: propsIsExpanded, onResetFilter, text, group, ...restProps } = props;
  const isExpandClickedRef = useRef(propsIsExpanded);
  const [isExpanded, setIsExpanded] = useState(propsIsExpanded);
  const iconName = isExpanded ? 'ChevronDown' : 'ChevronRight';

  useEffect(() => {
    if (!isExpandClickedRef.current) {
      setIsExpanded(propsIsExpanded);
    }
  }, [propsIsExpanded]);

  function handleClick() {
    isExpandClickedRef.current = true;
    setIsExpanded(!isExpanded);
  }

  function handleResetFilter(e) {
    e.preventDefault();
    e.stopPropagation();

    onResetFilter(group);
  }

  return (
    <GroupExpanderRoot className="Filter__SubHeader" style={{ height: isExpanded ? 'auto' : '24px' }} {...restProps}>
      <Divider />
      <DefaultButton
        className="filterGroupExpanderText"
        data-testid="GroupExpander-Button"
        iconProps={{ iconName }}
        onRenderIcon={() => (
          <>
            <IconBase iconName={iconName} styles={{ root: { fontSize: 16 } }} />
            {isActive && (
              <div
                aria-roledescription="Rest Filter"
                className="aone-GroupExpander-reset"
                onKeyPress={NOOP}
                onClick={handleResetFilter}
                role="button"
                tabIndex={-1}
                title="Clear Filters"
              >
                Clear
              </div>
            )}
          </>
        )}
        onClick={handleClick}
        onRenderText={onRenderGroupExpanderButtonText}
        styles={DefaultButtonStyle}
        text={text}
      />

      <div
        style={{
          height: isExpanded ? 'auto' : 0,
          display: isExpanded ? 'block' : 'none',
        }}
      >
        {children}
      </div>
    </GroupExpanderRoot>
  );
}

GroupExpander.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onResetFilter: PropTypes.func,
  text: PropTypes.string,
};

GroupExpander.defaultProps = {
  isActive: false,
  isExpanded: false,
  onResetFilter: () => {},
  text: '',
};

export default React.memo(GroupExpander);
