/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';


function Indents(props) {
  const { count } = props;

  return Array.from(Array(count))
    .map(() => uuidV4())
    .map(key => {
      //   const classNames = [className.indent, index === count - 1 ? className.indentLast : ''];
      return (
        <div
          style={{
            display: 'inline-block',
            position: 'relative',
            width: '8px',
          }}
          key={key}
        >
          <div />
        </div>
      );
    });
}

Indents.propTypes = {
  count: PropTypes.number,
};

Indents.defaultProps = {
  count: 0,
};

export default Indents;
