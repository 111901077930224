/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Icon } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Spin } from './styled';

export function PendingIcon(props) {
  const { width } = props;
  const fontSize = (width * 16) / 13.5;

  return (
    <Spin>
      <Icon data-testid="PendingIcon" iconName="ProgressRingDots" style={{ fontSize: `${fontSize}px` }} />
    </Spin>
  );
}

PendingIcon.propTypes = {
  width: PropTypes.number.isRequired,
};

export default PendingIcon;
