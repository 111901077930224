/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';

export const LoginProps = {
  isLoggingIn: PropTypes.bool,
  onSubmit: PropTypes.func,
  signupLink: PropTypes.string,
  styles: PropTypes.func,
  theme: PropTypes.object,
};

export const DefaultLoginProps = {
  isLoggingIn: false,
  onSubmit: () => {},
};
