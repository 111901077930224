/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { useEffect, useRef } from 'react';
import { TopbarValues } from '../../../context/TopbarValues';

export function useDeriveTopbarContextFromProps(nextProps) {
  const propsRef = useRef(nextProps);

  const firstMountRef = useRef(true);

  useEffect(() => {
    const firstMount = firstMountRef.current;
    firstMountRef.current = false;

    const prevProps = propsRef.current;

    // If first mount and respective value is not undefined set the value in context
    // Or if prev value is not equal to current then set the value in context

    if (
      nextProps?.accountPanelProps &&
      (firstMount ||
        nextProps?.accountPanelProps !== prevProps.accountPanelProps ||
        nextProps?.accountPanelProps?.onAccountSettingsClick !== prevProps.accountPanelProps?.onAccountSettingsClick ||
        nextProps?.accountPanelProps?.onLogoutClick !== prevProps.accountPanelProps?.onLogoutClick)
    ) {
      TopbarValues.setValues({ accountPanelProps: nextProps?.accountPanelProps });
    }

    if (nextProps?.altairAdminBaseUrl && nextProps?.altairAdminBaseUrl !== prevProps.altairAdminBaseUrl) {
      TopbarValues.setValues({ altairAdminBaseUrl: nextProps?.altairAdminBaseUrl });
    }

    if (nextProps?.altairOneBaseUrl && nextProps?.altairOneBaseUrl !== prevProps.altairOneBaseUrl) {
      TopbarValues.setValues({ altairOneBaseUrl: nextProps?.altairOneBaseUrl });
    }

    if (nextProps?.onlineHelpUrl && nextProps?.onlineHelpUrl !== prevProps.onlineHelpUrl) {
      TopbarValues.setValues({ onlineHelpUrl: nextProps?.onlineHelpUrl });
    }

    if (
      nextProps?.topbarProps &&
      (firstMount ||
        nextProps?.topbarProps !== prevProps.topbarProps ||
        nextProps?.topbarProps?.controlProps !== prevProps.topbarProps?.controlProps ||
        nextProps?.topbarProps?.signinButtonProps !== prevProps.topbarProps?.signinButtonProps ||
        nextProps?.topbarProps?.signupButtonProps !== prevProps.topbarProps?.signupButtonProps ||
        nextProps?.topbarProps?.waffleButtonProps !== prevProps.topbarProps?.waffleButtonProps)
    ) {
      TopbarValues.setValues({ topbarProps: nextProps?.topbarProps });
    }

    if (
      nextProps?.waffleMenuProps &&
      (firstMount ||
        nextProps?.waffleMenuProps !== prevProps.waffleMenuProps ||
        nextProps?.waffleMenuProps?.dashboardButtonProps !== prevProps.waffleMenuProps?.dashboardButtonProps ||
        nextProps?.waffleMenuProps?.homeButtonProps !== prevProps.waffleMenuProps?.homeButtonProps ||
        nextProps?.waffleMenuProps?.items !== prevProps.waffleMenuProps?.items)
    ) {
      TopbarValues.setValues({ waffleMenuProps: nextProps?.waffleMenuProps });
    }

    if (nextProps?.wordmark && nextProps?.wordmark !== prevProps.wordmark) {
      TopbarValues.setValues({ wordmark: nextProps?.wordmark });
    }

    propsRef.current = nextProps;
  }, [nextProps]);
}
