/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useState } from 'react';
import { setApi } from './api';

import { connect, Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import createStore from './store/createStore';
import * as actions from './actions';
import Main from './pages';

import ErrorBoundary from './components/ErrorBoundary';

import { useAppFrame } from './amdc-app-frame/context/useAppFrame';
import FooterContainer from './components/FooterContainer';

const defaultOnlineHelpUrl = 'https://help.altair.com/altairone/prod_help/altair_amdc/index.htm';

const mapStateToProps = (state) => {
  return {
    activeEntity: state.activeEntity,
    loggedInUser: state.loggedInUser,
    results: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function App(props) {
  const { setFooterHidden, setOnlineHelpUrl, setWordmark, setTopBarHidden } = useAppFrame();

  const { api, showFullPageLoader, onlineHelpUrl = defaultOnlineHelpUrl } = props;

  useEffect(() => {
    setOnlineHelpUrl(onlineHelpUrl);
  }, [onlineHelpUrl, setOnlineHelpUrl]);

  React.useEffect(() => {
    setFooterHidden(true);
  }, [setFooterHidden]);

  React.useEffect(() => {
    setTopBarHidden(true);
  }, [setTopBarHidden]);

  React.useEffect(() => {
    setWordmark();
  }, [setWordmark]);

  useEffect(() => {
    if (!api) return () => {};

    setApi(api);
  }, [api]);

  const [store, setStore] = useState(null);

  useEffect(() => {
    setStore(createStore());
  }, []);

  if (!store) return null;

  return (
    <ErrorBoundary showFullPageLoader={showFullPageLoader}>
      <Provider store={store}>
        <div style={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column' }}>
          <Main {...props} />
          <FooterContainer />
        </div>
      </Provider>
    </ErrorBoundary>
  );
}

/*
  Availale props:
*/
// App.propTypes = {
//   api: PropTypes.object.isRequired,
//   appName: PropTypes.string.isRequired,
//   history: PropTypes.object.isRequired,
//   isActive: PropTypes.bool,
//   loggedInUserEntity: PropTypes.object.isRequired,
//   path: PropTypes.string.isRequired,
//   userId: PropTypes.string,
// };

export default connect(mapStateToProps, mapDispatchToProps)(App);
