/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import defaultState from '../store/initialStates';

function viewedEntity(state = {}, action) {
  switch (action.type) {
    case 'CLEAR_VIEWED_ENTITY':
      return defaultState.viewedEntity;
    case 'REPLACE_VIEWED_ENTITY':
      return action.entity;
    default:
      return state;
  }
}

export default viewedEntity;
