import React, { useCallback } from 'react';
import { TextField } from '@fluentui/react';

function ImpactAssessmentTextField({ key, type, inputType, getMapValue, onValueChange, readOnly = false }) {
  const onChange = useCallback(
    (_event, value) => {
      onValueChange(type, inputType, value);
    },
    [onValueChange, type, inputType]
  );

  return <TextField key={key} value={getMapValue(type, inputType)} onChange={onChange} readOnly={readOnly} disabled={readOnly} />;
}

export default ImpactAssessmentTextField;
