/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function captchaChallenge({ length = 6 } = {}) {
  const captcha = [];
  for (let i = 0; i < length; i++) {
    // below code will not allow Repetition of Characters
    const index = Math.floor(Math.random() * chars.length + 1);
    if (captcha.indexOf(chars[index]) === -1) {
      captcha.push(chars[index]);
    } else {
      i--;
    }
  }

  return captcha.join('');
}
