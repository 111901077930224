/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

import contentApi from './content';

async function deleteMany(entities, parentDirectoryID) {
  const url = `/file/delete?moveToTrash=true`;

  const reqBody = entities.map(entity => {
    const {
      id,
      system: { contentType },
    } = entity;

    return {
      contentType,
      id,
      parentDirectoryID,
    };
  });

  const { data } = await axios.post(url, reqBody);

  return data;
}

async function search({ filters, text, scope }) {
  const url = `/file/search?scope=${scope}`;
  text = text || '';

  const reqBody = {
    ...filters,
    text,
  };

  const { data: entities } = await axios.post(url, reqBody);

  return entities;
}

async function get(id, collectionName) {
  return contentApi.getByIdAndContentType({ contentType: collectionName, id });
}

async function getActivities({ id, collectionName, offset, limit }) {
  const offsetStr = offset ? `&offset=${offset}` : '';
  const limitStr = limit ? `&limit=${limit}` : '';
  const url = `/activity/activity/entity?entityId=${id}&collectionName=${collectionName}${offsetStr}${limitStr}`;

  const { data } = await axios.get(url);

  return data;
}

async function getContentTypes(id, collectionName) {
  const url = `/content/content/entity?id=${id}&collectionName=${collectionName}`;

  const { data } = await axios.get(url);

  return data;
}

async function getDetails({ id, contentType, mapping }) {
  const url = `contents/${id}/details?contentType=${encodeURIComponent(contentType)}`;
  const { data } = await axios.post(url, { ...mapping });
  return data;
}

async function getParents(id, contentType, includeCollectionNames) {
  let mapping = {
    parent: {
      filter: '$.relations.from',
      isArray: true,
    },
  };

  if (Array.isArray(includeCollectionNames) && includeCollectionNames.length > 0) {
    mapping = {
      parent: {
        filter: `$.relations[?(${includeCollectionNames.map(cName => `@.from.system.collectionName == '${cName}'`).join(' || ')})].from`,
        isArray: true,
      },
    };
  }

  const { data } = await getDetails({ id, contentType, mapping });

  return data;
}

async function getPreview(id, collectionName) {
  const url = `/content/content/view/tab?id=${id}&collectionName=${collectionName}&tabId=preview`;

  const { data } = await axios.get(url);

  return data.data.entity;
}

async function getSearchData(id, collectionName) {
  const url = `/content/entities/${id}/search-data?collectionName=${collectionName}`;
  const { data } = await axios.get(url);

  return data;
}

async function getShared(id, collectionName) {
  const url = `/api/share?entityId=${id}&entityCollection=${collectionName}`;

  const { data } = await axios.get(url);

  return data;
}

export default {
  deleteMany,
  get,
  getActivities,
  getContentTypes,
  getParents,
  getPreview,
  getSearchData,
  getShared,
  search,
};
