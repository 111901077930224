/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton, DefaultButton } from '@fluentui/react';
import { Wrap, Left, Center, Right, SignInButton, SignUpButton, VerticalLine } from './styled';
import LeftPanelControls from './LeftPanelControls';
import RightPanelControls from './RightPanelControls';
import { useTopbar } from '../../../context';
import wordmarkSvg from './altairOne.svg';
import { DEFAULT_TOPBAR_CONTEXT } from '../../../context/TopbarContext';

const NOOP = () => {};
const EMPTY_STRING = null;

const WordmarkNode = <img data-testid="Header-AppWordmark" alt="Altair One" height="100%" src={wordmarkSvg} />;

const defaultTopbarProps = DEFAULT_TOPBAR_CONTEXT.topbarProps;
const Header = (props) => {
  const { onPanelControlClick = NOOP, showAppsPanel = true, activePanelId = EMPTY_STRING } = props;

  const {
    topbarProps: {
      controlProps,
      signinButtonProps = defaultTopbarProps.signinButtonProps,
      signupButtonProps = defaultTopbarProps.signupButtonProps,
      waffleButtonProps = defaultTopbarProps.waffleButtonProps,
      wordmark = WordmarkNode,
    } = {},
  } = useTopbar();

  return (
    <Wrap className="topbar-root">
      <Left>
        {!waffleButtonProps?.isHidden && (
          <>
            <LeftPanelControls active={showAppsPanel} onClick={onPanelControlClick} />
            <VerticalLine />
          </>
        )}
        <div className="wordmark">{wordmark}</div>
      </Left>

      <Center />

      <Right>
        {!controlProps?.isHidden && (
          <RightPanelControls activePanelId={activePanelId} items={controlProps?.items} onClick={onPanelControlClick} />
        )}
        {(!signinButtonProps?.isHidden || !signupButtonProps?.isHidden) && (
          <div style={{ marginRight: '10px', marginTop: '3px', marginBottom: '3px', marginLeft: '3px' }}>
            {!signupButtonProps?.isHidden && (
              <DefaultButton data-testid="Header-Button-SignUp" {...signupButtonProps} styles={SignUpButton} text="Sign Up" />
            )}
            {!signinButtonProps?.isHidden && (
              <PrimaryButton data-testid="Header-Button-SignIn" {...signinButtonProps} text="Sign In" styles={SignInButton} />
            )}
          </div>
        )}
      </Right>
    </Wrap>
  );
};

Header.propTypes = {
  activePanelId: PropTypes.string,
  onPanelControlClick: PropTypes.func,
  showAppsPanel: PropTypes.bool,
};

export default Header;
