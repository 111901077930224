/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import * as React from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';
import { styled } from '@fluentui/utilities';

import { Text } from '@fluentui/react';

import { classNamesFunction } from '@fluentui/utilities';

const getClassNames = classNamesFunction();

const getStyles = (props) => {
  const { isOpen, theme } = props;
  const { semanticColors } = theme;

  return {
    root: [
      'aone-resizablePanel',
      {
        bottom: 0,
        right: 0,
        top: '34px',
        boxSizing: 'border-box',
        position: 'fixed',
        transition: 'all 0.5s ease-out',
        zIndex: 12,
        overflowY: 'overlay',
        background: semanticColors.accountPanelBackground || semanticColors.bodyBackground,
      },
      isOpen && {
        boxShadow: '4px 8px 8px 4px rgba(0,0,0,0.25)',
      },
      !isOpen && {
        transform: 'translateX(100%)',
        visibility: 'hidden',
      },
    ],
    header: [
      {
        fontSize: '21px',
        fontWeight: 500,
        margin: '0px !important',
        padding: '20px 0px 18px 16px !important',
      },
    ],
    resizable: [
      {
        height: '100%',
      },
      isOpen && {
        paddingBottom: '4px',
      },
      !isOpen && {
        display: 'none',
      },
    ],
  };
};

function ResizablePanelBase(props) {
  const { styles, theme, children, isOpen, headerText, onResizeStart, onResizeStop, style = {} } = props;

  const classNames = getClassNames(styles, { isOpen, theme });

  return (
    <div className={classNames.root}>
      <Resizable
        data-testid="resizable"
        enable={{ left: true }}
        defaultSize={style.defaultSize ? style.defaultSize : { width: '340px' }}
        minWidth={style.minWidth ? style.minWidth : 340}
        className={classNames.resizable}
        onResizeStop={onResizeStop}
        onResizeStart={onResizeStart}
      >
        {headerText && isOpen && <Text className={classNames.header}>{headerText}</Text>}
        {isOpen && <div>{children}</div>}
      </Resizable>
    </div>
  );
}

export const ResizablePanel = styled(ResizablePanelBase, getStyles, undefined, { scope: 'ResizablePanel' });

ResizablePanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isOpen: PropTypes.bool,

  style: PropTypes.shape({
    Header: PropTypes.shape({}),
    Wrapper: PropTypes.shape({}),
    defaultSize: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    minWidth: PropTypes.number,
  }),
  headerText: PropTypes.string,
  onResizeStart: PropTypes.func,
  onResizeStop: PropTypes.func,
};

export default ResizablePanel;
