/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Link } from '@fluentui/react';
import PropTypes from 'prop-types';
import Topbar from '../../../topbar/main';
import { ErrorContainer, ErrorMessage } from './styled';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return (
      <>
        {hasError ? (
          <>
            <Topbar />
            <ErrorContainer>
              <ErrorMessage>
                <b>Internal Server Error</b>
                <p>
                  The server encountered an error and could not complete the request. <br /> Please try again later
                </p>
                <Link href="/" title="Return to Home Page">
                  Home
                </Link>
              </ErrorMessage>
            </ErrorContainer>
          </>
        ) : (
          children
        )}
      </>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;
