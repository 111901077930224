/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';

import { FilterInput, FilterRow } from '../styled';

function EnumFilter(props) {
  const { fieldProps, render } = props;

  return (
    <FilterRow>
      <FilterInput>{render(fieldProps)}</FilterInput>
    </FilterRow>
  );
}

EnumFilter.propTypes = {
  fieldProps: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
};

export default EnumFilter;
