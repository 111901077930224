/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { DEFAULT_TOPBAR_CONTEXT } from './TopbarContext';

let _events = [];
let _allValues = {
  ...DEFAULT_TOPBAR_CONTEXT,
};

export class TopbarValues {
  static _suppressUpdates = false;

  static getValues() {
    return _allValues;
  }

  static setValues(values) {
    _allValues = { ..._allValues, ...values };
    TopbarValues._raiseChange();
  }

  static observe(onChange) {
    _events.push(onChange);
  }

  static unobserve(onChange) {
    _events = _events.filter(cb => cb !== onChange);
  }

  static applyBatchedUpdates(code, suppressUpdate) {
    TopbarValues._suppressUpdates = true;
    try {
      code();
    } catch {
      /* do nothing */
    }
    TopbarValues._suppressUpdates = false;
    if (!suppressUpdate) {
      TopbarValues._raiseChange();
    }
  }

  static _raiseChange() {
    if (!TopbarValues._suppressUpdates) {
      _events.forEach(cb => cb());
    }
  }
}
