/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import initialStates from './initialStates';
import rootReducer from '../reducers/index';

const defaultState = {
  ...initialStates,
};

const composeEnhancers = composeWithDevTools({ name: 'app-frame', trace: true });

export default createStore(rootReducer, defaultState, composeEnhancers(applyMiddleware(thunk)));
