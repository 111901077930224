/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';
import Cookies from 'universal-cookie';
import searchAPI from './search';
import configApi from './config';

const cookies = new Cookies();
const cache = new Map();
// const cachePromise = new Map();

async function get(id, options = {}) {
  const { bypassCache = false } = options;

  if (!bypassCache && cache.has(id)) {
    return cache.get(id);
  }

  const userEntity = await this.entity.get(id, 'User');

  cache.set(id, userEntity);

  return userEntity;
}

async function getAll() {
  const filter = {
    payload: {
      filter: {
        $is: ['@contentType'],
      },
      bindVars: {
        contentTypes: ['User'],
      },
      options: {
        isConsistent: false,
      },
    },
  };

  const { entities } = await searchAPI.search({ filter, isLegacy: false });

  entities.forEach((userEntity) => {
    cache.set(userEntity.id, userEntity);
  });

  return entities;
}

function getSync(id) {
  // if (cache.size === 0) {
  //   throw new Error('User cache is empty');
  // }

  return cache.get(id);
}

async function getUsageDetails(type) {
  const url = `/activity/activity/usage?type=${type}`;

  const { data } = await axios.get(url);

  return data;
}
async function login(userName, password) {
  const url = `/api/auth/login`;

  const reqData = {
    data: {
      userName,
      password,
    },
    provider: 'solidthinking',
  };

  const { data } = await axios.post(url, reqData);

  cookies.set('userId', data.id, { path: '/' });

  const tokenObj = {
    jwt: data.jwt,
    expiresAt: new Date(data.expiryTime).getTime(),
    clearOnLogOut: true,
  };

  configApi.saveJWTToStorage(tokenObj);

  return data;
}

async function loginWithToken(token) {
  const url = `/api/auth/v1/login`;
  const nestedResult = await axios.post(url, { token });
  const { data } = nestedResult;
  cookies.set('userId', data.id, { path: '/' });
  console.log('login-data', data);
  return data;
}

async function search(text) {
  const filter = {
    payload: {
      filter: {
        $and: [
          {
            $is: ['@contentTypes'],
          },
          {
            $neq: ['system.isInternal', '@isInternal'],
          },
          {
            $or: [
              {
                $like: ['attributes.name', '@text'],
              },
              {
                $like: ['attributes.userName', '@text'],
              },
              {
                $like: ['attributes.primaryEmailId', '@text'],
              },
            ],
          },
        ],
      },
      bindVars: {
        contentTypes: ['User'],
        text,
        isInternal: true,
      },
      options: {
        isConsistent: false,
      },
    },
  };
  const { entities } = await searchAPI.search({ filter, isLegacy: false });

  return entities;
}

async function getByUserName(userName) {
  const filter = {
    payload: {
      filter: {
        $and: [
          {
            $is: ['@contentType'],
          },
          {
            $eq: ['attributes.userName', '@userName'],
          },
        ],
      },
      bindVars: {
        contentTypes: ['User'],
        userName,
      },
      options: {
        isConsistent: false,
      },
    },
  };

  const { entities } = await searchAPI.search({ filter, isLegacy: false });
  return entities[0];
}

async function put(id, attributes) {
  const url = `/api/users/${id}`;
  const reqConfig = {
    accept: 'application/json',
  };
  const { data } = await axios.put(url, attributes, reqConfig);
  return data;
}

export default {
  get,
  getAll,
  getSync,
  getUsageDetails,
  getByUserName,
  login,
  loginWithToken,
  search,
  put,
  //whoAmI,
};
