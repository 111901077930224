/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { CommandBar } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { common } from '../../../propTypes';
import { ButtonStyle } from './styled';

const DEFAULT_ARRAY = [];

function PanelControls(props) {
  const { activePanelId, items = DEFAULT_ARRAY, onClick } = props;

  const handleClick = useCallback(
    (e, item) => {
      const { key } = item;
      onClick(key);
    },
    [onClick]
  );

  return (
    <div data-testid="panel-controls">
      <CommandBar
        farItems={[
          {
            'data-testid': 'Feedback',
            key: 'FEEDBACK',
            text: 'Feedback',
            onClick: handleClick,
            buttonStyles: ButtonStyle,
          },
          ...items.map(control => ({ ...control, buttonStyles: control.ButtonStyles || ButtonStyle, 'data-testid': control.key })),
          {
            key: 'ACCOUNT',
            checked: activePanelId === 'ACCOUNT',
            'data-testid': 'ACCOUNT',
            iconProps: {
              iconName: 'Contact',
							styles: {
								root: { paddingRight: '10px' },
							},
            },
            onClick: handleClick,
            buttonStyles: ButtonStyle,
          },
        ]}
        styles={{
          root: {
            background: 'none',
            color: '#FFF',
            padding: '0 10px',
            height: 32,
          },
        }}
      />
    </div>
  );
}

PanelControls.propTypes = {
  activePanelId: PropTypes.string,
  items: common.ControlItemsProps,
  onClick: PropTypes.func,
};

PanelControls.defaultProps = {
  activePanelId: null,
  onClick: () => {},
};

export default PanelControls;
