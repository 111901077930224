/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
function appName(state = {}, action) {
  switch (action.type) {
    case 'REPLACE_APP_NAME':
      return action.appName;
    default:
      return state;
  }
}

export default appName;
