/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { DefaultButton, Icon, mergeStyleSets, Text } from '@fluentui/react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { LicenseState } from '../../common/hooks/useLicense';

import { styles } from './NoLicense.styles';

const classNames = mergeStyleSets(styles);

export function NoLicense(props) {
  const { licenseState } = props;

  let message = 'You do not have access to AMDC';
  if (licenseState === LicenseState.CheckoutFailed) {
    message = 'You do not have sufficient license tokens to access AMDC.';
  } else if (licenseState === LicenseState.HeartbeatFailed) {
    message = 'Not able to verify the License. Please login again to continue.';
  }

  return (
    <div className={classNames.root}>
      <Icon className={classNames.icon} iconName="Lock" />
      <Text className={classNames.message} variant="large">
        {message}
      </Text>
      {licenseState !== LicenseState.HeartbeatFailed && (
        <DefaultButton text="Back to Dashboard" iconProps={{ iconName: 'LeftArrow' }} href="/Dashboard" />
      )}
    </div>
  );
}

NoLicense.propTypes = {
  licenseState: PropTypes.string,
};
