/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import Proptypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

export const ConfirmDialog = props => {
  const { isOpen, isBlocking, header, body, primaryButtonText, defaultButtonText, onConfirm, onReject } = props;

  const handleOnConfirm = () => {
    onConfirm({ confirmed: true });
  };

  const handleOnDismiss = () => {
    onReject({ confirmed: false });
  };

  return (
    <Dialog
      hidden={!isOpen}
      onDismiss={handleOnDismiss}
      dialogContentProps={{
        title: header,
      }}
      modalProps={{
        isBlocking,
        styles: { main: { top: 50, maxWidth: '450px !important' } },
        topOffsetFixed: true,
      }}
    >
      {body}
      <DialogFooter>
        <DefaultButton onClick={handleOnDismiss} text={defaultButtonText} />
        <PrimaryButton onClick={handleOnConfirm} text={primaryButtonText} />
      </DialogFooter>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  isOpen: Proptypes.bool,
  isBlocking: Proptypes.bool,
  header: Proptypes.string,
  body: Proptypes.node,
  primaryButtonText: Proptypes.string,
  defaultButtonText: Proptypes.string,
  onConfirm: Proptypes.func,
  onReject: Proptypes.func,
};

ConfirmDialog.defaultProps = {
  isOpen: false,
  isBlocking: false,
  header: 'Confirm',
  body: 'Are you okay with the action?',
  primaryButtonText: 'Ok',
  defaultButtonText: 'Cancel',
  onConfirm: () => {},
  onReject: () => {},
};

export default (props, mountingNode) => {
  const wrapper = (mountingNode || document.body).appendChild(document.createElement('div'));

  const promise = new Promise(resolve => {
    try {
      ReactDOM.render(<ConfirmDialog onReject={resolve} onConfirm={resolve} isOpen {...props} />, wrapper);
    } catch (e) {
      console.error(e);
    }
  });

  function dispose() {
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(wrapper);
      setTimeout(() => document.body.removeChild(wrapper));
    }, 0);
  }

  return promise.then(
    result => {
      dispose();
      return result;
    },
    result => {
      dispose();
      return Promise.reject(result);
    }
  );
};
