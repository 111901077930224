/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { useState, useEffect } from 'react';

export function useEula({ language, fetchEula }) {
  const [eula, setEula] = useState();

  useEffect(() => {
    if (fetchEula) {
      import(`html-loader!./eula-${language}.html`).then((module) => setEula(module.default));
    }
  }, [fetchEula, language]);

  return { eula };
}
