/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { useState } from 'react';
import { useDebounce } from 'react-use';

function useCheckUniqueCriteriaName({ name, names }) {
  const [error, setError] = useState(null);

  useDebounce(
    () => {
      if (names.indexOf(name) > -1) {
        setError(`Criteria with name ${name} already exists.`);
      } else {
        setError(null);
      }
    },
    200,
    [name, names]
  );

  return error;
}

export default useCheckUniqueCriteriaName;
