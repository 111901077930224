/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import { Slide, toast } from 'react-toastify';
import { Notification } from './components/Notification';

let prevToastId;

const handleDismiss = (propsDismiss, toastDismiss) => () => {
  if (typeof propsDismiss === 'function') {
    propsDismiss();
  }
  toastDismiss();
};

const renderToast = ({ component, text, type, onDismiss }) => prop => {
  if (component) {
    return React.cloneElement(component, { onDismiss: prop.closeToast });
  }
  return (
    <Notification onDismiss={handleDismiss(onDismiss, prop.closeToast)} type={type} text={text}>
      <span title={text}>{text}</span>
    </Notification>
  );
};

/**
 * @description Show notification.
 * @param {React.ReactNode} component - Component to render in notification. Component will receive onDismiss props to dismiss the notification.
 * @param {string} text - Notification message.
 * @param {NotificationType} type - Notification type
 */
function notify({ component, config = {}, text, type }) {
  if (prevToastId) {
    if (toast.isActive(prevToastId)) {
      toast.update(prevToastId, {
        ...config,
        render: renderToast({ component, text, type }),
      });
    } else {
      prevToastId = null;
    }
  }
  if (!prevToastId) {
    prevToastId = toast(renderToast({ component, text, type }), {
      ...config,
      onClose() {
        if (typeof config.onClose === 'function') {
          config.onClose();
        }
        prevToastId = null;
      },
    });
  }
}

notify.configure = () =>
  toast.configure({
    className: 'NotificationAlert-container',
    toastClassName: 'Notification',
    bodyClassName: 'Notification-body',
    closeButton: false,
    closeOnClick: false,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_RIGHT,
    transition: Slide,
    draggable: false,
    // autoClose: false,
    pauseOnFocusLoss: false,
  });

notify.dismiss = notificationId => toast.dismiss(notificationId);

export default notify;
