/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
const checkObjectHasKeys = (obj, keys) => {
  return keys.every(key => Object.prototype.hasOwnProperty.call(obj, key));
};

export default checkObjectHasKeys;
