import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
/*
  Generate default values by parsing the schema.
  If itemType === '#/customTypes/path' is present in schema and content payload is avalbale, 
  then path value will be populaed using the content.

*/
function getDefaultValuesFromSchema({ content = {}, schema = {} }) {
  const defaultValue = {};

  const { properties = {} } = schema;
  Object.keys(properties).forEach(key => {
    const { $ref, const: constProp, default: defaultProp, enum: enumProp, type, preFillValue = true, selectionMode = 'both' } = properties[
      key
    ];
    if (preFillValue) {
      const itemType = type || $ref;
      const { values } = content;
      if (!isEmpty(values) && values[key]) {
        defaultValue[key] = values[key];
      } else if (itemType === '#/customTypes/path' && !isEmpty(content)) {
        const path = get(content, 'path');
        if (selectionMode === 'directory') {
          defaultValue[key] = {
            contentType: get(content, 'parentContentType'),
            path: path.slice(0, path.lastIndexOf('/')),
          };
        } else {
          defaultValue[key] = {
            contentType: content.contentType,
            path,
          };
        }
      } else if (!isEmpty(defaultProp)) {
        defaultValue[key] = defaultProp;
      } else if (!isEmpty(enumProp)) {
        defaultValue[key] = enumProp[0];
      } else if (!isEmpty(constProp)) {
        defaultValue[key] = constProp;
      }
    }
  });

  return defaultValue;
}

export default getDefaultValuesFromSchema;
