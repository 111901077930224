import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { MChart } from '@altair/mchart';
import { Checkbox, Dialog, DialogType, MessageBar, MessageBarType } from '@fluentui/react';
import { Margin5 } from '../../../pages/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as TextMapping from '../../../utils/textMapping';
import * as actions from '../../../actions';
import { convertSvgToPngAndCopy, svgElementToBlobUrl } from '../../../utils/plotutils';

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function LTHADialog({ hidden, onDismiss, plotData, roots, downloadPDF }) {
  const [chart, setChart] = useState(null);

  const [number, setNumber] = useState(50);
  const [percentageChecked, setPercentageChecked] = useState(false);
  const [downloadMsg, setDownloadMsg] = useState(false);
  const [hiddenChart, setHiddenChart] = useState(null);
  //const [hiddenChart2, setHiddenChart2] = useState(null);

  const svgRef = useRef();

  function dismiss() {
    setPercentageChecked(false);
    setNumber(50);
    onDismiss();
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chart]);

    return windowDimensions;
  }

  const { height, width } = useWindowDimensions();
  const dialogWidth = width - 100;
  const dialogHeight = height - 100;
  const dialogStyles = { innerContent: { overflow: 'hidden' } };

  const dialogContentProps = {
    type: DialogType.normal,
    closeButtonAriaLabel: 'Close',
    subText: '',
    styles: dialogStyles,
  };

  function showDownloadMessage() {
    setDownloadMsg(TextMapping.getUIText(TextMapping.UI_TEXT_DOWMLOAD_MESSAGE, roots.texts));
    setTimeout(() => {
      setDownloadMsg(false);
    }, 3500);
  }

  function showCopyMessage() {
    setDownloadMsg(TextMapping.getUIText(TextMapping.UI_TEXT_COPY_SUCCESS_MESSAGE, roots.texts));
    setTimeout(() => {
      setDownloadMsg(false);
    }, 3500);
  }

  useEffect(() => {
    if (chart) {
      var lthaInput = document.querySelector('#lthatext');

      lthaInput.onchange = function () {
        setNumber(this.value);
        let calculatedValue = percentageChecked ? this.value : (this.value / plotData?.y[0][0]) * 100;
        chart.data.istraight = parseFloat(calculatedValue);
        chart.redraw();
        // any update on main chart should be reflected on the hidden plot
        hiddenChart.data.istraight = parseFloat(calculatedValue);
        hiddenChart.redraw();
        // setNumber(this.value)
      };

      chart.dispatcher.on('istraight.input', function () {
        lthaInput.value = Math.round(chart.data.istraight);

        hiddenChart.data.istraight = parseFloat(lthaInput.value);
        hiddenChart.redraw();
        setNumber(lthaInput.value);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart, percentageChecked, plotData]);

  useEffect(() => {
    if (plotData && plotData.y) {
      if (!percentageChecked) {
        setNumber(Math.floor(plotData?.y[0][0] * 0.5));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotData]);

  useLayoutEffect(() => {
    if (chart && plotData) {
      let icons = {};
      let  clipboard = null;

      if (roots.perms && roots.perms.includes('ui.pdf')) {
        icons.download =
          "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' class='icon' height='1em' width='1em'%3E%3Cpath d='M7.503 13.002a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 1 0v-.5H8.5a1.5 1.5 0 0 0 0-3h-.997Zm.997 2h-.497v-1H8.5a.5.5 0 1 1 0 1Zm6.498-1.5a.5.5 0 0 1 .5-.5h1.505a.5.5 0 1 1 0 1h-1.006l-.001 1.002h1.007a.5.5 0 0 1 0 1h-1.007l.002.497a.5.5 0 0 1-1 .002l-.003-.998v-.002l.003-2.002Zm-3.498-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h.498a2 2 0 0 0 0-4H11.5Zm.5 3v-2a1 1 0 0 1 0 2ZM20 20v-1.164c.591-.281 1-.884 1-1.582V12.75c0-.698-.409-1.3-1-1.582v-1.34a2 2 0 0 0-.586-1.414l-5.829-5.828a.491.491 0 0 0-.049-.04a.63.63 0 0 1-.036-.03a2.072 2.072 0 0 0-.219-.18a.652.652 0 0 0-.08-.044l-.048-.024l-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 0 0-.624-.138a.56.56 0 0 1-.059-.007a.605.605 0 0 0-.082-.007H6a2 2 0 0 0-2 2v7.168c-.591.281-1 .884-1 1.582v4.504c0 .698.409 1.3 1 1.582V20a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2Zm-2 .5H6a.5.5 0 0 1-.5-.5v-.996h13V20a.5.5 0 0 1-.5.5Zm.5-10.5v1h-13V4a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5Zm-1.122-1.5H14a.5.5 0 0 1-.5-.5V4.621L17.378 8.5Zm-12.628 4h14.5a.25.25 0 0 1 .25.25v4.504a.25.25 0 0 1-.25.25H4.75a.25.25 0 0 1-.25-.25V12.75a.25.25 0 0 1 .25-.25Z'%3E%3C/path%3E%3C/svg%3E";
      }
      if (roots.perms && roots.perms.includes('ui.pdf')) {
        clipboard = true;
        icons.copy = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAwIiBoZWlnaHQ9IjgwMCIgdmlld0JveD0iMCAwIDE1IDE1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMSA5LjVBMS41IDEuNSAwIDAgMCAyLjUgMTFINHYtMUgyLjVhLjUuNSAwIDAgMS0uNS0uNXYtN2EuNS41IDAgMCAxIC41LS41aDdhLjUuNSAwIDAgMSAuNS41VjRINS41QTEuNSAxLjUgMCAwIDAgNCA1LjV2N0ExLjUgMS41IDAgMCAwIDUuNSAxNGg3YTEuNSAxLjUgMCAwIDAgMS41LTEuNXYtN0ExLjUgMS41IDAgMCAwIDEyLjUgNEgxMVYyLjVBMS41IDEuNSAwIDAgMCA5LjUgMWgtN0ExLjUgMS41IDAgMCAwIDEgMi41em00LTRhLjUuNSAwIDAgMSAuNS0uNWg3YS41LjUgMCAwIDEgLjUuNXY3YS41LjUgMCAwIDEtLjUuNWgtN2EuNS41IDAgMCAxLS41LS41eiIgZmlsbD0iIzAwMCIvPjwvc3ZnPg==";
      }

      try {
        //checking if connection exists
        var lthaColors = { gridcolor: { R: 128, G: 128, B: 128 } };

        chart.config('tools', icons);
        chart.config('colors', lthaColors);
        chart.config('clipboard', clipboard)

        if (!chart.connection) {
          var lthaRight = new MChart('ltha2', 'ltha2', true).get();
          var hiddenChart2Right = new MChart('ltha2', 'lthachart2hidden', true).get();
          hiddenChart2Right.config('colors', lthaColors);
          hiddenChart.connect(hiddenChart2Right);
          //setHiddenChart2(hiddenChart2Right);

          lthaRight.config('colors', lthaColors);
          lthaRight.config('tools', icons);
          lthaRight.config('clipboard', clipboard);

          lthaRight.config('download', true).dispatcher.on('download', () => {
            showDownloadMessage();
            //onDismiss();
            const ltha = document.getElementById('lthachart2hidden');
            // ltha.setAttribute('width', '')
            let materialTitle = '';
            if (roots.materialDatasheet.data && plotData.name) {
              materialTitle = roots.materialDatasheet.data.filter((d) => d.type === 'title')[0];
            }
            let materialId = (materialTitle && materialTitle.data.materialId) || '0';
            downloadPDF(materialId, 'ThermalEndurance', roots.appContent, 'diagram', ltha.outerHTML);
          });

          lthaRight.config('copy', true).dispatcher.on('copy', async () => {
            const ltha = document.getElementById('lthachart2hidden');
            if (ltha) {
              const blobUrl = svgElementToBlobUrl(ltha);
              const copyStatus = await convertSvgToPngAndCopy(blobUrl);
              if (copyStatus.success) showCopyMessage();
            }
          });
          chart.connect(lthaRight);
          hiddenChart.connect(hiddenChart2Right);
        }

        chart.config('download', true).dispatcher.on('download', () => {
          showDownloadMessage();
          //onDismiss();
          const ltha = document.getElementById('lthacharthidden');
          let materialTitle = '';
          if (roots.materialDatasheet.data && plotData.name) {
            materialTitle = roots.materialDatasheet.data.filter((d) => d.type === 'title')[0];
          }
          let materialId = (materialTitle && materialTitle.data.materialId) || '0';
          downloadPDF(materialId, 'LTHA', roots.appContent, 'diagram', ltha.outerHTML);
        });

        chart.config('copy', true).dispatcher.on('copy',async  () => {
            const ltha = document.getElementById('lthacharthidden');
            if (ltha) {
              const blobUrl = svgElementToBlobUrl(ltha);
              const copyStatus = await convertSvgToPngAndCopy(blobUrl);
              if (copyStatus.success) showCopyMessage();            }
        });

        chart.draw(plotData);
        chart.resize();
        chart.applyFinalSize(true);

        //hiddenChart.cfg.fullrangemin = isToggled;
        hiddenChart.config('legend', { width: 200, pos: 'bottom' });
        hiddenChart.draw(plotData);
        hiddenChart.resize();
        hiddenChart.applyFinalSize(true);
      } catch (e) {
        console.log('Exception during mchart render: ', e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotData, chart, width, height]);

  function layerDidMount() {
    if (svgRef.current && !chart) {
      setChart(new MChart('ltha1', 'ltha1').get());
      setHiddenChart(new MChart('ltha1', 'lthacharthidden').get());
    }
  }

  function layerWillUnmount() {
    setChart(null);
    setHiddenChart(null);
  }

  function numberChange(e) {
    setNumber(e.target.value);
  }

  function onPercentageChange(e, value) {
    setPercentageChecked(value);
    if (percentageChecked) {
      setNumber(Math.floor(plotData?.y[0][0] * (number / 100)));
    } else {
      setNumber(Math.round((number / plotData?.y[0][0]) * 100));
    }

    if (chart && value !== percentageChecked) {
      chart.switchYViewMode();
      hiddenChart.switchYViewMode();
    }
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={dismiss}
      minWidth={dialogWidth}
      maxWidth={dialogWidth}
      dialogContentProps={dialogContentProps}
      modalProps={{
        layerProps: {
          onLayerDidMount: layerDidMount,
          onLayerWillUnmount: layerWillUnmount,
        },
        isBlocking: true,
      }}
    >
      <div
        id="ltha1-container"
        className="mchart"
        style={{ margin: 'auto', width: dialogWidth - 20, height: dialogHeight, padding: '10px' }}
      >
        <Checkbox label="Percentaged Representation" checked={percentageChecked} onChange={onPercentageChange} />
        {TextMapping.getUIText(TextMapping.UI_TEXT_DEFINE_THRESHOLD, roots.texts, {
          unit: percentageChecked ? '%' : plotData?.axes?.y?.unit,
        })}
        <input
          style={{ marginLeft: '5px', width: '80px' }}
          type="number"
          id="lthatext"
          value={number}
          onChange={numberChange}
          max={percentageChecked ? 100 : plotData?.y[0][0]}
          min="1"
        />
        <div style={{ margin: '15px' }} />
        <div style={{ display: 'flex' }}>
          <div style={{ width: dialogWidth / 2 - 22, height: dialogHeight - 100, paddingRight: `${7}px` }}>
            <svg
              ref={svgRef}
              id="ltha1"
              className="ltha1"
              width={`${dialogWidth / 2 - 22}px`}
              height={dialogHeight - 50 > 0 ? `${dialogHeight - 150}px` : `${dialogHeight}px`}
              style={{ overflow: 'visible' }}
            ></svg>
          </div>
          <Margin5 />
          <div style={{ width: dialogWidth / 2 - 22, height: dialogHeight - 100, paddingLeft: `${7}px` }}>
            <svg
              id="ltha2"
              width={`${dialogWidth / 2 - 22}px`}
              height={dialogHeight - 50 > 0 ? `${dialogHeight - 150}px` : `${dialogHeight}px`}
              style={{ overflow: 'visible' }}
            ></svg>
          </div>
        </div>
      </div>
      <div
        id="parent-container-hidden"
        style={{ padding: '10px', position: 'absolute', opacity: 0, pointerEvents: 'none', width: '1200px', height: '450px' }}
      >
        <svg id="lthacharthidden" className="lthachart" width="650px" height="425px"></svg>
      </div>
      <div
        id="parent-container2-hidden"
        style={{ padding: '10px', position: 'absolute', opacity: 0, pointerEvents: 'none', width: '1200px', height: '650px' }}
      >
        <svg id="lthachart2hidden" className="lthachart" width="750px" height="525px"></svg>
      </div>
      {downloadMsg && (
        <div style={{ position: 'absolute', top: '20px', right: '20px', width: 'fit-content', padding: '0', zIndex: '1' }}>
          <MessageBar messageBarType={MessageBarType.info}> {downloadMsg} </MessageBar>
        </div>
      )}
    </Dialog>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LTHADialog);
