/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const EntityRoot = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Root = styled.div`
  min-height: 270px;
  flex-grow: 1;
  & > .Preview__CarouselRoot {
    &,
    .Preview_CarouselTray,
    .Preview_CarouselTrayWrap {
      height: 100%;
    }
  }
  & > .Preview__CarouselRoot .Preview__Slide-Inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    > .Preview__Slide--Image {
      max-height: 31vh;
      max-width: 49vw;
      object-fit: contain;
      min-height: 162px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const BrokenImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Caption = styled.div`
  position: absolute;
  bottom: 12px;
  width: 100%;
  text-align: center;
`;

const ImgRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const SliderRoot = styled.div`
  display: flex;
  flex-direction: column;
  &,
  .Preview__Slider,
  .Preview__SliderlTray,
  .Preview__SliderTrayWrap,
  .Preview__Slide {
    height: 100%;
  }

  & .Preview__SliderTray {
    display: flex;
    align-items: center;
  }

  & .Preview__Slider--Container {
    flex: 1 0 auto;
    position: relative;
    height: calc(100% - (8vh + 20px));
    & > .Preview__SlideButton {
      padding: 0;
      border: none;
      outline: none;
      background: #343a40;
      color: #ffffff;
      border-radius: 50%;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      &[disabled] {
        opacity: 0.3;
      }
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    & > .Preview__SlideButton--Left {
      left: 0;
    }
    & > .Preview__SlideButton--Right {
      right: 0;
    }
  }

  & .Preview__Image {
    max-height: 49vh;
    max-width: 49vw;
    object-fit: contain;
  }

  & > .Preview__Thumbnail--Container {
    margin: 10px;
    position: relative;
    display: flex;
    overflow: auto;
    & > .Preview__Thumbnail {
      margin-right: 5px;
      display: inline-block;
      min-width: 78px;
      max-width: 6vw;
      min-height: 52px;
      max-height: 8vh;
      border: 1px solid lightgray;
      cursor: pointer;
      position: relative;

      & > .Preview__Thumbnail--Action {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      & > .Preview__Thumbnail--Delete {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
      }
      &:hover > .Preview__Thumbnail--Delete {
        display: inline-block;
      }
      & .Preview__Thumbnail--AddButton {
        border: 1px solid black;
        border-radius: 4px;
      }
      & .Preview__Thumbnail--Caption {
        font-size: 12px;
      }

      & .Preview__Image {
        max-height: calc(8vh - 14px);
        width: 100%;
      }

      & .Preview__Image--Thumbnail {
        padding: 6px 0px;
      }
      &.active::after {
        background-color: rgba(0, 0, 0, 0.09);
        position: absolute;
        content: '';
        min-height: 52px;
        min-width: 78px;
        height: 100%;
        width: 100%;
        display: block;
        top: 0px;
        pointer-events: none;
      }
    }
  }
`;

export { BrokenImage, Caption, EntityRoot, ImgRoot, Root, SliderRoot };
