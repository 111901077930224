import React, { useState, useEffect, useCallback } from 'react';
import { PrimaryButton, IconButton, Checkbox, Pivot, PivotItem, FontIcon, SearchBox, Spinner, SpinnerSize } from '@fluentui/react';
import { Margin5 } from '../../pages/styles';
import { getApi } from '../../api';
import Panel from '../Panel';
import * as TextMapping from '../../utils/textMapping';
import SaveCriteriaDialog from '../gooey/SearchForm/Dialogs/SaveCriteriaDialog';
import SaveCriteriaAsDialog from '../gooey/SearchForm/Dialogs/SaveCriteriaAsDialog';
import LoadCriteriaDialog from '../gooey/SearchForm/Dialogs/LoadCriteriaDialog';
import { DefaultButton } from '@fluentui/react';

import { get } from 'lodash';
import { isEmpty } from 'lodash';

export const PROPERTY_FORMAT = 'PROP-2022-1';

function CustomizeTablePanel({ hidden, onDismiss, handleOnConfirm, tableColumns, preferences, texts, appContent, ranges, rangeStatus }) {
  const [body, setBody] = useState(new Map());
  //const [checkedItems, setCheckedItems] = useState([]);

  const [dragId, setDragId] = useState();
  const [searchStringCustomize, setSearchStringCustomize] = useState('');
  const [searchStringReorder, setSearchStringReorder] = useState('');

  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [maxOrder, setMaxOrder] = useState(0);

  const [isSaveAsDialogHidden, setIsSaveAsDialogHidden] = useState(true);
  const [isSaveDialogHidden, setIsSaveDialogHidden] = useState(true);
  const [isLoadDialogHidden, setIsLoadDialogHidden] = useState(true);

  const [currentName, setCurrentName] = useState();
  const [currentId, setCurrentId] = useState();

  const [loadedTableProps, setLoadedTableProps] = useState([]);
  const [tablePropNames, setTablePropNames] = useState([]);
  const [criterias, setCriterias] = useState([]);

  let maxPos = 0;

  let api = getApi();

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  function getPropertyId(property) {
    let vkey = property.vkey ? property.vkey : property.id;
    let id = property.type + '-' + vkey;

    if (property.use_mask && property.use_mask.length > 0) {
      id += '-' + property.use_mask.join('-');
    }

    return id;
  }

  function checkRange(property) {
    let hasRange = false;

    if (
      property.type !== 'Group' &&
      property.vtype === 'numeric' &&
      !(property.catalog.specialvalueset && property.catalog.specialvalueset.specialonly)
    ) {
      if (
        ranges &&
        ranges.Spt &&
        ranges.Spt[property.vkey] &&
        ranges.Spt[property.vkey].length === 2 &&
        (ranges.Spt[property.vkey][0] || ranges.Spt[property.vkey][0] === 0) &&
        (ranges.Spt[property.vkey][1] || ranges.Spt[property.vkey][1] === 0)
      ) {
        hasRange = true;
      }
    } else if (property.type === 'Group' && property.children && property.children.length) {
      let hasRange = false;
      for (let child of property.children) {
        hasRange = checkRange(child);
        if (hasRange) {
          return hasRange;
        }
      }
    } else if (property.type !== 'Group') {
      hasRange = true;
    }

    return hasRange;
  }

  function mapChildren(parentName, parentVkey, property, properties, depth) {
    let checked = false;

    let order = 0;

    let hasRange = checkRange(property);

    if (isEmpty(loadedTableProps)) {
      if (preferences && preferences.tableProps && preferences.tableProps.length > 0) {
        for (let prop of preferences.tableProps) {
          let prefUseMask = prop.use_mask && prop.use_mask.length ? prop.use_mask.join('-') : null;
          let propertyUseMask = property.use_mask && property.use_mask.length ? property.use_mask.join('-') : null;

          if (prop.id === property.vkey && prefUseMask === propertyUseMask) {
            checked = true;
            order = prop.order;
            if (order > maxPos) {
              maxPos = order;
            }
            break;
          }
        }
      }
    } else {
      for (let prop of loadedTableProps) {
        let prefUseMask = prop.use_mask && prop.use_mask.length ? prop.use_mask.join('-') : null;
        let propertyUseMask = property.use_mask && property.use_mask.length ? property.use_mask.join('-') : null;

        if (prop.id === property.vkey && prefUseMask === propertyUseMask) {
          checked = true;
          order = prop.order;
          if (order > maxPos) {
            maxPos = order;
          }
          break;
        }
      }
    }

    if (!hasRange) {
      return;
    }

    let key = getPropertyId(property);

    properties.set(key, {
      parentVkey: parentVkey,
      parentName: parentName,
      name: property.name,
      id: property.vkey,
      checked: checked,
      order: order,
      type: property.type,
      depth: depth,
      use_mask: property.use_mask,
      condition: property.condition,
      standardName: property.standard_name,
    });

    if (property.children && property.children.length > 0) {
      for (let child of property.children) {
        if (!collapsedVkeys.includes(property.vkey) || searchStringCustomize !== '') {
          mapChildren(property.name, property.vkey, child, properties, depth + 1);
        }
      }
    }

    if (maxPos > 0) {
      setMaxOrder(maxPos);
    }
  }

  useEffect(() => {
    let properties = new Map();

    if (tableColumns && tableColumns.length > 0 && tableColumns[0].children && tableColumns[0].children.length > 0)
      for (let child of tableColumns[0].children) {
        if (child.use_mask.includes(16)) {
          for (let property of child.children) {
            mapChildren('', child.vkey, property, properties, 0);
          }
        }
      }

    setBody(properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableColumns, preferences, collapsedVkeys, searchStringCustomize, loadedTableProps, ranges]);

  const getSearchCriteria = useCallback(async () => {
    if (appContent) {
      const { data } = await api.materials.getSearchCriteria(appContent, PROPERTY_FORMAT);
      let criteriasData = get(data, 'criterias');

      let newCriterias = [];

      for (let entry of criteriasData) {
        if (entry.id && entry.id.startsWith('SearchCriteria')) {
          newCriterias.push(entry);
        }
      }

      let names = [];
      for (let props of newCriterias) {
        names.push(props.name);
      }

      setCriterias(newCriterias);

      setTablePropNames(names);
    }
  }, [appContent, api.materials]);

  useEffect(() => {
    if (appContent) {
      getSearchCriteria();
    }
  }, [appContent, getSearchCriteria]);

  function dismissLoadDialog() {
    setIsLoadDialogHidden(true);
  }

  const handleDelete = useCallback(
    async (_criterias) => {
      const ids = _criterias.map((c) => c.id);

      for (let id of ids) {
        if (id === currentId) {
          setCurrentId(null);
          setCurrentName('');
        }
      }
      await api.materials.removeSearchCriteria(ids);

      getSearchCriteria();
      dismissLoadDialog();
    },
    [api.materials, getSearchCriteria, currentId]
  );

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function handleDrop(ev) {
    let targetId = ev.currentTarget.id;

    if (dragId !== targetId) {
      const dragItem = body.get(dragId);

      const dropItem = body.get(targetId);

      const dragItemOrder = dragItem.order;
      const dropItemOrder = dropItem.order;

      let newItemState = new Map(body);
      newItemState.set(dragId, { ...body.get(dragId), order: dropItemOrder });
      newItemState.set(targetId, { ...body.get(targetId), order: dragItemOrder });

      setBody(newItemState);
    }
  }

  function getOrder(id, value) {
    let order = maxOrder + 1;

    if (value) {
      setMaxOrder(order);
      return order;
    } else {
      return 0;
    }
  }

  const handleSaveSearchCriteria = useCallback(
    async (criteria) => {
      let tableProps = [];

      let itemArray = Array.from(body.values());

      for (let item of itemArray) {
        if (item.checked) {
          tableProps.push(item);
        }
      }

      const searchCriteriaInput = {
        id: criteria.id,
        name: criteria.name,
        format: PROPERTY_FORMAT,
        criterias: tableProps,
      };

      const { data } = await api.materials.saveSearchCriteria(searchCriteriaInput, appContent);
      if (data.criteria && data.criteria.id) {
        setCurrentId(data.criteria.id);
      }

      getSearchCriteria();
    },
    [api.materials, appContent, getSearchCriteria, body]
  );

  const handleLoad = useCallback(async (criteria) => {
    dismissLoadDialog();

    setCurrentName(criteria.name);
    setCurrentId(criteria.id);

    setLoadedTableProps(criteria.criterias);
  }, []);

  function dismissSaveAsDialog() {
    setIsSaveAsDialogHidden(true);
  }
  function handleSaveAs(name) {
    dismissSaveAsDialog();
    setCurrentName(name);

    let criteria = { name: name, id: null };
    handleSaveSearchCriteria(criteria);
  }

  function dismissSaveDialog() {
    setIsSaveDialogHidden(true);
  }
  function handleSave() {
    dismissSaveDialog();

    let criteria = { name: currentName, id: currentId };
    handleSaveSearchCriteria(criteria);
  }

  function onCheckboxChange(id, value) {
    setBody(new Map(body.set(id, { ...body.get(id), checked: value, order: getOrder(id, value) })));
  }

  function getCheckboxValue(id) {
    return body.get(id).checked;
  }

  let bodyArray = Array.from(body.values());

  function handleSearchString(e, type) {
    let value = e && e.target && e.target.value;
    if (type === 'customize') {
      value ? setSearchStringCustomize(value) : setSearchStringCustomize('');
    } else {
      value ? setSearchStringReorder(value) : setSearchStringReorder('');
    }
  }

  let customizeItems = [];
  let reorderItems = [];

  if (body && bodyArray.length > 0) {
    let filteredItems = bodyArray.filter((property) => {
      return (
        searchStringCustomize === '' ||
        property.name.toLowerCase().includes(searchStringCustomize.toLowerCase()) ||
        property.parentName.toLowerCase().includes(searchStringCustomize.toLowerCase()) ||
        (property.condition && property.condition.toLowerCase().includes(searchStringCustomize.toLowerCase())) ||
        (property.standardName && property.standardName.toLowerCase().includes(searchStringCustomize.toLowerCase()))
      );
    });

    if (filteredItems && filteredItems.length > 0) {
      customizeItems = filteredItems.map((property) => {
        let margin = property.depth * 10;

        if (property.type !== 'Group') {
          if (!collapsedVkeys.includes(property.parentVkey)) {
            return (
              <div
                key={'customizecheckboxwrapper' + getPropertyId(property)}
                style={{ marginLeft: margin + 30 + 'px', padding: '5px' }}
                id={getPropertyId(property)}
              >
                <Checkbox
                  key={'customizecheckbox-' + getPropertyId(property)}
                  checked={getCheckboxValue(getPropertyId(property))}
                  onChange={(event, value) => {
                    onCheckboxChange(getPropertyId(property), value);
                  }}
                  label={
                    property.name +
                    (property.condition && property.condition !== '' ? ', ' + property.condition : '') +
                    (property.standardName ? ' (' + property.standardName + ')' : '')
                  }
                />
              </div>
            );
          } else {
            return null;
          }
        } else {
          return (
            <div className="bodyText" key={'customizetoggler-' + property.id} style={{ marginLeft: margin + 'px' }}>
              <IconButton
                style={{ width: 'auto', height: 'auto' }}
                iconProps={{ iconName: collapsedVkeys.includes(property.id) ? 'RightSmall' : 'DownSmall' }}
                onClick={() => handleToggler(property.id, !collapsedVkeys.includes(property.id))}
                title="Close"
              />
              <button
                className="bodyText collapsibleGroupText"
                style={{ background: 'none', border: 'none', margin: 0, padding: 0, fontWeight: 'bold' }}
                onClick={() => handleToggler(property.id, !collapsedVkeys.includes(property.id))}
              >
                {property.name}
              </button>
            </div>
          );
        }
      });
    } else {
      customizeItems.push(<div>{TextMapping.getUIText(TextMapping.UI_TEXT_NO_COLUMNS_MATCH_FILTER, texts)}</div>);
    }
  }

  if (body && bodyArray.length > 0) {
    let filteredItems = bodyArray
      .filter((item) => {
        return item.checked;
      })
      .filter((property) => {
        return (
          searchStringReorder === '' ||
          property.name.toLowerCase().includes(searchStringReorder.toLowerCase()) ||
          (property.condition && property.condition.toLowerCase().includes(searchStringReorder.toLowerCase())) ||
          (property.standardName && property.standardName.toLowerCase().includes(searchStringReorder.toLowerCase()))
        );
      })
      .sort((a, b) => a.order - b.order);

    if (filteredItems && filteredItems.length > 0) {
      reorderItems = filteredItems.map((property) => (
        <div
          key={'reorder' + getPropertyId(property)}
          draggable={true}
          id={getPropertyId(property)}
          onDragOver={handleDrop}
          onDragStart={handleDrag}
          style={{ padding: '5px' }}
        >
          <FontIcon aria-label="Reorder" iconName="GripperDotsVertical" />{' '}
          {property.name +
            (property.condition && property.condition !== '' ? ', ' + property.condition : '') +
            (property.standardName ? ' (' + property.standardName + ')' : '')}
        </div>
      ));
    } else {
      reorderItems.push(<div key={'reordernoitems'}>{TextMapping.getUIText(TextMapping.UI_TEXT_NO_COLUMNS_MATCH_FILTER, texts)}</div>);
    }
  }

  let checkedPropertiesCount = Array.from(body.values()).filter((i) => i.checked).length;

  const clearSelections = () => {
    let newBody = new Map();
    for (let [key, value] of body.entries()) {
      let copy = Object.assign({}, value, { checked: false });
      newBody.set(key, copy);
    }

    setBody(newBody);
  };

  let opacity = rangeStatus === 'Loading' ? '0.5' : '1.0';

  return (
    <Panel isOpen={!hidden} onDismiss={onDismiss} title={TextMapping.getUIText(TextMapping.UI_TEXT_CUSTOMIZE_TABLE, texts)}>
      <div style={{ height: 'calc(100vh - 135px)', padding: '10px' }}>
        {rangeStatus !== 'SUCCESS' && (
          <div
            style={{
              zIndex: '10',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(255,255,255,.5)',
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <Spinner size={SpinnerSize.large} />
          </div>
        )}
        <div style={{ height: 'calc(100% - 40px)', overflowY: 'hidden', opacity: opacity }}>
          <div
            className="Action"
            style={{
              display: appContent && appContent.user && appContent.user.companyId > 0 ? 'flex' : 'none',
              columnGap: '5px',
              padding: '5px',
              justifyContent: 'right',
            }}
          >
            <DefaultButton
              data-testid="search-form-save"
              //disabled={state.isSaveDisabled}
              iconProps={{ iconName: 'Save' }}
              onClick={() => setIsSaveDialogHidden(false)}
              disabled={currentId == null || currentName === ''}
              text={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE, texts)}
            />
            <DefaultButton
              data-testid="search-form-save-as"
              //disabled={state.isSaveAsDisabled}
              iconProps={{ iconName: 'SaveAs' }}
              onClick={() => setIsSaveAsDialogHidden(false)}
              text={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_AS, texts)}
            />
            <DefaultButton
              data-testid="search-form-load-criteria"
              //disabled={criterias.length === 0}
              iconProps={{ iconName: 'UploadAlt' }}
              onClick={() => setIsLoadDialogHidden(false)}
              text={TextMapping.getUIText(TextMapping.UI_TEXT_LOAD_TABLE_PROFILE_BUTTON, texts)}
            />
          </div>
          <Pivot>
            <PivotItem
              headerText={TextMapping.getUIText(TextMapping.UI_TEXT_CUSTOMIZE_COLUMNS, texts)}
              itemKey="customize"
              style={{ padding: '10px' }}
            >
              {checkedPropertiesCount > 1 && (
                <>
                  <DefaultButton
                    text={TextMapping.getUIText(TextMapping.UI_TEXT_CLEAR_CUSTOMIZE_SELECTIONS, texts, { count: checkedPropertiesCount })}
                    onClick={clearSelections}
                  />
                  <Margin5 />
                </>
              )}
              <SearchBox
                onChange={(e) => handleSearchString(e, 'customize')}
                value={searchStringCustomize}
                placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SEARCH, texts)}
              />
              <Margin5 />
              <div data-testid="customize-columns-dev" style={{ overflowY: 'auto', height: 'calc(100vh - 302px)' }}>
                {customizeItems}
              </div>
            </PivotItem>
            <PivotItem
              headerText={TextMapping.getUIText(TextMapping.UI_TEXT_REORDER_COLUMNS, texts)}
              itemKey="reorder"
              style={{ padding: '10px' }}
            >
              <SearchBox
                onChange={(e) => handleSearchString(e, 'reorder')}
                value={searchStringReorder}
                placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SEARCH, texts)}
              />
              <Margin5 />
              <div className="bodyText" data-testid="reorder-columns-div" style={{ overflowY: 'auto', height: 'calc(100vh - 302px)' }}>
                {reorderItems}
              </div>
            </PivotItem>
          </Pivot>
        </div>
        <div style={{ marginTop: '10px' }}>
          <PrimaryButton
            onClick={() => {
              setLoadedTableProps([]);
              handleOnConfirm(body);
            }}
            text={TextMapping.getUIText(TextMapping.UI_TEXT_APPLY, texts)}
          />
        </div>
      </div>
      <SaveCriteriaAsDialog
        title={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_TABLE_PROPERTY_PROFILE, texts)}
        criteriaNames={tablePropNames}
        hidden={isSaveAsDialogHidden}
        onDismiss={() => setIsSaveAsDialogHidden(true)}
        onSave={(name) => handleSaveAs(name)}
        texts={texts}
      />
      <SaveCriteriaDialog
        title={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_TABLE_PROPERTY_PROFILE, texts)}
        hidden={isSaveDialogHidden}
        onDismiss={() => setIsSaveDialogHidden(true)}
        onSave={handleSave}
        texts={texts}
      />
      <LoadCriteriaDialog
        title={TextMapping.getUIText(TextMapping.UI_TEXT_LOAD_TABLE_PROPERTY_PROFILE, texts)}
        criterias={criterias}
        hidden={isLoadDialogHidden}
        onDelete={handleDelete}
        onDismiss={() => setIsLoadDialogHidden(true)}
        onLoad={handleLoad}
        texts={texts}
      />
    </Panel>
  );
}

export default React.memo(CustomizeTablePanel);
