

const convertSvgToPngAndCopy = async (svgBlobUrl) => {
    try {
        const img = new Image();
        img.src = svgBlobUrl;

        // Wait for the image to load
        await new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
        });

        // Create a canvas element
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Set canvas dimensions to match the SVG image
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the SVG image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Convert the canvas content to a PNG Blob
        const pngblob = await new Promise((resolve) => {
            canvas.toBlob((blob) => resolve(blob), 'image/png');
        });
        await navigator.clipboard.write([
            // eslint-disable-next-line no-undef
            new ClipboardItem({
                [pngblob.type]: pngblob
            })
        ]);
        return { success: true, message: 'Copied successfully' };

    } catch (err) {
        return { success: false, message: err.message };
    }
};

const svgElementToBlobUrl = (svgElement) => {
    var svgString = new XMLSerializer().serializeToString(svgElement);
    var svgBlob = new Blob([svgString], { type: 'image/svg+xml' });
    return URL.createObjectURL(svgBlob);
};

module.exports = {
    convertSvgToPngAndCopy,
    svgElementToBlobUrl
};