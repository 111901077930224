/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { createStore, get, set, del, entries } from 'idb-keyval';

export class CacheStorageService {
  customDBName = 'altairone';
  defaultStorage: IStorageType = 'localStorage';
  customIndexDBStore = createStore(this.customDBName, 'cache-store');
  /**
   * Takes storageType and storageOptions and returns the data as cache
   * @param storageOptions storage type params value should be either 'localStorage' | 'sessionStorage' | 'indexDB' "{ storageType, key }" key is for localStorage, sessionStorage and indexDB.
   */
  async getData(storageOptions){
    const { storageType = this.defaultStorage, key = '' } = storageOptions;

    switch (storageType) {
      case 'localStorage': {
        if (storageOptions?.key) {
          const result = localStorage.getItem(key);
          if (result) return JSON.parse(result);
          return result;
        }
        break;
      }
      case 'sessionStorage': {
        const result = sessionStorage.getItem(key);
        if (result) return JSON.parse(result);
        return result;
      }
      case 'indexDB': {
        return get(key, this.customIndexDBStore);
      }
      default: {
        return Promise.reject(new Error('Error in fetching data, Please specify correct storage type'));
      }
    }
  }
  /**
   * Takes storageType and storageOptions and save the data as cache
   * @param storageOptions "{ storageType, key, data }" Storage type params value should be either 'localStorage' | 'sessionStorage' | 'indexDB'
   */
  async setData(storageOptions) {
    const { storageType = this.defaultStorage, key = '', data = {} } = storageOptions;

    if (typeof data === 'undefined') {
      await this.removeData({ storageType, key });
      return;
    }

    switch (storageType) {
      case 'localStorage': {
        localStorage.setItem(key, JSON.stringify(data));
        break;
      }
      case 'sessionStorage': {
        sessionStorage.setItem(key, JSON.stringify(data));
        break;
      }
      case 'indexDB': {
        set(key, data, this.customIndexDBStore);
        break;
      }
      default: {
        return Promise.reject(new Error('Error in saving data, Please specify correct storage type'));
      }
    }
  }

  /**
   * Takes storageType and storageOptions and delete the data stored as cache
   * @param storageOptions "{ storageType, key, cacheName }" storage type params value should be either 'localStorage' | 'sessionStorage' | 'indexDB'
   */
  async removeData(storageOptions) {
    const { storageType = this.defaultStorage, key = '' } = storageOptions;

    switch (storageType) {
      case 'localStorage': {
        localStorage.removeItem(key);
        break;
      }
      case 'sessionStorage': {
        sessionStorage.removeItem(key);
        break;
      }
      case 'indexDB': {
        return del(key, this.customIndexDBStore);
      }
      default: {
        return Promise.reject(new Error('Error in deleting data, Please specify correct storage type'));
      }
    }
  }

  removeDataOnLogOut() {
    this.removeSessionStorageDataOnLogOut();
    this.removeLocalStorageDataOnLogOut();
    this.removeIndexDBDataOnLogOut();
  }

  removeSessionStorageDataOnLogOut() {
    const sessionStorageItems = { ...sessionStorage };
    for (const [key, value] of Object.entries(sessionStorageItems)) {
      const isJSON = this.isJSON(value);
      if (isJSON) {
        const parsedValue = JSON.parse(value);
        if (parsedValue?.clearOnLogOut === true) {
          this.removeData({ storageType: 'sessionStorage', key });
        }
      }
    }
  }

  removeLocalStorageDataOnLogOut() {
    const localStorageItems = { ...localStorage };
    for (const [key, value] of Object.entries(localStorageItems)) {
      const isJSON = this.isJSON(value);
      if (isJSON) {
        const parsedValue = JSON.parse(value);
        if (parsedValue?.clearOnLogOut === true) {
          this.removeData({ storageType: 'localStorage', key });
        }
      }
    }
  }

  async removeIndexDBDataOnLogOut() {
    for (const [key, value] of await this.getIndexDBEntries()) {
      if (value?.clearOnLogOut === true) {
        this.removeData({ storageType: 'indexDB', key: key });
      }
    }
  }

  async getIndexDBEntries() {
    return entries(this.customIndexDBStore);
  }

  isJSON(str) {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  }
}
