/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import Api from './package';

let currentFrameApi = {};
const childAppApis = new Map();

const createApi = () => {
  return new Api();
};

const createChildAppApi = appName => {
  return childAppApis.set(appName, createApi());
};

const getChildAppApi = appName => {
  return childAppApis.get(appName);
};

const getChildAppApis = () => {
  return childAppApis;
};

const getFrameApi = () => {
  return currentFrameApi;
};

const setChildAppApi = appName => {
  return childAppApis.get(appName);
};

const setFrameApi = api => {
  currentFrameApi = api;
};

const initFrameApi = () => {
  setFrameApi(createApi());
};

initFrameApi();

export { createApi, createChildAppApi, getChildAppApi, getChildAppApis, getFrameApi, setChildAppApi, setFrameApi };

export default getFrameApi();
