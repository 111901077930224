/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { styled } from '@fluentui/utilities';
import { NotificationBellIconBase } from './NotificationBellIcon.base';
import { getStyles } from './NotificationBellIcon.styles';
import { NotificationBellIconProps } from './NotificationBellIcon.types';

export const NotificationBellIcon = styled(NotificationBellIconBase, getStyles, undefined, {
  scope: 'NotificationBellIcon',
});

NotificationBellIcon.propTypes = NotificationBellIconProps;

export default NotificationBellIcon;
