/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { styled } from '@fluentui/utilities';
import { Footer as FooterBase } from './Footer.base';
import { getStyles } from './Footer.styles';

export const Footer = styled(FooterBase, getStyles, undefined, {
  scope: 'Footer',
});
