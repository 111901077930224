/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import clone from 'lodash/clone';
import get from 'lodash/get';
import merge from 'lodash/merge';
import omit from 'lodash/omit';

/*
 * Convert source object to below format, as PropertyTable understands it.
 * {
 *   key: {
 *     type: "",
 *     value: ""
 *   }
 * }
 *
 * */

function convert({ key, source = {} }) {
  const sourceValue = source || '';
  if (Array.isArray(sourceValue)) {
    return sourceValue.reduce((accumulator, currentValue, index) => {
      accumulator[index] = convert({ source: currentValue });
      return accumulator;
    }, {});
  }
  if (typeof sourceValue === 'object') {
    const target = {};
    Object.entries(sourceValue).forEach(([_key, value]) => {
      target[_key] = convert({ key, source: value });
    });
    return target;
  }
  return {
    isReadOnly: true,
    type: 'string',
    value: sourceValue,
  };
}

function mergeArrayEntityWithSchema(array, schema) {
  const { isReadOnly, items, sourcePath } = schema;

  return array.reduce((accumulator, currentValue, index) => {
    if (items.type === 'object') {
      accumulator[index] = mergeEntityWithSchema(currentValue, { ...items, isReadOnly, sourcePath: `${sourcePath}.[${index}]` });
    } else {
      accumulator[index] = {
        isReadOnly: true,
        sourcePath: `${sourcePath}.[${index}]`,
        type: 'string',
        value: currentValue,
      };
    }
    return accumulator;
  }, {});
}

function mergeEntityWithSchema(entity, schema) {
  const newSchema = clone(schema);

  let newEntity = { ...entity };

  Object.entries(newSchema).forEach(([key, value]) => {
    if (!value || typeof value !== 'object') return;

    const { sourcePath, type } = value;

    if (type !== 'array') {
      if (sourcePath) {
        value.value = get(newEntity, sourcePath) || '';
        newEntity = omit(newEntity, [sourcePath]);
      }
      const { result, trimmedEntity } = mergeEntityWithSchema(newEntity, value);

      newEntity = trimmedEntity;
      newSchema[key] = result;
    } else {
      newSchema[key] = {
        __settings__: value.__settings__,
        ...mergeArrayEntityWithSchema(get(newEntity, sourcePath) || [], value),
      };
      newEntity = omit(newEntity, [sourcePath]);
    }
  });

  Object.entries(newEntity).forEach(([key, value]) => {
    if (typeof value === 'object' && Object.keys(value).length === 0) {
      delete newEntity[key];
    }
  });

  Object.entries(newSchema).forEach(([key, value]) => {
    if (typeof value === 'object' && Object.keys(value).length === 1 && Object.prototype.hasOwnProperty.call(value, '__settings__')) {
      delete newSchema[key];
    }
  });

  return { result: newSchema, trimmedEntity: newEntity };
}

const createData = ({ entity, schema }) => {
  const path = entity.path.startsWith('//') ? entity.path.substring(2) : entity.path;
  const originalEntity = {
    system: entity.system,
    attributes: entity.attributes,
    contentType: entity.contentType,
    path,
  };

  const { result, trimmedEntity } = mergeEntityWithSchema(originalEntity, schema);

  delete trimmedEntity.system;

  const dormatEntity = convert({ source: trimmedEntity });

  return merge(result, dormatEntity);
};

export default createData;
