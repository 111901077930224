/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, DialogFooter, PrimaryButton } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';
import BaseDialog from '../BaseDialog';
import * as TextMapping from '../../../../../utils/textMapping';

const NOOP = () => {};

function SaveCriteria({ hidden = true, title = null, message = null, onDismiss = NOOP, onSave = NOOP, texts }) {
  return (
    <BaseDialog
      data-testid="SaveCriteriaDialog"
      dialogContentProps={{ styles: { innerContent: { fontSize: 11, paddingLeft: 20, width: 'auto' } } }}
      hidden={hidden}
      onDismiss={onDismiss}
      title={title ? title : TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_SEARCH_CRITERIA, texts)}
    >
      {message ? message : TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_CRITERIA_MESSAGE, texts)}
      <DialogFooter>
        <PrimaryButton data-testid="save-criteria-save" onClick={onSave} text={TextMapping.getUIText(TextMapping.UI_TEXT_SAVE, texts)} />
        <DefaultButton
          data-testid="save-criteria-do-not-save"
          onClick={onDismiss}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_DONT_SAVE, texts)}
        />
      </DialogFooter>
    </BaseDialog>
  );
}

SaveCriteria.propTypes = {
  hidden: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSave: PropTypes.func,
};

export default SaveCriteria;
