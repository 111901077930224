import React, { useCallback } from 'react';
import ImpactAssessmentTextField from '../ImpactAssessmentTextField';
import { IconButton } from '@fluentui/react';
import { Dropdown } from '@fluentui/react';
import * as TextMapping from '../../../utils/textMapping';
import { styled } from '@fluentui/utilities';

function ImpactAssessmentRowBase({
  type,
  data,
  name,
  getMapValue,
  onValueChange,
  canRemove = false,
  removeRow,
  uuid,
  dropdownOptions = null,
  onDropdownChange = null,
  texts,
  theme,
}) {
  let removeComponent = null;

  const onChange = useCallback(
    (e, value) => {
      onDropdownChange(e, { ...value, uuid: uuid });
    },
    [uuid, onDropdownChange]
  );

  const remove = useCallback(() => {
    removeRow(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  if (canRemove) {
    removeComponent = <IconButton iconProps={{ iconName: 'StatusCircleErrorX' }} onClick={remove} />;
  }

  let backgroundColor = theme?.semanticColors?.impactAssessmentRowBackground
    ? theme.semanticColors.impactAssessmentRowBackground
    : 'rgb(243, 244, 246)';

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        gap: '5px',
        background: backgroundColor,
        borderRadius: '5px',
        margin: '5px',
      }}
    >
      <div style={{ width: '40%', display: 'flex', alignItems: 'center' }}>
        {removeComponent}
        {dropdownOptions ? (
          <Dropdown
            key={'dropdown-' + name}
            options={dropdownOptions}
            placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_TYPE, texts, { type: name })}
            styles={{ root: { width: '100%', maxWidth: 'calc(100% - 32px)' } }}
            dropdownWidth="auto"
            onChange={onChange}
            selectedKey={data && data.type ? data.type : null}
          />
        ) : (
          data.name
        )}
      </div>
      <div style={{ width: '20%' }}>
        {
          <ImpactAssessmentTextField
            key={name + '-' + type + '-percentage'}
            type={type}
            getMapValue={getMapValue}
            onValueChange={onValueChange}
            inputType="percentage"
          />
        }
      </div>
      <div style={{ width: '20%' }}>
        <ImpactAssessmentTextField
          key={name + '-' + type + '-gwp'}
          type={type}
          getMapValue={getMapValue}
          onValueChange={onValueChange}
          inputType="gwp"
        />
      </div>
      <div style={{ width: '20%' }}>
        <ImpactAssessmentTextField
          key={name + '-' + type + '-co2'}
          type={type}
          getMapValue={getMapValue}
          onValueChange={onValueChange}
          inputType="co2"
        />
      </div>
    </div>
  );
}

const ImpactAssessmentRow = styled(ImpactAssessmentRowBase, () => {}, undefined, { scope: 'ImpactAssessmentRowBase' });

export default ImpactAssessmentRow;
