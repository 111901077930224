/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { CacheStorageService } from './cache-storage-service.js';

export class CacheApi {
  cacheService;
  constructor() {
    this.cacheService = new CacheStorageService();
  }
}

export const cacheService = new CacheApi();
