/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import platformPropTypes from '@altair/prop-types';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ContentTypeIcon from './ContentTypeIcon';

export function MaterialIcon(props) {
  const { entity, width } = props;

  /* Used to render extension icons when the content image can't be loaded. */
  const [forceContentTypeIcon, setForceContentTypeIcon] = useState(false);

  useEffect(() => {
    setForceContentTypeIcon(false);
  }, [entity]);

  if (forceContentTypeIcon || !entity.type || !entity.type.icon) {
    return <ContentTypeIcon entity={entity} width={width} />;
  }

  const { name } = entity.attributes;
  // const height = (width * 18.5) / 13.5;
  const height = width;
  const entityIcon = `/assets/path/a365/content-packs/materialdb/data/assets${entity.type.icon}`;

  return (
    <img
      data-testid={`MaterialIcon-${name}`}
      src={entityIcon}
      onError={() => {
        setForceContentTypeIcon(true);
      }}
      style={{ width: `${width}px`, height: `${height}px` }}
      alt="icon"
    />
  );
}

MaterialIcon.propTypes = {
  entity: platformPropTypes.entity.isRequired,
  width: PropTypes.number.isRequired,
};

export default MaterialIcon;
