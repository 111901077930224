/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

let units;
let unitsystems;

async function getByIdAndContentType({ contentType, id }) {
  const url = `/content/contents/${id}`;
  const { data } = await axios.get(url, { params: { contentType } });
  return data;
}

async function getByIdsAndContentType({ contentType, ids }) {
  const url = '/content/contents/bulk';

  const payload = {
    [contentType]: ids,
  };

  const { data } = await axios.get(url, { data: payload, headers: { 'Content-Type': 'application/json' } });
  return data[contentType];
}

async function getContentDefinitions({ contentDefinitions = [], merged = true }) {
  let url = `/content/content-definitions/?merged=${merged}`;
  if (contentDefinitions.length) {
    url = `${url}&contentDefinitions=${contentDefinitions.length && contentDefinitions.join()}`;
  }
  const { data } = await axios.get(url);
  return data;
}

async function getContentDefinition({ name, merged = false }) {
  const url = `/content/content-definitions/${encodeURIComponent(name)}?merged=${merged}`;
  const { data } = await axios.get(url);
  return data;
}

async function getDerivedContentDefinitions({ contentDefinitions = [], merged = false }) {
  const url = `/content/content-definitions/derived?contentDefinitions=${encodeURIComponent(contentDefinitions.join())}&merged=${merged}`;
  const { data } = await axios.get(url);
  return data;
}

async function getDetails({ id, contentType, mapping }) {
  const url = `/content/contents/${id}/details?contentType=${encodeURIComponent(contentType)}`;
  const { data } = await axios.post(url, mapping);
  return data;
}

async function createContent({ contentType, payload }) {
  const url = `/content/contents?contentType=${encodeURIComponent(contentType)}`;
  return axios.post(url, payload);
}

async function updateContent({ id, contentType, payload }) {
  const url = `/content/contents/${id}?contentType=${encodeURIComponent(contentType)}`;
  return axios.put(url, payload);
}

async function graphImport({ payload, validate = false, accept = 'application/json-graph' }) {
  const url = `/content/contents/graph/import?validate=${validate}`;
  return axios.post(url, payload, { headers: { Accept: accept } });
}

async function validate({ payload }) {
  const url = `/content/contents/graph/validate`;
  return axios.post(url, payload);
}

async function updateContentUnitSystem({ id, contentType, unitSystemName }) {
  const url = `/content/contents/${id}/unitsystem?contentType=${contentType}&unitSystemName=${unitSystemName}`;
  const { data } = await axios.patch(url);
  return data;
}

async function getUnitSystems(fromCache = true) {
  if (unitsystems && unitsystems.length && fromCache) return unitsystems;

  const url = '/search/search?isLegacy=false';
  const { data } = await axios.post(url, {
    payload: {
      filter: {
        $eq: ['system.contentType', '@UnitSystem'],
      },
      bindVars: { UnitSystem: 'UnitSystem' },
    },
  });
  unitsystems = data?.entities;
  return unitsystems;
}

async function getUnits(fromCache = true) {
  if (units && units.length && fromCache) return units;

  const url = '/search/search?isLegacy=false';
  const { data } = await axios.post(url, {
    payload: {
      filter: {
        $is: ['@contentTypes'],
      },
      bindVars: { contentTypes: ['Unit'] },
    },
  });
  units = data?.entities;
  return units;
}

export const getPermissions = async ({ ids }) => {
  const response = await fetch(`/content/contents/acl/permissions?ids=${ids}`);
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
};

export default {
  createContent,
  getByIdAndContentType,
  getByIdsAndContentType,
  getContentDefinitions,
  getContentDefinition,
  getDerivedContentDefinitions,
  getDetails,
  graphImport,
  updateContent,
  validate,
  updateContentUnitSystem,
  getUnitSystems,
  getUnits,
  getPermissions,
};
