/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withTopbar } from '../../../topbar/main';
import useOktaSSO, { errorHtml } from './hooks/useOktaSSO';
import LoadingScreen from '../LoadingScreen';
import Login from './Login';
import { EulaModal } from '../EulaModal';

function OktaLogin({ config, loginWithAccessToken, redirectUri, setUserProfile, skipToOkta }) {
  const { isEulaModalOpen, isLoggingIn, loginFailedMessage, onAcceptEula, redirectToOktaLogin, status } = useOktaSSO({
    loginWithAccessToken,
    redirectUri,
    setUserProfile,
    skipToOkta,
  });

  const { status: loginStatus } = useSelector((state) => state.loggedInUser);

  if (loginStatus === 'LOGIN_FAILED') {
    return <LoadingScreen isLoading={false} message={errorHtml} />;
  }

  if (!['AUTO_LOGGED_IN', 'ENTER_CREDENTIAL', 'GUEST_LOGGED_IN'].includes(status)) {
    return (
      <>
        <LoadingScreen isLoading={!loginFailedMessage} message={loginFailedMessage} />
        <EulaModal isOpen={isEulaModalOpen} onAccept={onAcceptEula} />
      </>
    );
  }

  if (status === 'AUTO_LOGGED_IN' && !redirectUri?.startsWith('/login')) {
    window.location.href = redirectUri ? redirectUri : '/';
    return <LoadingScreen isLoading />;
  }
  if (status === 'ENTER_CREDENTIAL') {
    return <Login isLoggingIn={isLoggingIn} onSubmit={redirectToOktaLogin} registerLink={config?.registerUri} />;
  }
  return null;
}

OktaLogin.propTypes = {
  config: PropTypes.shape({
    registerUri: PropTypes.string,
  }),
  loginWithAccessToken: PropTypes.func,
  redirectUri: PropTypes.string,
  setUserProfile: PropTypes.func,
  skipToOkta: PropTypes.bool,
};

export default withTopbar(OktaLogin);
