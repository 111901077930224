import React, { useCallback } from 'react';
import { Checkbox } from '@fluentui/react';

const NOOP = () => {};

function CompareCheckbox({
  name,
  type,
  diagramKey,
  getComponentKey,
  getCheckboxValue,
  onCheckboxChange,
  materialName = null,
  onDoubleClick = NOOP,
  label = null,
}) {
  const onChange = useCallback(
    (_event, value) => {
      onCheckboxChange(name, value, diagramKey, materialName);
    },
    [onCheckboxChange, name, materialName, diagramKey]
  );

  const doubleClick = useCallback(
    _event => {
      if (materialName) {
        onDoubleClick(name);
      }
    },
    [onDoubleClick, materialName, name]
  );

  return (
    <button style={{ background: 'none', border: 'none', margin: 0, padding: 0 }} onDoubleClick={doubleClick}>
      <Checkbox
        onDoubleClick={doubleClick}
        key={getComponentKey(name, type)}
        label={label ? label : name.toString()}
        checked={getCheckboxValue(name, diagramKey)}
        onChange={onChange}
      />
    </button>
  );
}

export default CompareCheckbox;
