/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { useCallback, useEffect, useRef, useState } from 'react';
import Poller from './poller';

export function usePoller(callback, interval, pollOnMount = false) {
  const [poller, setPoller] = useState(new Poller(callback, interval));
  const prevIntervalRef = useRef(interval);
  const prevCallbackRef = useRef(callback);

  useEffect(() => {
    if (pollOnMount) {
      poller.poll();
    }
    return () => poller.clear();
  }, [poller, pollOnMount]);

  useEffect(() => {
    if (prevIntervalRef.current !== interval || prevCallbackRef.current !== callback) {
      const nextPoller = new Poller(callback, interval);
      setPoller(nextPoller);
      nextPoller.poll();
    }
  }, [callback, interval]);

  const poll = useCallback(() => {
    poller.poll();
  }, [poller]);

  const stop = useCallback(() => {
    poller.clear();
  }, [poller]);

  return { poll, stop };
}
