/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
/*
TODO:
  - Put buttons in a CommandMenu.
*/

import PropTypes from 'prop-types';
import React from 'react';
import ChangeViewGroup from './ChangeViewGroup';
import ExpandButton from './ExpandButton';
// import OpenEditorButton from './OpenEditorButton';
import { Title, Wrap } from './styled';

// import EditWithSubActionsButton from './EditWithSubActionsButton';

function Controls(props) {
  const { activeViewerName, className, entity, isViewerExpanded, onItemClick, viewers } = props;

  const { name } = entity.attributes;

  return (
    <Wrap data-testid="controls" className={className}>
      {isViewerExpanded && <Title>{name}</Title>}

      <ExpandButton data-testid="ViewControls-ExpandButton" isViewerExpanded={isViewerExpanded} onItemClick={onItemClick} />

      <ChangeViewGroup
        data-testid="ViewControls-ChangeViewGroupButton"
        activeViewerName={activeViewerName}
        onItemClick={onItemClick}
        viewers={viewers}
      />
    </Wrap>
  );
}

Controls.propTypes = {
  activeViewerName: PropTypes.string,
  className: PropTypes.string,
  entity: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
    system: PropTypes.shape({
      contentType: PropTypes.string,
    }),
  }),
  isViewerExpanded: PropTypes.bool,
  onItemClick: PropTypes.func,
  viewers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
};

Controls.defaultProps = {
  entity: null,
  isViewerExpanded: false,
  onItemClick: () => {},
};

export default Controls;
