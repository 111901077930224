/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import * as React from 'react';

export const DEFAULT_APP_FRAME_CONTEXT = {
  appVersion: undefined,
  config: null,
  texts: null,
  appContent: null,
  footerHidden: true,
  notificationEnabled: true,
  panelPortalNode: document.body,
  onlineHelpUrl: undefined,
  setConfig: () => {},
  setFooterHidden: () => {},
  setTopBarHidden: () => {},
  setPanelPortalNode: () => {},
  setOnlineHelpUrl: () => {},
  setTexts: () => {},
  setAppContent: () => {},
  wordmark: null,
  setWordmark: () => {},
  waffleMenuProps: {},
  setWaffleMenuProps: () => {},
  setNotifications: () => {},
};

export const AppFrameContext = React.createContext(DEFAULT_APP_FRAME_CONTEXT);
