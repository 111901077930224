/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Icon } from '@fluentui/react';
import React from 'react';
import PropTypes from 'prop-types';
import Spin from './Spin';

function Loading(props) {
  const { message = 'Loading', color } = props;

  return (
    <div style={{ fontSize: '1.5em', margin: 'auto', textAlign: 'center', color: color }}>
      <Spin>
        <Icon iconName="ProgressRingDots" style={{ fontSize: '3em', color: color }} />
      </Spin>

      <div style={{ marginTop: '20px' }}>{message}</div>
    </div>
  );
}

Loading.propTypes = {
  message: PropTypes.string,
};

export default Loading;
