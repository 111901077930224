/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from '@fluentui/react/lib/Checkbox';

const NOOP = () => {};

function ViewContainer({ isChecked, onChange, styles, typeIcon, entity, onOpenApplication = NOOP }) {
  function handleChange(e, newValue) {
    onChange({ entity, newValue });
  }

  if (typeIcon) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
        <Checkbox checked={isChecked} onChange={handleChange} />
        <img height="100px" width="100px" style={{ margin: 'auto', display: 'block' }} src={`/mmdc/${typeIcon}`} alt="preview" />
        {entity.name}
      </div>
    );
  } else {
    return null;
  }
}

ViewContainer.propTypes = {
  api: PropTypes.object,
  collectionName: PropTypes.string,
  contentType: PropTypes.string,
  id: PropTypes.string,
  onOpenApplication: PropTypes.func,
};

export default ViewContainer;
