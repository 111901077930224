/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import checkObjectHasKeys from './checkObjectHasKeys';

const getColorFormat = color => {
  if (Array.isArray(color) && [3, 4].includes(color.length)) {
    return 'array';
  }

  if (typeof color === 'string' && color.startsWith('#') && [4, 7].includes(color.length)) {
    return 'hex';
  }

  if (typeof color === 'object' && checkObjectHasKeys(color, ['r', 'g', 'b'])) {
    return 'rgb';
  }

  throw new Error('ColorPicker.props.color format is not valid');
};

export default getColorFormat;
