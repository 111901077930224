/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { CDefCache } from '@altair/ui-cache';
import defaultSchema from '../data/defaultSchema.json';
import jsonSchemaToNativeSchame from './jsonSchemaToNativeSchame';

function createDefaultSchema(entity) {
  let newDefaultSchema = cloneDeep(defaultSchema);
  const { jsonSchema } = entity;
  if (jsonSchema) {
    const nativeSchame = jsonSchemaToNativeSchame(jsonSchema);

    if (Object.prototype.hasOwnProperty.call(nativeSchame, 'attributes')) {
      delete nativeSchame.attributes.name;
      delete nativeSchame.attributes.size;

      if (
        Object.keys(nativeSchame.attributes).length === 1 &&
        Object.prototype.hasOwnProperty.call(nativeSchame.attributes, '__settings__')
      ) {
        delete nativeSchame.attributes;
      }
    }

    newDefaultSchema = {
      ...newDefaultSchema,
      ...nativeSchame,
    };
  }

  const collectionName = get(entity, 'system.collectionName');
  const contentTypes = (CDefCache.getDerivedCDefs({ cDefNames: [collectionName], asList: true }) ?? {})[collectionName] || [];

  const options = contentTypes.map(contentType => contentType.definition.name);
  options.unshift(collectionName);
  newDefaultSchema.info.contentType.selectOptions = options;

  const isReadonly = get(entity, 'system.readonly', false);

  newDefaultSchema.info.name.isReadOnly = isReadonly;
  newDefaultSchema.info.contentType.isReadOnly = isReadonly;

  return newDefaultSchema;
}

export { createDefaultSchema };
