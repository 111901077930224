/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export const AuthEventType = {
  Login: 'Login',
  Logout: 'Logout',
};

class AuthApi {
  _authEventHandlers = {
    [AuthEventType.Login]: [],
    [AuthEventType.Logout]: [],
  };

  constructor() {
    this.loggedIn = false;
  }

  on(type, cb) {
    const handlers = this._authEventHandlers[type];

    if (!Array.isArray(handlers)) {
      return () => {};
    }

    handlers.push(cb);

    return () => {
      const index = handlers.indexOf(cb);
      if (index !== -1) {
        handlers.splice(index, 1);
      }
    };
  }

  off(type, cb) {
    const handlers = this._authEventHandlers[type];
    if (Array.isArray(handlers)) {
      const index = handlers.indexOf(cb);
      if (index !== -1) {
        handlers.splice(index, 1);
      }
    }
  }

  async executeHandlers(type, args = {}) {
    const handlers = this._authEventHandlers[type];
    if (Array.isArray(handlers)) {
      await Promise.allSettled(handlers.map(cb => cb(args)));
    }
  }
}

export const authApi = new AuthApi();
