/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${props => (props.position === 'horizontal' ? `repeat( ${props.length}, 1fr);` : `1fr`)};
  grid-gap: 16px;
  width: ${props => props.width};
  margin-left: auto;
  margin-right: auto;
`;

const Cell = styled.div`
  max-height: ${props => props.maxHeight};
  border: 1px solid #d4d2d0;
  border-radius: 4px;
  overflow: auto;
  padding: 14px;
`;

const Content = styled.div`
  margin-top: 10px;
  ${({ isOpen }) =>
    !isOpen &&
    `
        display: none;
      `}
`;

const Title = styled.div`
  font-family: 'Noto Sans';
  font-size: 16px;
`;

export { Cell, Content, Grid, Title };
