/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
const loggedInUser = {
  entity: null,
  id: null,
  username: null,
  name: null,
  status: null,
};

const panelId = null;

export default {
  loggedInUser,
  panelId,
};
