/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import gql from 'graphql-tag';

export const GET_APPS = gql`
  query GetApplicationByAppId($appId: String!) {
    application(appId: $appId) {
      id
      attributes
      name
    }
  }
`;
