/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { styled } from '@fluentui/utilities';
import { EulaModal as BaseEulaModal } from './EulaModal.base';
import { getStyles } from './EulaModal.styles';
import { EulaModalProps } from './EulaModal.types';

const EulaModal = styled(BaseEulaModal, getStyles, undefined, {
  scope: 'EulaModal',
});

EulaModal.propTypes = EulaModalProps;

export { EulaModal };
