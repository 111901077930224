/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useMemo } from 'react';
import { IconButton } from '@fluentui/react';
import PropTypes from 'prop-types';

const buttonStyle = {
  root: { height: 30, width: 30, backgroundColor: '#FFFFFF', borderRadius: '50%', boxShadow: '0 1px 4px 0 rgba(0,0,0,0.24)' },
  rootHovered: {
    backgroundColor: '#FFFFFF',
  },
  rootPressed: {
    backgroundColor: '#FFFFFF',
  },
};

function RoundIconButton({ iconProps: propsIconProps, ...restProps }) {
  const iconProps = useMemo(() => {
    return {
      ...propsIconProps,
      styles: { root: { fontSize: 16, height: 16, lineHeight: 16, color: '#000000' } },
    };
  }, [propsIconProps]);

  return <IconButton styles={buttonStyle} iconProps={iconProps} {...restProps} />;
}

RoundIconButton.propTypes = {
  iconProps: PropTypes.shape({}),
};

export default RoundIconButton;
