/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  > .Label {
    font-size: 11px;
    color: #1d2e38;
  }
  > .Input {
    flex: 1 0 auto;
    margin: 0px 16px;
  }
`;

export { Root };
