/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import React, { useCallback } from 'react';
import { loginWithToken } from '../api/auth';
import { TopbarValues } from './TopbarValues';

export const withTopbar = (WrappedComponent) => (props) => {
  const setUserProfile = useCallback((userProfile) => {
    const nextUserprofile = { ...userProfile };
    const username = nextUserprofile?.email;
    const role = nextUserprofile?.role;
    const name = nextUserprofile?.name?.fullName;
    let userDisplayRole = null;

    if (role === 'owner') {
      userDisplayRole = 'Account Owner';
    } else if (role === 'admin') {
      userDisplayRole = 'Account Administrator';
    }

    nextUserprofile.name = name;
    nextUserprofile.username = username;
    nextUserprofile.userDisplayRole = userDisplayRole;

    nextUserprofile.company = {
      name: userProfile?.companyName,
      id: userProfile?.companyId,
    };

    const nextTopbarValues = {
      ...TopbarValues.getValues(),
      userProfile: nextUserprofile,
    };

    TopbarValues.setValues(nextTopbarValues);

    return nextUserprofile;
  }, []);

  const loginWithAccessToken = useCallback(
    async (accessToken, refreshToken = null) => {
      const { altairOneBaseUrl } = TopbarValues.getValues();

      if (altairOneBaseUrl === null || altairOneBaseUrl === undefined) {
        throw new Error('altairOneBaseUrl is required by loginWithAccessToken. Set the value in <Topbar /> component.');
      }

      const userProfile = await loginWithToken({ altairOneBaseUrl, accessToken, refreshToken });

      return setUserProfile(userProfile);
    },
    [setUserProfile]
  );

  const setAltairAdminBaseUrl = useCallback((altairAdminBaseUrl) => {
    TopbarValues.setValues({ ...TopbarValues.getValues(), altairAdminBaseUrl });
  }, []);

  const setAltairOneBaseUrl = useCallback((altairOneBaseUrl) => {
    TopbarValues.setValues({ ...TopbarValues.getValues(), altairOneBaseUrl });
  }, []);

  const setWaffleMenuPanelProps = useCallback(({ dashboardButtonProps, homeButtonProps, items } = {}) => {
    const prevValues = TopbarValues.getValues();
    const nextValues = {
      ...prevValues,
      waffleMenuProps: {
        ...prevValues?.waffleMenuProps,
      },
    };

    if (dashboardButtonProps) {
      nextValues.waffleMenuProps.dashboardButtonProps = { ...nextValues.waffleMenuProps.dashboardButtonProps, ...dashboardButtonProps };
    }

    if (homeButtonProps) {
      nextValues.waffleMenuProps.homeButtonProps = { ...nextValues.waffleMenuProps.homeButtonProps, ...homeButtonProps };
    }

    if (items) {
      nextValues.waffleMenuProps.items = { ...nextValues.waffleMenuProps.items, ...items };
    }

    TopbarValues.setValues(nextValues);
  }, []);

  const setTopbarProps = useCallback(({ controlProps, signinButtonProps, signupButtonProps } = {}) => {
    const prevValues = TopbarValues.getValues();

    const nextValues = {
      ...prevValues,
      topbarProps: {
        ...prevValues?.topbarProps,
      },
    };

    if (controlProps) {
      nextValues.topbarProps.controlProps = { ...nextValues.topbarProps.controlProps, ...controlProps };
    }

    if (signinButtonProps) {
      nextValues.topbarProps.signinButtonProps = { ...nextValues.topbarProps.signinButtonProps, ...signinButtonProps };
    }

    if (signupButtonProps) {
      nextValues.topbarProps.signupButtonProps = { ...nextValues.topbarProps.signupButtonProps, ...signupButtonProps };
    }

    TopbarValues.setValues(nextValues);
  }, []);

  const setAccountPanelProps = useCallback(({ onAccountSettingsClick, onLogoutClick, texts } = {}) => {
    const prevValues = TopbarValues.getValues();

    const nextValues = {
      ...prevValues,
      accountPanelProps: {
        ...prevValues?.accountPanelProps,
      },
    };

    if (typeof onAccountSettingsClick === 'function') {
      nextValues.accountPanelProps.onAccountSettingsClick = onAccountSettingsClick;
    }

    if (typeof onLogoutClick === 'function') {
      nextValues.accountPanelProps.onLogoutClick = onLogoutClick;
    }

    nextValues.accountPanelProps.texts = texts;

    TopbarValues.setValues(nextValues);
  }, []);

  const setActivePanelId = useCallback((panelId = null) => {
    TopbarValues.setValues({ ...TopbarValues.getValues(), activePanelId: panelId });
  }, []);

  return (
    <WrappedComponent
      {...props}
      loginWithAccessToken={loginWithAccessToken}
      setAccountPanelProps={setAccountPanelProps}
      setAltairAdminBaseUrl={setAltairAdminBaseUrl}
      setAltairOneBaseUrl={setAltairOneBaseUrl}
      setUserProfile={setUserProfile}
      setTopbarProps={setTopbarProps}
      setWaffleMenuPanelProps={setWaffleMenuPanelProps}
      setActivePanelId={setActivePanelId}
    />
  );
};
