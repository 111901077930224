/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export function getStyles(props) {
  const { isMobile } = props;

  return {
    root: [
      {
        position: 'relative',
        width: '100%',
        height: '100%',
      },
      !isMobile && {
        width: 192,
        borderRight: '1px solid #d2d5d7',
      },
    ],
    subComponentStyles: {
      scrollablePane: {
        contentContainer: {
          padding: 4,
          selectors: {
            '> .RootNavContainer': {
              margin: 'auto -4px',
              paddingTop: 4,
            },
          },
        },
      },
      panel: {
        scrollableContent: {
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 auto',
        },
        content: {
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 auto',
          padding: 0,
        },
      },
    },
  };
}
