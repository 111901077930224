/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export function imageFromString(string) {
  const canvas = document.createElement('canvas');
  canvas.id = 'captcha';
  canvas.width = 108;
  canvas.height = 40;
  const ctx = canvas.getContext('2d');
  ctx.font = '25px Georgia';
  ctx.strokeText(string, 0, 30);

  return canvas.toDataURL();
}
