import React, { useMemo, useCallback } from 'react';
import { ComboBox } from '@fluentui/react';

function AdvancedSearchSpecialValue({ child, type, getComponentKey, triggerSearch, getPropValue, onValueChange }) {
  const options = useMemo(() => {
    let newOptions = [];
    newOptions.push({ key: '', text: '' });
    for (let name of child.catalog.specialvalueset.names) {
      newOptions.push({ key: name, text: name });
    }

    for (let limit of ['min', 'max']) {
      let storedValue = getPropValue(child, limit);

      if (storedValue) {
        // Check for freeform input and add to list
        let foundOption = false;
        for (let option of newOptions) {
          if (option.key === storedValue) {
            foundOption = true;
            break;
          }
        }

        if (!foundOption) {
          newOptions.push({ key: storedValue, text: storedValue });
        }
      }
    }

    return newOptions;
  }, [child, getPropValue]);

  const onChange = useCallback(
    (_event, option, _index, value) => {
      let newValue = null;
      if (option && option.key) {
        newValue = option.key;
      }
      if (!option && value) {
        newValue = value;
      }

      onValueChange(child, type, newValue);
    },
    [child, onValueChange, type]
  );

  const onBlur = useCallback(() => {
    triggerSearch(child, type);
  }, [child, triggerSearch, type]);

  return (
    <ComboBox
      key={getComponentKey(child, type)}
      allowFreeform={!child.catalog.specialvalueset.specialonly}
      options={options}
      onBlur={onBlur}
      selectedKey={getPropValue(child, type)}
      onChange={onChange}
    />
  );
}

export default AdvancedSearchSpecialValue;
