/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { mergeStyles } from '@fluentui/style-utilities';
import { IconButton } from '@fluentui/react';

import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import get from 'lodash/get';
import bytesToSize from '../../../utils/bytesToSize';
import Cell from './Cell';
import Indents from './Indents';
import Toggler from './Toggler';
import Value from './Value';

function Data(props) {
  const {
    collapsedPathKeys,
    data,
    depth = 0,
    invalidDataItems,
    isCollapsed = false,
    onBlur,
    onChange,
    onToggle,
    parentPathKeys = [],
    styles,
    typeRenderers,
    handleLink,
  } = props;

  const cellLabelClassName = useMemo(() => {
    return mergeStyles([
      {
        lineHeight: 24,
      },
      {
        ...styles.cellLabel,
      },
    ]);
  }, [styles]);

  /* Prevent infinite recursion -- just in case. */
  if (depth > 20) return null;

  function getIsCollapsed(pathKeys) {
    return collapsedPathKeys.some(keys => {
      const relevantPathKeys = pathKeys.slice(0, keys.length);

      return isEqual(keys, relevantPathKeys);
    });
  }

  if (!data) return null;

  const newData = Object.entries(data)
    .filter(([key]) => key !== '__settings__')
    .map(([key, dataItem]) => {
      const nextDataItem = cloneDeep(dataItem);
      const settings = nextDataItem.__settings__ || {};
      const title = nextDataItem.title || settings.title || key;
      const isLeaf = has(nextDataItem, 'value') || has(nextDataItem, 'values');
      const hasChildren = isLeaf ? false : typeof nextDataItem === 'object';
      const pathKeys = [...parentPathKeys, key];
      const areChildrenCollapsed = hasChildren ? getIsCollapsed(pathKeys) : null;

      if (nextDataItem.title === 'Size') {
        if (typeof nextDataItem.value === 'number') {
          nextDataItem.value = bytesToSize(nextDataItem.value);
        }
      }

      if (nextDataItem.value === '') {
        return '';
      }

      const labelCellStyles = {
        ...styles,
        cellRoot: {
          ...styles.cellRoot,
          display: nextDataItem.type === 'table' ? 'none' : '',
        },
      };

      const valueCellStyles = {
        ...styles,
        cellRoot: {
          ...styles.cellRoot,
          gridColumn: nextDataItem.type === 'table' ? '1 / span 2' : '',
          paddingLeft: nextDataItem.type === 'table' && isLeaf ? 28 : '',
          paddingBottom: nextDataItem.type === 'table' && isLeaf ? 12 : '',
        },
      };

      let plotLink = '';
      if (dataItem.type === 'plot') {
        plotLink = (
          <IconButton
            onClick={() => {
              handleLink(dataItem.plotEntity, dataItem.plotFileId);
            }}
            iconProps={{ iconName: 'AreaChart' }}
            title="Plot"
            ariaLabel="Plot"
          />
        );
      }

      return (
        <Fragment key={key}>
          <Cell isCollapsed={isCollapsed} styles={labelCellStyles}>
            <Indents count={depth} />

            <Toggler
              data={data}
              depth={depth}
              isCollapsed={isCollapsed || areChildrenCollapsed || get(data, '__settings__.collapse')}
              isLeaf={isLeaf}
              onToggle={onToggle}
              pathKeys={pathKeys}
              styles={styles}
            />

            <span className={cellLabelClassName}>{title}</span>
            {plotLink}
          </Cell>

          <Cell isCollapsed={isCollapsed} styles={valueCellStyles}>
            <Value
              dataItem={nextDataItem}
              invalidDataItems={invalidDataItems}
              onBlur={onBlur}
              onChange={onChange}
              pathKeys={pathKeys}
              typeRenderers={typeRenderers}
            />
          </Cell>

          {hasChildren && (
            <Data
              collapsedPathKeys={collapsedPathKeys}
              data={nextDataItem}
              depth={depth + 1}
              invalidDataItems={invalidDataItems}
              isCollapsed={areChildrenCollapsed}
              onBlur={onBlur}
              onChange={onChange}
              onToggle={onToggle}
              parentPathKeys={pathKeys}
              typeRenderers={typeRenderers}
              handleLink={handleLink}
            />
          )}
        </Fragment>
      );
    });

  return newData;
}

Data.propTypes = {
  data: PropTypes.object.isRequired,
  depth: PropTypes.number,
  invalidDataItems: PropTypes.instanceOf(Map),
  isCollapsed: PropTypes.bool,
  parentPathKeys: PropTypes.array,
  styles: PropTypes.shape({
    cellLabel: PropTypes.object,
    cellRoot: PropTypes.object,
    tableRoot: PropTypes.object,
  }),
};

Data.defaultProps = {
  depth: 0,
  invalidDataItems: new Map(),
  isCollapsed: false,
  parentPathKeys: [],
  styles: {},
};

export default Data;
