import React, { useEffect, useState } from 'react';

import { processBuildingBlock } from '../processBuildingBlock';
import '../buildingBlockStyles.css';
import { isEmpty } from 'lodash';

function ResultSummary({
  name,
  canAccess,
  onDownloadClick,
  handleContactUsClick,
  webSocket,
  resultSummary,
  getResultSummary,
  appContent,
  language,
  ranges,
  texts,
}) {
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);

  let body = [];

  function onTabClick(id, item) {
    setActiveTabMap(new Map(activeTabMap.set(id, item)));
  }

  useEffect(() => {
    if (appContent && language && name) {
      getResultSummary(name);
    }
  }, [appContent, language, getResultSummary, name]);

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += '.resultsummary' + styleElement + ' ';
        }
        styleSheet.innerText = styleText;
        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  function getActiveTab(id) {
    return activeTabMap.get(id)?.props?.itemKey;
  }

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  if (resultSummary && ranges && !isEmpty(ranges)) {
    for (let buildingBlock of resultSummary) {
      processBuildingBlock({
        buildingBlock,
        body,
        collapsedVkeys,
        handleToggler,
        onTabClick,
        getActiveTab,
        canAccess,
        onDownloadClick,
        layoutMap,
        setLayoutMap: onSetLayoutMap,
        handleContactUsClick: handleContactUsClick,
        webSocket,
        addStyle,
        ranges,
        texts,
      });
    }
  }
  return (
    <div className="resultsummary">
      <div className="amdc-block" style={{ fontSize: '12px' }}>
        <div>{body}</div>
      </div>
    </div>
  );
}

export default ResultSummary;
