/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { checkCollectionNames } from './checkCollectionNames';
import { checkPermissions } from './checkPermissions';
import { checkSelectionCount } from './checkSelectionCount';

export function createContextMenuProps({ actions, event, onDismiss, onContextMenuItemClick, selectedEntities }) {
  const items = actions
    .filter(action => {
      if (!action.restrictions) {
        return true;
      }

      if (
        !checkSelectionCount({
          allowedSelectionCounts: action.restrictions.allowedSelectionCounts,
          selectedEntitiesCount: selectedEntities.length,
        })
      ) {
        return false;
      }

      if (
        !checkCollectionNames({
          allowedCollectionNames: action.restrictions.allowedCollectionNames,
          entities: selectedEntities,
        })
      ) {
        return false;
      }

      if (
        !checkPermissions({
          allowedPermissions: action.restrictions.allowedPermissions,
          entities: selectedEntities,
        })
      ) {
        return false;
      }

      return true;
    })
    .map(action => {
      const onClick = action.onClick || onContextMenuItemClick;

      return {
        onClick: clickEvent => onClick({ action, entities: selectedEntities, event: clickEvent }),
        ...action,
      };
    });

  return {
    items,
    onDismiss,
    target: event,
  };
}
