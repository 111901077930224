/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { TextField } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';

function UnitScalarInput({ dataItem, errorMessage, onBlur, onChange, pathKeys }) {
  const { isReadOnly, value } = dataItem;
  const testId = `PropertyEditor-${pathKeys.join('-')}`;

  return (
    <TextField
      data-testid={testId}
      disabled={isReadOnly}
      errorMessage={errorMessage}
      onBlur={isReadOnly ? () => {} : e => onBlur(e.target.value)}
      onChange={isReadOnly ? () => {} : e => onChange(e.target.value)}
      type="text"
      value={value}
    />
  );
}

UnitScalarInput.propTypes = {
  dataItem: PropTypes.shape({
    isReadOnly: PropTypes.bool,
    value: PropTypes.any,
  }).isRequired,
  errorMessage: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  pathKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

UnitScalarInput.defaultProps = {
  errorMessage: null,
  onBlur: () => {},
  onChange: () => {},
};

export default UnitScalarInput;
