/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Callout, CommandButton, DirectionalHint, getAllSelectedOptions, Icon, Label, TextField, Checkbox, Text } from '@fluentui/react';
import { getTheme } from '@fluentui/style-utilities';
import { findIndex } from '@fluentui/utilities';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { DropdownItemWrapper, Root } from './styled';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const NOOP = () => {};
const MULTISELECT_DELIMITER_DEFAULT = ', ';
const transformOptions = options => options.map((option, index) => ({ ...option, index }));
const copyArray = array => array.map(item => item);

export function ComboBox(props = EMPTY_OBJECT) {
  const {
    'data-testid': dataTestId = 'ComboBox',
    isSearchable,
    disabled,
    dropDownItemWrapperMaxHeight,
    dropdownMaxWidth,
    dropdownWidth,
    label,
    multiSelect,
    multiSelectDelimiter = MULTISELECT_DELIMITER_DEFAULT,
    notifyOnReselect,
    onChange,
    onBlur = NOOP,
    onRenderPrefix = NOOP,
    options: propsOptions = EMPTY_ARRAY,
    placeholder,
    selectedKey,
    selectedKeys,
    required,
    useComboBoxAsMenuWidth = true,
  } = props;

  const calloutProps = useMemo(
    () => ({
      styles: {
        root: [
          {
            boxShadow: '0 2px 3px 0 rgba(0,0,0,0.25)',
            borderBottomLeftRadius: 2,
            borderBottomRightRadius: 2,
            border: '1px solid #DCDCDC',
          },
        ],
      },
    }),
    []
  );
  const dropDown = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [optionsWithIndex, setOptionsWithIndex] = useState(transformOptions(propsOptions));
  const [options, setOptions] = useState(optionsWithIndex);
  const [selectedIndices, setSelectedIndices] = useState(EMPTY_ARRAY);

  useEffect(() => {
    setOptionsWithIndex(transformOptions(propsOptions));
  }, [propsOptions]);

  useEffect(() => {
    setOptions(optionsWithIndex);
  }, [optionsWithIndex]);

  const prevOptionWithIndex = usePrevious(optionsWithIndex);
  const prevProps = usePrevious(props);

  const getAllSelectedIndices = useCallback(_options => {
    return _options.map((option, index) => (option.selected ? index : -1)).filter(index => index !== -1);
  }, []);

  const getSelectedIndex = useCallback((_options, _selectedKey) => {
    return findIndex(_options, option => {
      if (_selectedKey !== null) {
        return option.key === _selectedKey;
      }
      return !!option.isSelected || !!option.selected;
    });
  }, []);

  const getSelectedIndexes = useCallback(
    (_options, _selectedKey) => {
      if (_selectedKey === undefined) {
        if (multiSelect) {
          return getAllSelectedIndices(_options);
        }
        const selectedIndex = getSelectedIndex(_options, null);
        return selectedIndex !== -1 ? [selectedIndex] : [];
      }
      if (!Array.isArray(_selectedKey)) {
        const selectedIndex = getSelectedIndex(_options, _selectedKey);
        return selectedIndex !== -1 ? [selectedIndex] : [];
      }

      const nextSelectedIndices = _selectedKey.map(key => getSelectedIndex(_options, key)).filter(index => index !== -1);

      return nextSelectedIndices;
    },
    [getAllSelectedIndices, getSelectedIndex, multiSelect]
  );

  useEffect(() => {
    let selectedKeyProp;

    const didOptionsChange = optionsWithIndex !== prevOptionWithIndex;
    if (props.multiSelect) {
      if (didOptionsChange && props.defaultSelectedKeys !== undefined) {
        selectedKeyProp = 'defaultSelectedKeys';
      } else {
        selectedKeyProp = 'selectedKeys';
      }
    } else if (didOptionsChange && props.defaultSelectedKey !== undefined) {
      selectedKeyProp = 'defaultSelectedKey';
    } else {
      selectedKeyProp = 'selectedKey';
    }

    if (
      prevProps !== undefined &&
      props[selectedKeyProp] !== undefined &&
      (props[selectedKeyProp] !== prevProps[selectedKeyProp] || didOptionsChange)
    ) {
      setSelectedIndices(getSelectedIndexes(optionsWithIndex, props[selectedKeyProp]));
    }
  }, [getSelectedIndexes, optionsWithIndex, prevOptionWithIndex, prevProps, props]);

  const handleCalloutDismiss = useCallback(() => {
    setIsOpen(false);
    setOptions(optionsWithIndex);
    if (dropDown.current) {
      dropDown.current.focus();
    }
  }, [optionsWithIndex]);

  const onDropDownClick = useCallback(() => {
    if (!disabled) {
      setIsOpen(prevIsOpen => !prevIsOpen);
    }
  }, [disabled]);

  const handleBlur = useCallback(
    e => {
      if (disabled) {
        return;
      }

      if (isOpen) {
        // Do not onBlur when the callout is opened
        return;
      }

      onBlur(e);
    },
    [disabled, isOpen, onBlur]
  );

  const handleOnChange = useCallback(
    (event, _options, index, checked, _multiSelect) => {
      if (onChange) {
        // for single-select, option passed in will always be selected.
        // for multi-select, flip the checked value
        const changedOpt = _multiSelect ? { ..._options[index], selected: !checked } : _options[index];

        onChange({ ...event, target: dropDown.current }, changedOpt, index);
      }
    },
    [onChange]
  );

  const setSelectedIndex = useCallback(
    (event, index) => {
      const checked = selectedIndices ? selectedIndices.indexOf(index) > -1 : false;
      let newIndexes = [];
      const nextIndex = Math.max(0, Math.min(optionsWithIndex.length - 1, index));
      if (selectedKey !== undefined || selectedKeys !== undefined) {
        handleOnChange(event, optionsWithIndex, nextIndex, checked, multiSelect);
        return;
      }

      if (!multiSelect && !notifyOnReselect && nextIndex === selectedIndices[0]) {
        return;
      }

      if (multiSelect) {
        newIndexes = selectedIndices ? copyArray(selectedIndices) : [];
        if (checked) {
          const position = newIndexes.indexOf(nextIndex);
          if (position > -1) {
            // unchecked the current one
            newIndexes.splice(position, 1);
          }
        } else {
          // add the new selected index into the existing one
          newIndexes.push(nextIndex);
        }
      } else {
        // Set the selected option if this is an uncontrolled component
        newIndexes = [nextIndex];
      }

      event.persist();
      setSelectedIndices(newIndexes);
      if (handleOnChange) handleOnChange(event, optionsWithIndex, nextIndex, checked, multiSelect);
    },
    [multiSelect, notifyOnReselect, handleOnChange, optionsWithIndex, selectedIndices, selectedKey, selectedKeys]
  );

  const onItemClick = useCallback(
    item => event => {
      if (!item.disabled) {
        setSelectedIndex(event, item.index);
        if (!multiSelect) {
          // only close the callout when it's in single-select mode
          setIsOpen(false);
          setOptions(optionsWithIndex);
          dropDown.current.focus();
        }
      }
    },
    [multiSelect, optionsWithIndex, setSelectedIndex]
  );

  const onDropDownSearch = useCallback(
    (e, value) => {
      try {
        const regEx = new RegExp(`^.*${value}.*$`, 'i');
        const nextOptions = optionsWithIndex.filter(option => regEx.exec(option.key) || regEx.exec(option.text));
        setOptions(nextOptions);
      } catch (exc) {
        setOptions(optionsWithIndex);
      }
    },
    [optionsWithIndex]
  );

  const onRenderTitle = useCallback(
    items => {
      const displayText = items.map(i => i.text).join(multiSelectDelimiter);
      return (
        <Text title={displayText} variant="xSmall">
          {displayText}
        </Text>
      );
    },
    [multiSelectDelimiter]
  );

  const onRenderPlaceHolder = useCallback(() => {
    if (!placeholder) return null;
    return <Text variant="xSmall">{placeholder}</Text>;
  }, [placeholder]);

  const fieldOptions = useMemo(() => {
    return options.map((item, index) => {
      const isItemSelected = item.index !== undefined && selectedIndices ? selectedIndices.indexOf(item.index) > -1 : false;
      return (
        <CommandButton
          data-testid={`ComboBox-Option-${index}`}
          key={item.key}
          className={`dropDownItem${isItemSelected ? ' dropDownItem-selected' : ''}`}
          disabled={item.disabled}
          onClick={onItemClick(item)}
          title={item.title ? item.title : item.text}
        >
          {multiSelect && (
            <Checkbox
              checked={isItemSelected}
              inputProps={{
                onClick(e) {
                  e.preventDefault();
                  e.stopPropagation();
                },
              }}
            />
          )}
          <span className="dropdownOptionText">{item.text}</span>
          {!multiSelect && isItemSelected && <Icon iconName="CheckMark" className="dropDownSelectedIcon" />}
        </CommandButton>
      );
    });
  }, [multiSelect, onItemClick, options, selectedIndices]);

  const selectedOptions = useMemo(() => {
    return getAllSelectedOptions(optionsWithIndex, selectedIndices);
  }, [optionsWithIndex, selectedIndices]);

  const theme = getTheme();

  let calloutWidth = dropDown.current?.clientWidth;

  if (!useComboBoxAsMenuWidth && dropdownWidth) {
    calloutWidth = dropdownWidth;
  }

  return (
    <Root className="root" data-testid={`${dataTestId}-Root`} disabled={disabled} isOpen={isOpen} theme={theme}>
      {label ? (
        <Label className="label" required={required} disabled={disabled}>
          {label}
        </Label>
      ) : null}
      <div
        className="dropdown"
        data-testid="ComboBox-DropDown"
        onBlur={handleBlur}
        onClick={onDropDownClick}
        onKeyDown={NOOP}
        onKeyUp={NOOP}
        ref={dropDown}
        role="listbox"
        tabIndex={disabled ? -1 : 0}
      >
        {props.onRenderPrefix && <span className="prefix">{onRenderPrefix()}</span>}
        <span className="title" data-testid="ComboBox-Title">
          {selectedOptions.length ? onRenderTitle(selectedOptions) : onRenderPlaceHolder()}
        </span>
        <span className="carretDownWrapper">
          <Icon iconName="ChevronDown" styles={{ root: { height: 16, display: 'flex', alignItems: 'center' } }} />
        </span>
      </div>
      {isOpen && (
        <Callout
          isBeakVisible={false}
          gapSpace={0}
          doNotLayer={false}
          directionalHintFixed={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          {...calloutProps}
          className="callout"
          target={dropDown.current}
          onDismiss={handleCalloutDismiss}
          // onScroll={_onScroll}
          // onPositioned={_onPositioned}
          calloutWidth={calloutWidth}
          calloutMaxWidth={dropdownMaxWidth}
        >
          <DropdownItemWrapper
            className="dropdownItemsWrapper"
            data-testid={`${dataTestId}-DropdownItemWrapper`}
            dropDownItemWrapperMaxHeight={dropDownItemWrapperMaxHeight}
          >
            {isSearchable && (
              <div className="dropDownSearchWrapper">
                <TextField
                  data-testid="ComboBox-Option-Search"
                  styles={{ field: { paddingLeft: 24 } }}
                  iconProps={{ iconName: 'Search', styles: { root: { lineHeight: 16, bottom: 2, left: 4 } } }}
                  autoFocus
                  onChange={onDropDownSearch}
                />
              </div>
            )}
            {fieldOptions}
            {optionsWithIndex.length > 0 && options.length === 0 && <span className="dropdownNoOptionText">No options found</span>}
          </DropdownItemWrapper>
        </Callout>
      )}
    </Root>
  );
}

ComboBox.propTypes = {
  'data-testid': PropTypes.string,
  defaultSelectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  disabled: PropTypes.bool,
  dropDownItemWrapperMaxHeight: PropTypes.number,
  dropdownMaxWidth: PropTypes.number,
  dropdownWidth: PropTypes.number,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  multiSelect: PropTypes.bool,
  multiSelectDelimiter: PropTypes.string,
  notifyOnReselect: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  // onRenderCaretDown: PropTypes.func,
  // onRenderContainer: PropTypes.func,
  // onRenderLabel: PropTypes.func,
  // onRenderOption: PropTypes.func,
  onRenderPlaceholder: PropTypes.func,
  onRenderPrefix: PropTypes.func,
  onRenderTitle: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.any,
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
      id: PropTypes.string,
      isSelected: PropTypes.bool,
      itemType: PropTypes.oneOf([0, 1, 2]),
      index: PropTypes.number,
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      selected: PropTypes.bool,
      text: PropTypes.oneOfType([PropTypes.string]),
      title: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selectedKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  useComboBoxAsMenuWidth: PropTypes.bool,
};

export default React.memo(ComboBox);
