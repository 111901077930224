/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
function history(state = {}, action) {
  switch (action.type) {
    case 'REPLACE_HISTORY':
      return action.history;
    default:
      return state;
  }
}

export default history;
