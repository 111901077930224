/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useRef } from 'react';
import { ApolloClient } from 'apollo-client';
import PropTypes from 'prop-types';

function ChildAppWrapper(props) {
  const { childAppProps, children, isActive, loginStatus } = props;

  /*
    We want to "freeze" the props while this app is not active.
  */
  const lastActivePropsRef = useRef(null);

  if (isActive) {
    lastActivePropsRef.current = {
      ...childAppProps,
    };
  }

  const lastActiveProps = lastActivePropsRef.current;

  if (!lastActiveProps || loginStatus !== 'LOGGED_IN') {
    return null;
  }

  return (
    <div
      style={{
        display: isActive ? 'flex' : 'none',
        flex: '1 0 auto',
      }}
    >
      <children.type {...children.props} {...lastActiveProps}>
        {children}
      </children.type>
    </div>
  );
}

ChildAppWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  childAppProps: PropTypes.shape({
    api: PropTypes.object,
    apollo: PropTypes.instanceOf(ApolloClient),
    history: PropTypes.object.isRequired,
    loggedInUserEntity: PropTypes.object,
    path: PropTypes.string,
    userId: PropTypes.string,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  loginStatus: PropTypes.string,
};

ChildAppWrapper.defaultProps = {
  loginStatus: null,
};

export default ChildAppWrapper;
