/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';
import searchAPI from './search';

const cache = new Map();
// const cachePromise = new Map();

async function get(id, options = {}) {
  const { bypassCache = false } = options;

  if (!bypassCache && cache.has(id)) {
    return cache.get(id);
  }

  const teamEntity = await this.entity.get(id, 'Team');

  cache.set(id, teamEntity);

  return teamEntity;
}

async function search(text) {
  const filter = {
    payload: {
      filter: {
        $and: [
          {
            $is: ['@contentTypes'],
          },
          {
            $like: ['attributes.name', '@var0'],
          },
          {
            $neq: ['system.isInternal', '@var1'],
          },
        ],
      },
      bindVars: {
        contentTypes: ['Team'],
        var0: text,
        var1: true,
      },
      options: {
        isConsistent: false,
      },
    },
  };
  const { entities } = await searchAPI.search({ filter, isLegacy: false });

  return entities;
}

async function getUsers(teamId) {
  const url = `/api/teams/${teamId}/users`;

  const { data } = await axios.get(url);

  return data;
}

export default {
  get,
  getUsers,
  search,
};
