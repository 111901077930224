import React from 'react';

function VerticalTabItem(props) {
  return (
    <div className={props.format} style={{ height: '100%' }}>
      {props.children}
    </div>
  );
}

export { VerticalTabItem };
