/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';

function PlotInput() {
  return null;
}

PlotInput.propTypes = {
  dataItem: PropTypes.shape({
    isReadOnly: PropTypes.bool,
    value: PropTypes.any,
  }).isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  pathKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

PlotInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
};

export default PlotInput;
