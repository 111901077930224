/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import initialStates from '../store/initialStates';

function error(state = {}, action) {
  switch (action.type) {
    case 'ADD_ERROR':
      return {
        ...state,
        message: action.message,
      };
    case 'CLEAR_ERROR':
      return {
        ...initialStates.error,
      };
    default:
      return state;
  }
}

export default error;
