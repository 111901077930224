/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import gql from 'graphql-tag';

const VIEWER_FRAGMENT = gql`
  fragment ViewerPart on EntityViewer {
    id
    name
    url
  }
`;

const PREVIEW_FRAGMENT = gql`
  fragment PreviewPart on Preview {
    group
    id
    internal
    preview
    src
    thumbnail
  }
`;

const ENTITY_PREVIEW_FRAGMENT = gql`
  fragment FilePreviewPart on File {
    previews {
      ...PreviewPart
    }
  }
  fragment DirectoryPreviewPart on Directory {
    previews {
      ...PreviewPart
    }
  }
  fragment MaterialPreviewPart on Material {
    previews {
      ...PreviewPart
    }
  }
  ${PREVIEW_FRAGMENT}
`;

export const GET_ENTITY_QUERY = gql`
  query QUERY($collectionName: String!, $id: ID!) {
    entity(collectionName: $collectionName, id: $id) {
      id
      attributes
      system
      ... on Directory {
        viewers {
          ...ViewerPart
        }
      }
      ... on File {
        viewers {
          ...ViewerPart
        }
      }
      ... on Material {
        viewers {
          ...ViewerPart
        }
      }
      ...FilePreviewPart
      ...DirectoryPreviewPart
      ...MaterialPreviewPart
    }
  }
  ${ENTITY_PREVIEW_FRAGMENT}
  ${VIEWER_FRAGMENT}
`;

export const GET_PREVIEWS_QUERY = gql`
  query QUERY($previewInput: PreviewInput) {
    previews: preview(previewInput: $previewInput) {
      ...PreviewPart
    }
  }
  ${PREVIEW_FRAGMENT}
`;

export const DELETE_PREVIEWS_MUTATION = gql`
  mutation DELETE_PREVIEWS($deletePreviewInput: DeletePreviewInput) {
    deletePreviews(deletePreviewInput: $deletePreviewInput)
  }
`;
