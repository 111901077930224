/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import platformPropTypes from '@altair/prop-types';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ExtensionIcon from './ExtensionIcon';

export function ContentTypeIcon(props) {
  const { entity, width } = props;

  /* Used to render extension icons when the content image can't be loaded. */
  const [forceRenderExtensionIcon, setForceRenderExtensionIcon] = useState(false);

  useEffect(() => {
    setForceRenderExtensionIcon(false);
  }, [entity]);

  if (forceRenderExtensionIcon) {
    return <ExtensionIcon entity={entity} width={width} />;
  }

  const { name } = entity.attributes;
  const { contentType } = entity.system;

  const iconUrl = `/content/content-definitions/${contentType}/icon`;
  // const height = (width * 18.5) / 13.5;
  const height = width;

  return (
    <img
      data-testid={`ContentTypeIcon-${name}`}
      src={iconUrl}
      onError={() => {
        setForceRenderExtensionIcon(true);
      }}
      style={{ width: `${width}px`, height: `${height}px` }}
      alt="icon"
    />
  );
}

ContentTypeIcon.propTypes = {
  entity: platformPropTypes.entity.isRequired,
  width: PropTypes.number.isRequired,
};

export default ContentTypeIcon;
