/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Dialog, PrimaryButton, TextField, DefaultButton } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Root } from './styled';

const NOOP = () => {};
const DEFAULT_STRING = null;

const Status = {
  Failed: 'failed',
  Sending: 'sending',
  Sent: 'sent',
  New: 'new',
};

const EMPTY_OBJECT = {};

function CommentDialog({
  className,
  commentBoxProps = EMPTY_OBJECT,
  failureMsg = DEFAULT_STRING,
  hidden = true,
  message,
  onChange = NOOP,
  onDismiss = NOOP,
  onDismissed = NOOP,
  status: propsStatus = DEFAULT_STRING,
  successMsg = DEFAULT_STRING,
  title = DEFAULT_STRING,
  onSubmitClick = NOOP,
}) {
  const hasError = message !== null && (typeof message !== 'string' || message.trim().length === 0);
  const disableSubmit = !message || hasError || propsStatus === Status.Sending;

  return (
    <Dialog
      hidden={hidden}
      minWidth={400}
      maxWidth={456}
      modalProps={{
        onDismissed,
        isBlocking: propsStatus === Status.Sending,
      }}
      onDismiss={onDismiss}
    >
      <Root className={`CommentDialog__Root${className ? ` ${className}` : ''}`}>
        <div className="CommentDialog__Title">{title}</div>
        {propsStatus !== Status.Sent && (
          <TextField
            multiline
            rows={4}
            resizable={false}
            {...commentBoxProps}
            className="CommentDialog__CommentInput"
            disabled={propsStatus === Status.Sending}
            onChange={onChange}
            styles={{ root: { width: '100%' }, errorMessage: { display: 'none' }, fieldGroup: { height: 'auto' } }}
            value={message}
          />
        )}
        {propsStatus === Status.Failed && <span className="CommentDialog__CommentFailed">{failureMsg}</span>}
        {propsStatus === Status.Sent && <span className="CommentDialog__CommentSent">{successMsg}</span>}
        {propsStatus !== Status.Sent && (
          <PrimaryButton disabled={disableSubmit} text="Submit" styles={{ root: { width: 252, marginTop: 24 } }} onClick={onSubmitClick} />
        )}
        {propsStatus === Status.Sent && <DefaultButton text="Close" styles={{ root: { width: 252, marginTop: 24 } }} onClick={onDismiss} />}
      </Root>
    </Dialog>
  );
}

CommentDialog.propTypes = {
  className: PropTypes.string,
  commentBoxProps: PropTypes.object,
  failureMsg: PropTypes.string,
  hidden: PropTypes.bool,
  message: PropTypes.string,
  onChange: PropTypes.func,
  onDismiss: PropTypes.func,
  onDismissed: PropTypes.func,
  onSubmitClick: PropTypes.func,
  status: PropTypes.oneOf(['new', 'failed', 'sending', 'sent']),
  successMsg: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default CommentDialog;
