/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { combineReducers } from 'redux';

import error from './error';
import history from './history';
import roots from './roots';
import appName from './appName';
import loggedInUser from './loggedInUser';
import selectedEntities from './selectedEntities';
import viewedEntity from './viewedEntity';
import search from './search';
import notification from './notification';
import localNotifications from './localNotifications';

import initialStates from '../store/initialStates';

const appReducer = combineReducers({
  error,
  history,
  roots,
  appName,
  loggedInUser,
  selectedEntities,
  viewedEntity,
  search,
  notification,
  localNotifications,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = {
      ...initialStates,
      appName: state.appName,
      history: state.history,
    };
  }
  return appReducer(state, action);
};

export default rootReducer;
