/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
const buildSwatchStyle = (color, format) => {
  let background;

  if (format === 'array') {
    const a = color[3] === undefined ? 1 : color[3];

    background = `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${a})`;
  }

  if (format === 'hex') {
    background = color;
  }

  if (format === 'rgb') {
    const a = color[3] === undefined ? 1 : color.a;

    background = `rgba(${color.r}, ${color.g}, ${color.b}, ${a})`;
  }

  return {
    background,
    height: 24,
    width: 48,
  };
};

export default buildSwatchStyle;
