/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import platformPropTypes from '@altair/prop-types';

export function ProgressIcon(props) {
  const { entity } = props;
  const { totalSize, uploadedSize } = entity.pseudo.upload;
  const percentage = (100 * uploadedSize) / totalSize;

  return (
    <CircularProgressbar
      percentage={percentage}
      strokeWidth={50}
      textForPercentage={null}
      styles={{
        path: { strokeLinecap: 'butt' },
        text: { fill: '#000' },
      }}
    />
  );
}

ProgressIcon.propTypes = {
  entity: platformPropTypes.entity.isRequired,
};

export default ProgressIcon;
