/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

async function deleteEntity({ contentType, id }) {
  const url = `/api/versioning/${encodeURIComponent(contentType)}/${id}`;
  const { data } = await axios.delete(url);
  return data;
}

async function shareEntities(payload) {
  const promises = payload.map(({ contentType, id, userACL }) => {
    const url = `/api/versioning/${encodeURIComponent(contentType)}/${id}/share`;
    const body = {
      userACL,
    };
    return axios.post(url, body);
  });

  return Promise.all(promises);
}

function unshare({ contentType, id, teams, users }) {
  const url = `/api/versioning/${encodeURIComponent(contentType)}/${id}/share`;
  const params = {
    userIds: users,
    teamIds: teams,
  };
  return axios.delete(url, { params });
}

export default {
  deleteEntity,
  shareEntities,
  unshare,
};
