/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { clearViewedEntity, replaceViewedEntity } from './viewedEntity';

export function clearSelectedEntities() {
  return dispatch => {
    dispatch(clearViewedEntity());

    dispatch({
      type: 'CLEAR_SELECTED_ENTITIES',
    });
  };
}

export function overwriteSelectedEntities(entities) {
  return dispatch => {
    if (entities.length === 1) {
      dispatch(replaceViewedEntity(entities[0]));
    } else {
      dispatch(clearViewedEntity());
    }

    dispatch({
      type: 'OVERWRITE_SELECTED_ENTITIES',
      entities,
    });
  };
}

export function replaceSelectedEntities(entities) {
  return {
    type: 'REPLACE_SELECTED_ENTITIES',
    entities,
  };
}
