import React, { useState, useEffect } from 'react';
import { DefaultButton, Dropdown, PrimaryButton, Dialog, DialogFooter, DialogType, Checkbox } from '@fluentui/react';
import * as TextMapping from '../../../utils/textMapping';
import { Margin5 } from '../../../pages/styles';

function UserPreferencesDialog({ hidden, onDismiss, handleOnConfirm, availLanguages, unitSystems, preferences, texts, config }) {
  const dialogContentProps = {
    type: DialogType.normal,
    title: TextMapping.getUIText(TextMapping.UI_TEXT_USER_PREFERENCES, texts),
    closeButtonAriaLabel: 'Close',
    subText: '',
  };

  const [language, setLanguage] = useState();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [unitSystem, setUnitSystem] = useState();
  const [unitSystemOptions, setUnitSystemOptions] = useState([]);
  const [skipLanding, setSkipLanding] = useState(false);

  const languageDropdown = React.createRef();
  const unitSystemDropdown = React.createRef();

  useEffect(() => {
    if (preferences) {
      let options = [];
      if (availLanguages && availLanguages.options) {
        for (let [key, value] of Object.entries(availLanguages.options)) {
          options.push({ key: key, text: value });
        }
      }

      setLanguageOptions(options);

      if (preferences.language) {
        setLanguage(preferences.language);
      } else {
        setLanguage(availLanguages.default);
      }

      if (preferences.skipLanding) {
        setSkipLanding(preferences.skipLanding);
      }
    }
  }, [availLanguages, preferences, setLanguage]);

  useEffect(() => {
    if (preferences) {
      let options = [];
      if (unitSystems && unitSystems.options) {
        for (let [key, value] of Object.entries(unitSystems.options)) {
          options.push({ key: key, text: value });
        }
      }

      setUnitSystemOptions(options);

      if (preferences.unitSystem) {
        setUnitSystem(preferences.unitSystem.toString());
      } else {
        setUnitSystem(unitSystems.default.toString());
      }
    }
  }, [unitSystems, preferences, setUnitSystem]);

  function handleLanguageDropdownChange(e, value) {
    setLanguage(value.key);
  }

  function handleUnitSystemDropdownChange(e, value) {
    setUnitSystem(value.key);
  }

  function handleSkipLandingChange(e, value) {
    setSkipLanding(value);
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={{
        isBlocking: true,
      }}
    >
      {config.preferences && config.preferences.language && (
        <Dropdown
          data-testid="userpref-language-dropdown"
          placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_LANGUAGE, texts)}
          label={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_LANGUAGE, texts)}
          id="languageDropdown"
          ariaLabel={TextMapping.getUIText(TextMapping.UI_TEXT_LANGUAGE, texts)}
          options={languageOptions}
          onChange={handleLanguageDropdownChange}
          componentRef={languageDropdown}
          defaultSelectedKey={language ? language : ''}
        />
      )}
      <Margin5 />
      {config.preferences && config.preferences.units && (
        <Dropdown
          data-testid="userpref-unitsystem-dropdown"
          placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_UNIT_SYSTEM, texts)}
          label={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_UNIT_SYSTEM, texts)}
          id="unitSystemDropdown"
          ariaLabel={TextMapping.getUIText(TextMapping.UI_TEXT_UNIT_SYSTEM, texts)}
          options={unitSystemOptions}
          onChange={handleUnitSystemDropdownChange}
          componentRef={unitSystemDropdown}
          defaultSelectedKey={unitSystem ? unitSystem : ''}
        />
      )}
      <Margin5 />
      {config.preferences && config.preferences.skiplanding && (
        <Checkbox
          label={TextMapping.getUIText(TextMapping.UI_TEXT_SKIP_LANDING, texts)}
          checked={skipLanding}
          onChange={handleSkipLandingChange}
        />
      )}

      <DialogFooter>
        <DefaultButton onClick={onDismiss} text={TextMapping.getUIText(TextMapping.UI_TEXT_CANCEL, texts)} />
        <PrimaryButton
          onClick={() => {
            handleOnConfirm({ language: language, unitSystem: unitSystem, skipLanding: skipLanding });
          }}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_APPLY, texts)}
        />
      </DialogFooter>
    </Dialog>
  );
}

export default UserPreferencesDialog;
