/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { TopbarValues } from './TopbarValues';
import { DEFAULT_TOPBAR_CONTEXT, TopbarContext } from './TopbarContext';

export class TopbarProvider extends React.Component {
  constructor(props) {
    super(props);
    const { altairAdminBaseUrl = '', altairOneBaseUrl = '' } = props;
    TopbarValues.setValues({ altairAdminBaseUrl, altairOneBaseUrl });
    this.state = { ...DEFAULT_TOPBAR_CONTEXT, altairAdminBaseUrl, altairOneBaseUrl };
  }

  componentDidMount() {
    TopbarValues.observe(this._onCustomizationChange);
  }

  componentDidUpdate(prevProps) {
    if (this.props.altairOneBaseUrl !== prevProps.altairOneBaseUrl) {
      TopbarValues.setValues({ altairOneBaseUrl: this.props.altairOneBaseUrl });
    }
    if (this.props.altairAdminBaseUrl !== prevProps.altairAdminBaseUrl) {
      TopbarValues.setValues({ altairAdminBaseUrl: this.props.altairAdminBaseUrl });
    }
    if (this.props.onlineHelpUrl !== prevProps.onlineHelpUrl) {
      TopbarValues.setValues({ onlineHelpUrl: this.props.onlineHelpUrl });
    }
  }

  componentWillUnmount() {
    TopbarValues.unobserve(this._onCustomizationChange);
  }

  _onCustomizationChange = () => {
    this.setState({ ...TopbarValues.getValues() });
  };

  render() {
    return <TopbarContext.Provider value={this.state}>{this.props.children}</TopbarContext.Provider>;
  }
}

TopbarProvider.propTypes = {
  altairAdminBaseUrl: PropTypes.string,
  altairOneBaseUrl: PropTypes.string,
  onlineHelpUrl: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

TopbarProvider.displayName = 'TopbarProvider';
