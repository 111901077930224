/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, DetailsList, DialogFooter, PrimaryButton, Selection } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DetailListCheckbox from '../../../../../gooey/components/DetailListCheckbox';
import BaseDialog from '../BaseDialog';
import * as TextMapping from '../../../../../utils/textMapping';
import { isEmpty } from 'lodash';

const EMPTY_ARRAY = [];
const NOOP = () => {};

function LoadCriteriaDialog({
  criterias = EMPTY_ARRAY,
  hidden = true,
  title = null,
  onDelete = NOOP,
  onDismiss = NOOP,
  onLoad = NOOP,
  texts,
}) {
  const [selected, setSelected] = useState([]);
  const [loadedCriterias, setLoadedCriterias] = useState([]);

  useEffect(() => {
    setLoadedCriterias(criterias);
  }, [criterias]);
  const selectionRef = useRef(
    new Selection({
      getKey: (item) => item.id,
      onSelectionChanged: () => {
        const selection = selectionRef.current;
        const newSelectedCriterias = selection.getSelection();
        setSelected(newSelectedCriterias);
      },
    })
  );

  const handleDelete = useCallback(() => {
    let newLoadedCriterias = [];

    for (let criteria of loadedCriterias) {
      let found = false;
      for (let select of selected) {
        if (criteria.id === select.id && !criteria.id.startsWith('Application')) {
          found = true;
          break;
        }
      }

      if (!found) {
        newLoadedCriterias.push(criteria);
      }
    }

    setLoadedCriterias(newLoadedCriterias);
    onDelete(selected);
  }, [onDelete, selected, loadedCriterias]);

  const handleLoad = useCallback(() => {
    onLoad(selected[0]);
  }, [onLoad, selected]);

  const handleDismissed = useCallback(() => {
    const selection = selectionRef.current;
    selection.setAllSelected(false);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        name: 'Name',
        onRender: (searchCriteria) => <span>{searchCriteria.name}</span>,
      },
    ];
  }, []);

  const handleRenderCheckbox = useCallback((headerProps) => {
    return <DetailListCheckbox {...headerProps} />;
  }, []);

  let nothingSavedYet = isEmpty(criterias) ? TextMapping.getUIText(TextMapping.UI_TEXT_NOTHING_SAVED_YET, texts) : '';
  return (
    <BaseDialog
      data-testid="LoadCriteriaDialog"
      hidden={hidden}
      modalProps={{ onDismissed: handleDismissed }}
      onDismiss={onDismiss}
      title={title ? title : TextMapping.getUIText(TextMapping.UI_TEXT_LOAD_CRITERIA, texts)}
    >
      {nothingSavedYet}
      <DetailsList
        columns={columns}
        data-testid="LoadCriteriaDialog-DetailsList"
        isHeaderVisible={false}
        items={loadedCriterias}
        onRenderCheckbox={handleRenderCheckbox}
        selection={selectionRef.current}
      />

      <DialogFooter>
        <PrimaryButton
          data-testid="load-criteria-dialog-load"
          disabled={selected.length !== 1}
          onClick={handleLoad}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_LOAD, texts)}
        />
        <DefaultButton
          data-testid="load-criteria-dialog-delete"
          disabled={selected.length === 0}
          iconProps={{ iconName: 'Delete' }}
          onClick={handleDelete}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_DELETE, texts)}
        />
      </DialogFooter>
    </BaseDialog>
  );
}

LoadCriteriaDialog.propTypes = {
  criterias: PropTypes.arrayOf(PropTypes.object),
  hidden: PropTypes.bool,
  onDelete: PropTypes.func,
  onDismiss: PropTypes.func,
  onLoad: PropTypes.func,
};

function areEqual(prevProps, nextProps) {
  return prevProps.hidden === nextProps.hidden;
}

export default React.memo(LoadCriteriaDialog, areEqual);
