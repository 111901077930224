export function producturl(id, currentPath, tracking) {
  let newURL;

  if (tracking) {
    let regex = /datasheet\/[^/]+\/[^/]+\/[^/]+/;
    let encodedParts = [];
    let count = 0;

    if (tracking.url) {
      for (let component of tracking.url) {
        if (count === 1 && component) {
          component = component.replace(/%/g, '$p$');
        }
        encodedParts.push(encodeURIComponent(component));
        count++;
      }
    }

    let newPath = encodedParts.join('/');
    let separator = '/';

    if (currentPath.endsWith('/')) {
      separator = '';
    }

    if (currentPath && regex.test(currentPath)) {
      newURL = currentPath.replace(regex, newPath);
    } else {
      newURL = currentPath + separator + newPath;
    }
  }

  return newURL;
}
