/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { PrimaryButton, Modal, Checkbox, Icon, Text } from '@fluentui/react';
import ComboBox from '../../../gooey/components/ComboBox';
import { classNamesFunction } from '@fluentui/utilities';
import React, { useState } from 'react';

import { EulaModalProps } from './EulaModal.types';
import { useEula } from './eula';

const getClassNames = classNamesFunction();

const message = {
  title: {
    en: 'End-User License Agreement',
    fr: "Contrat de licence de l'utilisateur final",
  },
  accept: {
    en: 'I accept the End-User License Agreement',
    fr: "J'accepte le contrat de licence utilisateur final",
  },
  continue: {
    en: 'Continue',
    fr: 'Continuer',
  },
};

const languageOptions = [
  { key: 'en', text: 'English' },
  { key: 'fr', text: 'Français' },
];

const NOOP = () => {};

function EulaModal(props) {
  const { isOpen, onAccept = NOOP, styles, theme } = props;

  const [language, setLanguage] = useState('en');
  const [acceptedEula, setAcceptedEula] = useState(false);
  const [updatingEula, setUpdatingEula] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { eula } = useEula({ language, fetchEula: isOpen });

  function handleLanguageChange(e, option) {
    setLanguage(option.key);
  }

  async function acceptEula() {
    setUpdatingEula(true);
    const res = await fetch('/api/auth/v1/accepteula', { method: 'POST' });
    setUpdatingEula(false);
    if (res.ok) {
      onAccept();
    } else {
      setHasError(true);
    }
  }

  const classNames = getClassNames(styles, { theme });

  return (
    <Modal isModeless isOpen={isOpen} containerClassName={classNames.container} styles={{ layer: { position: 'fixed' } }}>
      <div className={classNames.innerContainer}>
        <div className={classNames.header}>{message.title[language]}</div>
        <div className={classNames.languageSelector}>
          <ComboBox
            onRenderPrefix={() => <Icon iconName="Globe" />}
            options={languageOptions}
            onChange={handleLanguageChange}
            selectedKey={language}
          />
        </div>
        <div className={classNames.body}>
          <div dangerouslySetInnerHTML={{ __html: eula }} />
          <div className={classNames.eulaAction}>
            <Checkbox label={message.accept[language]} onChange={(e, checked) => setAcceptedEula(checked)} />
          </div>
        </div>
      </div>
      <div className={classNames.footer}>
        <div className={classNames.eulaError}>
          {hasError && <Text variant="small">We are unable to process the request. Please try again later.</Text>}
        </div>

        <PrimaryButton
          text={message.continue[language]}
          className={classNames.footerButton}
          disabled={!acceptedEula || updatingEula}
          onClick={acceptEula}
        />
      </div>
    </Modal>
  );
}

EulaModal.propTypes = EulaModalProps;

export { EulaModal };
