/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
function updateGroups({ groups, newGroup }) {
  const newGroups = groups.map(filter => {
    if (filter.id === newGroup.id) {
      return newGroup;
    }

    return filter;
  });

  return newGroups;
}

export default updateGroups;
