/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { getRenderer } from './getRenderer';

export function createColumn({ column, onColumnClick, sortingEntityPath, sortingIsAscending }) {
  validateArgs({ column, onColumnClick, sortingEntityPath, sortingIsAscending });

  const output = {
    key: column.name,
    fieldName: column.entityPath,
    isRowHeader: true,
    isResizable: true,
    isPadded: true,
    onColumnClick,
    onRender: column.onRender || getRenderer(column.type),
    ...column,
  };

  if (sortingEntityPath) {
    if (typeof output.isSorted !== 'boolean') {
      output.isSorted = output.entityPath === sortingEntityPath;
    }

    if (typeof output.isSortedDescending !== 'boolean' && output.isSorted) {
      output.isSortedDescending = !sortingIsAscending;
    }
  }

  return output;
}

function isBoolean(value) {
  return typeof value === 'boolean';
}

function isFunction(value) {
  return typeof value === 'function';
}

function isString(value) {
  return typeof value === 'string';
}

function validateArgs({ column, onColumnClick, sortingEntityPath, sortingIsAscending }) {
  if (!isString(column.entityPath) && !column.onRender) {
    throw new TypeError('Either column.entityPath or column.onRender is required');
  }

  if (!isString(column.name) && !isString(column.iconName)) {
    throw new TypeError('Either column.name or column.iconName is required');
  }

  if (!isString(column.type) && !column.onRender) {
    throw new TypeError('column.type or column.onRender is required');
  }

  if (onColumnClick && !isFunction(onColumnClick)) {
    throw new TypeError('onColumnClick must be a function');
  }

  if (sortingEntityPath && !isString(sortingEntityPath)) {
    throw new TypeError('sortingEntityPath must be a string if it is defined');
  }

  if (sortingIsAscending !== undefined && !isBoolean(sortingIsAscending)) {
    throw new TypeError('sortingIsAscending must be a boolean if it is defined');
  }
}
