/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const ModalContentContainer = styled.div`
  position: relative;
  overflow-y: auto;
  border-top: 1px solid #eaeaea;
  height: 100%;
  border-top-width: ${props => (props.scrolled ? 1 : 0)}px;
`;

const ModalContentRoot = styled.div`
  margin: 0 34px 0 48px;
  max-height: calc(100vh - 270px);
  position: relative;
`;

const H2 = styled.h2`
  margin: 0px;
  padding: 10px 0px 40px;
  font-size: 28px;
  font-weight: 100;
`;

const H3 = styled.h3`
  margin: 0px 0 18px;
  font-size: 17px;
  font-weight: 600;
  text-align: right;
`;

const ImportRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ProviderContainer = styled.div`
  position: absolute;
  left: 180px;
  top: 0px;
  width: 300px;
`;

const UnitContainer = styled.div`
  position: absolute;
  left: 180px;
  top: 40px;
  width: 300px;
`;

const SourceContainer = styled.div`
  position: absolute;
  left: 180px;
  top: 80px;
  width: 300px;
`;

const DataFileContainer = styled.div`
  position: absolute;
  left: 180px;
  top: 120px;
  width: 300px;
`;

const DocumentFileContainer = styled.div`
  position: absolute;
  left: 180px;
  top: 160px;
  width: 300px;
`;

const DescriptionContainer = styled.div`
  position: absolute;
  left: 180px;
  top: 220px;
  width: 300px;
`;

const ImportGrid = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
`;

export {
  H2,
  H3,
  ModalContentContainer,
  ModalContentRoot,
  ProviderContainer,
  UnitContainer,
  SourceContainer,
  DataFileContainer,
  DocumentFileContainer,
  DescriptionContainer,
  ImportRow,
  ImportGrid,
};
