import React, { useState } from 'react';
import { Dialog, PrimaryButton, TextField, DefaultButton } from '@fluentui/react';
import * as TextMapping from '../../../utils/textMapping';

const NOOP = () => {};

const FeedbackStatus = {
  Failed: 'failed',
  New: 'new',
  Sending: 'sending',
  Sent: 'sent',
};

function getDefaultDialogState({ message = '' }) {
  return {
    message,
    feedbackStatus: FeedbackStatus.New,
    error: null,
  };
}

function UpgradeDialog({ altairOneBaseUrl = '', api, message: propsMessage, hidden, onDismiss = NOOP, onDismissed = NOOP, texts }) {
  const [{ message, feedbackStatus, error }, setState] = useState(getDefaultDialogState({ message: propsMessage }));
  const hasError = message !== null && typeof message !== 'string';
  const disableSubmit = hasError || feedbackStatus === FeedbackStatus.Sending;

  async function sendFeedback() {
    try {
      setState((prevState) => ({ ...prevState, feedbackStatus: FeedbackStatus.Sending }));
      await api.notification.sendSupportRequest({ appName: 'Material Data Center', comment: message }, altairOneBaseUrl);
      await api.account.sendComment({ appName: 'Material Data Center', comment: message }, altairOneBaseUrl);
      setState({ message: '', feedbackStatus: FeedbackStatus.Sent });
    } catch (e) {
      // TODO:
      setState({
        message,
        error: TextMapping.getUIText(TextMapping.UI_TEXT_FEEDBACK_ERROR_MESSAGE, texts),
        feedbackStatus: FeedbackStatus.Failed,
      });
    }
  }

  return (
    <Dialog
      hidden={hidden}
      minWidth={584}
      maxWidth={584}
      modalProps={{
        isBlocking: true,
        onDismissed,
      }}
      onDismiss={onDismiss}
    >
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
        <div style={{ width: '50%', height: '100%', marginLeft: '30px' }}>
          <div style={{ fontStyle: 'bold', fontSize: '16px', color: '#4D4D4D' }}>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_FREE_VERSION_FEATURES, texts))}
          </div>
          <div style={{ marginTop: '8px', marginRight: '30px' }}>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_CURRENT_VERSION_INCLUDES, texts))}
          </div>
          <div style={{ marginTop: '9px', lineHeight: '16px', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                marginRight: '9px',
                fontFamily: 'Unity-Font-Icon-20200218',
                fontSize: '16px',
                textAlign: 'center',
                background: '#63C9D1',
                borderRadius: '50%',
                color: '#FFFFFF',
              }}
            >
              
            </div>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_VIEW_MATERIALS, texts))}
          </div>

          <div style={{ marginTop: '9px', lineHeight: '16px', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                marginRight: '9px',
                fontFamily: 'Unity-Font-Icon-20200218',
                fontSize: '16px',
                textAlign: 'center',
                background: '#F08860',
                borderRadius: '50%',
                color: '#FFFFFF',
              }}
            >
              
            </div>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_ACCESS_TO_PLOTS, texts))}
          </div>

          <div style={{ marginTop: '9px', lineHeight: '16px', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                marginRight: '9px',
                fontFamily: 'Unity-Font-Icon-20200218',
                fontSize: '16px',
                textAlign: 'center',
                background: '#F08860',
                borderRadius: '50%',
                color: '#FFFFFF',
              }}
            >
              
            </div>
            Compare Material Plots
          </div>

          <div style={{ marginTop: '9px', lineHeight: '16px', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                marginRight: '9px',
                fontFamily: 'Unity-Font-Icon-20200218',
                fontSize: '16px',
                textAlign: 'center',
                background: '#F08860',
                borderRadius: '50%',
                color: '#FFFFFF',
              }}
            >
              
            </div>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_DOWNLOAD_CAE_MODELS, texts))}
          </div>
        </div>
        <div style={{ width: '1px', height: '317px', background: '#D8D8D8' }}></div>
        <div style={{ width: '50%', height: '100%', marginLeft: '30px' }}>
          <div style={{ fontStyle: 'bold', fontSize: '16px', color: '#4D4D4D' }}>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_PREMIUM_VERSION_FEATURES, texts))}
          </div>
          <div style={{ marginTop: '8px', marginRight: '30px' }}>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_GET_ALL_FEATURES, texts))}
          </div>
          <div style={{ marginTop: '9px', lineHeight: '16px', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                marginRight: '9px',
                fontFamily: 'Unity-Font-Icon-20200218',
                fontSize: '16px',
                textAlign: 'center',
                background: '#63C9D1',
                borderRadius: '50%',
                color: '#FFFFFF',
              }}
            >
              
            </div>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_VIEW_MATERIALS, texts))}
          </div>

          <div style={{ marginTop: '9px', lineHeight: '16px', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                marginRight: '9px',
                fontFamily: 'Unity-Font-Icon-20200218',
                fontSize: '16px',
                textAlign: 'center',
                background: '#63C9D1',
                borderRadius: '50%',
                color: '#FFFFFF',
              }}
            >
              
            </div>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_ACCESS_TO_PLOTS, texts))}
          </div>

          <div style={{ marginTop: '9px', lineHeight: '16px', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                marginRight: '9px',
                fontFamily: 'Unity-Font-Icon-20200218',
                fontSize: '16px',
                textAlign: 'center',
                background: '#63C9D1',
                borderRadius: '50%',
                color: '#FFFFFF',
              }}
            >
              
            </div>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_COMPARE_MATERIAL_PLOTS, texts))}
          </div>

          <div style={{ marginTop: '9px', marginBottom: '10px', lineHeight: '16px', display: 'flex', flexDirection: 'row' }}>
            <div
              style={{
                marginRight: '9px',
                fontFamily: 'Unity-Font-Icon-20200218',
                fontSize: '16px',
                textAlign: 'center',
                background: '#63C9D1',
                borderRadius: '50%',
                color: '#FFFFFF',
              }}
            >
              
            </div>
            {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_DOWNLOAD_CAE_MODELS, texts))}
          </div>

          <TextField
            multiline
            rows={4}
            resizable={false}
            onChange={(e, text) => setState((prevState) => ({ ...prevState, error: null, message: text }))}
            value={message}
            placeholder={TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_OPTIONAL_MESSAGE, texts))}
          />
          {feedbackStatus === FeedbackStatus.Failed && <span style={{ color: '#FF0000' }}>{error}</span>}
          {feedbackStatus === FeedbackStatus.Sent && (
            <span style={{ fontWeight: 'bold' }}>
              {TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_FEEDBACK_SENT_MESSAGE, texts))}
            </span>
          )}
          {feedbackStatus !== FeedbackStatus.Sent && (
            <PrimaryButton
              disabled={disableSubmit}
              text={TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_SUBMIT, texts))}
              styles={{ root: { width: 252, marginTop: 24 } }}
              onClick={sendFeedback}
            />
          )}
          {feedbackStatus === FeedbackStatus.Sent && (
            <DefaultButton
              text={TextMapping.getUIText(TextMapping.getUIText(TextMapping.UI_TEXT_CLOSE, texts))}
              styles={{ root: { width: 252, marginTop: 24 } }}
              onClick={onDismiss}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default UpgradeDialog;
