/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, DialogFooter, PrimaryButton, TextField } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import useCheckUniqueCriteriaName from '../../hooks/useCheckUniqueCriteriaName';
import BaseDialog from '../BaseDialog';
import { Root } from './styled';
import * as TextMapping from '../../../../../utils/textMapping';

const EMPTY_ARRAY = [];
const NOOP = () => {};

function SaveCriteriaAsDialog({ criteriaNames = EMPTY_ARRAY, title = null, hidden = true, onDismiss = NOOP, onSave = NOOP, texts }) {
  const [name, setName] = useState('');

  const error = useCheckUniqueCriteriaName({ name, names: criteriaNames });

  const handleSave = useCallback(
    e => {
      e.preventDefault();
      onSave(name);
    },
    [onSave, name]
  );

  const handleDismissed = useCallback(() => {
    setName('');
  }, []);

  return (
    <BaseDialog
      data-testid="SaveCriteriaAsDialog"
      dialogContentProps={{ styles: { innerContent: { fontSize: 11, paddingLeft: 20, width: 'auto' } } }}
      hidden={hidden}
      modalProps={{ onDismissed: handleDismissed }}
      onDismiss={onDismiss}
      title={title ? title : TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_SEARCH_CRITERIA_AS, texts)}
    >
      <form onSubmit={handleSave}>
        <Root>
          <span className="Label">{title ? title : TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_SEARCH_CRITERIA_AS, texts)}</span>
          <TextField
            className="Input"
            data-testid="SaveCriteriaAsDialog-Input"
            errorMessage={error}
            name="SaveSearchCriteriaAs"
            onChange={(e, value) => setName(value)}
            required
            value={name}
          />
        </Root>
      </form>
      <DialogFooter>
        <PrimaryButton
          data-testid="save-criteria-as-dialog-save"
          autoFocus
          disabled={name.trim().length === 0}
          onClick={handleSave}
          text={error ?  TextMapping.getUIText(TextMapping.UI_TEXT_OVERWRITE, texts) : TextMapping.getUIText(TextMapping.UI_TEXT_SAVE, texts)}
        />
        <DefaultButton
          data-testid="save-criteria-as-dialog-cancel"
          onClick={onDismiss}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_CANCEL, texts)}
        />
      </DialogFooter>
    </BaseDialog>
  );
}

SaveCriteriaAsDialog.propTypes = {
  criteriaNames: PropTypes.arrayOf(PropTypes.string),
  hidden: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSave: PropTypes.func,
};

function areEqual(prevProps, nextProps) {
  return prevProps.hidden === nextProps.hidden;
}

export default React.memo(SaveCriteriaAsDialog, areEqual);
