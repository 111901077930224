/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { styled } from '@fluentui/utilities';
import { Login as BaseLogin } from './Login.base';
import { getStyles } from './Login.styles';
import { LoginProps, DefaultLoginProps } from './Login.types';

const Login = styled(BaseLogin, getStyles, undefined, {
  scope: 'Login',
});

Login.propTypes = LoginProps;

Login.defaultProps = DefaultLoginProps;

export default Login;
