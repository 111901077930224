/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import isString from 'lodash/isString';

function validateString({ newDataItem }) {
  let { value, values } = newDataItem;

  if (newDataItem.values) {
    values = values.map(elem => validate(elem));
  } else {
    value = validate(value);
  }

  return { value, values };
}

function validate(value) {
  if (!isString(value)) {
    throw new TypeError(`"${value}" is not a string`);
  } else if (value.startsWith('.') || value.startsWith(' ')) {
    throw new TypeError(`Name should not start with dot (.) or an empty space`);
  }

  return value;
}

export default validateString;
