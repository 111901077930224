/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useState } from 'react';
import { IconButton, Link, Text } from '@fluentui/react';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { Cell, Content, Grid, Title } from './styled';

const styles = {
  root: {
    lineHeight: '20px',
    marginLeft: '10px',
    display: 'table',
  },
};

const emptyArray = [];

export function AdditionalServices({ className, position = 'horizontal', services = emptyArray, maxHeight = '100%', width = '100%' }) {
  const [allServices, setAllServices] = useState(services);

  const handleVisibility = (additionalServices) => {
    const newServices = [...allServices];
    const index = allServices.findIndex((e) => e.serviceName === additionalServices.serviceName);
    newServices[index] = { ...newServices[index], isOpen: !newServices[index].isOpen };
    setAllServices(newServices);
  };

  return (
    <Grid
      position={position}
      width={width}
      length={allServices.length}
      data-testid="AdditionalServices"
      className={classNames('ao-AdditionalService-root', className)}
    >
      {allServices &&
        allServices.map((services) => (
          <Cell
            maxHeight={maxHeight}
            key={services.serviceName}
            data-testid={`AdditionalServices-${services.serviceName}`}
            className="ao-AdditionalService-service"
          >
            <Title className="ao-AdditionalService-title">
              <Text style={{ fontSize: 16 }} className="ao-AdditionalService-titleText additionalServiceTitleText">
                {services.serviceName}
              </Text>
              {position === 'vertical' && (
                <IconButton
                  styles={{ root: { height: '22px', float: 'right' } }}
                  iconProps={{ iconName: services.isOpen ? 'ChevronDown' : 'ChevronUp' }}
                  onClick={() => handleVisibility(services)}
                  data-testid={`${services.serviceName}-Button-ExpandCollapse`}
                />
              )}
            </Title>
            <Content isOpen={services.isOpen} className="ao-AdditionalService-content">
              {services.serviceLinks.map((link) => {
                if (link.action) {
                  let onClick = () => {};

                  if (link.action.type === 'bbpagemodal') {
                    onClick = () => {
                      window.bbPageModal(null, 'page=' + link.action.arg);
                    };
                  }
                  return (
                    <Link
                      data-testid={`${services.serviceName}-Link-${link.title}`}
                      key={link.title}
                      className="ao-additionalService-link"
                      styles={styles}
                      href={link.url}
                      onClick={onClick}
                    >
                      {link.title}
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      key={link.title}
                      className="ao-AdditionalService-link"
                      styles={styles}
                      href={link.url}
                      target={link.target ? link.target : '_blank'}
                      data-testid={`${services.serviceName}-Link-${link.title}`}
                    >
                      {link.title}
                    </Link>
                  );
                }
              })}
            </Content>
          </Cell>
        ))}
    </Grid>
  );
}

AdditionalServices.propTypes = {
  altairOneBaseUrl: PropTypes.string,
  className: PropTypes.string,
  onlineHelpUrl: PropTypes.string,
  position: PropTypes.string,
  services: PropTypes.arrayOf(PropTypes.any),
  maxHeight: PropTypes.string,
  width: PropTypes.string,
};

export default AdditionalServices;
