/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { useEffect, useMemo, useRef } from 'react';

import { checkCallbackPolicyChanged, createConditionalCallback, getSocket, removeWatchingIds, updateWatchingIds } from './utils';

export function useSocketCallback(callback, callbackPolicy) {
  const prevCallbackPolicyRef = useRef(null);
  const prevCallbackPolicy = prevCallbackPolicyRef.current;

  const socket = useMemo(() => getSocket(), []);

  /* Memoize callbackPolicy to simply hook usage. Hook user doesn't need to worry about it. */
  const memoizedCallbackPolicy = useMemo(() => {
    const hasCallbackPolicyChange = checkCallbackPolicyChanged(callbackPolicy, prevCallbackPolicy);

    if (hasCallbackPolicyChange) {
      return callbackPolicy;
    }

    return prevCallbackPolicy;
  }, [callbackPolicy, prevCallbackPolicy]);

  useEffect(() => {
    if (!memoizedCallbackPolicy || !socket) {
      return () => {};
    }

    /* Store conditional callbacks in an object to reuse in socket.removeListener in the cleanup. */
    const conditionalCallbacks = {};

    Object.entries(memoizedCallbackPolicy).forEach(([eventType, eventIds]) => {
      conditionalCallbacks[eventType] = createConditionalCallback({
        callback,
        eventType,
        memoizedCallbackPolicy,
      });

      const conditionalCallback = conditionalCallbacks[eventType];

      /* Register event handler. */
      socket.on(eventType, conditionalCallback);

      updateWatchingIds(socket, conditionalCallback, new Set(eventIds));
    });

    return () => {
      Object.entries(conditionalCallbacks).forEach(([eventType, conditionalCallback]) => {
        socket.removeListener(eventType, conditionalCallback);
        removeWatchingIds(socket, conditionalCallback);
      });
    };
  }, [callback, memoizedCallbackPolicy, socket]);

  prevCallbackPolicyRef.current = callbackPolicy;
}
