/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
const LinkButtonStyle = {
  rootChecked: {
    opacity: 0.8,
    color: '#FFFFFF',
    background: '#3A393D',
  },
  rootHovered: {
    opacity: 0.8,
    color: '#FFFFFF',
    backgroundColor: '#3A393D',
  },
  root: {
    background: 'none',
    minWidth: 20,
    color: '#FFFFFF',
  },
  rootCheckedHovered: {
    backgroundColor: '#3A393D',
  },
  rootPressed: {
    backgroundColor: '#3A393D',
    color: '#FFFFFF',
    opacity: 0.8,
  },
};

const ButtonStyle = {
  icon: {
    color: '#4D4D4D',
    fontSize: 16,
    margin: 0,
  },
  label: {
    margin: 0,
  },
  iconChecked: {
    opacity: 0.8,
    // color: '#FFA500',
  },
  iconHovered: {
    opacity: 0.8,
    // color: '#FFA500',
  },
  root: {
    background: 'none',
    minWidth: 16,
    color: ' #4D4D4D',
    fontSize: 12,
    padding: 0,
    marginLeft: 12,
    border: 'none',
  },
  rootHovered: {
    background: 'none',
    color: 'black',
    opacity: 0.8,
  },
  rootChecked: {
    background: '#3A393D',
  },
  rootCheckedHovered: {
    backgroundColor: ' #4D4D4D',
  },
  rootPressed: {
    background: 'none',
    color: ' #4D4D4D',
    opacity: 0.8,
  },
};

const Count = {
  rootChecked: {
    opacity: 1,
    color: '#FFFFFF',
    background: 'transparent',
  },
  rootHovered: {
    opacity: 0.8,
    color: '#FFFFFF',
    backgroundColor: 'transparent',
  },
  root: {
    position: 'absolute',
    top: 5,
    marginLeft: -20,
    background: 'none',
    minWidth: 20,
    color: '#FFFFFF',
    fontSize: 11,
    zIndex: 1,
  },
  rootCheckedHovered: {
    backgroundColor: 'transparent',
    color: '#FFFFFF',
  },
  rootPressed: {
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    opacity: 1,
  },
};

export { ButtonStyle, LinkButtonStyle, Count };
