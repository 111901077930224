/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const AnnouncementBanner = styled.div`
  flex-shrink: 0;
`;

const Content = styled.div`
  display: flex;
  flex: 1 0 auto;
  width: 100vw;
  flex-direction: column;
  position: relative;
`;

const Wrap = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  overflow: hidden;
`;

export { AnnouncementBanner, Content, Wrap };
