/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { AOneUnityTheme } from '@altair/a365-themes-amdc';
import '@altair/a365-themes-amdc/css/NotoSans.css';
import '@altair/a365-themes-amdc/css/UnityFontIcon.css';
import { notify } from '../gooey/components/NotificationAlert';
import '../gooey/components/NotificationAlert/notification-alert.css';
import { ApolloProvider } from '@apollo/react-hooks';
import 'file-icon-vectors/dist/file-icon-vivid.min.css';
import { Fabric } from '@fluentui/react';
import { classNamesFunction } from '@fluentui/utilities';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { TopbarProvider } from '../topbar/main';

import herems from './herems';
import apolloClient from './apollo';
import ErrorBoundary from './components/ErrorBoundary';
import './assets/app.css';
import history from './common/history';
import Main from './components/Main';
import store from './store';
import { createChildAppApi } from './api';
import AutoLoggedOutDialog from './components/AutoLoggedOutDialog';
import OktaImplicitCallback from './components/OktaImplicitCallback';
import AutoLogin from './components/AutoLogin';
import { Footer } from './components/Footer';
import { AppFrameProvider } from './context/AppFrameProvider';
import { LoginHelp } from './components/LoginHelp';
import { authApi, AuthEventType } from './AuthApi';
import { createUnityTheme } from '../themes/CustomTheme';

notify.configure();

export const darkTheme = {
  palette: {
    themePrimary: '#0dbd0d',
    themeLighterAlt: '#010801',
  },
};

const getClassNames = classNamesFunction();
const classNames = getClassNames({
  root: { fontSize: 12, position: 'relative', display: 'flex', flexDirection: 'column', height: '100%' },
  container: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  panelPortal: {
    position: 'fixed',
    top: 34,
    bottom: 0,
    right: 0,
    zIndex: 12,
  },
});

const DEFAULT_CONFIG = {
  notificationEnabled: true,
  enableUsercentrics: false,
  enableGoogleAnalytics: false,
  enableHubSpot: false,
  enableFullStory: false,
};

function App(props) {
  const { appNames, children: allChildren, config = DEFAULT_CONFIG, defaultAppName, theme, setTheme, appContent } = props;

  const children = React.Children.toArray(allChildren); // removes non-component children
  const [hideAutoLoggedOutDialog, setHideAutoLoggedOutDialog] = useState(true);

  useEffect(() => {
    appNames.forEach((appName) => {
      createChildAppApi(appName);
    });
  }, [appNames]);

  useEffect(() => {
    herems.on('LOGGEDOUT', () => {
      authApi.loggedIn = false;
      authApi.executeHandlers(AuthEventType.Logout);
      setHideAutoLoggedOutDialog(false);
    });
    herems.on('LOGGEDIN', ({ user }) => {
      const currentLoggedInUserId = store.getState().loggedInUser?.id;

      // If multiple tabs are opened and one tab is in the process of refresh
      if (currentLoggedInUserId === null) {
        return;
      }
      if (currentLoggedInUserId !== user.id) {
        window.location.href = '/';
      } else {
        if (!authApi.loggedIn) {
          authApi.loggedIn = true;
          // TODO: Some bug, both tab keep on refreshing in infinite loop
          // store.dispatch(replaceLoggedInUser(user));
          authApi.executeHandlers(AuthEventType.Login);
          setHideAutoLoggedOutDialog(true);
        }
      }
    });
  }, []);

  const [panelPortalNode, setPanelPortalNode] = useState();

  const notificationEnabled = config?.notificationEnabled ?? true;
  const appVersion = config?.appVersion;
  const onlineHelpUrl = config?.onlineHelpUrl;

  let semanticColors = null;
  if (appContent && appContent.config && appContent.config.semanticcolors && theme && appContent.config.semanticcolors[theme]) {
    semanticColors = appContent.config.semanticcolors[theme];
  } else if (appContent && appContent.config && appContent.config.semanticcolors && theme && !appContent.config.semanticcolors[theme]) {
    semanticColors = appContent.config.semanticcolors['light'];
    setTheme('light');
  } else if (sessionStorage.getItem('semanticcolors')) {
    semanticColors = JSON.parse(sessionStorage.getItem('semanticcolors'));
  }

  return (
    <AppFrameProvider
      notificationEnabled={notificationEnabled}
      panelPortalNode={panelPortalNode}
      appVersion={appVersion}
      onlineHelpUrl={onlineHelpUrl}
    >
      <AOneUnityTheme theme={createUnityTheme(semanticColors)}>
        <ErrorBoundary activeAppName={defaultAppName}>
          <TopbarProvider>
            <AutoLoggedOutDialog hidden={hideAutoLoggedOutDialog} />
            <Fabric className={classNames.root} style={{ height: '100vh' }}>
              <div className={classNames.container + ' ' + theme}>
                <Router history={history}>
                  <ReduxProvider store={store}>
                    <ApolloProvider client={apolloClient}>
                      <Switch>
                        <Route path="/login/help" component={LoginHelp} />
                        <Route path="/login" component={AutoLogin} />
                        <Route path="/implicit/callback" component={OktaImplicitCallback} />
                        <Route
                          path="/logout/callback"
                          render={(routeProps) => {
                            const state = new URLSearchParams(routeProps.location.search).get('state') ?? '/';
                            return <Redirect to={state} />;
                          }}
                        />
                        <Route
                          path="/"
                          render={(routeProps) => {
                            const activeAppName = appNames.find((appName) => appName?.toLowerCase() === 'materials');
                            const { pathname } = routeProps.location;

                            if (!activeAppName) {
                              return <Redirect to={`/`} />;
                            }

                            if (pathname.startsWith('/Materials')) {
                              let newpathname = pathname.replace('/Materials', '');
                              return <Redirect to={newpathname + routeProps.location.search} />;
                            }

                            return (
                              <Main
                                activeAppName={activeAppName}
                                appNames={appNames}
                                defaultAppName={defaultAppName}
                                pathname={pathname}
                                enableFullStory={config?.enableFullStory}
                              >
                                {children}
                              </Main>
                            );
                          }}
                        />
                        <Route>
                          <Redirect to={`/`} />
                        </Route>
                      </Switch>
                    </ApolloProvider>
                  </ReduxProvider>
                </Router>
              </div>
              <Footer styles={{ flexShrink: 0 }} />
              <div className={classNames.panelPortal} ref={(node) => setPanelPortalNode(node)} />
            </Fabric>
          </TopbarProvider>
        </ErrorBoundary>
      </AOneUnityTheme>
    </AppFrameProvider>
  );
}

App.propTypes = {
  appNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  config: PropTypes.shape({
    appVersion: PropTypes.string,
    notificationEnabled: PropTypes.bool,
    enableUsercentrics: PropTypes.bool,
    enableGoogleAnalytics: PropTypes.bool,
    enableHubSpot: PropTypes.bool,
    enableFullStory: PropTypes.bool,
  }),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  defaultAppName: PropTypes.string.isRequired,
};

export * from './components/Header/useApplications';
export * from './context/useAppFrame';
export * from './AuthApi';

export default App;
