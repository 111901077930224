/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

const { useContext } = require('react');
const { AppFrameContext } = require('./AppFrameContext');

export function useAppFrame() {
  return useContext(AppFrameContext);
}
