/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
function updateFilters({ filters, newFilter }) {
  if (!filters || !newFilter) return filters;

  function mapChildren(childFilters) {
    const newFilters = childFilters.map(filter => {
      if (filter.id === newFilter.id) {
        return newFilter;
      }

      if (filter.children) {
        filter.children = mapChildren(filter.children);
      }

      return filter;
    });

    return newFilters;
  }

  return mapChildren(filters);
}

export default updateFilters;
