/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
const parseColor = (color, format) => {
  if (format === 'array') {
    return {
      r: color[0],
      g: color[1],
      b: color[2],
      a: color[3] === undefined ? 1 : color[3],
    };
  }

  return color;
};

export default parseColor;
