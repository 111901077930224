/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
export function checkCollectionNames({ allowedCollectionName, entities }) {
  if (!allowedCollectionName) {
    return true;
  }

  /* All entities must have supported a collectionName. */
  return entities.every(entity => {
    return allowedCollectionName.includes(entity.system.collectionName);
  });
}
