const mathjs = require('mathjs');

mathjs.createUnit({
	Nm: '1 N * m',
	tenmins: '10 mins',
	fiveteenmins: '15 mins',
	fiveteenmm: '15 mm',
	ksi: '1000 psi',
	slug: '14.5939029 kg',
	Msi: ' 1000000 psi',
	point: '0.0003527778 m',
	gravity: '9.80665 m/s^2',
	tex: { definition: '1 g / km', prefixes: 'short' },
	denier: { definition: '0.1111111111111111 tex', aliases: ['den'] },
	Emin3: '1e-3',
	Emin4: '1e-4',
	Emin6: '1e-6'
});

const isSameUnit = (unit1, unit2) => {
  return unit1 === unit2 || (unit1 + '').replace('^', '') === (unit2 + '').replace('^', '');
};

//@todo use regex to transfer 1/* to *^-1
export const transfer2MathjsUnit = unit => {
  const transferUnit = (unit, beingReplace, replaced) => {
    return unit.replace(beingReplace, replaced);
  };
  const beingTransferredList = [
		{ usingUnit: '⁹', mathjsUnit: '^9' },
		{ usingUnit: '⁸', mathjsUnit: '^8' },
		{ usingUnit: '⁷', mathjsUnit: '^7' },
		{ usingUnit: '⁶', mathjsUnit: '^6' },
		{ usingUnit: '⁵', mathjsUnit: '^5' },
		{ usingUnit: '⁴', mathjsUnit: '^4' },
		{ usingUnit: '³', mathjsUnit: '^3' },
		{ usingUnit: '²', mathjsUnit: '^2' },
		{ usingUnit: '¹', mathjsUnit: '^1' },
		{ usingUnit: '⁰', mathjsUnit: '^0' },
		{ usingUnit: '₀', mathjsUnit: '0' },
		{ usingUnit: '₁', mathjsUnit: '1' },
		{ usingUnit: '₂', mathjsUnit: '2' },
		{ usingUnit: '₃', mathjsUnit: '3' },
		{ usingUnit: '₄', mathjsUnit: '4' },
		{ usingUnit: '₅', mathjsUnit: '5' },
		{ usingUnit: '₆', mathjsUnit: '6' },
		{ usingUnit: '₇', mathjsUnit: '7' },
		{ usingUnit: '₈', mathjsUnit: '8' },
		{ usingUnit: '₉', mathjsUnit: '9' },
		{ usingUnit: '°', mathjsUnit: 'deg' },
		{ usingUnit: 'µ', mathjsUnit: 'u' },
		{ usingUnit: 'μ', mathjsUnit: 'u' },
		{ usingUnit: 'min', mathjsUnit: 'mins' },
		{ usingUnit: '10mins', mathjsUnit: 'tenmins' },
		{ usingUnit: '15mins', mathjsUnit: 'fiveteenmins' },
		{ usingUnit: '15mm', mathjsUnit: 'fiveteenmm' },
		{ usingUnit: '1/ms', mathjsUnit: 'ms^-1' },
		{ usingUnit: '1/s', mathjsUnit: 's^-1' },
		{ usingUnit: '1/mins', mathjsUnit: 'mins^-1' },
		{ usingUnit: '1/K', mathjsUnit: 'K^-1' },
		{ usingUnit: '1/°C', mathjsUnit: 'degC^-1' },
		{ usingUnit: '1/Pa', mathjsUnit: 'Pa^-1' },
		{ usingUnit: '1/mm', mathjsUnit: 'mm^-1' },
		{ usingUnit: 'Ohm', mathjsUnit: 'ohm' },
		/*{ usingUnit: 'E-3 m3/kg', mathjsUnit: 'm3/Mg' },
		{ usingUnit: 'E-3 m^3/kg', mathjsUnit: 'm3/Mg' },
		{ usingUnit: 'E-6 mm3/Nm', mathjsUnit: 'mm3/MNm' },
		{ usingUnit: 'E-6 mm^3/Nm', mathjsUnit: 'mm3/MNm' },
		{ usingUnit: 'E-6/K', mathjsUnit: 'Emin6K' },*/
		{ usingUnit: 'E-3', mathjsUnit: 'Emin3' },
		{ usingUnit: 'E-4', mathjsUnit: 'Emin4' },
		{ usingUnit: 'E-6', mathjsUnit: 'Emin6' },
		{ usingUnit: 'Points', mathjsUnit: '1' },
		{ usingUnit: 'points', mathjsUnit: '1' },
		{ usingUnit: '#', mathjsUnit: '1' },
		{ usingUnit: 'Dg', mathjsUnit: 'dg' },
		{ usingUnit: 'CO2 eq.', mathjsUnit: '' },
		{ usingUnit: 'CO2', mathjsUnit: '' },
		{ usingUnit: 'GWP', mathjsUnit: '' }
  ];

  for (const beingTransferredUnit of beingTransferredList) {
    if (unit.includes(beingTransferredUnit.usingUnit)) {
      unit = transferUnit(unit, beingTransferredUnit.usingUnit, beingTransferredUnit.mathjsUnit);
    }
  }
  return unit;
};

function convertMathUnit(sourceValue, sourceUnit, targetUnit) {
	if (
		(!sourceValue && sourceValue !== 0) ||
		!sourceUnit ||
		!targetUnit ||
		isNaN(parseFloat(sourceValue)) ||
		typeof sourceValue === 'object' ||
		isSameUnit(sourceUnit, targetUnit)
	) {
		return sourceValue;
	}
	sourceUnit = transfer2MathjsUnit(sourceUnit);
	targetUnit = transfer2MathjsUnit(targetUnit);
	if (isSameUnit(sourceUnit, targetUnit)) {
		return sourceValue;
	}
	try {
		const sourceObj = mathjs.unit(sourceValue, sourceUnit);
		// return sourceObj.toNumber(targetUnit);
		return Number(mathjs.format(sourceObj.toNumber(targetUnit), 10));
	} catch (e) {
		//console.log(e, sourceValue, sourceUnit, targetUnit);
		return '';
	}
}

/*
  using math.evaluate assumes, that the source value is given in the base unit!
*/
export function convertUnit(sourceValue, source, target) {
	try {
		if (!source || !target || (typeof source === 'string' && typeof target === 'string')) {
			return convertMathUnit(sourceValue, source, target);
		}
		let formula = target.formula && target.formula.length ? target.formula : false;
		let unit = target.unit ? target.unit : target.baseunit;
		if (formula) {
			formula = transfer2MathjsUnit(formula);
			if (formula[0] === ':') {
				unit = transfer2MathjsUnit(formula.substring(1));
				formula = false;
			}
		}
		let sourceunit = typeof source === 'string' ? source : source.unit;
		return formula
			? mathjs.evaluate(`number( ${sourceValue} * ${formula}, ${transfer2MathjsUnit(sourceunit)})`)
			: convertMathUnit(sourceValue, sourceunit, unit);
	} catch (e) {
		//console.log(e, sourceValue, source, target);
		return '';
	}
}
