/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import { v4 as uuidV4 } from 'uuid';

/*
  - Not exactly sure why this is needed, but it solves the "missing data" issue when using fragments in queries.
  - Without it:
    - "WARNING: heuristic fragment matching going on!" shows in console.
    - "Missing field <field name> in" shows in console.
  - Found this solution in this issue: https://github.com/apollographql/apollo-client/issues/3397#issuecomment-421433032
*/
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [],
    },
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const headersLink = setContext((_, { headers }) => {
  const nextHeaders = {
    ...headers,
    sourceid: `AOneUI-${uuidV4()}`,
  };

  return {
    headers: nextHeaders,
  };
});

const httpLink = new HttpLink({
  uri: '/graphql/',
});

const link = ApolloLink.from([errorLink, headersLink, httpLink]);

const dataIdFromObject = object => {
  switch (object.__typename) {
    case 'Branch':
      return null;
    case 'Version':
      return null;
    default:
      return defaultDataIdFromObject(object); // fall back to default handling
  }
};

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({ dataIdFromObject, fragmentMatcher }),
  link,
});

export default apolloClient;
