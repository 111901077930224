/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';

export const LoginHelpProps = {
  styles: PropTypes.func,
  theme: PropTypes.object,
  userId: PropTypes.string,
};

export const LoginHelpDefaultProps = {};
