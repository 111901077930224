import React, { useMemo } from 'react';

function AdvancedSearchRow({
  child,
  errorMessage,
  getComponentKey,
  parentMatchedFilter,
  matchedFilter,
  margin,
  input,
  range,
  getDisplayName,
}) {
  const divStyle = useMemo(() => {
    return { border: errorMessage ? '1px solid #FF0000' : '', width: '100%' };
  }, [errorMessage]);

  const rowStyle = useMemo(() => {
    let display = parentMatchedFilter || matchedFilter;

    if (child.type === 'Spt' && !range) {
      display = false;
    }

    return {
      display: display ? 'flex' : 'none',
      marginLeft: '23px',
      gap: '5px',
      paddingTop: '5px',
      paddingBottom: '5px',
      alignItems: 'center',
      width: '100%',
      borderBottom: '1px solid #DDDDDD',
    };
  }, [parentMatchedFilter, matchedFilter, child, range]);

  const nameStyle = useMemo(() => {
    return { width: '40%', paddingLeft: margin + 'px' };
  }, [margin]);

  const inputStyle = useMemo(() => {
    return { display: 'flex', gap: '5px' };
  }, []);

  const unitStyle = useMemo(() => {
    return { width: '15%' };
  }, []);

  const rangeStyle = useMemo(() => {
    return { width: '20%' };
  }, []);

  const inputWrapperStyle = useMemo(() => {
    return { width: '25%' };
  }, []);

  return (
    <div className="bodyText" key={getComponentKey(child, 'tablerow')} style={divStyle}>
      <div style={rowStyle}>
        <div style={nameStyle}>
          {getDisplayName(child)}
          {child.condition ? ', ' + child.condition : ''} {child.standard_name ? '(' + child.standard_name + ')' : ''}
        </div>
        <div style={inputWrapperStyle}>
          <div style={inputStyle}>{input}</div>
        </div>
        <div style={unitStyle}>{child.unit_name}</div>
        <div style={rangeStyle}>{child.vtype === 'numeric' ? range : ''}</div>
      </div>
    </div>
  );
}

export default AdvancedSearchRow;
