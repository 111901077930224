/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import gql from 'graphql-tag';

export const GET_ENTITY_QUERY = gql`
  query QUERY($collectionName: String!, $id: ID!) {
    entity(collectionName: $collectionName, id: $id) {
      id
      attributes
      contentType
      system
      createdUser {
        id
        username
      }
      modifiedUser {
        id
        username
      }
      ... on File {
        jsonSchema
        path
      }
      ... on Directory {
        jsonSchema
        path
      }
    }
  }
`;

export const UPDATE_ENTITY_MUTATION = gql`
  mutation UPDATE_ENTITY($entityInput: UpdateEntityInput!) {
    updateEntity(entityInput: $entityInput) {
      id
      attributes
      system
    }
  }
`;

export const UPDATE_CONTENT_TYPE_MUTATION = gql`
  mutation UPDATE_CONTENT_TYPE($updateContentTypeInput: UpdateContentTypeInput!) {
    updateContentType(updateContentTypeInput: $updateContentTypeInput) {
      id
    }
  }
`;
