/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import PropTypes from 'prop-types';

export const NotificationBellIconProps = {
  count: PropTypes.number,
  hasNotifications: PropTypes.bool,
  onClick: PropTypes.func,
};
