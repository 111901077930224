import { getPlaceholderStyles } from '@fluentui/style-utilities';

export const getStyles = ({ theme }) => {
  const { semanticColors } = theme;

  return {
    root: {
      display: 'flex',
      flex: '1 0 auto',
      width: '100vw',
      position: 'relative',
    },
    brandmark: {
      position: 'absolute',
      top: 60,
      left: 60,
    },
    container: {
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    productName: {
      fontSize: 28,
      fontWeight: 'bold',
      color: '#4D4D4D',
      marginBottom: 14,
    },
    productTradeMark: {
      fontSize: 9,
    },
    messageContainer: {
      marginTop: 6,
    },
    errorLabel: {
      color: semanticColors.errorText,
      textAlign: 'center',
    },
    pendingLabel: {
      textAlign: 'center',
    },
    buttonContainer: {
      marginTop: 16,
      display: 'flex',
      selectors: {
        '& > .aone-Login-signupbutton~.aone-Login-loginbutton': {
          marginLeft: 12,
        },
      },
    },
    subComponentStyles: {
      button: {
        root: {
          flex: '1 0 auto',
        },
      },
      textField: {
        root: {
          marginBottom: 2,
          width: 308,
        },
        fieldGroup: {
          height: 36,
          padding: '4px 8px',
        },
        field: [
          {
            fontSize: 14,
          },
          getPlaceholderStyles({ fontSize: 14 }),
        ],

        suffix: {
          background: 'transparent',
          paddingRight: 0,
        },
        subComponentStyles: {
          label: {
            root: {
              fontSize: 14,
            },
          },
        },
      },
    },
  };
};
