/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Badge = styled.span`
  font-size: 12px;
  color: #a2aaad;
  line-height: 16px;
  margin-left: 4px;
`;

const FilterInput = styled.div`
  flex-grow: 1;
`;

const FilterRow = styled.div`
  display: flex;
  margin-bottom: 4px;
  margin-top: 4px;
  padding: 0px 8px;
`;

const ExpandableFilter = styled.div`
  display: flex;
  flex-direction: row;
`;

export { Badge, ExpandableFilter, FilterInput, FilterRow };
