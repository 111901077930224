/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CommentDialog from '../CommentDialog';

const NOOP = () => {};

function getDefaultDialogState() {
  return {
    message: undefined,
    feedbackStatus: 'new',
    error: null,
  };
}

export const FeedbackDialog = ({ altairOneBaseUrl = '', hidden = true, onDismiss = NOOP }) => {
  const [{ message, feedbackStatus, error }, setState] = useState(getDefaultDialogState());

  async function sendFeedback() {
    try {
      setState(prevState => ({ ...prevState, feedbackStatus: 'sending' }));
      const feedbackResponse = await fetch(`${altairOneBaseUrl}/notification/feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message }),
        credentials: 'include',
      });

      if (feedbackResponse.ok) {
        setState({ message: '', feedbackStatus: 'sent' });
      } else {
        setState({ message, feedbackStatus: 'failed', error: 'Unable to submit feedback at this time. Please try again later' });
      }
    } catch (e) {
      setState({
        message,
        error: 'Unable to submit feedback at this time. Please try again later',
        feedbackStatus: 'failed',
      });
    }
  }

  return (
    <CommentDialog
      hidden={hidden}
      onDismiss={onDismiss}
      successMsg="Thank you for your feedback"
      title="Leave Feedback"
      onChange={(e, message) => setState(prevState => ({ ...prevState, error: null, message }))}
      onDismissed={() => {
        setState(getDefaultDialogState());
      }}
      onSubmitClick={sendFeedback}
      message={message}
      status={feedbackStatus}
      failureMsg={error}
      commentBoxProps={{ placeholder: 'Add your feedback', rows: 5 }}
    />
  );
};

FeedbackDialog.propTypes = {
  altairOneBaseUrl: PropTypes.string,
  hidden: PropTypes.bool,
  onDismiss: PropTypes.func,
};

export default FeedbackDialog;
