/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
export function clearViewedEntity() {
  return {
    type: 'CLEAR_VIEWED_ENTITY',
  };
}

export function replaceViewedEntity(entity) {
  return {
    type: 'REPLACE_VIEWED_ENTITY',
    entity,
  };
}
