/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { useState, useCallback, useEffect } from 'react';
import { captchaChallenge } from './captchaChallenge';
import { imageFromString } from './imageFromString';

export function useCaptcha() {
  const [image, setImage] = useState();
  const [challenge, setChallenge] = useState();

  const regenerate = useCallback(() => {
    const nextChallenge = captchaChallenge();
    const nextImage = imageFromString(nextChallenge);
    setChallenge(nextChallenge);
    setImage(nextImage);
  }, []);

  const verify = useCallback(
    input => {
      return challenge === input;
    },
    [challenge]
  );

  useEffect(() => {
    regenerate();
  }, [regenerate]);

  return { image, regenerate, verify };
}
