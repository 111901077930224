/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import get from 'lodash/get';

export function getInitialActiveTabKey(props) {
  const { children, defaultActiveTab, activeTab } = props;

  if (!children) return null;

  return activeTab || defaultActiveTab || get(React.Children.toArray(children), '[0].props.name');
}
