/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;
  & > .ChangeViewButton:not(:last-child) {
    margin-right: 8px;
  }
`;

export { Root };
