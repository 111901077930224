/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
export function checkPermissions({ allowedPermissions, entities }) {
  if (!allowedPermissions) {
    return true;
  }

  /* All entities must have at least 1 supported permission. */
  return entities.every(entity => {
    if (!Array.isArray(entity.permissions)) {
      return false;
    }

    return allowedPermissions.some(allowedPermissionName => {
      return entity.permissions.includes(allowedPermissionName);
    });
  });
}
