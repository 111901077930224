/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import get from 'lodash/get';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import IFrame from './IFrame';
import Preview from '../../Preview';
import ImageViewer from '../../../viewers/ImageViewer';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

function EntityViewer({ entity, onAddPreviewClick, onDeletePreviewClick, showComments, viewer }) {
  const isMountedRef = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
  const { id, name } = viewer || EMPTY_OBJECT;
  let { url } = viewer || EMPTY_OBJECT;
  const urlRef = useRef(url);

  const isPreviewViewer = name === 'Preview';
  const isImageViewer = name === 'Image';

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isPreviewViewer) {
      setIsLoading(false);
    } else if (urlRef.current !== url) {
      urlRef.current = url;
      setIsLoading(true);
    }
  }, [isPreviewViewer, url]);

  function handleLoad() {
    if (!isMountedRef.current) return;

    setIsLoading(false);
  }

  if (!viewer) return null;

  const isReadonly = get(entity, 'system.readonly', false);
  const previews = get(viewer, 'previews', EMPTY_ARRAY);

  if (isPreviewViewer) {
    return (
      <Preview
        entity={entity}
        previews={previews}
        noAddPreview
        noDeletePreview={isReadonly}
        onAddPreviewClick={onAddPreviewClick}
        onDeletePreviewClick={onDeletePreviewClick}
      />
    );
  }

  if (isImageViewer) {
    return <ImageViewer id={id} onLoad={handleLoad} />;
  }

  if (url && showComments) {
    url += '&showComments=true';
  }

  return <IFrame onLoad={handleLoad} isLoading={isLoading} id={id} url={url} />;
}

EntityViewer.propTypes = {
  entity: PropTypes.shape({
    attributes: PropTypes.shape({
      isGeneratingPreview: PropTypes.bool,
      name: PropTypes.string,
      previewError: PropTypes.string,
    }).isRequired,
    system: PropTypes.shape({
      contentType: PropTypes.string,
    }).isRequired,
  }),
  onAddPreviewClick: PropTypes.func,
  onDeletePreviewClick: PropTypes.func,
  showComments: PropTypes.bool,
  viewer: PropTypes.shape({
    isPreview: PropTypes.bool,
    src: PropTypes.string,
    url: PropTypes.string,
    previews: PropTypes.arrayOf(
      PropTypes.shape({
        group: PropTypes.string,
        internal: PropTypes.string,
        preview: PropTypes.string,
        src: PropTypes.string,
        thumbnail: PropTypes.string,
      })
    ),
  }),
};

EntityViewer.defaultProps = {
  viewer: null,
};

export default EntityViewer;
