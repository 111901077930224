/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import cloneDeep from 'lodash/cloneDeep';

import defaultSchema from '../data/defaultSchema';

function createDefaultSchema(entity) {
  const newDefaultSchema = cloneDeep(defaultSchema);

  return newDefaultSchema;
}

export { createDefaultSchema };
