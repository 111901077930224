/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
export const getStyles = props => {
  const { theme } = props;

  return {
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
      width: '85%',
      selectors: {
        '@media only screen and (max-width: 600px)': {
          width: '95%',
        },
      },
    },
    innerContainer: {
      padding: '24px 24px 8px',
    },
    header: {
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 700,
    },
    languageSelector: {
      width: 120,
      margin: '8px 0',
    },
    body: {
      overflowY: 'auto',
      maxHeight: 'calc(100vh - 380px)',
      selectors: {
        '@media only screen and (max-height: 600px)': {
          maxHeight: 'calc(100vh - 180px)',
        },
      },
    },
    eulaAction: {
      display: 'flex',
      justifyContent: 'center',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      padding: 20,
      borderTop: '1px solid #d2d5d7',
    },
    eulaError: {
      flexGrow: 1,
      color: theme.palette.red,
    },
    footerButton: {
      width: 120,
    },
  };
};
