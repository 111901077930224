/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
/*
 * Higher-order function, whose purpose is to give conditionalCallback access to eventType.
 */
export function createConditionalCallback({ callback, eventType, memoizedCallbackPolicy }) {
  /* Only call callback if the callbackPolicy dictates it. */
  return function conditionalCallback(message) {
    const messageIds = message.payload.refs.map(ref => ref.id);
    const hasIdMatch =
      memoizedCallbackPolicy[eventType].length > 0 && memoizedCallbackPolicy[eventType].some(id => messageIds.includes(id));

    if (hasIdMatch) {
      callback(message);
    }
  };
}
