/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ResizablePanel } from '../ResizablePanel';
import { UserAccount } from '../UserAccount';
import { useTopbar } from '../../context';

function Panels(props) {
  const { activePanelId, onDismiss } = props;
  const [, setRender] = useState(false); // Force a render to update Panel if it is resized

  const { accountPanelProps, altairAdminBaseUrl, altairOneBaseUrl, userProfile } = useTopbar();

  return (
    <div style={{ position: 'relative' }}>
      <ResizablePanel
        isOpen={activePanelId === 'ACCOUNT'}
        onDismiss={onDismiss}
        onResizeStop={() => setRender(true)}
        onResizeStart={() => setRender(false)}
      >
        {activePanelId === 'ACCOUNT' && (
          <UserAccount
            altairAdminBaseUrl={altairAdminBaseUrl}
            altairOneBaseUrl={altairOneBaseUrl}
            onDismiss={onDismiss}
            userProfile={userProfile}
            {...accountPanelProps}
          />
        )}
      </ResizablePanel>
    </div>
  );
}

Panels.propTypes = {
  activePanelId: PropTypes.string,
  onDismiss: PropTypes.func,
};

Panels.defaultProps = {
  activePanelId: null,
  onDismiss: () => {},
};

export default Panels;
