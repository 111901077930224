/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';
import { notify, NotificationType } from '../../../gooey/main';

import contentApi from './content';

async function getOpenWithAction(id, contentType = 'OpenWithAction') {
  const url = '/action/actions';

  const params = {
    contentType,
    id,
  };
  const { data } = await axios.get(url, { params });
  return data;
}

const EDIT_RUNNER = 'InteractiveEditActionRunner';

async function createLaunchAction(appName) {
  const payload = {
    attributes: {
      inputs: {
        application: appName,
      },
      status: 'Created',
      name: appName.replace(' ', '_'),
      runHook: {
        contentType: 'PythonFile',
        path: '/a365/content-packs/altair/actions/ApplicationLaunch/run.py',
      },
      postHook: {
        contentType: 'PythonFile',
        path: '/a365/content-packs/altair/actions/ApplicationLaunch/post.py',
      },
    },
  };

  const res = await fetch(`/content/contents?contentType=${EDIT_RUNNER}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status !== 200) {
    const err = await res.json();
    throw new Error(err.message);
  }

  const action = await res.json();

  return action;
}

async function launchApplication(appName) {
  // Optimistic notification. Backend notification won't appear as alert for this create event.
  notify({ text: `${appName} launch triggered`, type: NotificationType.INFO });

  const action = await createLaunchAction(appName);
  window.open(`/Session?contentType=${action.system.contentType}&id=${action.id}`);
}

async function getActiveSessions(loggedInUserId) {
  const url = `/search/search/query`;

  const body = {
    contentType: 'InteractiveEditActionRunner',
    payload: {
      filter: {
        $and: [
          {
            $eq: ['system.createdUser', '@loggedInUserId'],
          },
          {
            $in: ['attributes.status', '@statuses'],
          },
        ],
      },
      bindVars: {
        loggedInUserId,
        statuses: ['Created', 'Running', 'Queued', 'Hold', 'Waiting', 'Exiting', 'Suspended', 'Abort', 'Aborting'],
      },
      pagination: {
        offset: 0,
        limit: 50,
        sort: [
          {
            field: 'system.createdTime',
            direction: 'ASC',
          },
        ],
      },
    },
  };

  const { data } = await axios.post(url, body);
  return data;
}

async function getSessionsByIds({ contentType = EDIT_RUNNER, ids } = {}) {
  return contentApi.getByIdsAndContentType({ contentType, ids });
}

async function getEditSessions(ids) {
  const url = '/content/contents/details/bulk';
  const body = {
    ids,
    contentType: EDIT_RUNNER,
    mapping: {
      EditSession: {
        filter: "$.relations[?(@.system.type == 'outputs')].to",
        isArray: true,
      },
      status: {
        filter: '$.attributes.status',
        isArray: false,
      },
      actionId: {
        filter: '$.id',
        isArray: false,
      },
    },
  };
  const { data } = await axios.post(url, body);

  return data;
}
export default {
  createLaunchAction,
  getActiveSessions,
  getOpenWithAction,
  getSessionsByIds,
  launchApplication,
  getEditSessions,
};
