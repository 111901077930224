/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DirectionalHint, Icon, TooltipHost } from '@fluentui/react';

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import SpinnerIcon from './SpinnerIcon';

export function JobIcon({ title = '', width, status }) {
  /* Used to render extension icons when the content image can't be loaded. */
  const [forceRenderExtensionIcon, setForceRenderExtensionIcon] = useState(false);

  let color = '#2DCCD3';
  let iconName = 'Suitcase';
  switch (status) {
    case 'Completed':
      iconName = 'CompletedSolid';
      color = '#47A23F';
      break;
    case 'Created':
      iconName = 'StepIn';
      color = '#47A23F';
      break;
    case 'Hold':
    case 'Suspended':
      iconName = 'JobSuspend';
      color = '#FA4616';
      break;
    case 'Abort':
    case 'Aborting':
    case 'Aborted':
    case 'Failed':
      iconName = 'WarningTriangle';
      color = '#FA4616';
      break;
    case 'Exiting':
      iconName = 'StepOut';
      color = '#FA4616';
      break;
    case 'Running':
    case 'Queued':
    case 'Waiting':
      iconName = 'Spinner';
      break;
    default:
      break;
  }

  const iconWidth = width > 100 ? width / 7.5 : width / 1.5;
  const iconMarginBottom = width > 100 ? width / 4.4 : 0;
  const iconMarginRight = width > 100 ? width / 4.8 : 0;
  const iconUrl = `/content/content-definitions/Directory/icon`;

  const style = {
    height: `${iconWidth}px`,
    width: `${iconWidth}px`,
    color,
    position: 'absolute',
    bottom: `${iconMarginBottom}px`,
    right: `${iconMarginRight}px`,
    fontSize: `${iconWidth}px`,
    display: 'inline',
    lineHeight: `${iconWidth}px`,
    textAlign: 'center',
    zIndex: 1,
  };

  useEffect(() => {
    setForceRenderExtensionIcon(false);
  }, [status]);

  if (forceRenderExtensionIcon) {
    return (
      <span data-testid="ExtensionIcon-Directory" className="fiv-viv fiv-icon-folder" style={{ width: `${width}px`, height: `${width}px` }}>
        {iconName === 'Spinner' && (
          <SpinnerIcon width={width} iconWidth={iconWidth} iconMarginBottom={iconMarginBottom} iconMarginRight={iconMarginRight} overlay />
        )}
        {iconName !== 'Spinner' && <Icon data-testid="JobIcon" iconName={iconName} styles={{ root: style }} />}
      </span>
    );
  }

  return (
    <TooltipHost content={title} directionalHint={DirectionalHint.bottomAutoEdge}>
      <img
        data-testid="ContentTypeIcon-Directory"
        src={iconUrl}
        onError={() => {
          setForceRenderExtensionIcon(true);
        }}
        style={{ width: `${width}px`, height: `${width}px` }}
        alt="icon"
      />

      {iconName === 'Spinner' && (
        <SpinnerIcon width={width} iconWidth={iconWidth} iconMarginBottom={iconMarginBottom} iconMarginRight={iconMarginRight} overlay />
      )}
      {iconName !== 'Spinner' && <Icon data-testid="JobIcon" iconName={iconName} styles={{ root: style }} />}
    </TooltipHost>
  );
}

JobIcon.propTypes = {
  status: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number.isRequired,
};

export default JobIcon;
