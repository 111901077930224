import React, { useEffect, useState } from 'react';

import { processBuildingBlock } from '../processBuildingBlock';
import '../buildingBlockStyles.css';
import * as TextMapping from '../../utils/textMapping';
import Panel from '../Panel';

function LCAGeneric({
  canAccess,
  onDownloadClick,
  handleContactUsClick,
  webSocket,
  lcaGeneric,
  getLCAGeneric,
  appContent,
  language,
  co2CalcVisible,
  texts,
  closeCo2Calc,
}) {
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState([]);

  function onTabClick(id, item) {
    setActiveTabMap(new Map(activeTabMap.set(id, item)));
  }

  useEffect(() => {
    if (appContent && language) {
      getLCAGeneric();
    }
  }, [appContent, language, getLCAGeneric]);

  useEffect(() => {
    if (lcaGeneric) {
      let newBody = [];
      for (let buildingBlock of lcaGeneric) {
        if (buildingBlock.type !== 'title') {
          processBuildingBlock({
            appContent,
            buildingBlock,
            body: newBody,
            collapsedVkeys,
            handleToggler,
            onTabClick,
            getActiveTab,
            canAccess,
            onDownloadClick,
            layoutMap,
            setLayoutMap: onSetLayoutMap,
            handleContactUsClick: handleContactUsClick,
            webSocket,
            addStyle,
            texts,
          });
        } else {
          setTitle(buildingBlock.data.content);
        }
      }

      setBody(newBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lcaGeneric]);

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += '.lcageneric' + styleElement + ' ';
        }
        styleSheet.innerText = styleText;
        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  function getActiveTab(id) {
    return activeTabMap.get(id)?.props?.itemKey;
  }

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  return (
    <Panel
      isOpen={co2CalcVisible}
      onDismiss={closeCo2Calc}
      title={title ? title : TextMapping.getUIText(TextMapping.UI_TEXT_IMPACT_ASSESSMENT, texts)}
    >
      <div
        className="lcageneric"
        style={{
          padding: '10px',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflowY: 'hidden',
          height: 'calc(100vh - 135px)',
        }}
      >
        {body}
      </div>
    </Panel>
  );
}

export default LCAGeneric;
