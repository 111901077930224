/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';

import localPropTypes from '../propTypes';
import BooleanFilterGroup from './BooleanFilterGroup';
import EnumFilterGroup from './EnumFilterGroup';

function FilterGroup(props) {
  const { group } = props;
  const { type } = group;

  if (type === 'boolean') {
    return <BooleanFilterGroup {...props} />;
  }

  if (type === 'enum') {
    return <EnumFilterGroup {...props} />;
  }

  return null;
}

FilterGroup.propTypes = {
  group: localPropTypes.group,
};

FilterGroup.defaultProps = {
  group: {},
};

export default React.memo(FilterGroup);
