import React, { useCallback } from 'react';
import { Checkbox } from '@fluentui/react';

function AdvancedSearchCheckbox({ parentVkey, child, getComponentKey, getCheckboxValue, onCheckboxChange, parentUseMask }) {
  const onChange = useCallback(
    (_event, value) => {
      onCheckboxChange(child, value, child.type, parentVkey, parentUseMask);
    },
    [onCheckboxChange, child, parentVkey, parentUseMask]
  );

  return <Checkbox key={getComponentKey(child, 'checkbox')} label={child.name} checked={getCheckboxValue(child)} onChange={onChange} />;
}

export default AdvancedSearchCheckbox;
