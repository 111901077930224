/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import { Checkbox } from '@fluentui/react';
import PropTypes from 'prop-types';

function BooleanInput({ dataItem, onBlur, onChange, pathKeys }) {
  const { isReadOnly, value } = dataItem;
  const testId = `PropertyEditor-${pathKeys.join('-')}`;

  return (
    <div data-testid={testId}>
      <Checkbox
        checked={value}
        disabled={isReadOnly}
        onBlur={isReadOnly ? () => {} : e => onBlur(e.target.checked)}
        onChange={isReadOnly ? () => {} : e => onChange(e.target.checked)}
      />
    </div>
  );
}

BooleanInput.propTypes = {
  dataItem: PropTypes.shape({
    isReadOnly: PropTypes.bool,
    value: PropTypes.any,
  }).isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  pathKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

BooleanInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
};

export default BooleanInput;
