import React, { useEffect, useState } from 'react';
import SmilesDrawer from 'smiles-drawer';
import { v4 as uuidv4 } from 'uuid';

function Smile({ value, options, format, id }) {
  const [componentId, setComponentId] = useState('smiles-svg');

  useEffect(() => {
    setComponentId('smiles-' + uuidv4());
  }, []);

  useEffect(() => {
    const svgDrawer = new SmilesDrawer.SvgDrawer(options);

    const drawSmile = (s) => {
      SmilesDrawer.parse(
        s,
        function (tree) {
          svgDrawer.draw(tree, id ? id : componentId, 'light');
        },
        function (err) {
          console.log(err);
        }
      );
    };

    drawSmile(value);
  }, [value, options, id, componentId]);

  return (
    <div className={format} style={{ width: options.width, height: options.height }}>
      <svg id={id ? id : componentId} xmlns="http://www.w3.org/2000/svg" className="border"></svg>
    </div>
  );
}

export default Smile;
