/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, PrimaryButton, TextField } from '@fluentui/react';
import { classNamesFunction } from '@fluentui/utilities';
import PropTypes from 'prop-types';
import React from 'react';
import Brandmark from '../../../assets/AltairBrandmark.png';

const getClassNames = classNamesFunction();

function Login(props) {
  const { errorText, isLoggingIn, registerLink, styles, theme } = props;

  const handleSubmit = e => {
    e.preventDefault();

    const username = e.target.username.value;
    const password = e.target.password.value;
    const { onSubmit } = props;

    const data = {
      username,
      password,
    };

    onSubmit(data);
  };

  const focus = ref => {
    if (ref) ref.focus();
  };

  const classNames = getClassNames(styles, { theme });

  return (
    <div className={classNames.root}>
      <div className={classNames.brandmark}>
        <img src={Brandmark} alt="Altair Brandmark" />
      </div>
      <div className={classNames.container}>
        <div className={classNames.productName}>
          Welcome to Altair One<sup className={classNames.productTradeMark}>&trade;</sup>
        </div>
        <form autoComplete="off" className={classNames.innerContainer} data-testid="login-form" onSubmit={handleSubmit}>
          <TextField
            componentRef={focus}
            data-testid="username"
            disabled={isLoggingIn}
            label="Username"
            name="username"
            required
            type="text"
            styles={classNames.subComponentStyles.textField()}
          />

          <TextField
            data-testid="password"
            disabled={isLoggingIn}
            label="Password"
            name="password"
            required
            styles={classNames.subComponentStyles.textField()}
            type="password"
          />

          <div className={classNames.messageContainer}>
            {errorText && <div className={classNames.errorLabel}>{errorText}</div>}
            {isLoggingIn && <div className={classNames.pendingLabel}>Logging in...</div>}
          </div>

          <div className={classNames.buttonContainer}>
            {registerLink && (
              <DefaultButton
                className="aone-Login-signupbutton"
                data-testid="signup"
                disabled={isLoggingIn}
                href={registerLink}
                target="_blank"
                styles={classNames.subComponentStyles.button()}
                text="Sign Up"
              />
            )}
            <PrimaryButton
              className="aone-Login-loginbutton"
              data-testid="submit"
              disabled={isLoggingIn}
              styles={classNames.subComponentStyles.button()}
              text="Login"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

Login.propTypes = {
  errorText: PropTypes.string,
  isLoggingIn: PropTypes.bool,
  onSubmit: PropTypes.func,
  registerLink: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  theme: PropTypes.object,
};

Login.defaultProps = {
  errorText: null,
  isLoggingIn: false,
  onSubmit: () => {},
};

export default Login;
