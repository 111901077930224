/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

/* eslint-disable react/prop-types */
import React from 'react';
import { getId, classNamesFunction } from '@fluentui/utilities';

const getClassNames = classNamesFunction();

export function onRenderGroupExpanderButtonText(props) {
  const { styles, text, theme } = props;

  const labelId = getId();
  const classNames = getClassNames(styles, { theme });

  return (
    <div key={labelId} className={classNames.label} id={labelId}>
      <span title={text}>{text}</span>
    </div>
  );
}
