import React, { useEffect, useState } from 'react';

import { processBuildingBlock } from '../processBuildingBlock';
import '../buildingBlockStyles.css';
import { SearchBox } from '@fluentui/react';
import * as TextMapping from '../../utils/textMapping';
import TopbarContainer from '../TopbarContainer';
import Panels from '../../topbar/components/panels';

function Landing({
  texts,
  canAccess,
  onDownloadClick,
  handleContactUsClick,
  webSocket,
  executeSearch,
  updateQuickSearch,
  quickSearchValue,
  landingBB,
  getLandingBuildingBlocks,
  appContent,
  language,
  view,
  handleWaffleMenuClick,
  handleUserButtonClick,
  handleNotificationBellClick,
  panelId,
  handlePanelDismiss,
}) {
  let body = [];

  useEffect(() => {
    if ((appContent && language) || view === '404') {
      getLandingBuildingBlocks(view);
    }
  }, [appContent, language, getLandingBuildingBlocks, view]);

  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);

  function onTabClick(id, item) {
    setActiveTabMap(new Map(activeTabMap.set(id, item)));
  }

  function addSearch(body) {
    body.push(
      <SearchBox
        onChange={updateQuickSearch}
        placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SEARCH_MATERIALS, texts)}
        value={quickSearchValue}
        onSearch={executeSearch}
        onClear={() => {
          updateQuickSearch(null, '');
        }}
        disableAnimation={true}
      />
    );
  }

  function addStyle(id, style) {
    if (!addedStyles.includes(id)) {
      var styleSheet = document.createElement('style');

      let styleText = '';
      if (style && style.length > 0) {
        for (let styleElement of style) {
          styleText += '.landing ' + styleElement + ' ';
        }
        styleSheet.innerText = styleText;
        document.head.appendChild(styleSheet);
      }

      setAddedStyles((prevStyles) => [...prevStyles, id]);
    }
  }

  function getActiveTab(id) {
    return activeTabMap.get(id)?.props?.itemKey;
  }

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  if (landingBB) {
    for (let buildingBlock of landingBB) {
      if (buildingBlock.type !== 'title') {
        processBuildingBlock({
          appContent,
          buildingBlock,
          body,
          collapsedVkeys,
          handleToggler,
          onTabClick,
          getActiveTab,
          canAccess,
          onDownloadClick,
          layoutMap,
          setLayoutMap: onSetLayoutMap,
          handleContactUsClick: handleContactUsClick,
          webSocket,
          addSearch,
          addStyle,
          texts,
        });
      }
    }
  }

  return (
    <div className="landing" style={{ height: '100%', width: '100vw' }}>
      <TopbarContainer
        onWaffleButtonClick={handleWaffleMenuClick}
        onNotificationBellClick={handleNotificationBellClick}
        onUserButtonClick={handleUserButtonClick}
      />
      <div data-testid="landing-scrollable-area" className="amdc-block" style={{ height: '100%', width: '100vw', overflowY: 'auto' }}>
        {body}
      </div>
      <Panels activePanelId={panelId} onDismiss={handlePanelDismiss} />
    </div>
  );
}

export default Landing;
