/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { IconButton, PrimaryButton as FPrimaryButton, DefaultButton as FDefaultButton } from '@fluentui/react/lib/Button';

import {
  ModalContainer,
  ModalRoot,
  ModalItemContainer,
  ModalActionContainer,
  ModalCloseContainer,
  ModalContentContainer,
  ModalFooterContainer,
} from './styles';

const CloseButton = (props) => {
  return <IconButton iconProps={{ iconName: 'ChromeClose', style: { fontSize: 14 } }} title="Close" onClick={props.onClick} />;
};

const PrimaryButton = (props) => {
  return <FPrimaryButton styles={{ root: { marginRight: 10 } }} {...props} />;
};

const DiscardButton = ({ text, onClick }) => {
  return <FDefaultButton text={text} styles={{ root: { marginRight: 10 } }} onClick={onClick} />;
};

const Description = () => null;

const getDefaultComponents = (props) => {
  return {
    CloseButton,
    Description,
    PrimaryButton,
    DiscardButton,
    ...props.components,
  };
};

const DefaultModal = (props) => {
  const {
    isOpen = false,
    isBlocking = true,
    inProgress = false,
    primaryButtonText = 'Click',
    discardButtonText = 'Discard',
    onDismiss,
    onPrimaryClick,
    children,
    styles = {},
  } = props;

  if (!isOpen) return null;

  const { CloseButton, PrimaryButton, DiscardButton } = getDefaultComponents(props);

  return (
    <Modal isOpen={isOpen} isBlocking={isBlocking} onDismiss={onDismiss}>
      <ModalContainer>
        <ModalRoot style={styles.root}>
          <ModalItemContainer style={styles.itemContainer}>
            <ModalActionContainer>
              <ModalCloseContainer>
                <CloseButton onClick={onDismiss} />
              </ModalCloseContainer>
            </ModalActionContainer>

            <ModalContentContainer>{children}</ModalContentContainer>

            <ModalFooterContainer>
              <DiscardButton text={discardButtonText} onClick={onDismiss} />
              <PrimaryButton text={inProgress ? <Spinner size={SpinnerSize.xSmall} /> : primaryButtonText} onClick={onPrimaryClick} />
            </ModalFooterContainer>
          </ModalItemContainer>
        </ModalRoot>
      </ModalContainer>
    </Modal>
  );
};

export default DefaultModal;
