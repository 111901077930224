/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
function updateFilterCollapsed({ filter }) {
  if (!filter) return filter;

  const newFilter = {
    ...filter,
    isCollapsed: !filter.isCollapsed,
  };

  return newFilter;
}

export default updateFilterCollapsed;
