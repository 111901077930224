/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import isEqual from 'lodash/isEqual';

export function checkCallbackPolicyChanged(a, b) {
  if (!a && !b) {
    return false;
  }

  if (!!a !== !!b) {
    return true;
  }

  const aEventKeys = Object.keys(a);
  const bEventKeys = Object.keys(b);
  const haveEventKeysChanged = !isEqual([...aEventKeys].sort(), [...bEventKeys].sort());

  if (haveEventKeysChanged) {
    return true;
  }

  const eventKeys = aEventKeys;

  const haveAnyEventIdsChanged = eventKeys.some(eventKey => {
    const aIds = a[eventKey];
    const bIds = b[eventKey];

    const haveEventIdsChanged = !isEqual([...aIds].sort(), [...bIds].sort());

    return haveEventIdsChanged;
  });

  return haveAnyEventIdsChanged;
}
