/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getInitialActiveTabKey } from './utils/getInitialActiveTabKey';
import Pivot from './Pivot';
import PropertyEditor from './PropertyEditor';
import Tab from './Tab';
import Tabs from './Tabs';
import View from './View';

const NOOP = () => {};

function ContentDetails(props) {
  const { activeTab, children = null, onTabClick = NOOP } = props;

  const [activeTabKey, setActiveTabKey] = useState(getInitialActiveTabKey(props));

  useEffect(() => {
    setActiveTabKey(activeTab);
  }, [activeTab]);

  function handlePivotClick(item) {
    const { itemKey } = item.props;

    // Condition for controlled component
    if (activeTab) {
      onTabClick(itemKey);
    } else {
      setActiveTabKey(itemKey);
    }
  }

  const tabChildren = React.Children.toArray(children).filter((child) => child.type === Tab);

  useEffect(() => {
    const tabNames = tabChildren.map((tab) => tab.props.name);
    if (tabNames.length > 0 && !tabNames.includes(activeTab)) {
      onTabClick(tabNames[0]);
    }
  });

  return (
    <>
      <Pivot activeTabKey={activeTabKey} onItemClick={handlePivotClick}>
        {tabChildren}
      </Pivot>

      <Tabs activeTabKey={activeTabKey}>{tabChildren}</Tabs>
    </>
  );
}

ContentDetails.PropertyEditor = PropertyEditor;
ContentDetails.Tab = Tab;
ContentDetails.View = View;

ContentDetails.propTypes = {
  activeTab: PropTypes.string,
  //children: propTypes.tabs,
  // defaultActiveTab: PropTypes.string,
  onTabClick: PropTypes.func,
};

export { PropertyEditor, Tab, View };

export default React.memo(ContentDetails);
