/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import PropTypes from 'prop-types';

const DEFAULT_ICONPROPS = {
  iconName: 'Forward',
};
const DEFAULT_STYLES = {
  flexContainer: {},
  label: {},
};

function ButtonByType({ buttonType = 'default', children, ...restProps }) {
  if (buttonType === 'primary') {
    return <PrimaryButton {...restProps}>{children}</PrimaryButton>;
  }

  return <DefaultButton {...restProps}>{children}</DefaultButton>;
}
ButtonByType.propTypes = {
  buttonType: PropTypes.string,
  children: PropTypes.node,
};

function NavButton({
  buttonType = 'default',
  children,
  'data-testId': dataTestId,
  iconProps = DEFAULT_ICONPROPS,
  styles = DEFAULT_STYLES,
  ...restProps
}) {
  return (
    <ButtonByType
      buttonType={buttonType}
      data-testid={dataTestId}
      iconProps={{
        ...iconProps,
      }}
      styles={{
        ...styles,
        flexContainer: {
          // this places icon on right side
          flexDirection: 'row-reverse',
          ...styles.flexContainer,
        },
        label: {
          ...styles.label,
          whiteSpace: 'nowrap',
        },
      }}
      {...restProps}
    >
      {children}
    </ButtonByType>
  );
}

NavButton.propTypes = {
  buttonType: PropTypes.string,
  children: PropTypes.node,
  'data-testId': PropTypes.string,
  iconProps: PropTypes.shape({}),
  styles: PropTypes.shape({}),
};

export default NavButton;
