/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { classNamesFunction, getNativeProps, divProperties } from '@fluentui/utilities';

const getClassNames = classNamesFunction();

export function WistiaPlayerBase(props) {
  const { className, styles, theme, videoId, ...restProps } = props;

  useEffect(() => {
    if (!document.getElementById(`wistia_embed_script_${videoId}`)) {
      const wistiaEmbedScript = document.createElement('script');
      wistiaEmbedScript.id = `wistia_embed_script_${videoId}`;
      wistiaEmbedScript.type = 'text/javascript';
      wistiaEmbedScript.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
      wistiaEmbedScript.async = true;
      document.body.appendChild(wistiaEmbedScript);
    }

    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }

    let handle;

    window._wq = window._wq || [];
    window._wq.push({
      id: videoId,
      options: {
        plugin: {
          facebookPixel: {
            on: false,
          },
        },
      },
      onEmbedded: video => {
        handle = video;
      },
    });

    return () => handle?.remove();
  }, [videoId]);

  const classNames = getClassNames(styles, { className, videoId, theme });
  const nativeProps = getNativeProps(restProps, divProperties);

  return (
    <div {...nativeProps} className={classNames.root}>
      <div className={classNames.wrapper}>
        <span className={classNames.embed}>&nbsp;</span>
      </div>
    </div>
  );
}

WistiaPlayerBase.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  theme: PropTypes.object,
  videoId: PropTypes.string.isRequired,
};
