async function sendFeedback(payload, altairOneBaseUrl = '') {
  const res = await fetch(`${altairOneBaseUrl}/notification/feedback`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
    credentials: 'include',
  });
  if (!res.ok) {
    throw new Error('Failed send request');
  }
}

async function sendSupportRequest(payload, altairOneBaseUrl = '') {
  const url = `${altairOneBaseUrl}/notification/contactus`;
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
    credentials: 'include',
  });
  if (!res.ok) {
    throw new Error('Failed send request');
  }
}
async function sendAPANofication(payload, altairOneBaseUrl = '') {
  const url = `${altairOneBaseUrl}/notification/apa`;
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
    credentials: 'include',
  });
  if (!res.ok) {
    throw new Error('Failed send request');
  }
}

function sendTrialRequest(appName, autoApproved, errorMessage) {
  return fetch(`/notification/trialrequest`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ appName, autoApproved, errorMessage }),
  });
}

export default {
  sendFeedback,
  sendSupportRequest,
  sendTrialRequest,
  sendAPANofication,
};
