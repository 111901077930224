/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
export function checkSelectionCount({ allowedSelectionCounts, selectedEntitiesCount }) {
  if (selectedEntitiesCount === 0 && allowedSelectionCounts.includes('NONE')) {
    return true;
  }

  if (selectedEntitiesCount === 1 && allowedSelectionCounts.includes('ONE')) {
    return true;
  }

  if (selectedEntitiesCount > 1 && allowedSelectionCounts.includes('MANY')) {
    return true;
  }

  return false;
}
