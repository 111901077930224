/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 16px;
  gap: 16px;
`;

const ButtonOffset = styled.div`
  padding-left: 10px;
  padding-bottom: 32px;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const HeaderTopSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  align-self: stretch;
`;

const HeaderBottomSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 16px;
  gap: 12px;
  align-self: stretch;
`;

export { ButtonOffset, Content, HeaderSection, HeaderTopSection, HeaderBottomSection };
