/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

async function getUserSubscription(userId, altairOneBaseUrl = '') {
  const url = `${altairOneBaseUrl}/account/subscription/?userid=${userId}`;
  const res = await fetch(url, {
    method: 'GET',
    credentials: 'include',
  });
  if (res.ok) {
    return res.json();
  }

  return null;
}

async function requestTrial(appId, userId, altairOneBaseUrl = '') {
  const res = await fetch(`${altairOneBaseUrl}/account/requesttrial/${appId}?userid=${userId}`, {
    method: 'GET',
    credentials: 'include',
  });

  const result = await res.json();

  if (res.ok) {
    return result;
  }

  throw result;
}

async function mySuites(altairOneBaseUrl = '') {
  const res = await fetch(`${altairOneBaseUrl}/account/licensepackage`, {
    method: 'GET',
    credentials: 'include',
  });
  if (res.ok) {
    return res.json();
  }

  throw new Error('Failed to get suites');
}

async function sendComment({ appName, comment }, altairOneBaseUrl = '') {
  const url = `${altairOneBaseUrl}/account/comment`;
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ comment, product: appName }),
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  if (res.ok) {
    return res.json();
  }

  throw new Error('Failed to send comment');
}

export default {
  getUserSubscription,
  mySuites,
  requestTrial,
  sendComment,
};
