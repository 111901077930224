/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStore, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withTopbar } from '../../../topbar/main';
import Login from './Login';
import { loginWithUserDetails, loggingIn, loginFail } from '../../actions';
import LoadingScreen from '../LoadingScreen';
import { getFrameApi } from '../../api';

function ClientCredentialLogin({ config, setUserProfile, redirectUri = `${window.location.pathname}` }) {
  const [errorText, setErrorText] = useState('');

  const { dispatch } = useStore();
  const handleLogin = async ({ username, password }) => {
    setErrorText('');
    dispatch(loggingIn());
    const api = getFrameApi();
    try {
      const user = await api.user.login(username, password);
      setUserProfile(user);
      dispatch(loginWithUserDetails(user));
    } catch (e) {
      let message = e.response?.data?.message;
      if (!message || message.startsWith('Internal')) {
        message = 'Login failed';
      }
      setErrorText(message);
      dispatch(loginFail());
    }
  };

  const { status } = useSelector((state) => state.loggedInUser);

  /*
  const autoLoginFromSession = useCallback(async () => {
    const byPassLogin = window.location.pathname?.toLowerCase().startsWith('/marketplace') ?? false;

    try {
      const user = await whoAmI();
      user.fromSession = true;
      setUserProfile(user);
      dispatch(loginWithUserDetails(user));
    } catch (e) {
      if (byPassLogin) {
        dispatch(guestLogin());
      } else {
        dispatch({ type: 'LOGOUT' });
      }
    }
  }, [dispatch, setUserProfile]);

  useEffect(() => {
    autoLoginFromSession();
  }, [autoLoginFromSession]);
  */

  if ([null, 'AUTO_LOGGING_IN'].includes(status)) {
    return <LoadingScreen />;
  }

  if (status === 'LOGGED_IN' && redirectUri) {
    return <Redirect to={redirectUri ? redirectUri : '/'} />;
  }

  return <Login errorText={errorText} isLoggingIn={status === 'LOGGING_IN'} onSubmit={handleLogin} registerLink={config?.registerUri} />;
}

ClientCredentialLogin.propTypes = {
  config: PropTypes.shape({
    registerUri: PropTypes.string,
  }),
  setUserProfile: PropTypes.func,
  redirectUri: PropTypes.string,
};

export default withTopbar(ClientCredentialLogin);
