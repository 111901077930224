/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { mergeStyles } from '@fluentui/style-utilities';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

function Cell(props) {
  const { children, isCollapsed, styles } = props;

  const cellClassName = useMemo(() => {
    return mergeStyles([
      {
        flexGrow: 1,
        padding: '1px 3px',
        position: 'relative',
        color: '#1D2E38',
        wordBreak: 'break-word',
        // lineHeight: 17
        // display: 'flex',
        // alignItems: 'center',
        // width: 'calc(100% - 6px)',
        // selectors: {
        //   '& > span': {
        //     width: '100%',
        //   }
        // }
      },
      isCollapsed && {
        height: 0,
        paddingBottom: 0,
        paddingTop: 0,
        visibility: 'hidden',
      },
      {
        ...styles.cellRoot,
      },
    ]);
  }, [isCollapsed, styles]);

  return <div className={cellClassName}>{children}</div>;
}

Cell.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isCollapsed: PropTypes.bool,
  styles: PropTypes.shape({
    cellRoot: PropTypes.object,
    tableRoot: PropTypes.object,
  }),
};

Cell.defaultProps = {
  isCollapsed: false,
  styles: {},
};

export default Cell;
