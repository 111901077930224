/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { useSocketCallback } from '../../../../../react-hooks/hooks/useSocketCallback';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import View from '../../../../components/__experimental/View';
import { DELETE_PREVIEWS_MUTATION, GET_ENTITY_QUERY, GET_PREVIEWS_QUERY } from './graphql';
import { createRefetchPolicy } from './utils/createRefetchPolicy';

function enrichViewers({ contentType, id, loading, previews, viewers } = {}) {
  if (!viewers && loading) {
    return undefined;
  }

  const nextViewers = [...viewers];
  if (contentType === 'ImageFile') {
    nextViewers.unshift({ id, name: 'Image' });
  } else if (contentType !== 'HTMLFile') {
    nextViewers.unshift({
      id: 'Preview',
      name: 'Preview',
      previews: previews.filter(preview => !preview.src.endsWith('_thumbnail.png')),
    });
  }

  return nextViewers;
}

const EMPTY_ARRAY = [];
const NOOP = () => {};

function ViewContainer({
  activeViewerName,
  api,
  collectionName = null,
  contentType = null,
  id = null,
  styles,
  onActiveViewerChange = NOOP,
  onOpenApplication = NOOP,
}) {
  const { data, error, loading } = useQuery(GET_ENTITY_QUERY, {
    // TODO: Review fetchPolicy
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
    variables: {
      collectionName,
      id,
    },
  });

  const backendViewers = data?.entity?.viewers ?? EMPTY_ARRAY;
  const previews = data?.entity?.previews ?? EMPTY_ARRAY;

  const client = useApolloClient();

  const [viewers, setViewers] = useState(() => enrichViewers({ contentType, id, loading, previews, viewers: backendViewers }));

  useEffect(() => {
    setViewers(enrichViewers({ contentType, id, loading, previews, viewers: backendViewers }));
  }, [contentType, backendViewers, id, loading, previews]);

  const fetchPreviews = useCallback(async () => {
    const { data: nextData, error: nextError } = await client.query({
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
      query: GET_PREVIEWS_QUERY,
      variables: {
        previewInput: {
          contentType,
          entityId: id,
        },
      },
    });

    if (!nextError) {
      const nextPreviews = nextData?.previews ?? EMPTY_ARRAY;
      setViewers(prevViewers =>
        prevViewers.map(viewer => {
          if (viewer.id === 'Preview') {
            return {
              ...viewer,
              previews: nextPreviews.filter(preview => !preview.src.endsWith('_thumbnail.png')),
            };
          }

          return viewer;
        })
      );
    } else {
      console.error(nextError);
    }
  }, [contentType, client, id]);

  const handleDeletePreview = useCallback(
    async preview => {
      const previewIds = [preview.id];

      await client.mutate({
        mutation: DELETE_PREVIEWS_MUTATION,
        variables: {
          deletePreviewInput: {
            contentType,
            entityId: id,
            previewIds,
          },
        },
      });

      setViewers(prevViewers =>
        prevViewers.map(viewer => {
          if (viewer.id === 'Preview') {
            const nextPreviews = viewer.previews.filter(p => p.id !== preview.id);
            return {
              ...viewer,
              previews: nextPreviews,
            };
          }

          return viewer;
        })
      );
    },
    [contentType, client, id]
  );

  const refetchHandler = useCallback(
    event => {
      const { type, payload } = event;
      if (
        type === 'PREVIEW_CREATE' ||
        type === 'PREVIEW_DELETE' ||
        (type === 'MDE_STATUS_CHANGE' && payload.newStatus !== payload.oldStatus && payload.newStatus === 'Completed')
      ) {
        fetchPreviews();
      }
    },
    [fetchPreviews]
  );

  const refetchPolicy = createRefetchPolicy(data);

  useSocketCallback(refetchHandler, refetchPolicy);

  if (error) {
    console.error(error);
  }

  const entity = data?.entity ?? null;

  return (
    <View
      activeViewerName={activeViewerName}
      api={api}
      loading={loading && entity?.id !== id}
      entity={entity}
      onActiveViewerChange={onActiveViewerChange}
      onOpenApplication={onOpenApplication}
      onDeletePreviewClick={handleDeletePreview}
      styles={styles}
      viewers={viewers}
    />
  );
}

ViewContainer.propTypes = {
  activeViewerName: PropTypes.string,
  api: PropTypes.object,
  collectionName: PropTypes.string,
  contentType: PropTypes.string,
  id: PropTypes.string,
  onActiveViewerChange: PropTypes.func,
  onOpenApplication: PropTypes.func,
  styles: View.propTypes.styles,
};

export { GET_ENTITY_QUERY };

export default ViewContainer;
