/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

let roles = null;

async function getAll() {
  if (roles !== null) {
    return roles;
  }
  const url = `/api/roles`;
  roles = new Promise(resolve => {
    axios.get(url, { params: { includePermissions: true } }).then(({ data }) => resolve(data));
  });

  return roles;
}

export default {
  getAll,
};
