/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { PrimaryButton, TextField, Text, Link, Spinner, SpinnerSize } from '@fluentui/react';
import { classNamesFunction } from '@fluentui/utilities';
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginProps, DefaultLoginProps } from './Login.types';
import Brandmark from '../../../assets/AltairBrandmark.png';
import { validateEmail } from '../../../utils/validateEmail';

const getClassNames = classNamesFunction();

export function Login(props) {
  const { isLoggingIn, onSubmit, registerLink, styles, theme } = props;
  const usernameRef = useRef(null);

  const [errorText, setErrorText] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();

    if (isLoggingIn) {
      return;
    }

    const username = e.target.username.value;
    if (!validateEmail(username)) {
      setErrorText('Please enter a valid e-mail address');
      usernameRef.current.focus();
      return;
    }
    onSubmit({ username });
  };

  const classNames = getClassNames(styles, { theme });

  const histroy = useHistory();
  function onNeedHelpClick(e) {
    e.preventDefault();
    histroy.push('/login/help');
  }

  return (
    <div className={classNames.root}>
      <div className={classNames.brandmark}>
        <img src={Brandmark} alt="Altair Brandmark" />
      </div>
      <div className={classNames.productName}>
        Welcome to Altair One<sup className={classNames.productTradeMark}>&trade;</sup>
      </div>
      <form autoComplete="off" noValidate="novalidate" className={classNames.container} data-testid="login-form" onSubmit={handleSubmit}>
        <TextField
          autoComplete="username"
          autoFocus
          componentRef={usernameRef}
          disabled={isLoggingIn}
          id="email"
          label="E-mail"
          name="username"
          onChange={() => setErrorText(null)}
          onRenderSuffix={({ disabled }) => (
            <Link disabled={disabled} href="/login/help" onClick={onNeedHelpClick} styles={classNames.subComponentStyles.helpLink}>
              Need Help?
            </Link>
          )}
          placeholder="E-mail address"
          required
          styles={classNames.subComponentStyles.textField()}
          type="text"
          inputMode="email"
        />
        <div className={classNames.messageContainer}>{errorText && <div className={classNames.errorLabel}>{errorText}</div>}</div>

        <div className={classNames.actionContainer}>
          <PrimaryButton id="continue" name="continue" styles={classNames.subComponentStyles.button()} type="submit">
            Continue
            {isLoggingIn && <Spinner className={classNames.helpProgress} size={SpinnerSize.xSmall} />}
          </PrimaryButton>
        </div>
        {registerLink && (
          <div className={classNames.registerLink}>
            <Text>
              Don&apos;t have an account?{' '}
              <Link id="register" href={registerLink} rel="noopener">
                Register
              </Link>
            </Text>
          </div>
        )}
      </form>
    </div>
  );
}

Login.propTypes = LoginProps;

Login.defaultProps = DefaultLoginProps;
