/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IconButton } from '@fluentui/react';
import PropTypes from 'prop-types';
import { Resizable } from 're-resizable';
import React, { useState, useRef } from 'react';
import { Header } from './styled';
import { useWindowSize } from 'react-use';
import { Spinner, SpinnerSize } from '@fluentui/react';

function Panel(props) {
  const {
    addNavigation = () => {},
    children,
    isOpen,
    onDismiss,
    title,
    notifyResizeStart,
    notifyResizeStop,
    notifyFullScreen,
    loading = false,
  } = props;
  const [isResizing, setIsResizing] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [width, setWidth] = useState(-1);
  const targetRef = useRef();

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 640;

  function handleResizeStop() {
    let newWidth = width;

    setIsResizing(false);
    if (targetRef.current) {
      newWidth = targetRef.current.size.width;

      // leave some room for left side panel
      if (newWidth > windowSize.width - 200) {
        newWidth = windowSize.width - 200;
      }
      setWidth(newWidth);
    }
    if (typeof notifyResizeStop === 'function') {
      notifyResizeStop(newWidth);
    }
  }

  function handleResizeStart() {
    setIsResizing(true);
    if (typeof notifyResizeStart === 'function') notifyResizeStart();
  }

  function handleFullScreen() {
    setIsFullScreen(!isFullScreen);
    if (typeof notifyFullScreen === 'function') {
      notifyFullScreen(!isFullScreen);
    }
  }

  const navigation = addNavigation();

  let opacity = loading ? '0.5' : '1.0';

  return (
    <Resizable
      className={isOpen ? 'panel-box-shadow bodyBackground panelBackground panelText' : 'bodyBackground panelBackground panelText'}
      ref={targetRef}
      size={{ width: isFullScreen || isMobile ? '100%' : width < 0 ? '50%' : width }}
      enable={{ left: true }}
      // minWidth={400}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      style={{
        borderLeft: 'solid 1px #e4e4e4',
        bottom: 0,
        right: 0,
        top: 0,
        boxSizing: 'border-box',
        display: isOpen ? 'flex' : 'none',
        flexDirection: 'column',
        transform: isOpen ? null : 'translateX(100%)',
        transition: isResizing ? null : 'all 0.5s ease-out',
        visibility: isOpen ? null : 'hidden',
        position: isFullScreen || isMobile ? 'absolute' : 'relative',
        //zIndex: '1000',
        zIndex: '2',
        opacity: opacity,
      }}
    >
      {loading && (
        <div
          style={{
            zIndex: '10',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,.5)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <Header className="bodyText panelText">
        <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          {navigation} {title}
        </div>

        <IconButton
          data-testid={`full-screen-button-${title.replace(/\s/g, '')}`}
          iconProps={{ iconName: isFullScreen ? 'BackToWindow' : 'FullScreen' }}
          onClick={handleFullScreen}
          title={isFullScreen ? 'Back to Window' : 'Full Screen'}
          ariaLabel={isFullScreen ? 'Back to Window' : 'Full Screen'}
        />
        <IconButton
          data-testid={`close-button-${title.replace(/\s/g, '')}`}
          iconProps={{ iconName: 'ChromeClose' }}
          onClick={onDismiss}
          title="Close"
          ariaLabel="Close"
        />
      </Header>
      {children}
    </Resizable>
  );
}

Panel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isOpen: PropTypes.bool,
  onDismiss: PropTypes.func,
  title: PropTypes.string,
};

Panel.defaultProps = {
  isOpen: false,
  onDismiss: () => {},
  title: '',
};

export default Panel;
