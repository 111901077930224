/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React from 'react';
import ChangeViewButton from './ChangeViewButton';
import { Root } from './styled';

const EMPTY_ARRAY = [];
const NOOP = () => {};

function ChangeViewGroup({ activeViewerName, onItemClick = NOOP, viewers = EMPTY_ARRAY }) {
  return (
    <Root>
      {viewers.map(viewer => (
        <ChangeViewButton
          active={viewer.name === activeViewerName}
          key={viewer.name}
          onClick={() => onItemClick({ viewerName: viewer.name, key: 'CHANGE_VIEW' })}
          view={viewer}
        />
      ))}
    </Root>
  );
}

ChangeViewGroup.propTypes = {
  activeViewerName: PropTypes.string,
  onItemClick: PropTypes.func,
  viewers: PropTypes.arrayOf(PropTypes.object),
};

export default ChangeViewGroup;
