/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export const getStyles = ({ hasError, theme }) => {
  const { semanticColors } = theme;

  return {
    root: [
      {
        flex: '1 0 auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
      },
    ],
    brandmark: {
      margin: '30px 0px 0px 30px',
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      margin: 12,
      selectors: {
        '>.ms-Button': {
          margin: '0px 8px',
          width: 116,
        },
      },
    },
    container: {
      display: 'flex',
      flex: '1 0 auto',
      margin: '0px auto',
      width: '75%',
      overflowY: 'auto',
      selectors: {
        '@media screen and (max-width: 639px)': {
          flexDirection: 'column',
        },
      },
    },
    main: {
      flex: '1 0 calc(50% - 48px)',
      padding: 8,
      selectors: {
        '@media screen and (max-width: 639px)': {
          flexBasis: 'auto',
        },
      },
    },
    divider: {
      borderLeft: '1px solid #D2D5D7',
      margin: '0px 24px',
      selectors: {
        '@media screen and (max-width: 639px)': {
          borderTop: '1px solid #D2D5D7',
          width: 'calc(100% - 16px)',
          margin: 0,
        },
      },
    },
    info: {
      flex: '1 0 calc(50% - 48px)',
      padding: 8,
      selectors: {
        '.ms-Link': {
          fontSize: 12,
        },
      },
    },
    infoTop: {
      marginBottom: 120,
      selectors: {
        '@media screen and (max-width: 639px)': {
          marginBottom: 12,
        },
        '> ol': {
          paddingLeft: 16,
        },
      },
    },
    innerContent: {
      maxWidth: 280,
    },
    helpOptions: {
      marginBottom: 12,
      selectors: {
        ' .dropdown > .title': {
          fontSize: '12px !important',
          paddingLeft: '4px !important',
        },
      },
    },
    email: {
      marginBottom: 12,
      width: '100%',
    },
    captchaContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 8,
    },
    continueButton: {
      width: 120,
      marginBottom: 12,
    },
    helpProgress: {
      marginLeft: 4,
    },
    message: [
      {
        color: semanticColors.successText,
      },
      hasError && {
        color: semanticColors.errorText,
      },
    ],
    footer: {
      marginTop: 12,
    },
    table: {
      marginTop: 8,
      borderCollapse: 'collapse',
      selectors: {
        'table, th, td': {
          border: '1px solid #E1E5E7',
        },
        th: {
          color: '#4D4D4D',
        },
        'td, th': {
          padding: '4px 10px',
          color: '#4D4D4D',
        },
      },
    },
  };
};
