/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { getPlaceholderStyles } from '@fluentui/style-utilities';

export const getStyles = ({ theme }) => {
  const { semanticColors } = theme;

  return {
    root: [
      {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flex: '1 0 auto',
      },
    ],
    brandmark: {
      position: 'absolute',
      top: 60,
      left: 60,
    },
    productName: {
      fontSize: 28,
      fontWeight: 'bold',
      color: '#4D4D4D',
      marginBottom: 14,
    },
    productTradeMark: {
      fontSize: 9,
    },
    messageContainer: {
      marginTop: 6,
    },
    errorLabel: {
      color: semanticColors.errorText,
      textAlign: 'center',
    },
    pendingLabel: {
      textAlign: 'center',
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '16px auto 12px auto',
    },
    forgotPassword: {
      textAlign: 'center',
      selectors: {
        '>.ms-Button': {
          height: 16,
        },
      },
    },
    registerLink: {
      textAlign: 'center',
    },
    helpProgress: {
      marginLeft: 4,
    },
    subComponentStyles: {
      button: {
        root: {
          width: 164,
        },
      },
      textField: {
        root: {
          // marginBottom: 2,
          width: 308,
        },
        fieldGroup: {
          height: 36,
          padding: '4px 8px',
        },
        field: [
          {
            fontSize: 14,
          },
          getPlaceholderStyles({ fontSize: 14 }),
        ],

        suffix: {
          background: 'transparent',
          paddingRight: 0,
        },
        subComponentStyles: {
          label: {
            root: {
              fontSize: 14,
            },
          },
        },
      },
      helpLink(props) {
        const { isDisabled } = props;
        return {
          root: [
            isDisabled && {
              color: semanticColors.disabledText,
              cursor: 'default',
              selectors: {
                '&:active, &:hover, &:active:hover': {
                  textDecoration: 'none',
                },
              },
            },
          ],
        };
      },
    },
  };
};
