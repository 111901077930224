/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { ScreenWidthMinLarge, ScreenWidthMinXXLarge } from '@fluentui/style-utilities';

const sharedPaddingStyles = {
  paddingLeft: 0,
  paddingRight: 0,
  selectors: {
    [`@media screen and (min-width: ${ScreenWidthMinLarge}px)`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [`@media screen and (min-width: ${ScreenWidthMinXXLarge}px)`]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
};

export const getStyles = ({ theme }) => {
  return {
    content: [
      {
        margin: 0,
        width: '100%',
      },
      sharedPaddingStyles,
      'aone-MenuPanel-content',
    ],
    header: [
      {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        paddingLeft: 16,
        paddingRight: 0,
        paddingTop: 20,
        paddingBottom: 18,
      },
      'aone-MenuPanel-header',
    ],
    headerText: [
      {
        fontFamily: 'Noto Sans',
        fontSize: 21,
        fontWeight: 500,
      },
      'aone-MenuPanel-headerText',
    ],
    main: [
      {
        boxShadow: '0px 0px 4px rgb(0 0 0 / 25%);',
        background: theme?.semanticColors?.waffleMenuBackground || theme?.semanticColors?.bodyBackground || '#FFFFFF',
      },
      'aone-MenuPanel-main',
    ],
    root: [
      {
        top: 34,
        color: theme?.semanticColors?.waffleMenuText || theme?.semanticColors?.bodyText || '#000000',
      },
      'aone-MenuPanel',
    ],
    commands: {
      display: 'none',
    },
    scrollableContent: { display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0px', gap: '16px' },
  };
};
