/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTopbar } from '../../../topbar/main';
import LoadingScreen from '../LoadingScreen';
import { initAuthConfig } from '../../api/package/auth';
import OktaLogin from '../OktaLogin';
import ClientCredentialLogin from '../ClientCredentialLogin';
import { useAppFrame } from '../../context/useAppFrame';

const errorHtml = `
<div style="font-size: 14px;">
  <h3 style="color: #fa4716; margin: 4px 0px;">Sorry! Something went wrong on our end.</h3>
  <p style="margin: 0;">Please refresh the page to try again or contact our <a href="mailto:support@altair.com?subject=Not able to login">customer support</a> if the problem persists.</p>
</div>
`;

function AutoLogin() {
  const [configError, setConfigError] = useState();
  //const { postLoginRedirectURL, configError: postLoginRedirectError, isLoading: isRedirectUrlLoading } = usePostLoginRedirectURL();

  const { config, setConfig } = useAppFrame();

  const init = useCallback(async () => {
    try {
      const nextConfig = await initAuthConfig();
      setConfig(nextConfig);
    } catch (e) {
      setConfigError(errorHtml);
    }
  }, [setConfig]);

  useEffect(() => {
    init();
  }, [init]);

  let { redirectUri, sso } = (() => {
    const params = new URLSearchParams(window.location.search);
    return { redirectUri: params.get('redirectUri'), sso: params.get('sso') };
  })();

  const name = config?.name;
  if (name === 'Okta') {
    return <OktaLogin config={config?.config} redirectUri={redirectUri} skipToOkta={sso?.toLowerCase() === 'okta'} />;
  }
  if (config) {
    return <ClientCredentialLogin redirectUri={redirectUri} config={config?.config} />;
  }

  return <LoadingScreen isLoading={!configError} message={configError} />;
}

AutoLogin.propTypes = {
  setAltairAdminBaseUrl: PropTypes.func,
  setAltairOneBaseUrl: PropTypes.func,
};

export default withTopbar(AutoLogin);
