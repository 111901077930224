import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { processBuildingBlock } from '../processBuildingBlock';

import { useAppFrame } from '../../amdc-app-frame/context/useAppFrame';
import { isEmpty } from 'lodash';

const mapStateToProps = (state) => {
  return {
    appContent: state.roots.appContent,
    config: state.roots.config,
    bbTopBar: state.roots.bbTopBar,
    ranges: state.roots.ranges,
    canAccess: state.roots.canAccess,
    language: state.roots.language,
    texts: state.roots.texts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function TopbarContainer({
  appContent,
  config,
  bbTopBar,
  getBBTopBar,
  ranges,
  canAccess,
  onWaffleButtonClick,
  onNotificationBellClick,
  onUserButtonClick,
  language,
  texts,
}) {
  const [collapsedVkeys, setCollapsedVkeys] = useState([]);
  const [activeTabMap, setActiveTabMap] = useState(new Map());
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [addedStyles, setAddedStyles] = useState([]);
  const [body, setBody] = useState([]);

  const { setTopBarHidden } = useAppFrame();

  const onTabClick = useCallback(
    (id, item) => {
      setActiveTabMap(new Map(activeTabMap.set(id, item)));
    },
    [activeTabMap]
  );

  useEffect(() => {
    if (!isEmpty(config)) {
      if (config.topbar && language) {
        setTopBarHidden(true);
        getBBTopBar(config.topbar.page);
      } else {
        setTopBarHidden(false);
      }
    }
  }, [config, getBBTopBar, language, setTopBarHidden]);

  const addStyle = useCallback(
    (id, style) => {
      if (!addedStyles.includes(id)) {
        var styleSheet = document.createElement('style');

        let styleText = '';
        if (style && style.length > 0) {
          for (let styleElement of style) {
            styleText += '.topbar' + styleElement + ' ';
          }
          styleSheet.innerText = styleText;
          document.head.appendChild(styleSheet);
        }

        setAddedStyles((prevStyles) => [...prevStyles, id]);
      }
    },
    [addedStyles]
  );

  const getActiveTab = useCallback(
    (id) => {
      return activeTabMap.get(id)?.props?.itemKey;
    },
    [activeTabMap]
  );

  function handleToggler(vkey, value) {
    if (value === true) {
      setCollapsedVkeys((oldCollapsedVkeys) => [...oldCollapsedVkeys, vkey]);
    } else {
      setCollapsedVkeys((oldCollapsedVkeys) =>
        oldCollapsedVkeys.filter((collapsedVkey) => {
          return collapsedVkey !== vkey;
        })
      );
    }
  }

  function onSetLayoutMap(map) {
    setLayoutMap(map);
  }

  useEffect(() => {
    let newBody = [];

    if (bbTopBar) {
      for (let buildingBlock of bbTopBar) {
        processBuildingBlock({
          appContent,
          buildingBlock,
          body: newBody,
          collapsedVkeys,
          handleToggler,
          onTabClick,
          getActiveTab,
          canAccess,
          layoutMap,
          setLayoutMap: onSetLayoutMap,
          addStyle,
          ranges,
          handleWaffleButtonClick: onWaffleButtonClick,
          handleNotificationBellClick: onNotificationBellClick,
          handleUserButtonClick: onUserButtonClick,
          texts,
        });
      }
    }

    setBody(newBody);
  }, [
    bbTopBar,
    layoutMap,
    collapsedVkeys,
    canAccess,
    ranges,
    addStyle,
    getActiveTab,
    onTabClick,
    onNotificationBellClick,
    onWaffleButtonClick,
    onUserButtonClick,
    appContent,
    texts,
  ]);

  if (config.topbar) {
    return <div className="amdc-block topbar">{body}</div>;
  } else {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopbarContainer);
