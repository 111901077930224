/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const ErrorContainer = styled.div`
  float: left;
  position: relative;
  left: 50%;
`;

const ErrorMessage = styled.div`
  display: flex;
  height: 70vh;
  justify-content: center;
  flex-direction: column;
  font-size: 18px;
  float: left;
  position: relative;
  left: -50%;
`;

export { ErrorContainer, ErrorMessage };
