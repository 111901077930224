/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import apolloClient from '../../amdc-app-frame/apollo';
import gql from 'graphql-tag';

const CREATE_NOTIFICATION_MUTATION = gql`
  mutation CREATE_NOTIFICATION_MUTATION($notificationInput: NotificationInput!) {
    createNotification(notificationInput: $notificationInput) {
      id
      attributes
      system
    }
  }
`;

export function createNotification({ level, primaryText, type, userId, variables }) {
  const notificationInput = {
    appId: 'MATERIAL_DB',
    level,
    primaryText,
    type,
    userId,
    variables,
  };

  apolloClient.mutate({
    mutation: CREATE_NOTIFICATION_MUTATION,
    variables: {
      notificationInput,
    },
  });
}
