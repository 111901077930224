/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CommentDialog from '../../../components/dialogs/CommentDialog';

const DEFAULT_TITLE = 'Thank you for your interest in Altair Units.';
const DEFAULT_PLACEHOLDER = 'Please enter any comments or questions you have for our sales team.';
const FEEDBACK_SENT_MESSAGE = 'A member of our local sales team will contact you shortly!';

const NOOP = () => {};

const FeedbackStatus = {
  Failed: 'failed',
  New: 'new',
  Sending: 'sending',
  Sent: 'sent',
};

function getDefaultDialogState({ message = null }) {
  return {
    message,
    feedbackStatus: FeedbackStatus.New,
    error: null,
  };
}

export function ContactUsDialog({
  altairOneBaseUrl = '',
  api,
  appName,
  hidden = true,
  message: porpsMessage,
  onDismiss = NOOP,
  placeholder = DEFAULT_PLACEHOLDER,
  title = DEFAULT_TITLE,
}) {
  const [{ message, feedbackStatus, error }, setState] = useState(getDefaultDialogState({ message: porpsMessage }));

  async function sendFeedback() {
    try {
      setState(prevState => ({ ...prevState, feedbackStatus: FeedbackStatus.Sending }));
      await api.notification.sendSupportRequest({ appName, comment: message }, altairOneBaseUrl);
      await api.account.sendComment({ appName, comment: message }, altairOneBaseUrl);
      setState({ message: '', feedbackStatus: FeedbackStatus.Sent });
    } catch (e) {
      // TODO:
      setState({
        message,
        error: 'Unable to submit comment at this time. Please try again later',
        feedbackStatus: FeedbackStatus.Failed,
      });
    }
  }

  return (
    <CommentDialog
      hidden={hidden}
      onDismiss={onDismiss}
      successMsg={FEEDBACK_SENT_MESSAGE}
      title={title}
      onChange={(e, text) => setState(prevState => ({ ...prevState, error: null, message: text }))}
      onDismissed={() => {
        setState(getDefaultDialogState({ message: porpsMessage }));
      }}
      onSubmitClick={sendFeedback}
      message={message}
      status={feedbackStatus}
      failureMsg={error}
      commentBoxProps={{ placeholder, rows: 5 }}
    />
  );
}

ContactUsDialog.propTypes = {
  altairOneBaseUrl: PropTypes.string,
  api: PropTypes.object,
  appName: PropTypes.string,
  hidden: PropTypes.bool,
  message: PropTypes.string,
  onDismiss: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default ContactUsDialog;
