/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
function updateGroup({ group, newFilters, newValue }) {
  const newGroup = {
    ...group,
    filters: newFilters,
  };

  if (newValue !== undefined) {
    newGroup.value = newValue;
  }

  return newGroup;
}

export default updateGroup;
