/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DirectionalHint, Icon, TooltipHost } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';

export function ErrorIcon({ title, width }) {
  const style = { height: `${width}px`, width: `${width}px`, color: '#f05228', fontSize: width };

  return (
    <TooltipHost content={title} directionalHint={DirectionalHint.bottomAutoEdge}>
      <Icon data-testid="ErrorIcon" iconName="StatusErrorFull" styles={{ root: style }} />
    </TooltipHost>
  );
}

ErrorIcon.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default ErrorIcon;
