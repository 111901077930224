/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';

import propTypes from './propTypes';
import Tab from './Tab';

function Tabs(props) {
  const { activeTabKey, children } = props;

  return (
    <>
      {React.Children.map(children, child => {
        const { children: tabChildren, name: tabName } = child.props;
        const isVisible = tabName === activeTabKey;

        return <Tab isVisible={isVisible}>{tabChildren}</Tab>;
      })}
    </>
  );
}

Tabs.propTypes = {
  children: propTypes.tabs,
  activeTabKey: PropTypes.string.isRequired,
};

Tabs.defaultProps = {
  children: null,
};

export default Tabs;
