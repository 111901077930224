/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
class Poller {
  constructor(callback, interval = 100) {
    if (typeof callback !== 'function') {
      throw new TypeError('Callback must be a function');
    }

    this._callback = callback;
    this._interval = interval;
  }

  poll() {
    this.clear();
    this.timeoutId = setTimeout(async () => {
      await this._callback();
      this.poll();
    }, this._interval);
  }

  clear() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}

export default Poller;
