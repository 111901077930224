/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
function localNotifications(state = {}, action) {
  switch (action.type) {
    case 'SHOW_LOCAL_NOTIFICATION':
      return [action.localNotification, ...state];
    case 'HIDE_LOCAL_NOTIFICATION':
      return state.filter(note => note && note.id !== action.localNotification.id);
    default:
      return state;
  }
}

export default localNotifications;
