/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import isInteger from 'lodash/isInteger';

function validateColor({ newDataItem }) {
  let { value, values } = newDataItem;

  if (values) {
    values = values.map(elem => validate(elem));
  } else {
    value = validate(value);
  }

  return { value, values };
}

function validate(value) {
  if (typeof value === 'object') {
    if (value.r === undefined || value.g === undefined || value.b === undefined) {
      throw new TypeError(`Color object must have the keys r, g, and b.`);
    }

    if (!isInteger(value.r) || !isInteger(value.g) || !isInteger(value.b)) {
      throw new TypeError(`Color object r, g, and b values must be integers.`);
    }

    if (value.r < 0 || value.g < 0 || value.b < 0) {
      throw new TypeError(`Color object r, g, and b values cannot be negative.`);
    }

    if (value.r > 255 || value.g > 255 || value.b > 255) {
      throw new TypeError(`Color object r, g, and b values cannot be greater than 255.`);
    }

    if (value.a !== undefined) {
      if (!isInteger(value.a)) {
        throw new TypeError(`Color object "a" value must be an integer.`);
      }

      if (value.a < 0) {
        throw new TypeError(`Color object "a" value cannot be negative.`);
      }

      if (value.a > 1) {
        throw new TypeError(`Color object "a" value cannot be greater than 1.`);
      }
    }
  } else if (typeof value === 'string') {
    if (!value.startsWith('#') || ![4, 7].includes(value.length)) {
      throw new TypeError(`Color string must be of the form #000 or #000000.`);
    }
  } else {
    throw new TypeError(`Color must be an array, object, or string.`);
  }

  return value;
}

export default validateColor;
