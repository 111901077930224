import React from 'react';
import { ScrollablePane, SearchBox, Panel, PanelType, classNamesFunction } from '@fluentui/react';
import { Left, Margin5 } from '../../pages/styles';
import * as TextMapping from '../../utils/textMapping';
import FilterControl from '../FilterControl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import { useWindowSize } from 'react-use';
import { getStyles } from './styles';
import { styled } from '@fluentui/utilities';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

const NOOP = () => {};
const getClassNames = classNamesFunction();

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

function LeftSidePanelBase({
  roots,
  getMaterials,
  entityView,
  history,
  updateQuickSearch,
  quickSearchValue,
  search,
  qs,
  resultSummaries,
  filteredTags,
  sortColumn,
  sortDirection,
  searchFilters,
  isPanelOpen = false,
  onPanelDismiss = NOOP,
  scrollableTarget,
  favoritedClick,
  theme,
  themeName,
}) {
  const { appContent, texts, counts } = roots;
  const { width } = useWindowSize();
  const isMobile = width < 640;

  const classNames = getClassNames(getStyles({ isMobile }));

  let placeHolder = theme?.semanticColors?.leftSidePanelSearchBoxPlaceholder
    ? {
        '::placeholder': {
          color: theme?.semanticColors?.leftSidePanelSearchBoxPlaceholder,
        },
      }
    : null;

  let fieldColor = theme?.semanticColors?.leftSidePanelSearchBoxText
    ? {
        color: theme?.semanticColors?.leftSidePanelSearchBoxText,
      }
    : null;

  let iconColor = theme?.semanticColors?.leftSidePanelSearchBoxIcon
    ? {
        color: theme?.semanticColors?.leftSidePanelSearchBoxIcon,
      }
    : null;

  let opacity = roots.firstLoad && roots.status !== 'SUCCESS' ? { opacity: '0.5' } : { opacity: '1.0' };

  const Layout = (
    <Left style={{ ...opacity, width: isMobile ? '100%' : '260px' }} className="leftSidePanelBackground leftSidePanelText">
      {roots.firstLoad && roots.status !== 'SUCCESS' && (
        <div
          style={{
            zIndex: '10',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(255,255,255,.5)',
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
      <ScrollablePane data-testid="left-side-panel">
        <Margin5>
          <SearchBox
            className="leftSidePanelSearchBoxBackground"
            data-testid="material-quick-search"
            label="Search"
            styles={{
              root: {
                height: '24px',
                fontSize: '11px',
                borderRadius: '12px',
              },
              field: { ...placeHolder, ...fieldColor },
              iconContainer: {
                maxWidth: '20px',
              },
              icon: {
                width: '20px',
                height: '20px',
                ...iconColor,
              },
            }}
            onChange={updateQuickSearch}
            placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SEARCH_MATERIALS, texts)}
            value={quickSearchValue}
            onSearch={() => {
              qs.current && qs.current.pattern ? search(qs.current.pattern + ',' + quickSearchValue) : search(quickSearchValue);
            }}
            onClear={() => {
              search('');
            }}
            defaultValue={qs.current.pattern}
            disableAnimation={true}
          />
        </Margin5>
        {resultSummaries}
        <Margin5>
          <FilterControl
            qs={qs}
            appContent={appContent}
            history={history}
            tags={filteredTags}
            counts={counts}
            getApps={getMaterials}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            view={entityView}
            updateLoadIndex
            updateOffset
            cleanEntityItems
            searchFilters={searchFilters}
            texts={texts}
            scrollableTarget={scrollableTarget}
            favoritedClick={favoritedClick}
          />
        </Margin5>
      </ScrollablePane>
    </Left>
  );

  if (!isMobile) {
    return Layout;
  }
  return (
    <Panel
      className={themeName}
      isHiddenOnDismiss
      isLightDismiss
      isOpen={isPanelOpen}
      onDismiss={onPanelDismiss}
      styles={classNames.subComponentStyles.panel()}
      type={PanelType.smallFluid}
    >
      {Layout}
    </Panel>
  );
}

const LeftSidePanel = styled(LeftSidePanelBase, () => {}, undefined, { scope: 'LeftSidePanel' });

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidePanel);
