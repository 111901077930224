/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export const getStyles = props => {
  const { className, videoId } = props;

  return {
    root: [
      'aone-WistiaPlayer',
      'wistia_responsive_padding',
      className,
      {
        position: 'relative',
        height: '100%',
        width: '100%',
        minWidth: 150,
        minHeight: 150,
      },
    ],
    wrapper: [
      'aone-WistiaPlayer-wrapper',
      'wistia_responsive_wrapper',
      {
        height: '100%',
        left: 0,
        position: 'absolute',
        top: 0,
        width: '100%',
      },
    ],
    embed: [
      'aone-WistiaPlayer-embed',
      'wistia_embed popover=true popoverAnimateThumbnail=true videoFoam=true',
      `wistia_async_${videoId}`,
      {
        display: 'inline-block',
        height: '100%',
        width: '100%',
      },
    ],
  };
};
