/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import PropTypes from 'prop-types';

function LoadingSpinner({ label }) {
  return (
    <div
      style={{
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <Spinner size={SpinnerSize.large} label={label} />
    </div>
  );
}

LoadingSpinner.propTypes = {
  label: PropTypes.string,
};

export default LoadingSpinner;
