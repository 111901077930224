/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@fluentui/react';
import { getNativeProps, buttonProperties } from '@fluentui/utilities';

export function WaffleButtonBase({ styles, ...restProps }) {
  const nativeButtonProps = getNativeProps(restProps, buttonProperties);

  const buttonStyles = styles(restProps);

  return <IconButton styles={buttonStyles} {...nativeButtonProps} iconProps={{ iconName: 'AppMenu' }} />;
}

WaffleButtonBase.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
