/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import isBoolean from 'lodash/isBoolean';

function validateBoolean({ newDataItem }) {
  let { value, values } = newDataItem;

  if (values) {
    values = values.map(elem => validate(elem));
  } else {
    value = validate(value);
  }

  return { value, values };
}

function validate(value) {
  if (!isBoolean(value)) {
    throw new TypeError(`"${value}" is not a boolean`);
  }

  return value;
}

export default validateBoolean;
