/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
export function formatDuration(ms) {
  if (!ms) return null;

  const seconds = Math.round(ms / 1000);

  if (seconds < 1) {
    return `${ms} ms`;
  }

  const minutes = Math.round(seconds / 60);

  if (minutes < 1) {
    return `${seconds} s`;
  }

  const hours = Math.round(minutes / 60);

  if (minutes < 1) {
    return `${hours} min`;
  }

  const days = Math.round(hours / 24);

  if (days < 1) {
    return `${hours} hr`;
  }

  return `${days} day`;
}
