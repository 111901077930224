/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useState } from 'react';
import { IconButton, Link, ShimmerElementType, ShimmerElementsGroup, Shimmer } from '@fluentui/react';

import PropTypes from 'prop-types';
import { Cell, Content, Grid, Title } from './styled';

const styles = {
  root: {
    lineHeight: '20px',
    marginLeft: '10px',
    display: 'table',
  },
};

const customElementsGroup = () => {
  return (
    <div style={{ margin: '30px 0px 20px' }}>
      <ShimmerElementsGroup
        flexWrap
        width="100%"
        shimmerElements={[
          { type: ShimmerElementType.line, width: '100%', height: 10 },
          { type: ShimmerElementType.gap, width: '100%', height: 10 },
          { type: ShimmerElementType.line, width: '100%', height: 10 },
          { type: ShimmerElementType.gap, width: '100%', height: 10 },
          { type: ShimmerElementType.line, width: '100%', height: 10 },
        ]}
      />
    </div>
  );
};

export function AdditionalServices({ altairOneBaseUrl = null, position = 'horizontal', maxHeight = '100%', width = '100%' }) {
  const [allServices, setAllServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getAdditionalServices() {
      try {
        setLoading(false);
        // TODO: Cleanup after fileservice issue is fixed in test namespace
        // const additionalServices = await api.config.getConfigByPath(additionalServicesConfigFilePath);

        if (altairOneBaseUrl === null) return;

        const res = await fetch(`${altairOneBaseUrl}/additional/AdditionalServices.json`, { credentials: 'include' });
        if (res.status === 200) {
          const additionalServices = await res.json();
          setAllServices(
            additionalServices.content.map(services => {
              const newServices = services;
              newServices.isOpen = true;
              return newServices;
            })
          );
        }
      } catch (e) {
        // error in getting the config file
      }
    }
    getAdditionalServices();
  }, [altairOneBaseUrl]);

  const handleVisibility = services => {
    const newServices = [...allServices];
    const index = allServices.findIndex(e => e.serviceName === services.serviceName);
    newServices[index] = { ...newServices[index], isOpen: !newServices[index].isOpen };
    setAllServices(newServices);
  };

  if (loading)
    return (
      <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <Shimmer customElementsGroup={customElementsGroup()} />
      </div>
    );

  return (
    <Grid position={position} width={width} length={allServices.length}>
      {allServices &&
        allServices.map(services => (
          <Cell maxHeight={maxHeight} key={services.serviceName}>
            <Title>
              {services.serviceName}
              {position === 'vertical' && (
                <IconButton
                  styles={{ root: { height: '22px', float: 'right' } }}
                  iconProps={{ iconName: services.isOpen ? 'ChevronDown' : 'ChevronUp' }}
                  onClick={() => handleVisibility(services)}
                  data-testid={`${services.serviceName}-Button-ExpandCollapse`}
                />
              )}
            </Title>
            <Content isOpen={services.isOpen}>
              {services.serviceLinks.map(link => (
                <Link
                  key={link.title}
                  styles={styles}
                  href={link.url}
                  target="_blank"
                  data-testid={`${services.serviceName}-Link-${link.title}`}
                >
                  {link.title}
                </Link>
              ))}
            </Content>
          </Cell>
        ))}
    </Grid>
  );
}

AdditionalServices.propTypes = {
  altairOneBaseUrl: PropTypes.string,
  position: PropTypes.string,
  maxHeight: PropTypes.string,
  width: PropTypes.string,
};

export default AdditionalServices;
