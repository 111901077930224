/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
/**
 * Returns a specific data item from the full data object.
 * @param {Object} data - Full data object passed as prop.
 * @returns {Object}
 */
const getDataItem = (data, pathKeys) => {
  let iterData = data;
  let dataItem;

  pathKeys.forEach((key, index) => {
    iterData = iterData[key];

    const isLastKey = index === pathKeys.length - 1;

    if (isLastKey) dataItem = iterData;
  });

  return dataItem;
};

export default getDataItem;
