/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { getOktaAuthClient, initAuthConfig, oktaUserNameByEmail } from '../../../api/package/auth';
import { guestLogin } from '../../../actions';

export const errorHtml = `
<div style="font-size: 14px;">
  <h3 style="color: #fa4716; margin: 4px 0px;">Sorry! Something went wrong on our end.</h3>
  <p style="margin: 0;">Please refresh the page to try again or contact our <a href="mailto:support@altair.com?subject=Not able to login">customer support</a> if the problem persists.</p>
</div>
`;

function useOktaSSO({ redirectUri: propsRedirectUri }) {
  const { dispatch } = useStore();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [status, setStatus] = useState();

  const redirectToOktaLogin = useCallback(
    async ({ username }) => {
      setIsLoggingIn(true);

      let loginHint = username;
      try {
        const oktaLogin = await oktaUserNameByEmail({ email: username });
        if (oktaLogin) {
          loginHint = oktaLogin;
        }
      } catch {
        // TODO
      }

      const param = new URLSearchParams(window.location.search);
      param.delete('sso');

      const redirectUri =
        propsRedirectUri ||
        `${window.location.pathname}${Array.from(param.values()).length > 0 ? `?${decodeURIComponent(param.toString())}` : ''}`;
      let authClient = await getOktaAuthClient();
      if (!authClient) {
        await initAuthConfig();
        authClient = await getOktaAuthClient();
      }
      authClient.token.getWithRedirect({
        responseType: ['code', 'token'],
        scopes: ['openid', 'email', 'offline_access'],
        state: redirectUri,
        loginHint,
      });
    },
    [propsRedirectUri]
  );

  const initiateOktaSSO = useCallback(async () => {
    setStatus('INIT_OKTA_CLIENT');

    setStatus('GUEST_LOGGED_IN');
    dispatch(guestLogin());
  }, [dispatch]);

  useEffect(() => {
    initiateOktaSSO();
  }, [initiateOktaSSO]);

  return { isLoggingIn, redirectToOktaLogin, status };
}

export default useOktaSSO;
