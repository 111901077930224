/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export const styles = {
  root: [
    'aone-NoLicense',
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '1 0 auto',
    },
  ],
  icon: {
    fontSize: '72px',
    lineHeight: '72px',
  },
  message: {
    marginBottom: '12px',
  },
};
