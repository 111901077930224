/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const ModalContainer = styled.div`
  width: 60vw;
  overflow: hidden;
  max-height: calc(100vh - 80px);
  min-width: 400px;
`;

const ModalRoot = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const ModalDescriptionContainer = styled.div`
  box-sizing: border-box;
  padding: 40px 32px;
  width: 230px;
  flex: 0 0 auto;
  overflow: auto;
  background: #f8f8f8;
`;

const ModalItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const ModalActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalCloseContainer = styled.div`
  margin: 5px;
  text-align: center;
  align-self: flex-end;
  width: 48px;
  height: 32px;
`;

const ModalContentContainer = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
`;

const ModalFooterContainer = styled.div`
  border-top: 1px solid #eaeaea;
  padding: 18px 48px;
  display: flex;
  justify-content: flex-end;
`;

export {
  ModalContainer,
  ModalRoot,
  ModalDescriptionContainer,
  ModalItemContainer,
  ModalActionContainer,
  ModalCloseContainer,
  ModalContentContainer,
  ModalFooterContainer,
};
