/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Pivot as OfficePivot, PivotItem } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';
import propTypes from './propTypes';

function Pivot(props) {
  const { activeTabKey, children, onItemClick } = props;

  return (
    <div>
      <OfficePivot onLinkClick={onItemClick} selectedKey={activeTabKey}>
        {React.Children.map(children, child => {
          const { name: tabName } = child.props;

          return <PivotItem headerText={tabName} itemKey={tabName} />;
        })}
      </OfficePivot>
    </div>
  );
}

Pivot.propTypes = {
  activeTabKey: PropTypes.string.isRequired,
  children: propTypes.tabs,
  onItemClick: PropTypes.func,
};

Pivot.defaultProps = {
  children: [],
  onItemClick: () => {},
};

export default Pivot;
