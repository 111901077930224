/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import BooleanInput from './BooleanInput';
import ColorInput from './ColorInput';
import DateInput from './DateInput';
import IntegerInput from './IntegerInput';
import NumberInput from './NumberInput';
import StringInput from './StringInput';
import UnitScalarInput from './UnitScalarInput';

function Readonly({ dataItem, onBlur, onChange, pathKeys }) {
  const [mode, setMode] = useState('READONLY');

  const handleOnBlur = useCallback(
    (...args) => {
      setMode('READONLY');
      onBlur(args);
    },
    [onBlur]
  );

  const handleDoubleClick = useCallback(() => {
    if (!dataItem.isReadOnly) {
      setMode('WRITE');
    }
  }, [dataItem]);

  const component = useMemo(() => {
    const { type, value } = dataItem;

    const props = { dataItem, onBlur: handleOnBlur, onChange, pathKeys };

    const testId = `PropertyEditor-${pathKeys.join('-')}`;
    if (mode === 'READONLY') {
      let nextValue = value;
      if (type === 'date') {
        nextValue = format(value, 'M/D/YYYY h:mm A');
      } else if (type === 'color') {
        nextValue = <ColorInput dataItem={{ value, isReadOnly: true }} pathKeys={pathKeys} />;
      } else if (type === 'boolean') {
        nextValue = Boolean(value).toString();
      }

      return (
        <span data-testid={testId} onDoubleClick={handleDoubleClick} style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          {nextValue}
        </span>
      );
    }

    switch (type) {
      case 'boolean':
        return <BooleanInput {...props} />;
      case 'color':
        return <ColorInput {...props} />;
      case 'date':
        return <DateInput {...props} />;
      case 'integer':
        return <IntegerInput {...props} />;
      case 'number':
        return <NumberInput {...props} />;
      case 'string':
        return <StringInput {...props} />;
      case 'unitScalar':
        return <UnitScalarInput {...props} />;
      default:
        return <StringInput {...props} />;
    }
  }, [dataItem, handleDoubleClick, handleOnBlur, mode, onChange, pathKeys]);

  return component;
}

Readonly.propTypes = {
  dataItem: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.any,
  }).isRequired,
  pathKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Readonly;
