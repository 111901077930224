import React from 'react';
import MBaseLogo from './M-base.png';
import { DefaultButton } from '@fluentui/react';
import * as TextMapping from '../../utils/textMapping';
import { isEmpty } from 'lodash';

function MBaseLink({ appContent, linkRef, canAccess, config, handleUpgradeClick, texts, uicheckRun }) {
  let showUpgradeButton = false;

  if ((uicheckRun && !canAccess && config.upgradepremium) || (config.upgradepremium && config.upgradepremium.showalways)) {
    showUpgradeButton = true;
  }

  if (
    appContent &&
    appContent.user &&
    appContent.user.license &&
    !isEmpty(appContent.user.license) &&
    config.upgradepremium &&
    config.upgradepremium.hide
  ) {
    let found = false;
    for (let license of appContent.user.license) {
      if (config.upgradepremium.hide.includes(license)) {
        found = true;
        break;
      }
    }
    if (!found) {
      showUpgradeButton = true;
    }
  }

  return (
    <div
      ref={linkRef}
      style={{
        display: 'flex',
        marginLeft: 'auto',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        fontSize: '12px',
        color: '#4D4D4D',
        float: 'right',
        paddingTop: '5px',
      }}
    >
      {config.visitmbase && (
        <DefaultButton
          style={{
            marginLeft: '10px',
            marginRight: '10px',
            border: '1px solid #A2AAAD',
            borderRadius: '16px',
            fontSize: '12px',
            textAlign: 'center',
            lineHeight: '16px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          href="https://www.materialdatacenter.com/mb/okta"
          target="_blank"
        >
          {' '}
          <img height="16px" src={MBaseLogo} alt="MBase" style={{ marginRight: '5px' }} />
          {TextMapping.getUIText(TextMapping.UI_TEXT_VISIT_MBASE, texts, { mbase: 'M-Base' })}
        </DefaultButton>
      )}
      {showUpgradeButton && (
        <DefaultButton
          style={{
            background: '#005879',
            borderRadius: '16px',
            fontSize: '12px',
            color: '#FFFFFF',
            textAlign: 'center',
            lineHeight: '16px',
            marginRight: '10px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_UPGRADE_TO_PREMIUM, texts)}
          M-Base
          onClick={handleUpgradeClick}
        />
      )}
    </div>
  );
}

export default MBaseLink;
