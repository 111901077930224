/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

async function getList() {
  const url = `/file/trash/list`;

  const { data } = await axios.get(url);

  return data;
}

async function empty() {
  const url = `/file/trash/empty`;

  await axios.delete(url);

  return {};
}

async function restore(entityList, { applyToAll = false, keepBoth = false, replace = false }) {
  const url = `/file/trash/restore`;

  const reqConfig = {
    params: {
      applyToAll,
      keepBoth,
      replace,
    },
  };

  const reqBody = entityList.map(entity => {
    return {
      contentType: entity.system.contentType,
      id: entity.id,
    };
  });

  const { data } = await axios.post(url, reqBody, reqConfig);

  return data;
}

async function remove(entityList) {
  const url = `/file/trash/delete`;

  const reqBody = entityList.map(entity => {
    return {
      contentType: entity.system.contentType,
      id: entity.id,
    };
  });

  const { data } = await axios.post(url, reqBody);

  return data;
}

export default {
  empty,
  getList,
  restore,
  remove,
};
