/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export const getStyles = props => {
  const { hasCount, theme } = props;
  const { palette } = theme;

  return {
    redDot: {
      height: 6,
      width: 6,
      backgroundColor: palette.red,
      borderRadius: '50%',
      display: 'inline-block',
      position: 'absolute',
      top: 5,
      right: 0,
    },
    // badge: [
    //   'aone-NotificationBellIcon-badge',
    //   {
    //     position: 'absolute',
    //     left: 16,
    //     top: 2,
    //     lineHeight: 16,
    //     borderRadius: 8,
    //     padding: '0px 4px',
    //     color: palette.white,
    //     background: palette.red,
    //     ...fonts.tiny,
    //   },
    // ],
    subComponentStyles: {
      button: {
        root: [
          'aone-NotificationBellIcon',
          {
            color: ' #4D4D4D',
            background: 'none',
            minWidth: 16,
            padding: 0,
            marginLeft: 12,
          },
          // hasCount && {
          //   width: 40,
          // },
        ],
        rootHovered: {
          background: 'none',
          opacity: 0.8,
        },
        rootChecked: {
          background: '#3A393D',
        },
        rootCheckedHovered: {
          backgroundColor: ' #4D4D4D',
        },
        rootPressed: {
          background: 'none',
          color: ' #4D4D4D',
          opacity: 0.8,
          selectors: {
            ':active > *': {
              position: 'initial',
            },
          },
        },
        icon: {
          color: '#4D4D4D',
          fontSize: 16,
          margin: 0,
        },
        label: {
          margin: 0,
        },
        iconChecked: {
          opacity: 0.8,
        },
        iconHovered: {
          opacity: 0.8,
        },
        flexContainer: [
          hasCount && {
            justifyContent: 'flex-start',
          },
        ],
      },
    },
  };
};
