/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

function search(state = {}, action) {
  switch (action.type) {
    case 'CLEAR_SEARCH':
      return {};
    case 'FETCH_FAILED_SEARCH':
      return {
        status: 'FETCH_FAILED',
      };
    case 'FETCHING_SEARCH':
      return {
        ...state,
        status: 'FETCHING',
      };
    case 'REPLACE_SEARCH_ENTITIES':
      return {
        entities: [...action.entities],
        status: 'SUCCESS',
      };
    default:
      return state;
  }
}

export default search;
