/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Icon } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { LayoutSelectorContainer } from './styled';

export const Layout = {
  List: 'list',
  TilesWithDetails: 'grid-info',
  TreeList: 'TreeList',
};

const NOOP = () => {};

function LayoutSelector({ enableTreeLayout = false, changeLayout = NOOP, currentLayout: propsCurrentLayout }) {
  const controlledRef = useRef(propsCurrentLayout);
  const [currentLayout, setCurrentLayout] = useState(propsCurrentLayout);
  const handleLayout = useCallback(
    layout => () => {
      if (!controlledRef.current) {
        setCurrentLayout(layout);
      }
      if (currentLayout !== layout) {
        changeLayout(layout);
      }
    },
    [changeLayout, currentLayout]
  );

  useEffect(() => {
    setCurrentLayout(propsCurrentLayout);
  }, [propsCurrentLayout]);

  return (
    <LayoutSelectorContainer>
      <Icon
        onClick={handleLayout(Layout.List)}
        style={{ fontSize: '24px', opacity: currentLayout === 'list' ? 1 : 0.5 }}
        iconName="Hamburger"
        title="View items and details in a list"
      />
      {enableTreeLayout && (
        <Icon
          onClick={handleLayout(Layout.TreeList)}
          style={{ fontSize: '24px', opacity: currentLayout === 'TreeList' ? 1 : 0.5 }}
          iconName="TreeView"
          title="View items and details in a tree list"
        />
      )}
      <Icon
        onClick={handleLayout(Layout.TilesWithDetails)}
        style={{ fontSize: '20px', marginRight: '4px', opacity: currentLayout === 'grid-info' ? 1 : 0.5 }}
        iconName="TileView"
        title="View items in tile previews with details"
      />
    </LayoutSelectorContainer>
  );
}

LayoutSelector.propTypes = {
  changeLayout: PropTypes.func,
  enableTreeLayout: PropTypes.bool,
  currentLayout: PropTypes.string,
};

export default LayoutSelector;
