/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { DefaultButton, IconButton } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

function ChangeViewButton({ active, onClick, view }) {
  const buttonStyles = useMemo(() => {
    if (!view) {
      return {};
    }
    let fontFamily;
    if (view.name === 'Text') {
      fontFamily = '"Times New Roman", Times, serif';
    }
    return {
      root: [
        {
          fontFamily,
          fontSize: 16,
          height: 30,
          width: 30,
          borderRadius: 2,
          border: '1px solid #000000',
          padding: 0,
          minWidth: 30,
          background: '#FFFFFF',
        },
        active && {
          background: '#00AAE6',
          borderColor: '#00AAE6',
          color: '#FFFFFF',
        },
      ],
      rootHovered: [
        {
          background: '#FFFFFF',
        },
        active && {
          color: '#FFFFFF',
          background: '#00AAE6',
        },
      ],
      rootPressed: [
        {
          background: '#FFFFFF',
        },
        active && {
          color: '#FFFFFF',
          background: '#00AAE6',
        },
      ],
    };
  }, [active, view]);

  const button = useMemo(() => {
    if (!view) return null;

    switch (view.name) {
      case 'Preview':
        return (
          <IconButton
            data-testid="PreviewChangeViewButton"
            className="ChangeViewButton"
            iconProps={{ iconName: 'Image' }}
            onClick={onClick}
            styles={buttonStyles}
            title={view.id}
          />
        );
      case 'Text':
        return (
          <DefaultButton
            data-testid="TextChangeViewButton"
            className="ChangeViewButton"
            onClick={onClick}
            styles={buttonStyles}
            text="T"
            title={view.name}
          />
        );
      case '3D':
        return (
          <DefaultButton
            data-testid="ThreeDChangeViewButton"
            className="ChangeViewButton"
            onClick={onClick}
            styles={buttonStyles}
            text="3D"
            title={view.name}
          />
        );
      default:
        return (
          <IconButton
            data-testid="DefaultChangeViewButton"
            className="ChangeViewButton"
            iconProps={{ iconName: 'RedEye' }}
            onClick={onClick}
            styles={buttonStyles}
            title={view.name}
          />
        );
    }
  }, [buttonStyles, onClick, view]);

  return button;
}

ChangeViewButton.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
  view: PropTypes.object,
};

ChangeViewButton.defaultProps = {
  activeViewerIndex: 0,
  onItemClick: () => {},
  viewers: [],
};

export default ChangeViewButton;
