/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import get from 'lodash/get';
import { Icon } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { CDefCache } from '@altair/ui-cache';
import { ContentTypeIcon } from './ContentTypeIcon';
import { ErrorIcon } from './ErrorIcon';
import { ExtensionIcon } from './ExtensionIcon';
import { JobIcon } from './JobIcon';
import { PendingIcon } from './PendingIcon';
import { ProgressIcon } from './ProgressIcon';
import { MDEIcon } from './MDEIcon';
import { MaterialIcon } from './MaterialIcon';

const _defaultWidth = 20;

export function EntityIcon(props) {
  const { 'data-testid': dataTestId, entity, noError, noPending, noProgress, noSpinner, noVersion, width } = props;

  const isVersioned = get(entity, 'system.isVersioned', false);
  let icon = null;

  const jobTypes = useMemo(() => {
    const { Job: cdefs = [] } = CDefCache.getDerivedCDefs({ asList: true, cDefNames: ['Job'] });
    return ['Job', ...cdefs.map(cdef => get(cdef, 'definition.name'))];
  }, []);

  const contentType = get(entity, 'system.contentType') || '';
  if (jobTypes && jobTypes.includes(contentType)) {
    const jobStatus = get(entity, 'attributes.status') || '';
    icon = <JobIcon status={jobStatus} width={width} />;
  } else {
    const { previewError } = entity.attributes;
    const isUploadEntity = !!get(entity, 'pseudo.upload');
    const isPendingEntity = get(entity, 'pseudo.isPending') || false;
    const isMDERunning = get(entity, 'isMDERunning') || false;

    if (isUploadEntity && !noProgress) {
      icon = <ProgressIcon entity={entity} width={width} />;
    } else if (isPendingEntity && !noPending) {
      icon = <PendingIcon width={width} />;
    } else if (isMDERunning && !noSpinner) {
      icon = <MDEIcon entity={entity} width={width} />;
    } else if (previewError && !noError) {
      let title;
      if (typeof previewError === 'string') {
        title = previewError;
      }
      icon = <ErrorIcon width={width} title={title} />;
      // content type icons are hosted in content svc temporarily, will be added as part of content-def later
    } else if (contentType === 'Material') {
      icon = <MaterialIcon entity={entity} width={width} />;
    } else if (contentType) {
      icon = <ContentTypeIcon entity={entity} width={width} />;
    } else {
      icon = <ExtensionIcon entity={entity} width={width} />;
    }
  }

  // const height = (width * 18.5) / 13.5;
  const height = width;

  return (
    <span
      data-testid={dataTestId}
      style={{
        position: 'relative',
        height: `${height}px`,
        // width: `${width + (isVersioned ? width * 0.75 : 0)}px`,
        width: `${!noVersion && isVersioned ? width + 16 : width}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon}
      {!noVersion && isVersioned && <Icon iconName="Branch" />}
    </span>
  );
}

EntityIcon.propTypes = {
  'data-testid': PropTypes.string,
  entity: PropTypes.object,
  noError: PropTypes.bool,
  noPending: PropTypes.bool,
  noProgress: PropTypes.bool,
  noSpinner: PropTypes.bool,
  noVersion: PropTypes.bool,
  width: PropTypes.number,
};

EntityIcon.defaultProps = {
  'data-testid': 'EntityIcon',
  entity: null,
  noError: false,
  noPending: false,
  noProgress: false,
  noSpinner: false,
  width: _defaultWidth,
};

export default EntityIcon;
