/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';
import actionApi from './action';
import searchAPI from './search';

const cache = new Map();

async function deleteFavorite(id) {
  const url = `/api/favourites?id=${id}&className=Application`;

  const { data } = await axios.delete(url);
  return data;
}

async function get({ contentType }) {
  const url = `/content/application/contenttype/${contentType}`;
  const { data } = await axios.get(url);

  return data;
}

async function getAllFromSearch() {
  const filter = {
    payload: {
      filter: {
        $is: ['@contentType'],
      },
      bindVars: {
        contentTypes: ['User'],
      },
    },
  };

  const { entities } = await searchAPI.search({ filter, isLegacy: false });

  return entities;
}

async function getFavorites({ apps }) {
  const appIds = apps.map(app => app.id);
  const url = '/api/favourites/status/bulk?contentType=Application';
  const { data } = await axios.post(url, { ids: appIds });

  const favs = {};
  data.forEach(f => {
    favs[f.id] = f;
  });
  const favApps = apps.filter(app => {
    const fav = favs[app.id];
    return fav !== undefined && fav.status === true;
  });

  return favApps;
}

async function getDisplayApps() {
  const apps = await getAllFromSearch({ apiObj: this });
  const favs = await getFavorites({ apiObj: this, apps });

  let favApps = favs || [];

  let defaultApps = apps
    ? apps.filter(app => {
        return app.attributes.isDefault === true && app.attributes.APP_ID !== 'Dashboard' && app.attributes.APP_ID !== 'Marketplace';
      })
    : [];
  defaultApps = defaultApps.sort((a1, a2) =>
    a1.attributes && a1.attributes.name ? a1.attributes.name.localeCompare(a2.attributes.name) : 0
  );

  const defaultAppIds = defaultApps.map(app => app.id);
  favApps = favApps
    .filter(app => !defaultAppIds.includes(app.id))
    .sort((a1, a2) => (a1.attributes && a1.attributes.name ? a1.attributes.name.localeCompare(a2.attributes.name) : 0));

  // Sorted default App first then sorted favApps
  return [...defaultApps, ...favApps];
}

function getSync({ name }) {
  if (cache.size === 0) {
    throw new Error('No application entities in cache');
  }

  const cachedEntities = [...cache.values()];

  return cachedEntities.find(entity => {
    return entity.attributes.name === name;
  });
}

function getSupportedSync({ contentType, hasComputeInfo }) {
  if (cache.size === 0) {
    throw new Error('No application entities in cache');
  }

  if (!contentType) {
    throw new TypeError('contentType must be defined');
  }

  const cachedEntities = [...cache.values()];

  return cachedEntities.filter(entity => {
    const supportedContents = entity.attributes.supportedContents.map(item => item.toLowerCase());

    if (!supportedContents.includes(contentType.toLowerCase())) return false;

    if (hasComputeInfo === true && !entity.attributes.computeInfo) {
      return false;
    }

    if (hasComputeInfo === false && entity.attributes.computeInfo) {
      return false;
    }

    if (entity.attributes.isExtractor === true) {
      return false;
    }

    return true;
  });
}

function open({ app }) {
  if (app?.isWebApp) {
    const host = `${window.location.protocol}//${window.location.host}`;
    const url = app?.attributes?.url?.replace('{host}', host);
    window.open(url, '_blank');
    return;
  }
  const name = app?.attributes?.name;
  actionApi.launchApplication(name);
}

export default {
  deleteFavorite,
  get,
  getAllFromSearch,
  getDisplayApps,
  getFavorites,
  getSync,
  getSupportedSync,
  open,
};
