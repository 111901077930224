import { useEffect } from 'react';

export const usePluginListener = (appContent, eventDefinitions) => {
  useEffect(() => {
    const listener = listenForPluginEvents(appContent, eventDefinitions);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [appContent, eventDefinitions]);
};

export const listenForPluginEvents = (appContent, eventDefinitions) => {
  if (appContent && appContent.config && appContent.config.pluginsources) {
    const listener = (event) => {
      // Do we trust the sender of this message?
      if (!appContent.config.pluginsources.includes(event.origin)) return;

      for (let eventDefinition of eventDefinitions) {
        if (eventDefinition.type && eventDefinition.callback) {
          if (event.data.type === eventDefinition.type) {
            eventDefinition.callback(event.data.data);
          }
        }
      }
    };

    window.addEventListener('message', listener);

    return listener;
  }
};
