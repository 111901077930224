/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
export function changeDialog(id, data = {}) {
  return {
    type: 'CHANGE_DIALOG',
    data,
    id,
  };
}

export function clearDialog() {
  return {
    type: 'CLEAR_DIALOG',
  };
}
