/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import initialStates from '../store/initialStates';

function loggedInUser(state = {}, action) {
  switch (action.type) {
    case 'AUTO_LOGGING_IN':
      return {
        ...state,
        status: 'AUTO_LOGGING_IN',
      };
    case 'CLEAR_LOGGED_IN_USER':
      return {
        ...initialStates.loggedInUser,
      };
    case 'LOGOUT':
      return {
        ...initialStates.loggedInUser,
        status: 'LOGGED_OUT',
      };
    case 'LOGGING_IN':
      return {
        ...state,
        status: 'LOGGING_IN',
      };
    case 'LOGIN_FAILED':
      return {
        ...initialStates.loggedInUser,
        status: 'LOGIN_FAILED',
      };
    case 'REPLACE_LOGGED_IN_USER':
      return {
        entity: action.entity,
        id: action.entity.id,
        username: action.entity.userName,
        name: action.entity.name,
        status: 'LOGGED_IN',
      };
    case 'SIGNING_UP':
      return {
        ...state,
        status: 'SIGNING_UP',
      };
    case 'SIGNUP_FAILED':
      return {
        ...initialStates.loggedInUser,
        status: 'SIGNUP_FAILED',
      };
    default:
      return state;
  }
}

export default loggedInUser;
