/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

async function share({ entityRefs, permissionNames, roleID, targetUserIds, targetTeamIds }) {
  const url = `/file/share`;

  const reqBody = {
    entityRefs,
    permissionNames,
    userIDs: targetUserIds,
    teamIDs: targetTeamIds,
  };

  if (roleID) {
    reqBody.roleID = roleID;
    delete reqBody.permissionNames;
  }

  const { data: entities } = await axios.post(url, reqBody);

  return entities;
}

async function shareEntityByType({ entityIds, collectionName, id, roleId, roleName, type }) {
  if (entityIds && entityIds.length > 0) {
    const url = `/api/share?entityId=${id}&entityClass=${collectionName}`;
    const reqBody = {
      roleId,
      roleName,
      type,
      entityIds,
    };
    return axios.post(url, reqBody);
  }
  return null;
}

async function shareEntity({ id, collectionName, roleName, roleId, targetUserIds, targetTeamIds }) {
  await shareEntityByType({ id, collectionName, roleId, roleName, entityIds: targetUserIds, type: 'User' });
  await shareEntityByType({ id, collectionName, roleId, roleName, entityIds: targetTeamIds, type: 'Team' });
}

async function getSharedByMe() {
  const url = `/file/shared-by-me/list`;

  const { data } = await axios.get(url);
  return data;
}

async function getSharedWithMe() {
  const url = `/file/shared-with-me/list`;

  const { data } = await axios.get(url);
  return data;
}

function unshare({ contentType, id, teams, users }) {
  const url = `/file/unshare/${id}?contentType=${encodeURIComponent(contentType)}`;

  const reqBody = {
    userIDs: users,
    teamIDs: teams,
  };

  return axios.post(url, reqBody);
}

async function unshareEntityByType({ entityIds, collectionName, id, type }) {
  if (entityIds && entityIds.length > 0) {
    const url = `/api/share/remove?entityId=${id}&entityClass=${collectionName}`;
    const reqBody = {
      type,
      entityIds,
    };
    return axios.post(url, reqBody);
  }
  return null;
}

async function unshareEntity({ id, collectionName, targetUserIds = [], targetTeamIds = [] }) {
  await unshareEntityByType({ id, collectionName, entityIds: targetUserIds, type: 'User' });
  await unshareEntityByType({ id, collectionName, entityIds: targetTeamIds, type: 'Team' });
}

export default {
  getSharedByMe,
  getSharedWithMe,
  share,
  shareEntity,
  unshare,
  unshareEntity,
};
