/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import io from 'socket.io-client';

const url = `${window.location.protocol}//${window.location.host}`;
let socket = null;

function getSocket() {
  if (!socket) {
    socket = io.connect(url);
  }
  return socket;
}

export { getSocket };
