/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Resizable } from 're-resizable';
import { Header, Wrapper } from './styled';

export function ResizablePanel(props) {
  const { children, isOpen, headerText, onResizeStart, onResizeStop, style = {} } = props;

  const resizableStyles = useMemo(() => {
    if (!isOpen) {
      return { display: 'none' };
    }

    return { paddingBottom: '4px' };
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen} style={style.Wrapper} id="scrollableDiv">
      <Resizable
        data-testid="resizable"
        enable={{ left: true }}
        defaultSize={style.defaultSize ? style.defaultSize : { width: '340px', height: 'auto' }}
        minWidth={style.minWidth ? style.minWidth : 340}
        style={resizableStyles}
        onResizeStop={onResizeStop}
        onResizeStart={onResizeStart}
      >
        {headerText && isOpen && <Header style={style.Header}>{headerText}</Header>}
        {isOpen && <div>{children}</div>}
      </Resizable>
    </Wrapper>
  );
}

ResizablePanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isOpen: PropTypes.bool,

  style: PropTypes.shape({
    Header: PropTypes.shape({}),
    Wrapper: PropTypes.shape({}),
    defaultSize: PropTypes.shape({
      width: PropTypes.string,
      height: PropTypes.string,
    }),
    minWidth: PropTypes.number,
  }),
  headerText: PropTypes.string,
  onResizeStart: PropTypes.func,
  onResizeStop: PropTypes.func,
};

export default ResizablePanel;
