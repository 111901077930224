/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import cloneDeep from 'lodash/cloneDeep';

/**
 * Returns an updated copy of the full data object.
 * @param {Object} data - Full data object passed as prop. Will not be mutated.
 * @returns {Object}
 */
const updateData = ({ data, pathKeys, value, values }) => {
  const newData = cloneDeep(data);
  let iterData = newData;

  pathKeys.forEach((key, index) => {
    const isLastKey = index === pathKeys.length - 1;

    if (isLastKey) {
      if (values) {
        iterData[key].values = values;
      } else {
        iterData[key].value = value;
      }
    } else {
      iterData = iterData[key];
    }
  });

  return newData;
};

export default updateData;
