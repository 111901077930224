/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useState, useEffect } from 'react';

import { Dropdown, DropdownMenuItemType } from '@fluentui/react/lib/Dropdown';
import DefaultModal from '../DefaultModal';

import { H3, ModalContentContainer, ModalContentRoot, ImportGrid } from './styles';

const ImportModal = props => {
  const { isOpen, onDismiss, onPrimaryClick, onFileChange, onProviderChange, onSourceTemplateChange, providerCdefs, providers } = props;

  const [cdefOptions, setCdefOptions] = useState([]);

  useEffect(() => {
    let options = [];
    for (const item of providerCdefs) {
      options.push({ key: item.cdef.definition.name, text: item.cdef.definition.displayName, itemType: DropdownMenuItemType.Header });
      if (item.derivedCdefs && item.derivedCdefs.length > 0) {
        for (const derivedItem of item.derivedCdefs) {
          options.push({ key: derivedItem.cdef.definition.name, text: derivedItem.cdef.definition.displayName });
        }
      }
    }

    setCdefOptions(options);
  }, [providerCdefs]);

  return (
    <DefaultModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      onPrimaryClick={onPrimaryClick}
      styles={{
        root: {
          minHeight: 300,
        },
      }}
    >
      <ModalContentContainer>
        <ModalContentRoot>
          <ImportGrid>
            <H3> Provider </H3>
            <Dropdown
              placeholder="Select a provider"
              options={providers}
              required={true}
              defaultSelectedKey=""
              onChange={onProviderChange}
            />

            <H3> Source Template </H3>
            <Dropdown
              placeholder="Select a source template"
              options={cdefOptions}
              defaultSelectedKey=""
              required={true}
              onChange={onSourceTemplateChange}
            />

            <H3> Source File </H3>
            <input type="file" onChange={onFileChange} />
          </ImportGrid>
        </ModalContentRoot>
      </ModalContentContainer>
    </DefaultModal>
  );
};

export default ImportModal;
