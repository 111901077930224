/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTopbar } from '../../../topbar/main';
import { EulaModal } from '../EulaModal';
import useOktaImplicitCallback from './hooks/useOktaImplicitCallback';
import LoadingScreen from '../LoadingScreen';
import { initAuthConfig } from '../../api/package/auth';
import { useAppFrame } from '../../context/useAppFrame';

const errorHtml = `
<div style="font-size: 14px;">
  <h3 style="color: #fa4716; margin: 4px 0px;">Sorry! Something went wrong on our end.</h3>
  <p style="margin: 0;">Please contact our <a href="mailto:support@altair.com?subject=Not able to login">customer support</a>.</p>
</div>
`;

function OktaImplicitCallback({ loginWithAccessToken, setAltairAdminBaseUrl }) {
  const { config, setConfig } = useAppFrame();
  const [configError, setConfigError] = useState();

  const init = useCallback(async () => {
    try {
      const nextConfig = await initAuthConfig();
      setConfig(nextConfig);
    } catch (e) {
      setConfigError(errorHtml);
      window.history.pushState('', document.title, '/');
    }
  }, [setConfig]);

  useEffect(() => {
    init();
  }, [init]);

  const { loginFailedMessage, onAcceptEula, redirectTo, isEulaModalOpen } = useOktaImplicitCallback({
    isConfigLoaded: !!config,
    loginWithAccessToken,
  });

  useEffect(() => {
    setAltairAdminBaseUrl(config?.config?.altairAdminBaseUrl);
  }, [config, setAltairAdminBaseUrl]);

  if (config && redirectTo) {
    window.location.href = redirectTo;
  }

  return (
    <>
      <LoadingScreen isLoading={!(loginFailedMessage || configError)} message={loginFailedMessage ?? configError} />
      <EulaModal isOpen={isEulaModalOpen} onAccept={onAcceptEula} />
    </>
  );
}

OktaImplicitCallback.propTypes = {
  loginWithAccessToken: PropTypes.func,
  setAltairAdminBaseUrl: PropTypes.func,
};

export default withTopbar(OktaImplicitCallback);
