/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { Footer as FooterBase } from '../../../gooey/components/Footer';
import { useAppFrame } from '../../context/useAppFrame';

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1, transition: 'opacity 250ms ease-in' },
  exited: { opacity: 1 },
};

export function Footer({ styles }) {
  const { appVersion, footerHidden } = useAppFrame();

  return (
    <Transition in={!footerHidden} timeout={50} unmountOnExit>
      {state => <FooterBase styles={{ root: { ...transitionStyles[state], ...styles } }} appVersion={appVersion} />}
    </Transition>
  );
}

Footer.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};
