/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
const NotificationType = {
  INFO: 0,
  ERROR: 1,
  SUCCESS: 2,
  WARNING: 3,
};

/**
 * Enum for notification color based on notification type.
 * @readonly
 * @enum {number}
 */
const NotificationColor = {
  0: '#2DCCD3',
  1: '#FA4616',
  2: '#47A23F',
  3: '#FFC600',
};

export { NotificationColor, NotificationType };
