/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import platformPropTypes from '@altair/prop-types';
import parsePath from 'parse-filepath';
import PropTypes from 'prop-types';
import React from 'react';
import extensions from './extensions';

export function ExtensionIcon(props) {
  const { entity, width } = props;
  const { name } = entity.attributes || '';
  const { collectionName } = entity.system;
  const style = { height: `${width}px`, width: `${width}px` };

  const ext = parsePath(name)
    .ext.replace(/^\./, '')
    .toLowerCase();

  if (collectionName === 'Directory') {
    return <span data-testid="ExtensionIcon-Directory" className="fiv-viv fiv-icon-folder" style={style} />;
  }

  if (collectionName === 'FileEntity' && extensions.indexOf(ext) >= 0) {
    return <span data-testid={`ExtensionIcon-${name}`} className={`fiv-viv fiv-icon-${ext}`} style={style} />;
  }

  return <span data-testid="ExtensionIcon-Blank" className="fiv-viv fiv-icon-blank" style={style} />;
}

ExtensionIcon.propTypes = {
  entity: platformPropTypes.entity.isRequired,
  width: PropTypes.number.isRequired,
};

export default ExtensionIcon;
