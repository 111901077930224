/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
let store = new Map();

export function clearStore() {
  store = new Map();
}

export function getAllWatchingIds() {
  const ids = new Set();

  store.forEach(eventIds => {
    eventIds.forEach(id => ids.add(id));
  });

  return ids;
}

export function removeWatchingIds(socket, callback) {
  store.delete(callback);
  socket.emit('watchingEntities', [...getAllWatchingIds()]);
}

export function updateWatchingIds(socket, callback, ids) {
  store.set(callback, ids);
  socket.emit('watchingEntities', [...getAllWatchingIds()]);
}
