/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useMemo, useState } from 'react';
import { mergeStyles } from '@fluentui/style-utilities';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import getDataItem from '../utils/getDataItem';
import updateData from '../utils/updateData';
import Data from './Data';

function Table(props) {
  const { data: propsData, invalidDataItems, onBlur, onChange, styles, typeRenderers, handleLink } = props;
  const [collapsedPathKeys, setCollapsedPathKeys] = useState([]);
  const [data, setData] = useState(propsData);

  useEffect(() => {
    setData(propsData);
  }, [propsData]);

  const tableRootClassname = useMemo(() => {
    return mergeStyles([
      {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        fontSize: '14px',
        fontWeight: 200,
      },
      {
        ...styles.tableRoot,
      },
    ]);
  }, [styles]);

  function handleBlur({ pathKeys }) {
    const oldDataItem = getDataItem(propsData, pathKeys);
    const newDataItem = getDataItem(data, pathKeys);
    const newData = data;

    onBlur({ newDataItem, oldDataItem, pathKeys, newData });
  }

  function handleChange({ value, values, pathKeys, oldDataItem }) {
    const newData = updateData({ data, pathKeys, value, values });
    const newDataItem = getDataItem(newData, pathKeys);

    setData(newData);
    onChange({ newData, newDataItem, oldDataItem, pathKeys });
  }

  function toggleGroup(pathKeys) {
    const index = collapsedPathKeys.findIndex(keys => isEqual(keys, pathKeys));
    const isCollapsed = index > -1;
    const newCollapsedPathKeys = [...collapsedPathKeys];

    if (isCollapsed) {
      newCollapsedPathKeys.splice(index, 1);
    } else {
      newCollapsedPathKeys.push(pathKeys);
    }

    setCollapsedPathKeys(newCollapsedPathKeys);
  }

  return (
    <div className={tableRootClassname}>
      <Data
        collapsedPathKeys={collapsedPathKeys}
        data={data}
        invalidDataItems={invalidDataItems}
        onBlur={handleBlur}
        onChange={handleChange}
        onToggle={toggleGroup}
        styles={styles}
        typeRenderers={typeRenderers}
        handleLink={handleLink}
      />
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.object.isRequired,
  invalidDataItems: PropTypes.instanceOf(Map),
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  styles: PropTypes.shape({
    cellLabel: PropTypes.object,
    cellRoot: PropTypes.object,
    tableRoot: PropTypes.object,
  }),
  typeRenderers: PropTypes.objectOf(PropTypes.func),
  handleLink: PropTypes.func,
};

Table.defaultProps = {
  invalidDataItems: new Map(),
  onChange: () => {},
  styles: {},
  typeRenderers: {},
  handleLink: () => {},
};

export default Table;
