/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { classNamesFunction } from '@fluentui/utilities';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import ComboBox from '../../../gooey/components/ComboBox';
import {
  Link as FLink,
  TextField,
  PrimaryButton,
  Text,
  Spinner,
  SpinnerSize,
  IconButton,
  TooltipHost,
  DirectionalHint,
} from '@fluentui/react';

import { loginHelp, initAuthConfig } from '../../api/package/auth';
import { LoginHelpProps, LoginHelpDefaultProps } from './LoginHelp.types';
import Brandmark from '../../assets/AltairBrandmark.png';
import { validateEmail } from '../../utils/validateEmail';
import { useCaptcha } from './useCaptcha';
import LoadingScreen from '../LoadingScreen';

const getClassNames = classNamesFunction();

const errorHtml = `
<div style="font-size: 14px;">
  <h3 style="color: #fa4716; margin: 4px 0px;">Sorry! Something went wrong on our end.</h3>
  <p style="margin: 0;">Please refresh the page to try again or contact our <a href="mailto:support@altair.com?subject=Not able to login">customer support</a> if the problem persists.</p>
</div>
`;

const helpOptions = [
  { key: 'NS', text: '< Please make a selection >' },
  { key: 'FP', text: 'I forgot my password', data: { category: 'forgotPassword' } },
  { key: 'AM', text: 'I did not receive activation mail', data: { category: 'reactivate' } },
  { key: 'AME', text: 'My activation mail expired', data: { category: 'reactivate' } },
  { key: 'U', text: 'My account is locked', data: { category: 'unlock' } },
  { key: 'MFA', text: 'I want to reset multi-factor authentication', data: { category: 'resetMFA' } },
  { key: 'IDK', text: `I don't know but can’t login`, data: { category: 'notSure' } },
];

export function LoginHelpBase(props) {
  const { styles, theme, userId } = props;

  const [selectedIssueKey, setSelectedIssueKey] = useState('NS');
  const [helpCategory, setHelpCategory] = useState();
  const [email, setEmail] = useState();
  const [helpInProgress, setHelpInProgress] = useState(false);
  const [captchaChallenge, setCaptchaChallenge] = useState();

  const { image, regenerate, verify } = useCaptcha();

  const [message, setMessage] = useState();

  const emailRef = useRef();

  const [config, setConfig] = useState();
  const [configError, setConfigError] = useState();

  const init = useCallback(async () => {
    try {
      const authConfig = await initAuthConfig();
      setConfig(authConfig.config);
    } catch (e) {
      setConfigError(errorHtml);
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  async function doHelp(email, category) {
    if (helpInProgress) {
      return;
    }

    if (!validateEmail(email)) {
      setMessage({ message: 'Please enter a valid E-mail id.', success: false });
      return;
    }

    if (!verify(captchaChallenge)) {
      setMessage({ message: 'Please enter the correct captch.', success: false });
      return;
    }

    setHelpInProgress(true);
    try {
      const data = await loginHelp({ category, email });
      if (data.success) {
        setMessage(data);
        setMessage({ ...data.data, success: data.success });
      } else {
        setMessage({ ...data.error, success: data.success });
      }
    } catch (error) {
      setMessage({ message: error?.message });

      if (emailRef.current) {
        emailRef.current.focus();
      }
    } finally {
      setHelpInProgress(false);
      regenerate();
      setCaptchaChallenge();
    }
  }

  function onEmailChange(e, nextEmail) {
    setEmail(nextEmail);
    setMessage();
  }

  function onHelpCategoryChange(e, option) {
    setSelectedIssueKey(option.key);
    setHelpCategory(option.data?.category);
    setMessage();
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }

  if (!config) {
    return <LoadingScreen isLoading={!configError} message={configError} />;
  }

  const classNames = getClassNames(styles, { hasError: message && !message.success, theme });

  return (
    <div className={classNames.root}>
      <div className={classNames.brandmark}>
        <img src={Brandmark} alt="Altair" />
      </div>
      <div className={classNames.header}>
        {!userId && (
          <>
            <PrimaryButton iconProps={{ iconName: 'LeftArrow' }} text="Login" href="/login" />
            <PrimaryButton text="Sign Up" href={config?.registerUri} />
          </>
        )}
        {userId && <PrimaryButton iconProps={{ iconName: 'LeftArrow' }} text="Altair One" href="/" />}
      </div>
      <div className={classNames.container}>
        <div className={classNames.main}>
          <h1 className={classNames.title}>Account and Login Help</h1>
          <div className={classNames.innerContent}>
            <TextField
              autoFocus
              className={classNames.email}
              disabled={helpInProgress}
              label="E-mail"
              name="e-mail"
              onChange={onEmailChange}
              placeholder="E-mail address"
              componentRef={emailRef}
              required
              type="email"
              value={email || ''}
            />
            <div className={classNames.helpOptions}>
              <ComboBox
                disabled={helpInProgress}
                label="Select what you need help with"
                onChange={onHelpCategoryChange}
                options={helpOptions}
                selectedKey={selectedIssueKey}
              />
            </div>
            <div className={classNames.captcha}>
              <div className={classNames.captchaContainer}>
                <img src={image} alt="Captch" />
                <TooltipHost content="Refresh Captch" directionalHint={DirectionalHint.bottomCenter}>
                  <IconButton
                    disabled={helpInProgress}
                    iconProps={{ iconName: 'Refresh', styles: { root: { fontSize: 20 } } }}
                    onClick={() => {
                      regenerate();
                      setCaptchaChallenge();
                    }}
                    styles={{ rootDisabled: { background: 'transparent' } }}
                  />
                </TooltipHost>
              </div>
              <TextField
                autoComplete="off"
                required
                disabled={helpInProgress}
                label="Captcha"
                name="captcha"
                onChange={(e, value) => setCaptchaChallenge(value)}
                placeholder="Please enter the text shown above"
                value={captchaChallenge || ''}
              />
            </div>
          </div>
          <div className={classNames.footer}>
            <PrimaryButton
              disabled={!(email && selectedIssueKey !== 'NS' && captchaChallenge)}
              className={classNames.continueButton}
              onClick={() => doHelp(email, helpCategory)}
              styles={{ root: [helpInProgress && { cursor: 'progress' }] }}
            >
              Continue
              {helpInProgress && <Spinner className={classNames.helpProgress} size={SpinnerSize.xSmall} />}
            </PrimaryButton>
            {message?.message && (
              <div className={classNames.message}>
                <div dangerouslySetInnerHTML={{ __html: message?.message }} />
              </div>
            )}
          </div>
        </div>
        <div className={classNames.divider} />
        <div className={classNames.info}>
          <div className={classNames.infoTop}>
            <h1>Other Tips</h1>
            <ol>
              <li>
                <Text variant="small">Clear your browser cookies and local storage, if you continue experiencing problems.</Text>
              </li>
              <li>
                <Text variant="small">Please check for no-reply@altairone.com mails in your junk or spam folder.</Text>
              </li>
              <li>
                <Text variant="small">Please verify that the links in the activation e-mails are not expired.</Text>
              </li>
              <li>
                <Text variant="small">
                  For better experience, please ensure that you are using a supported browser. Please refer to the list below for
                  comparison.
                </Text>
                <table className={classNames.table}>
                  <thead>
                    <tr>
                      <th>Browser</th>
                      <th>Supported Version (or higher)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Chrome</td>
                      <td>84.0.4147.0</td>
                    </tr>
                    <tr>
                      <td>Firefox</td>
                      <td>78.0.2</td>
                    </tr>
                    <tr>
                      <td>Edge</td>
                      <td>84.0.4147.0</td>
                    </tr>
                    <tr>
                      <td>Safari</td>
                      <td>13.1.1 (14609.2.9.1.3)</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ol>
          </div>
          <div className={classNames.infoBottom}>
            <h1>Not sure how to login?</h1>
            <Text variant="small">
              <FLink href="mailto:support@altair.com?subject=Not able to login">Contact us</FLink> with a brief description of the problem,
              your registered e-mail id and company details. Our support team will gladly reach out to you for further assistance.
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}

LoginHelpBase.propTypes = LoginHelpProps;

LoginHelpBase.defaultProps = LoginHelpDefaultProps;
