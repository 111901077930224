/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import initialStates from '../store/initialStates';

function panelId(state = {}, action) {
  switch (action.type) {
    case 'CLEAR_PANEL':
      return initialStates.panelId;
    case 'REPLACE_PANEL':
      return action.panelId;
    default:
      return state;
  }
}

export default panelId;
