/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const LayoutSelectorContainer = styled.div`
  margin-right: 6px;
  margin-left: 6px;
  height: 30px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  &:hover {
    cursor: pointer;
  }
`;

export { LayoutSelectorContainer };
