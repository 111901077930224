/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { styled } from '@fluentui/utilities';
import { WistiaPlayerBase } from './WistiaPlayer.base';
import { getStyles } from './WistiaPlayer.styles';

export const WistiaPlayer = styled(WistiaPlayerBase, getStyles, undefined, {
  scope: 'WistiaPlayer',
});
