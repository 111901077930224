/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { createColumn } from './createColumn';

export function createColumns({ columns, getEntityIcon, onColumnClick, sortingEntityPath, sortingIsAscending }) {
  let output = columns.map(column => {
    return createColumn({ column, onColumnClick, sortingEntityPath, sortingIsAscending });
  });

  if (getEntityIcon) {
    const iconColumn = {
      key: 'icon',
      fieldName: 'icon',
      iconName: 'Page',
      minWidth: 16,
      maxWidth: 16,
      isResizable: false,
      ariaLabel: 'Icon',
      onRender: getEntityIcon,
    };

    output = [iconColumn, ...output];
  }

  return output;
}
