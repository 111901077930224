/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, PrimaryButton, DialogFooter, DefaultButton } from '@fluentui/react';

function AutoLoggedOutDialog({ hidden = true }) {
  function reload() {
    window.location.reload();
  }

  function gotoDefaultPage() {
    window.location.href = '/';
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={gotoDefaultPage}
      dialogContentProps={{
        title: 'Logout',
        styles: {
          inner: { padding: '8px 32px 20px' },
          innerContent: { maxWidth: 316, fontSize: 14 },
          title: { padding: '12px 24px', fontSize: 16 },
        },
      }}
      modalProps={{ isModeless: true }}
    >
      You have been logged out. Please login to continue.
      <DialogFooter>
        <PrimaryButton text="Login" onClick={reload} />
        <DefaultButton text="Close" onClick={gotoDefaultPage} />
      </DialogFooter>
    </Dialog>
  );
}

AutoLoggedOutDialog.propTypes = {
  hidden: PropTypes.bool,
};

export default AutoLoggedOutDialog;
