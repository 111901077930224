/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';

import { buildSwatchStyle, getColorFormat, parseColor } from './utils';
import { Popover, SwatchInner, SwatchOuter } from './styled';

function ColorPicker(props) {
  const { disabled, onBlur, onChange, value } = props;
  const popoverRef = useRef(null);
  const swatchRef = useRef(null);
  const [color, setColor] = useState(value);
  const [format, setFormat] = useState(getColorFormat(value));
  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const handleChange = useCallback(
    newValue => {
      let newColor;

      if (format === 'array') {
        const { rgb } = newValue;

        newColor = [rgb.r, rgb.g, rgb.b, rgb.a];
      }

      if (format === 'hex') {
        newColor = newValue.hex;
      }

      if (format === 'rgb') {
        newColor = newValue.rgb;
      }

      onChange(newColor);
      setColor(newColor);
    },
    [format, onChange]
  );

  const handleClose = useCallback(() => {
    setIsPickerVisible(false);
    onBlur(color);
  }, [color, onBlur]);

  /*
    Handles picker closing when user clicks "outside" of the picker.
  */
  const handleClick = useCallback(
    e => {
      if (isPickerVisible) {
        const pickerElem = popoverRef.current;
        const swatchElem = swatchRef.current;
        const wasPickerClicked = pickerElem.contains(e.target);
        const wasSwatchClicked = swatchElem.contains(e.target);

        if (!wasPickerClicked && !wasSwatchClicked) {
          handleClose();
        }
      }
    },
    [handleClose, isPickerVisible]
  );

  const handleSwatchClick = useCallback(() => {
    if (disabled) return;

    if (isPickerVisible) {
      handleClose();
    } else {
      setIsPickerVisible(true);
    }
  }, [disabled, handleClose, isPickerVisible]);

  useEffect(() => {
    if (!isPickerVisible) return () => {};

    document.addEventListener('mousedown', handleClick, false);

    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [color, handleClick, isPickerVisible]);

  useEffect(() => {
    const newColor = value;
    const newFormat = getColorFormat(newColor);

    setColor(newColor);
    setFormat(newFormat);
  }, [value]);

  const parsedColor = parseColor(color, format);
  const swatchStyle = buildSwatchStyle(color, format);

  return (
    <div>
      <SwatchOuter onClick={handleSwatchClick} onKeyPress={handleSwatchClick} ref={swatchRef} role="button" tabIndex="0">
        <SwatchInner style={swatchStyle} />
      </SwatchOuter>

      <Popover
        ref={popoverRef}
        style={{
          display: isPickerVisible ? 'block' : 'none',
        }}
      >
        <SketchPicker color={parsedColor} onChange={handleChange} />
      </Popover>
    </div>
  );
}

ColorPicker.propTypes = {
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.shape({
      r: PropTypes.number.isRequired,
      g: PropTypes.number.isRequired,
      b: PropTypes.number.isRequired,
      a: PropTypes.number,
    }),
    PropTypes.string,
  ]),
};

ColorPicker.defaultProps = {
  disabled: false,
  onBlur: () => {},
  onChange: () => {},
  value: {
    r: 0,
    g: 0,
    b: 0,
    a: 0,
  },
};

export default ColorPicker;
