/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;

Popover.displayName = 'Popover';

const SwatchInner = styled.div`
  border-radius: 2px;
  height: 14px;
  width: 36px;
`;

SwatchInner.displayName = 'SwatchInner';

const SwatchOuter = styled.div`
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  display: inline-block;
  padding: 2px;
`;

SwatchOuter.displayName = 'SwatchOuter';

export { Popover, SwatchInner, SwatchOuter };
