/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: 0.3rem;
`;

const Header = styled.div`
  display: flex;
  font-size: 1.4rem;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export { Content, Header };
