/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import get from 'lodash/get';

export function sortEntities({ entities, sortingEntityPath, sortingIsAscending }) {
  if (!sortingEntityPath) {
    return entities;
  }

  const sortedEntities = [...entities].sort((a, b) => {
    let aVal = get(a, sortingEntityPath);
    let bVal = get(b, sortingEntityPath);

    if (typeof aVal === 'string') aVal = aVal.toLowerCase();
    if (typeof bVal === 'string') bVal = bVal.toLowerCase();

    if (sortingIsAscending) {
      if (aVal === undefined && bVal !== undefined) return -1;
      if (aVal !== undefined && bVal === undefined) return 1;
      if (aVal < bVal) return -1;
      if (aVal > bVal) return 1;
      return 0;
    }

    if (aVal === undefined && bVal !== undefined) return 1;
    if (aVal !== undefined && bVal === undefined) return -1;
    if (aVal < bVal) return 1;
    if (aVal > bVal) return -1;
    return 0;
  });

  return sortedEntities;
}
