/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';
import { NotificationColor } from '../../utils/constants';

const Root = styled.div`
  background: #ffffff;
  min-height: 60px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  display: flex;
  word-break: break-word;
  border-left: 4px solid ${({ type }) => NotificationColor[type]};
  > .iconContainer {
    font-size: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    flex-shrink: 0;
    margin: 14px;
    margin-right: 0px;
  }

  > .text {
    display: flex;
    flex-grow: 1;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
    margin-right: 16px;
    line-height: 20px;
    font-size: 12px;
    color: #1d2e38;
    min-width: 0px;
    > .innerText {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* white-space: pre-wrap;
      text-overflow: ellipsis;
      overflow: visible; */
    }
  }

  > .dismissal {
    flex-shrink: 0;
    margin: 8px;
    margin-left: 0px;
    height: 24px;
  }
`;

export { Root };
