/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Wrapper = styled.div`
  background: white;
  bottom: 0;
  right: 0;
  top: 62px;
  ${({ isOpen }) =>
    isOpen
      ? `box-shadow: 4px 8px 8px 4px rgba(0,0,0,0.25);`
      : `
transform: translateX(100%);
visibility: hidden; `};
  box-sizing: border-box;
  display: table-column;
  position: fixed;
  transition: all 0.5s ease-out;
  z-index: 12;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Header = styled.div`
  font-family: Noto Sans;
  font-size: 21px;
  font-weight: 500;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 16px !important;
  padding-right: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 18px !important;
`;

export { Wrapper, Header };
