import React from 'react';
import * as TextMapping from '../../../utils/textMapping';
import ContactUsDialog from '../../../gooey/api-components/dialogs/ContactUsDialog';

const NOOP = () => {};

function NoDriveLicenseDialog({ api, hidden, onDismiss = NOOP, texts }) {
  return (
    <ContactUsDialog
      api={api}
      appName="Material Data Center"
      hidden={hidden}
      onDismiss={onDismiss}
      title={
        <>
          <div style={{ textAlign: 'center' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_NO_DRIVE_LICENSE, texts)}</div>
        </>
      }
      placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_NO_DRIVE_LICENSE_PLACEHOLDER, texts)}
    />
  );
}

export default NoDriveLicenseDialog;
