/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';

import { Dropdown } from '@fluentui/react/lib/Dropdown';
import DefaultModal from '../DefaultModal';

import { H3, ModalContentContainer, ModalContentRoot, ImportGrid } from './styles';

const ExportModal = (props) => {
  const { isOpen, onDismiss, onPrimaryClick, onUnitSystemChange, onFlagChange, unitSystems } = props;

  return (
    <DefaultModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      onPrimaryClick={onPrimaryClick}
      styles={{
        root: {
          minHeight: 300,
        },
      }}
    >
      <ModalContentContainer>
        <ModalContentRoot>
          <ImportGrid>
            <H3> Unit System </H3>
            <Dropdown placeholder="Unit System" options={unitSystems} required={true} onChange={onUnitSystemChange} />

            <H3> Type </H3>
            <Dropdown
              placeholder="Flag"
              options={[
                { key: 'E-N', text: 'E-N' },
                { key: 'S-N', text: 'S-N' },
                { key: 'both', text: 'Both' },
              ]}
              required={true}
              onChange={onFlagChange}
            />
          </ImportGrid>
        </ModalContentRoot>
      </ModalContentContainer>
    </DefaultModal>
  );
};

export default ExportModal;
