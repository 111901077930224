/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import orderBy from 'lodash/orderBy';

const ADD_NOTIFICATION_ENTITIES = (state, action) => {
  let { entities } = action;

  entities = orderBy(entities, ['attributes.time'], ['desc']);

  return {
    ...state,
    entities: [...entities, ...state.entities],
  };
};

const ADD_NOTIFICATION_ENTITY = (state, action) => {
  return {
    ...state,
    entities: [action.entity, ...state.entities],
  };
};

const UPDATE_NOTIFICATION_ENTITY = (state, action) => {
  const newEntity = action.entity;

  const newEntities = state.entities.map(entity => {
    if (entity.id === newEntity.id) {
      return newEntity;
    }

    return entity;
  });

  return {
    ...state,
    entities: newEntities,
  };
};

function notification(state = {}, action) {
  switch (action.type) {
    case 'ADD_NOTIFICATION_ENTITIES':
      return ADD_NOTIFICATION_ENTITIES(state, action);
    case 'ADD_NOTIFICATION_ENTITY':
      return ADD_NOTIFICATION_ENTITY(state, action);
    case 'UPDATE_NOTIFICATION_ENTITY':
      return UPDATE_NOTIFICATION_ENTITY(state, action);
    default:
      return state;
  }
}

export default notification;
