/**
 * Matomo Tracking - Matomo Tag Manager
 *
 * dataLayer structure
 * --------------------
 *
 * event: AMDCEvent
 * eventCategory: <activity type> (general, search, searchResult, compare, navigation, feedback, userPreference)
 * eventAction: <activity name> (generalSearch, openDocument, ...)
 * eventLabel: <name> (productName, csName, ...)
 * eventValue: <number>*
 * eventData:
 */

import { merge } from 'lodash';

const defaultOptions = {
  debug: false,
  id: '',
  url: '',
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: false,
  enableHeartBeatTimer: 30,
  userid: '',
  tracker: '',
};

let options = {};
let tagmanagerhasdata = false;

function tagManagerInited() {
  if (!tagmanagerhasdata) {
    tagmanagerhasdata =
      window.MatomoTagManager &&
      window.MatomoTagManager.containers &&
      window.MatomoTagManager.containers.length &&
      window.MatomoTagManager.containers[0].idsite;
    if (tagmanagerhasdata) {
      options.debug && console.info('[matomo] SiteId: ' + window.MatomoTagManager.containers[0].idsite);
      window._paq.push(['setSiteId', window.MatomoTagManager.containers[0].idsite]);
    }
  }
  return tagmanagerhasdata;
}

/**
 * Delete Custom Dimensions for Action (1-10)
 */
function resetDimensions() {
  for (let customDimensionId = 1; customDimensionId <= 10; customDimensionId++) {
    window._paq.push(['deleteCustomDimension', customDimensionId]);
    //window._paq.push(['setCustomDimension', customDimensionId,' ']);
  }
}

function trackPageView(page) {
  if (tagManagerInited()) {
    window._paq.push(['setCustomUrl', document.documentURI]);
    let title = document.title;
    options.debug && console.info('[matomo] Tracking ' + document.documentURI);
    resetDimensions();
    window._paq.push(['trackPageView', e(title), {}]);
  }
}

function decodeURIComponentSafe(uri, mod) {
  let out = '';
  let arr,
    i = 0,
    l,
    x;

  if (typeof mod === 'undefined') {
    mod = 0;
  }
  arr = uri.split(/(%(?:d0|d1)%.{2})/);
  for (l = arr.length; i < l; i++) {
    try {
      x = decodeURIComponent(arr[i]);
    } catch (e) {
      x = mod ? arr[i].replace(/%(?!\d+)/g, '%25') : arr[i];
    }
    out += x;
  }
  return out;
}

function e(v) {
  return encodeURIComponent(decodeURIComponentSafe(v + '')).replace(/%20/gi, ' ');
}

function siteSearch(payload) {
  if (payload.keywords.length) {
    options.debug && console.info('[matomo] siteSearch', payload);
    /*
			if (payload.gtime && payload.gtime >= 0) {
					window._paq.push(['setGenerationTimeMs', payload.gtime]);
			}*/
    window._paq.push([
      'trackSiteSearch',
      // Search keyword searched for
      payload.keywords.join(', '),
      // Search category selected in your search engine. If you do not need this, set to false
      payload.category || false,
      // Number of results on the Search results page. Zero indicates a 'No Result Search Keyword'. Set to false if you don't know
      payload.num,
    ]);
    // We recommend not to call trackPageView() on the Site Search Result page
  }
}

function event(payload, name) {
  name = name || 'AMDCEvent';
  if (tagManagerInited()) {
    let data = {};
    merge(
      data,
      {
        event: name,
        eventData: {
          page: options.router ? options.router.currentRoute.name : '',
        },
      },
      payload
    );

    /*
			if (payload.gtime && payload.gtime >= 0) {
					window._paq.push(['setGenerationTimeMs', parseInt(payload.gtime)]);
			}*/

    /*
			data.eventLabel = e(data.eventLabel);
			if (typeof data.eventData.producer !== 'undefined')
					data.eventData.producer = e(data.eventData.producer);
			if (typeof data.eventData.brand !== 'undefined')
					data.eventData.brand = e(data.eventData.brand);
			if (typeof data.eventData.keywords !== 'undefined')
					data.eventData.keywords = e(data.eventData.keywords);
			if (typeof data.eventData.name !== 'undefined')
					data.eventData.name = e(data.eventData.name);
			if (typeof data.eventData.category !== 'undefined')
					data.eventData.category = e(data.eventData.category);
			if (typeof data.eventData.subcategory !== 'undefined')
					data.eventData.subcategory = e(data.eventData.subcategory);
			if (typeof data.eventData.filename !== 'undefined')
					data.eventData.filename = e(data.eventData.filename);
			 */

    data.eventLabel = e(data.eventLabel);

    for (const dim in data.eventData) {
      data.eventData[dim] = e(data.eventData[dim]);
    }

    resetDimensions();
    window._mtm.push(data);
    options.debug && console.info('[matomo] ' + data.eventCategory + ':' + data.eventAction, data, payload);
  }

  return true;
}

function viewDocument(payload) {
  /*
  let id = payload.id || 0;
  id = isNaN(id) ? v.$hashids.decode(payload.id)[0] : id;
  let oid = payload.oid ? v.$hashids.decode(payload.oid)[0] : 0;
  let data = {
    eventCategory: 'document',
    eventAction: payload.action || 'view',
    eventLabel: payload.name,
    eventValue: id,
    eventData: {
      id: oid,
      name: payload.oname,
      category: payload.category,
      subcategory: payload.subcategory,
      filename: payload.filename,
      keywords: payload.keywords || '',
    },
    gtime: typeof payload.gtime !== 'undefined' ? parseInt(payload.gtime) : -1,
  };
  if (payload.producer) {
    data.eventData.producer = payload.producer;
  }
  if (payload.brand) {
    data.eventData.brand = payload.brand;
  }
  return event('AMDCEvent', data);
  */
}

function datasheetView(payload) {
  let data = {
    eventCategory: 'datasheet',
    eventAction: 'html',
    eventLabel: payload.name,
    eventData: {
      page: 'default',
      name: payload.name,
      producer: payload.producer,
      language: payload.language,
      unitsystem: payload.unitsystem,
      application: payload.application,
      provider: payload.provider,
      materialtype: payload.materialtype,
    },
    gtime: typeof payload.gtime !== 'undefined' ? parseInt(payload.gtime) : -1,
  };

  return event(data, 'AMDCEvent');
}

function datasheetPDF(payload) {
  let data = {
    eventCategory: 'datasheet',
    eventAction: 'pdf',
    eventLabel: payload.name,
    eventData: {
      page: 'default',
      name: payload.name,
      producer: payload.producer,
      language: payload.language,
      unitsystem: payload.unitsystem,
      application: payload.application,
      provider: payload.provider,
      materialtype: payload.materialtype,
    },
    gtime: typeof payload.gtime !== 'undefined' ? parseInt(payload.gtime) : -1,
  };

  return event(data, 'AMDCEvent');
}

function caedownload(payload) {
  let data = {
    eventCategory: 'cae',
    eventAction: payload.dest === 'drive' ? 'save' : 'download',
    eventLabel: payload.name,
    eventData: {
      page: payload.list,
      name: payload.name,
      producer: payload.producer,
      language: payload.language,
      unitsystem: payload.unitsystem,
      application: payload.application,
      provider: payload.provider,
      materialtype: payload.materialtype,
    },
    gtime: typeof payload.gtime !== 'undefined' ? parseInt(payload.gtime) : -1,
  };

  return event(data, 'AMDCEvent');
}

function visit(payload) {
  let data = {
    eventCategory: 'visit',
    eventAction: 'load',
    eventLabel: 'Visit',
    eventData: {
      license: payload.license,
      user: payload.user,
    },
    gtime: typeof payload.gtime !== 'undefined' ? parseInt(payload.gtime) : -1,
  };

  if (!tagManagerInited()) {
    setTimeout(() => visit(payload), 3000);
  }
  return event(data, 'AMDCEvent');
}

function search(payload) {
  let data = {
    eventCategory: 'search',
    eventAction: payload.type,
    eventLabel: payload.text,
    eventValue: typeof payload.num !== 'undefined' ? payload.num : -1,
    eventData: {
      keywords: payload.group || '',
      category: payload.category || '',
    },
    gtime: typeof payload.gtime !== 'undefined' ? parseInt(payload.gtime) : -1,
  };
  if (payload.keywords) {
    data.eventData.keywords = payload.keywords;
  }
  return event('AMDCEvent', data);
}

function refresh() {
  if (options.enableLinkTracking) {
    options.debug && console.info('[matomo] Refresh Link Tracking');
    window._paq.push(['enableLinkTracking']);
  }
}

class Matomo {
  constructor(setupOptions) {
    options = Object.assign({}, defaultOptions, setupOptions);
    const { url, id, tracker } = options;
    if (id && id.length) {
      let d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.defer = true;
      g.src = url + '/js/container_' + id + '.js?' + Date.now();
      s.parentNode.insertBefore(g, s);

      window._paq = window._paq || [];
      window._mtm = window._mtm || [];

      if (tracker && tracker.length) {
        window._paq.push(['setTrackerUrl', url + '/' + tracker]);
      }

      window._mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });

      if (options.debug) {
        window._mtm.push(['enableDebugMode']);
      }

      /*window._paq.push(['setCustomDimension', 11, options.country || 'Unknown' ]);
        window._paq.push(['setCustomDimension', 12, options.userid || 'Unknown' ]);
        window._paq.push(['setCustomDimension', 13, options.usercontext || 'Unknown' ]);
        window._paq.push(['setCustomDimension', 14, options.username || 'Unknown' ]);
        window._paq.push(['setCustomDimension', 15, options.defsc || 'Unknown' ]);
        window._paq.push(['setCustomDimension', 16, options.bcontext || 'Unknown' ]);*/
      //window._paq.push(['setUserId', options.userid]);

      window.matomoTagManagerAsyncInit = function() {
        options.debug && console.info('[matomo] Init TagManager');
        if (options.trackInitialView) {
          // Register first page view
          trackPageView();
        }
      };
      if (options.requireConsent) {
        window._paq.push(['requireConsent']);
      }
      if (options.enableLinkTracking) {
        window._paq.push(['enableLinkTracking']);
      }
      if (options.enableHeartBeatTimer > 0) {
        window._paq.push(['enableHeartBeatTimer', options.enableHeartBeatTimer]);
      }
      options.debug && console.info('[matomo] Init Tracking');
    }
  }

  event(payload) {
    return event(payload);
  }
  search(payload) {
    return search(payload);
  }
  viewDocument(payload) {
    return viewDocument(payload);
  }
  siteSearch(payload) {
    return siteSearch(payload);
  }
  refresh() {
    return refresh();
  }
  datasheetView(payload) {
    return datasheetView(payload);
  }
  caedownload(payload) {
    return caedownload(payload);
  }
  datasheetPDF(payload) {
    return datasheetPDF(payload);
  }
  trackPageView(page) {
    return trackPageView(page);
  }
  visit(payload) {
    return visit(payload);
  }
}

export { Matomo };
