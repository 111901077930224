/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const DismissRoot = styled.div`
  position: absolute;
  right: 0px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin: 4px 8px 0 0;
  display: none;
`;

const Root = styled.div`
  position: relative;
  display: block;
  min-height: 64px;
  margin: 0 16px 10px 0px;
  &:hover ${DismissRoot} {
    display: block;
  }
  font-family: 'Noto Sans', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
`;

const Container = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  padding: 14px 14px 14px 0;
`;

const DocumentationContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  padding: 14px;
  margin-top: 100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  padding: 14px;
  justify-content: center;
`;

const Divider = styled.div`
   {
    width: 15px;
    height: auto;
    display: inline-block;
  }
`;

const Avatar = styled.div`
  width: 52px;
  flex: 0 0 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullName = styled.div`
  font-size: 14px;
  color: #1d2e38;
  text-align: left;
  max-width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Email = styled.div`
  font-size: 12px;
  color: #9b9b9b;
  text-align: left;
`;

const License = styled.div`
  font-size: 12px;
  color: #9b9b9b;
  text-align: left;
`;

const Company = styled.div`
  font-size: 12px;
  color: #9b9b9b;
  text-align: left;
`;

export { Avatar, ButtonContainer, Container, Divider, DocumentationContainer, Email, License, FullName, Root, Company };
