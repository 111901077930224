/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React from 'react';
import { WaffleButton } from '../../../WaffleButton';

function PanelControls(props) {
  const { onClick, active } = props;

  return (
    <div data-testid="panel-controls">
      <WaffleButton active={active} data-testid="MENU" onClick={() => onClick('MENU')} />
    </div>
  );
}

PanelControls.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

PanelControls.defaultProps = {
  active: false,
  onClick: () => {},
};

export default PanelControls;
