/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React from 'react';

import { common } from '../../propTypes';
import PanelControls from './core/index';

function PanelControlsContainer(props) {
  const { activePanelId, onClick, items } = props;

  return <PanelControls activePanelId={activePanelId} onClick={onClick} items={items} />;
}

PanelControlsContainer.propTypes = {
  activePanelId: PropTypes.string,
  items: common.ControlItemsProps,
  onClick: PropTypes.func,
};

PanelControlsContainer.defaultProps = {
  activePanelId: null,
  onClick: () => {},
};

export default PanelControlsContainer;
