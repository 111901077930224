/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { styled } from '@fluentui/utilities';
import { LoginHelpBase } from './LoginHelp.base';
import { getStyles } from './LoginHelp.styles';
import { LoginHelpProps, LoginHelpDefaultProps } from './LoginHelp.types';

export const LoginHelp = styled(LoginHelpBase, getStyles, undefined, {
  scope: 'LoginHelp',
});

LoginHelp.propTypes = LoginHelpProps;

LoginHelp.defaultProps = LoginHelpDefaultProps;
