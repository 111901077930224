/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';
import FineUploaderTraditional from 'fine-uploader-wrappers';

async function get(id, contentType) {
  const url = `/file/previews/?id=${id}&contentType=${encodeURIComponent(contentType)}`;

  const { data } = await axios.get(url);

  return data;
}

async function upload({
  onComplete = () => {},
  onError = () => {},
  onProgress = () => {},
  onUpload = () => {},
  fileid,
  file,
  contentType,
}) {
  const uploadPreview = () => {
    const endpoint = `/file/previews?id=${fileid}&contentType=${encodeURIComponent(contentType)}`;

    const uploader = new FineUploaderTraditional({
      options: {
        request: {
          endpoint,
          filenameParam: 'filename',
          inputName: 'upfile',
          maxConnections: 1,
          method: 'POST',
          paramsInBody: true,
          params: {
            fileid,
          },
        },
        validation: {
          allowEmpty: true,
        },
        callbacks: {
          onUpload: (id, name) => {
            const uniqueId = uploader.methods.getUuid(id);

            onUpload({ id, name, uniqueId });
          },
          onProgress: (id, name, uploaded, total) => {
            const uniqueId = uploader.methods.getUuid(id);

            onProgress({ uniqueId, id, name, uploaded, total });
          },
          onComplete: (id, name, obj, xhr) => {
            const uniqueId = uploader.methods.getUuid(id);
            onComplete({ id, name, obj, uniqueId, xhr });
          },
          onError: (id, name, errorReason, xhr) => {
            const uniqueId = uploader.methods.getUuid(id);
            onError({ errorReason, id, name, uniqueId, xhr });
          },
        },
      },
    });

    uploader.methods.addFiles(file);
  };

  uploadPreview();

  return {};
}

export default {
  get,
  upload,
};
