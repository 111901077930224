/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { useStore, useSelector } from 'react-redux';
import { useEffect, useCallback, useRef, useState } from 'react';
import { loginWithUserDetails } from '../../../actions';
import { getOktaAuthClient } from '../../../api/package/auth';
import config from '../../../api/package/config';

const errorHtml = `
<div style="font-size: 14px;">
  <h3 style="color: #fa4716; margin: 4px 0px;">Sorry! Something went wrong on our end.</h3>
  <p style="margin: 0;">Please refresh the page to try again or contact our <a href="mailto:support@altair.com?subject=Not able to login">customer support</a> if the problem persists.</p>
</div>
`;

const oktaSDKErrorHtml = message => `
<div style="font-size: 14px;">
  <h3 style="color: #fa4716; margin: 4px 0px;">${message}</h3>
  <p style="margin: 0;">Please check <a href="/login/help">here</a> for list of supported browsers.</p>
</div>
`;

function useOktaCallback({ isConfigLoaded, loginWithAccessToken }) {
  const redirectToUriRef = useRef();
  const [user, setUser] = useState();
  const [isEulaModalOpen, setIsEulaModalOpen] = useState(false);
  const [loginFailedMessage, setLoginFailedMessage] = useState();

  const { dispatch } = useStore();
  const loggedInUser = useSelector(state => state.loggedInUser);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user?.attributes?.eulaAccepted) {
      setIsEulaModalOpen(false);
      dispatch(loginWithUserDetails(user));
    } else {
      setIsEulaModalOpen(true);
    }
  }, [dispatch, user]);

  function onAcceptEula() {
    setIsEulaModalOpen(false);
    dispatch(loginWithUserDetails(user));
  }

  const implicitLogin = useCallback(async () => {
    try {
      const authClient = await getOktaAuthClient();
      const token = await authClient.token.parseFromUrl();
      const accessToken = token?.tokens?.accessToken;
      const refreshToken = token?.tokens?.refreshToken;
      if(authClient) {
        authClient.tokenManager.add('accessToken', accessToken);
      }

      const redirectToUri = token?.state || '/';
      redirectToUriRef.current = redirectToUri;

      const userProfile = await loginWithAccessToken(accessToken?.accessToken, refreshToken?.refreshToken);

      // if (userProfile.attributes.redirectUrl) {
      //   window.open(userProfile.attributes.redirectUrl, '_self');
      // }
      setUser(userProfile);
      // dispatch(loginWithToken(accessToken?.value, loginWithAccessToken));
      const tokenObj = {
        jwt: accessToken.accessToken,
        expiresAt: accessToken.expiresAt * 1000,
        clearOnLogOut: true,
      };

      config.saveJWTToStorage(tokenObj);
    } catch (e) {
      if (e?.name === 'AuthSdkError') {
        if (e.message === 'Unable to parse a token from the url') {
          window.location.href = '/';
        } else {
          setLoginFailedMessage(oktaSDKErrorHtml(e.message));
        }
      } else if (e.message && e.message.indexOf('internal server error') > -1) {
        setLoginFailedMessage(errorHtml);
      } else {
        setLoginFailedMessage(e.message);
      }
    }
  }, [loginWithAccessToken]);

  useEffect(() => {
    if (isConfigLoaded) {
      implicitLogin();
    }
  }, [implicitLogin, isConfigLoaded]);

  return { loginFailedMessage, onAcceptEula, redirectTo: loggedInUser.status === 'LOGGED_IN' && redirectToUriRef.current, isEulaModalOpen };
}

export default useOktaCallback;
