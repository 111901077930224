/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import React from 'react';

const Topbar = React.lazy(() => import('./index'));

export default function LazyTopbar(props) {
  return (
    <React.Suspense
      fallback={
        <div style={{ height: 32, borderBottom: '1px solid rgb(210, 213, 215)', width: '100vw', display: 'flex', alignItems: 'center' }} />
      }
    >
      <Topbar {...props} />
    </React.Suspense>
  );
}
