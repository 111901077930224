/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import BooleanInput from './BooleanInput';
import ColorInput from './ColorInput';
import DateInput from './DateInput';
import IntegerInput from './IntegerInput';
import NumberInput from './NumberInput';
import Readonly from './Readonly';
import StringInput from './StringInput';
import UnitScalarInput from './UnitScalarInput';
import PlotInput from './PlotInput';
import ImageInput from './ImageInput';
import MultilineStringInput from './MultilineStringInput';

const typeRenderers = {
  boolean: BooleanInput,
  color: ColorInput,
  date: DateInput,
  integer: IntegerInput,
  number: NumberInput,
  string: StringInput,
  readonly: Readonly,
  unitScalar: UnitScalarInput,
  plot: PlotInput,
  image: ImageInput,
  multilinestring: MultilineStringInput,
};

export default typeRenderers;
