/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Cell = styled.div`
  flex-grow: 1;
  padding: 3;
  position: relative;
`;

const Title = styled.span`
  line-height: 32px;
`;

const TogglerNoIcon = styled.div`
  display: inline-block;
  position: relative;
  width: 28px;
`;

export { Cell, Title, TogglerNoIcon };
