import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import Plot from '../plot.js';
import { setupDiagram, createMultipleDiagram, onDoubleClick, onRelayout } from '../processBuildingBlock.js';
import { Dropdown, IconButton, TooltipHost } from '@fluentui/react';
import * as TextMapping from '../../utils/textMapping';
import CompareCheckbox from './CompareCheckbox/index.js';
import { niceRange } from '../processBuildingBlock';
import { isEmpty } from 'lodash';
import LimitSelectionDialog from '../dialogs/LimitSelectionDialog/index.js';

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

function CompareOverlay({ selectedEntities, catalogs, texts, roots, diagramsAvailable }) {
  const [selectedItem, setSelectedItem] = useState();
  const [optionArray, setOptionArray] = useState([]);
  const [savedEntityMap, setSavedEntityMap] = useState();
  const [layoutMap, setLayoutMap] = useState(new Map());
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedCurves, setSelectedCurves] = useState(new Map());
  const [materialOptions, setMaterialOptions] = useState(new Map());
  const [diagram, setDiagram] = useState();
  const [plotData, setPlotData] = useState();
  const [checkboxVisibility, setCheckboxVisibility] = useState(true);
  const [isLimitSelectionDialogHidden, setIsLimitSelectionDialogHidden] = useState(true);
  const [maxSelections, setMaxSelections] = useState(10);

  const mapDiagrams = useCallback(
    (plots) => {
      let diagramMap = new Map();

      if (plots && plots.dia && plots.dia.length > 0) {
        for (let entry of plots.dia) {
          let diagram = entry.value[0];
          let catalog = entry.catalog;

          diagram.type = 'dia';
          diagram.catalogname = entry.catalog.name;
          diagram.materialName = plots.name;
          if (diagram.moisturestate) {
            diagram.materialName += ' (' + diagram.moisturestate + ')';
          }

          diagram.key = plots._key;
          diagram.catalogKey = entry.catalog._key;
          diagram.catalogOptions = entry.catalog.options;
          diagram.zUnit = entry.catalog.catalog.units.z;

          const mapKey = diagram.type + '/' + diagram.vkey;

          if (Array.isArray(catalog.axes.y)) {
            diagram.plotData = createMultipleDiagram(catalog, diagram, layoutMap);
          } else {
            diagram.plotData = setupDiagram(catalog, diagram, layoutMap);
          }

          if (diagram.vkey) {
            if (!diagramMap.has(mapKey)) {
              diagramMap.set(mapKey, [diagram]);
            } else {
              let diagramArray = diagramMap.get(mapKey);
              diagramArray.push(diagram);
            }
          }
        }
      }

      return diagramMap;
    },
    [layoutMap]
  );

  useEffect(() => {
    if (roots && roots.config && roots.config.plotly && roots.config.plotly.overlay && roots.config.plotly.overlay.maxcurves) {
      setMaxSelections(roots.config.plotly.overlay.maxcurves);
    }
  }, [roots]);

  useEffect(() => {
    let selectedEntityMap = new Map();
    let propertyNameMap = new Map();
    let options = [];

    if (selectedEntities.length === 0) {
      resetCheckboxes();
    }

    // remove items that are no longer selected from selected curves and selected materials

    setSelectedCurves((s) => {
      for (let key of s.keys()) {
        if (!selectedEntities.some((entity) => entity._key === key.split('|')[0])) {
          s.delete(key);
        }
      }

      return new Map(s);
    });

    setSelectedMaterials((s) => {
      return s.filter((value) => selectedEntities.some((entity) => entity._key === value.split('|')[0]));
    });

    for (let selectedEntity of selectedEntities) {
      let diagramMap = new Map();

      if (selectedEntities && selectedEntities.length > 0) {
        diagramMap = mapDiagrams(selectedEntity);

        selectedEntityMap.set(selectedEntity._key, diagramMap);

        for (let [key, diagramArray] of diagramMap.entries()) {
          if (diagramArray && diagramArray.length > 0) {
            for (let diagram of diagramArray) {
              // look for diagram for diagram model to prevent duplicates
              let foundDiagram = false;

              if (diagram.type === 'diamodel') {
                let resultArray = diagramMap.get('dia/' + diagram.vkey);

                if (resultArray) {
                  for (let result of resultArray) {
                    if (result.moisturestate === diagram.moisturestate && result.variant === diagram.variant && result.plotData) {
                      foundDiagram = true;
                      break;
                    }
                  }
                }
              }
              if (!foundDiagram) {
                propertyNameMap.set(key + '|' + diagram.variant, diagram.catalogname);
              }
            }
          }
        }
      }
    }

    for (const [key, value] of propertyNameMap) {
      options.push({ key: key, text: value });
    }

    setOptionArray(options);
    setSavedEntityMap(selectedEntityMap);
  }, [selectedEntities, catalogs, layoutMap, mapDiagrams]);

  function onPlotChange(e, value) {
    setSelectedCurves(new Map());
    setSelectedMaterials([]);
    setSelectedItem(value);
  }

  function onDismissLimitSelectionDialog() {
    setIsLimitSelectionDialogHidden(true);
  }

  function toggleCheckboxVisibility() {
    setCheckboxVisibility(!checkboxVisibility);
  }

  function resetCheckboxes() {
    setSelectedCurves(new Map());
    setSelectedMaterials([]);
  }

  const getNameCheckboxValue = useCallback(
    (_name, key) => {
      if (selectedMaterials.includes(key)) {
        return true;
      } else {
        return false;
      }
    },
    [selectedMaterials]
  );

  const onNameCheckboxChange = useCallback(
    (_name, value, key) => {
      if (value) {
        if (!selectedMaterials.includes(key)) {
          setSelectedMaterials((prevMaterials) => [...prevMaterials, key]);
        }
      } else {
        setSelectedMaterials(selectedMaterials.filter((k) => k !== key));
      }
    },
    [selectedMaterials]
  );

  const onCurveCheckboxChange = useCallback(
    (name, value, key) => {
      if (value) {
        let count = 0;
        for (let valueArray of selectedCurves.values()) {
          count += valueArray.length;
        }

        if (count >= maxSelections) {
          setIsLimitSelectionDialogHidden(false);
        } else {
          if (!selectedCurves.has(key)) {
            setSelectedCurves(new Map(selectedCurves.set(key, [name])));
          } else {
            if (!selectedCurves.get(key).includes(name)) {
              let newArray = selectedCurves.get(key);
              newArray.push(name);
              setSelectedCurves(new Map(selectedCurves.set(key, newArray)));
            }
          }
        }

        onNameCheckboxChange(null, value, key);
      } else {
        if (selectedCurves.has(key)) {
          if (selectedCurves.get(key).filter((n) => n !== name).length === 0) {
            selectedCurves.delete(key);
            setSelectedCurves(new Map(selectedCurves));
          } else {
            setSelectedCurves(
              new Map(
                selectedCurves.set(
                  key,
                  selectedCurves.get(key).filter((n) => n !== name)
                )
              )
            );
          }
        }
      }
    },
    [selectedCurves, maxSelections, onNameCheckboxChange]
  );

  const getCurveCheckboxValue = useCallback(
    (name, key) => {
      if (selectedCurves.has(key) && selectedCurves.get(key).includes(name)) {
        return true;
      } else {
        return false;
      }
    },
    [selectedCurves]
  );

  const doubleClick = useCallback(
    (curveName) => {
      for (let [key, diagram] of materialOptions.entries()) {
        for (let curve of diagram.plotData.data) {
          if (curve.curveName === curveName) {
            onCurveCheckboxChange(curve.curveName, !getCurveCheckboxValue(curve.curveName, key), key);
          }
        }
      }
    },
    [materialOptions, onCurveCheckboxChange, getCurveCheckboxValue]
  );

  const getComponentKey = useCallback((name, type) => {
    return name + '-' + type;
  }, []);

  const getDiagramKey = useCallback((diagram) => {
    return diagram.key + '|' + diagram.moisturestate;
  }, []);

  useEffect(() => {
    if (savedEntityMap && selectedItem) {
      let newMaterialsMap = new Map();

      for (const value of savedEntityMap.values()) {
        let keyVariantPair = selectedItem.key.split('|');
        let diagramArray = value.get(keyVariantPair[0]);
        if (diagramArray && diagramArray.length > 0) {
          for (let diagram of diagramArray) {
            setDiagram(diagram);
            if (diagram.variant?.toString() === keyVariantPair[1]) {
              let key = getDiagramKey(diagram);

              newMaterialsMap.set(key, diagram);
            }
          }
        }
      }

      setMaterialOptions(newMaterialsMap);
    }
  }, [savedEntityMap, selectedItem, getDiagramKey]);

  useEffect(() => {
    if (diagram) {
      let plotData = JSON.parse(JSON.stringify(diagram.plotData));

      plotData.data = [];
      plotData.layout.annotations = [];

      let xValues = [];
      let yValues = [];

      let legendAnnotationSet = new Set();

      for (let [key, diagram] of materialOptions.entries()) {
        if (selectedMaterials.includes(key) && selectedCurves.has(key)) {
          for (let curve of diagram.plotData.data) {
            if (selectedCurves.get(key).includes(curve.curveName) || curve.curveName === null) {
              curve.x.forEach((point) => {
                xValues.push(point);
              });

              curve.y.forEach((point) => {
                yValues.push(point);
              });

              let newCurve = JSON.parse(JSON.stringify(curve));
              newCurve.line.color =
                plotData.data.length < newCurve.color_discrete_sequence.length
                  ? newCurve.color_discrete_sequence[plotData.data.length]
                  : newCurve.color_discrete_sequence[newCurve.color_discrete_sequence.length - 1];
              newCurve.name = curve.name + ' ' + diagram.materialName;
              plotData.data.push(newCurve);
            }
          }

          for (let annotation of diagram.plotData.layout.annotations) {
            if (selectedCurves.get(key).includes(annotation.curveName)) {
              plotData.layout.annotations.push(annotation);
            }

            if (annotation.xref === 'paper') {
              let textAnnotations = annotation.text.split('<br>');
              for (let text of textAnnotations) {
                legendAnnotationSet.add(text);
              }
            }
          }
        }
      }

      if (legendAnnotationSet.size > 0) {
        plotData.layout.annotations.push({
          showarrow: false,
          text: Array.from(legendAnnotationSet).join('<br>'),
          align: 'left',
          xref: 'paper',
          yref: 'paper',
          xanchor: 'left',
          x: 1.03,
          y: 0,
        });
      }

      let isLogX = plotData.layout.xaxis.type === 'log' ? true : false;
      let isLogY = plotData.layout.yaxis.type === 'log' ? true : false;

      let xRange = niceRange(layoutMap.has(diagram.catalogKey) ? layoutMap.get(diagram.catalogKey).xaxis : xValues, isLogX);
      let yRange = niceRange(layoutMap.has(diagram.catalogKey) ? layoutMap.get(diagram.catalogKey).yaxis : yValues, isLogY);

      plotData.layout.xaxis.range = xRange.range;
      plotData.layout.xaxis.dtick = xRange.dtick;

      plotData.layout.yaxis.range = yRange.range;
      plotData.layout.yaxis.dtick = yRange.dtick;

      let firstCurve = null;
      let sameValues = true;

      for (let [key, value] of selectedCurves.entries()) {
        if (firstCurve === null) {
          firstCurve = value;
          if (firstCurve.length !== 1) {
            sameValues = false;
            break;
          }
        } else if (!isEmpty(value)) {
          if (firstCurve.length !== 1 || value.length !== 1 || value[0] !== firstCurve[0]) {
            if (selectedMaterials.includes(key)) {
              sameValues = false;
              break;
            }
          }
        }
      }

      let sameMaterialName = null;

      let keyArray = Array.from(selectedCurves.keys());

      for (let key of keyArray) {
        if (!selectedMaterials.includes(key)) {
          // material isn't selected, so ignore this curve for now
          keyArray = keyArray.filter((item) => {
            return item !== key;
          });
        }
      }

      if (keyArray.length === 1) {
        sameMaterialName = materialOptions.get(keyArray[0]).materialName;
      }

      plotData.layout.title.text = diagram.catalogname;

      if (sameMaterialName) {
        plotData.layout.title.text = plotData.layout.title.text + '<br>' + sameMaterialName;

        for (let data of plotData.data) {
          if (sameMaterialName) {
            data.name = data.name.replace(sameMaterialName, '');
          }
        }
      }

      if (sameValues && firstCurve && firstCurve.length === 1) {
        if (sameMaterialName) {
          plotData.layout.title.text = plotData.layout.title.text + ' ' + firstCurve[0] + ' ' + diagram.zUnit;
        } else {
          plotData.layout.title.text = plotData.layout.title.text + '<br>' + firstCurve[0] + ' ' + diagram.zUnit;
        }

        for (let data of plotData.data) {
          data.name = data.name.replace(firstCurve[0] + ' ' + diagram.zUnit, '');

          if (isEmpty(data.name.trim())) {
            plotData.layout.showlegend = false;
          } else {
            plotData.layout.showlegend = true;
          }
        }
      }

      let legendLength = 0;
      for (let data of plotData.data) {
        if (data.name && data.name.length > legendLength) {
          legendLength = data.name.length;
        }
      }

      plotData.legendLength = legendLength;

      setPlotData(plotData);
    }
  }, [diagram, selectedCurves, selectedMaterials, materialOptions, layoutMap]);

  let materialSelectionRows = [];
  let zUnit = '';

  let count = 0;

  for (let [key, diagram] of materialOptions.entries()) {
    let curves = [];

    zUnit = diagram.zUnit;
    for (let curve of diagram.plotData.data) {
      curves.push(
        <div style={{ minWidth: '100px', paddingLeft: '5px' }}>
          <CompareCheckbox
            materialName={diagram.materialName}
            diagramKey={key}
            name={curve.curveName !== null ? curve.curveName : TextMapping.getUIText(TextMapping.UI_TEXT_CURVE_PLACEHOLDER, texts)}
            type="curve"
            onDoubleClick={doubleClick}
            onCheckboxChange={onCurveCheckboxChange}
            getCheckboxValue={getCurveCheckboxValue}
            getComponentKey={getComponentKey}
          />
        </div>
      );
    }

    if (count === 0) {
      materialSelectionRows.push(
        <div style={{ display: 'flex', flexDirection: 'row', padding: '5px' }}>
          <div style={{ width: '30%' }}></div>
          <div style={{ width: '70%', display: 'flex', flexDirection: 'row' }}>{zUnit}</div>
        </div>
      );
    }

    count++;

    materialSelectionRows.push(
      <div style={{ display: 'flex', flexDirection: 'row', padding: '5px', borderBottom: '1px solid #DDDDDD' }}>
        <div style={{ width: '30%' }}>
          <CompareCheckbox
            name={diagram.materialName}
            diagramKey={getDiagramKey(diagram)}
            type="name"
            zUnit={zUnit}
            onCheckboxChange={onNameCheckboxChange}
            getCheckboxValue={getNameCheckboxValue}
            getComponentKey={getComponentKey}
          />
        </div>
        <div style={{ width: '70%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>{curves}</div>
      </div>
    );
  }

  if (diagramsAvailable) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', flexGrow: '1' }}>
        <div style={{ display: 'flex', padding: '5px', alignItems: 'center' }}>
          {optionArray.length > 0 && (
            <Dropdown
              placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_A_PLOT, texts)}
              options={optionArray}
              required={true}
              defaultSelectedKey={selectedItem && selectedItem.key}
              onChange={onPlotChange}
              selectedKey={selectedItem ? selectedItem.key : undefined}
              style={{ width: '300px' }}
            />
          )}
          <TooltipHost content={TextMapping.getUIText(TextMapping.UI_TEXT_RESET, texts)} id={'reset-tooltip'} setAriaDescribedBy={false}>
            <IconButton iconProps={{ iconName: 'Refresh' }} onClick={resetCheckboxes} />
          </TooltipHost>
          <TooltipHost content={TextMapping.getUIText(TextMapping.UI_TEXT_HIDE, texts)} id={'hide-tooltip'} setAriaDescribedBy={false}>
            <IconButton iconProps={{ iconName: 'Hide' }} onClick={toggleCheckboxVisibility} />
          </TooltipHost>
        </div>
        <div style={{ padding: '5px', width: '100%', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
          <div
            key={selectedItem}
            data-testid="compare-overlay-selection-div"
            style={{
              display: checkboxVisibility ? 'flex' : 'none',
              flexDirection: 'column',
              minWidth: '270px',
              height: '200px',
              overflow: 'auto',
            }}
          >
            {materialSelectionRows}
          </div>
          <div key="plot" style={{ display: 'flex', flexDirection: 'column', minWidth: '270px', flexGrow: '1' }}>
            {plotData && plotData.data.length > 0 && (
              <Plot
                name="overlay"
                plotData={plotData}
                onRelayout={(event) =>
                  onRelayout(
                    diagram.catalogKey,
                    event,
                    diagram.catalogOptions.scale.x === 'log',
                    diagram.catalogOptions.scale.y === 'log',
                    layoutMap,
                    setLayoutMap
                  )
                }
                onDoubleClick={() => onDoubleClick(diagram.catalogKey, layoutMap, setLayoutMap)}
              />
            )}
          </div>
        </div>
        <LimitSelectionDialog
          hidden={isLimitSelectionDialogHidden}
          onDismiss={onDismissLimitSelectionDialog}
          texts={texts}
          max={maxSelections}
        />
      </div>
    );
  } else {
    return <div style={{ padding: '5px' }}>{TextMapping.getUIText(TextMapping.UI_TEXT_NO_DIAGRAMS_AVAILABLE, texts)}</div>;
  }
}

export default connect(mapStateToProps)(CompareOverlay);
