/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import platformPropTypes from '@altair/prop-types';
import SpinnerIcon from './SpinnerIcon';
import ExtensionIcon from './ExtensionIcon';

export function MDEIcon({ width, entity }) {
  /* Used to render extension icons when the content image can't be loaded. */
  const [forceRenderExtensionIcon, setForceRenderExtensionIcon] = useState(false);

  const { name } = entity.attributes;
  const { contentType } = entity.system;

  const iconWidth = width > 100 ? width / 7.5 : width / 1.5;
  const iconMarginBottom = width > 100 ? width / 4.4 : 0;
  const iconMarginRight = width > 100 ? width / 4.8 : 0;
  const iconUrl = `/content/content-definitions/${contentType}/icon`;

  useEffect(() => {
    setForceRenderExtensionIcon(false);
  }, [entity]);

  if (forceRenderExtensionIcon) {
    return (
      <div style={{ fontSize: '0px' }}>
        <ExtensionIcon entity={entity} width={width} />
        <SpinnerIcon width={width} iconWidth={iconWidth} iconMarginBottom={iconMarginBottom} iconMarginRight={iconMarginRight} overlay />
      </div>
    );
  }

  return (
    <div style={{ fontSize: '0px' }}>
      <img
        data-testid={`ContentTypeIcon-${name}`}
        src={iconUrl}
        onError={() => {
          setForceRenderExtensionIcon(true);
        }}
        style={{ width: `${width}px`, height: `${width}px` }}
        alt="icon"
      />

      <SpinnerIcon width={width} iconWidth={iconWidth} iconMarginBottom={iconMarginBottom} iconMarginRight={iconMarginRight} overlay />
    </div>
  );
}

MDEIcon.propTypes = {
  entity: platformPropTypes.entity.isRequired,
  width: PropTypes.number.isRequired,
};

export default MDEIcon;
