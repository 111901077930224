/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import format from 'date-fns/format';
import { TextField } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';

export function DatePicker(props) {
  const { disabled, onChange, value: propValue } = props;
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  function handleChange(newDate) {
    let newValue;

    if (typeof value === 'number') {
      newValue = newDate.valueOf();
    } else if (typeof value === 'string') {
      newValue = newDate.format();
    } else if (value instanceof Date) {
      newValue = newDate.toDate();
    } else {
      throw new TypeError(`props.value is not a number, string, or instance of Date.`);
    }

    setValue(newValue);
    onChange(newValue);
  }

  return (
    <>
      {disabled && <TextField disabled value={format(value, 'M/D/YYYY h:mm A')} />}

      {!disabled && <DateTimePicker onChange={handleChange} value={new Date(value)} />}
    </>
  );
}

DatePicker.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]),
};

DatePicker.defaultProps = {
  disabled: false,
  onChange: () => {},
  value: null,
};

export default DatePicker;
