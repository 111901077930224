/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import PropTypes from 'prop-types';

export const ControlItemsProps = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string.isRequired,
    text: PropTypes.string,
    iconProps: PropTypes.shape({
      iconName: PropTypes.string.isRequired,
    }),
    onClick: PropTypes.func,
    ButtonStyles: PropTypes.object,
  })
);

export const WaffleMenuProps = PropTypes.shape({
  dashboardButtonProps: PropTypes.shape({
    isHidden: PropTypes.bool,
  }),
  homeButtonProps: PropTypes.shape({
    isHidden: PropTypes.bool,
  }),
  onlineHelpUrl: PropTypes.string,
  items: PropTypes.node,
});

export default {
  ControlItemsProps,
  WaffleMenuProps,
};
