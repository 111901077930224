/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { Component } from 'react';
import { Link } from '@fluentui/react';
import PropTypes from 'prop-types';
import { ErrorContainer, ErrorMessage } from './styled';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      message: '',
      reloadUrl: window.location.href,
    };

    this.updateReloadUrl = this.updateReloadUrl.bind(this);
  }

  componentDidMount() {
    this.updateReloadUrl();
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.hasError !== this.state.hasError && this.state.hasError) {
      this.props.showFullPageLoader(false);
    }
  }

  updateReloadUrl() {
    const paths = window.location.pathname.split('/').filter((p) => {
      return p !== 'Materials';
    });
    // if some catalog or path is open, want to retain that
    if (paths.length > 0) {
      this.setState({
        reloadUrl: window.location.href,
      });
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    // this.updateReloadUrl();
    return { hasError: true, message: error.message };
  }

  render() {
    const { children } = this.props;
    const { hasError, reloadUrl, message } = this.state;

    return (
      <>
        {hasError ? (
          <>
            <ErrorContainer>
              {message === 'Unauthorized' ? (
                ''
              ) : (
                <ErrorMessage>
                  <b>Internal Server Error</b>
                  <p>
                    The server encountered an error and could not complete the request. <br />
                    Please try again later.
                  </p>
                  <Link href={reloadUrl} title="Return to Home Page">
                    Home
                  </Link>
                </ErrorMessage>
              )}
            </ErrorContainer>
          </>
        ) : (
          children
        )}
      </>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  showFullPageLoader: PropTypes.func,
};

export default ErrorBoundary;
