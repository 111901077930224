/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import { browserApi } from '../../../common/ui-sdk/browser';

const pathConfigCache = new Map();
const contentTypeVdefCache = new Map();
const JWT_TOKEN_KEY = 'aone-jwt';

async function getDirectoryPathById(id) {
  const res = await fetch(`/file/directories/${id}/path`);
  if (res.status !== 200) {
    return null;
  }

  const { pathEntities } = await res.json();

  if (pathEntities[0].attributes.name === '/') {
    pathEntities.shift();
  }

  return `/${pathEntities.map(entity => entity.attributes.name).join('/')}`;
}

async function getConfigByPath(path, fromCache = true) {
  /* If cache is true return the promise from cache */
  if (fromCache && pathConfigCache.get(path) !== undefined) {
    return pathConfigCache.get(path);
  }

  const promise = new Promise((resolve, reject) => {
    fetch(`/assets/path/${encodeURIComponent(path)}`)
      .then(configResponse => {
        if (configResponse.status === 200) {
          configResponse
            .text()
            .then(config => {
              const json = JSON.parse(config);
              resolve(json);
            })
            .catch(reject);
        } else {
          resolve(null);
        }
      })
      .catch(reject);
  });

  if (!pathConfigCache.has(path)) {
    /* Set the cahce */
    pathConfigCache.set(path, promise);
  }

  return promise;
}

async function getLocalVdefByContentType(contentType, userName) {
  const path = `/Users/${userName}/MyFiles/a365/ContentDefinitions/${contentType}/${contentType}.vdef`;
  const vdef = await getConfigByPath(path);
  if (vdef) {
    return { ...vdef, path };
  }
  return vdef;
}

export const getVDefByContentType = async ({ contentType }) => {
  const response = await fetch(`/assets/path/a365/content-packs/altair/filetypes/${contentType}/${contentType}.vdef`);
  if (!response.ok) {
    throw await response.json();
  }
  return response.json();
};

async function getDefaultVdefByContentTypeAndVdefDirId(contentType, vdefDirId) {
  const vdefDirPath = await getDirectoryPathById(vdefDirId);
  const path = `${vdefDirPath}/${contentType}.vdef`;
  const vdef = await getConfigByPath(path);
  if (vdef) {
    return { ...vdef, path };
  }
  return vdef;
}

async function getVdef({ contentType, vdefDirectoryId, userName, fromCache = true }) {
  if (fromCache && contentTypeVdefCache.get(contentType) !== undefined) {
    return contentTypeVdefCache.get(contentType);
  }

  const promise = new Promise((resolve, reject) => {
    try {
      getLocalVdefByContentType(contentType, userName)
        .then(vdef => {
          if (vdef !== null) {
            resolve(vdef);
          } else {
            getDefaultVdefByContentTypeAndVdefDirId(contentType, vdefDirectoryId)
              .then(defaultVdef => {
                resolve(defaultVdef);
              })
              .catch(reject);
          }
        })
        .catch(reject);
    } catch (e) {
      reject(e);
    }
  });

  if (!contentTypeVdefCache.has(contentType)) {
    /* Set the cahce */
    contentTypeVdefCache.set(contentType, promise);
  }

  return promise;
}

function saveJWTToStorage(tokenObj) {
  browserApi.cacheService.setData({ storageType: 'localStorage', key: JWT_TOKEN_KEY, data: tokenObj });
}

function deleteJWTFromStorage() {
  return browserApi.cacheService.removeData({ storageType: 'localStorage', key: JWT_TOKEN_KEY });
}

function getJWTFromStorage() {
  return browserApi.cacheService.getData({ storageType: 'localStorage', key: JWT_TOKEN_KEY });
}

export default {
  getConfigByPath,
  getVdef,
  getVDefByContentType,
  saveJWTToStorage,
  getJWTFromStorage,
  deleteJWTFromStorage,
};
