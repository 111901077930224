/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';


import defaultTypeRenderers from '../defaultTypeRenderers';

function Value(props) {
  const { dataItem, invalidDataItems, onBlur, onChange, pathKeys, typeRenderers } = props;
  const path = pathKeys.join('.');
  const isGroup = dataItem.value === undefined && dataItem.values === undefined;

  if (isGroup) return null;

  const { type = 'string' } = dataItem;
  const isValid = !invalidDataItems.has(path);
  const { errorMessage } = invalidDataItems.get(path) || {};
  const renderCustomValue = dataItem.render || typeRenderers[type] || defaultTypeRenderers[type];

  if (!renderCustomValue) {
    throw new Error(`No renderer for type "${type}".`);
  }

  function handleBlur() {
    onBlur({ pathKeys });
  }

  function handleChange(newValue, index) {
    if (dataItem.values) {
      const newValues = [...dataItem.values.slice(0, index), newValue, ...dataItem.values.slice(index + 1)];

      onChange({
        oldDataItem: dataItem,
        pathKeys,
        values: newValues,
      });
    } else {
      onChange({
        oldDataItem: dataItem,
        pathKeys,
        value: newValue,
      });
    }
  }

  if (dataItem.values) {
    if (!isArray(dataItem.values)) {
      throw new TypeError('values must be an array');
    }

    if (dataItem.type === 'table') {
      return renderCustomValue({ dataItem });
    }

    return dataItem.values.map((value, index) => {
      const modifiedDataItem = cloneDeep(dataItem);

      modifiedDataItem.value = value;
      const key = uuidV4();
      return (
        <span key={key}>
          {renderCustomValue({
            dataItem: modifiedDataItem,
            errorMessage,
            isValid,
            onBlur: handleBlur,
            onChange: newValue => handleChange(newValue, index),
            pathKeys,
          })}
        </span>
      );
    });
  }

  return (
    <span style={{ width: '100%' }}>
      {renderCustomValue({
        dataItem,
        errorMessage,
        isValid,
        onBlur: handleBlur,
        onChange: handleChange,
        pathKeys,
      })}
    </span>
  );
}

Value.propTypes = {
  dataItem: PropTypes.object,
  invalidDataItems: PropTypes.instanceOf(Map),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  pathKeys: PropTypes.array,
  typeRenderers: PropTypes.object.isRequired,
};

Value.defaultProps = {
  dataItem: null,
  invalidDataItems: new Map(),
  onBlur: () => {},
  onChange: () => {},
  pathKeys: [],
};

export default Value;
