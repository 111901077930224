/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

export async function loginWithToken({ altairOneBaseUrl, accessToken, refreshToken }) {
  const url = `${altairOneBaseUrl}/api/auth/v1/login`;
  const headers = {
    'Content-Type': 'application/json',
  };
  const response = await fetch(url, { method: 'POST', mode: 'cors', headers, body: JSON.stringify({ token: accessToken, refreshToken }) });
  if (response.ok) {
    return response.json();
  }
  const error = await response.json();
  throw error;
}
