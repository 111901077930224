/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import format from 'date-fns/format';
import filesize from 'filesize';
import get from 'lodash/get';
import { formatDuration } from './formatDuration';

export function getRenderer(type) {
  let render;

  if (type === 'date') {
    render = (item, index, column) => {
      const value = get(item, column.entityPath);

      if (!value) return null;

      const formattedValue = format(value, 'MMM D, YYYY HH:mm');

      return formattedValue === 'Invalid Date' ? null : formattedValue;
    };
  } else if (type === 'duration') {
    render = (item, index, column) => {
      const value = get(item, column.entityPath);

      return formatDuration(value);
    };
  } else if (type === 'fileSize') {
    render = (item, index, column) => {
      const value = get(item, column.entityPath);

      if (!Number.isInteger(value)) return null;

      return filesize(value, { base: 10, round: 0 });
    };
  } else if (type === 'string') {
    render = (item, index, column) => {
      const value = get(item, column.entityPath);

      return value;
    };
  } else if (type === 'exponent') {
    render = (item, index, column) => {
      const value = get(item, column.entityPath);

      return Number.parseFloat(value).toExponential();
    };
  } else {
    throw new Error(`No renderer for type "${type}"`);
  }

  return render;
}
