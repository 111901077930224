/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import materials from './materials';

let current = null;

const getApi = () => {
  return current;
};

const setApi = api => {
  api.bindResource(materials, 'materials');

  current = api;
};

export { getApi, setApi };

export default getApi();
