/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';

import LoadingSpinner from './LoadingSpinner';

const NOOP = () => {};

function IFrame({ id, isLoading = false, label, onLoad = NOOP, url }) {
  return (
    <div className="IFrame__Root" data-testid="IFrame" style={{ display: 'flex' }}>
      <iframe
        id={id}
        onLoad={onLoad}
        src={url}
        style={{
          border: 0,
          height: '100%',
          width: isLoading ? 0 : '100%',
        }}
        title="Viewer"
        scrolling="no"
      />
      {isLoading && <LoadingSpinner label={label} />}
    </div>
  );
}

IFrame.propTypes = {
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  onLoad: PropTypes.func,
  url: PropTypes.string,
};

export default React.memo(IFrame);
