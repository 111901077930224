/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';
import get from 'lodash/get';

async function getActiveEditSessions() {
  const { userId } = this.getAuth();
  const url = `/api/jobs/active/${userId}`;

  const { data } = await axios.get(url);
  return data;
}

async function getFileInfo({ api, fileEntity, dirId }) {
  let contentType = null;
  let id = null;
  let parentId = null;
  if (fileEntity) {
    ({ id } = fileEntity);
    if (dirId) {
      parentId = dirId;
    } else {
      const { contentType } = fileEntity.system;
      const includeCollectionNames = ['Directory'];
      const parents = await api.entity.getParents(id, contentType, includeCollectionNames);
      parentId = get(parents, '[0].id');
    }
    ({ contentType } = fileEntity.system);
  }
  return { contentType, dirId: parentId, fileId: id };
}

async function getSession({ sessionId }) {
  const url = `/api/sessions/${sessionId}`;

  const { data } = await axios.get(url);
  return data;
}

async function startJob({ appId, userId, contentType, fileId = null, dirId }) {
  const url = '/api/jobs/';
  const body = {
    type: 'display',
    name: 'jobname',
    appId,
    userId,
    params: {
      contentType,
      dirId,
      fileId,
    },
  };
  const req = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
    url,
  };

  try {
    const resp = await axios(req);
    const {
      id: jobId,
      attributes: { sessionId },
    } = resp.data;

    return { jobId, sessionId };
  } catch (err) {
    return { jobId: null, sessionId: null };
  }
}

async function startEditJob({ appEntity = null, fileEntity = null, parentDirId = null }) {
  if (!appEntity && !fileEntity) {
    throw new Error('Cannot open: an application or file must be specified');
  }

  const fileInfo = await getFileInfo({ api: this, fileEntity, dirId: parentDirId });
  let { dirId } = fileInfo;
  if (!dirId) {
    // open app without a file using My Files as the parent directory
    const rootDirs = await this.dir.getRoots();
    const myFiles = rootDirs.filter(entity => {
      return entity.attributes.name === 'My Files';
    })[0];
    dirId = myFiles ? myFiles.id : null;
  }

  let app = appEntity;
  if (!app) {
    // get default app based on file content type
    const apps = await this.application.get({ contentType: fileInfo.contentType });
    app = apps[0];
    if (!app) {
      throw new Error('No supported apps found');
    }
  }

  const appId = app.name || app.attributes.name;
  const { userId } = this.getAuth();
  const { jobId, sessionId } = await startJob({
    appId,
    userId,
    contentType: fileInfo.contentType,
    fileId: fileInfo.fileId,
    dirId,
  });
  if (jobId && sessionId) {
    const host = `${window.location.protocol}//${window.location.host}`;
    const openUrl = `${host}/opened?jobId=${jobId}&sessionId=${sessionId}`;
    window.open(openUrl, '_blank');
  } else {
    throw new Error('Invalid job or session');
  }
}

async function stopJob({ jobId }) {
  const url = `/api/jobs/${jobId}`;

  const { data } = await axios.delete(url);

  return data;
}

async function submitJob(data) {
  const { app, entity, fields, parentEntity } = data;
  const { userId } = this.getAuth();
  const { ApplicationId } = app;
  const url = '/api/jobs/';

  // create parent directory
  const folderName = fields.get('JOB_NAME').value;
  const parentId = parentEntity.entity.id;
  const jobDirEntity = await this.dir.create(parentId, folderName);

  const jobData = {
    type: 'solve',
    name: fields.get('JOB_NAME').value,
    appId: ApplicationId.value,
    userId,
    params: {
      fileId: entity.id,
      parentId: jobDirEntity.id,
      APP_ID: ApplicationId.value,
    },
  };

  fields.forEach((fieldData, key) => {
    if (!fieldData || fieldData.value === undefined) return;
    jobData.params[key] = fieldData.value;
  });

  const opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jobData),
  };

  // submit job
  return fetch(url, opts).then(resp => {
    return resp.json();
  });
}

export default {
  getActiveEditSessions,
  getSession,
  startEditJob,
  stopJob,
  submitJob,
};
