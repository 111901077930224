/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

function createFilterTags({ tags, urlParams }) {
  let filters = [];

  if (urlParams && tags) {
    let groupValues = urlParams.get('tags') ? urlParams.get('tags').split(',') : [];
    groupValues = groupValues.map((value) => {
      return value.split('-')[1];
    });

    const mapChildren = (parent, filters, categoryVkey) => {
      if (parent.children) {
        return parent.children.map((child) => {
          if (child.children) {
            mapChildren(child, filters, categoryVkey);
          }

          if (groupValues.indexOf(child.vkey.toString()) > -1) {
            let filter = JSON.parse(JSON.stringify(child));

            filter.categoryVkey = categoryVkey;
            filters.push(filter);
          }

          return filters;
        });
      }
    };

    tags.map((tag) => {
      return mapChildren(tag, filters, tag.useMask.join(''));
    });
  }

  return filters;
}

export default createFilterTags;
