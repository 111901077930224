/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import toNumber from 'lodash/toNumber';

function validateNumber({ newDataItem }) {
  let { value, values } = newDataItem;

  if (value === '') {
    return { value, values };
  }

  if (values) {
    values = values.map(elem => validate(elem));
  } else {
    value = validate(value);
  }

  return { value, values };
}

function validate(value) {
  if (Number.isNaN(toNumber(value))) {
    throw new TypeError(`"${value}" is not a number`);
  }

  return toNumber(value);
}

export default validateNumber;
