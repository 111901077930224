/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Spinner } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { Spin } from './styled';

function SpinnerIcon(props) {
  const { width, iconWidth, iconMarginBottom, iconMarginRight, overlay } = props;

  let styles = {
    circle: {
      height: width,
      width,
    },
  };
  if (overlay) {
    styles = {
      circle: {
        height: iconWidth - 4,
        width: iconWidth - 4,
      },
      root: {
        position: 'absolute',
        bottom: `${iconMarginBottom}px`,
        right: `${iconMarginRight}px`,
        textAlign: 'center',
        fontSize: `${iconWidth}px`,
        display: 'inline',
        lineHeight: `${iconWidth}px`,
      },
    };
  }
  return (
    <Spin data-testid="SpinnerIcon">
      <Spinner data styles={styles} />
    </Spin>
  );
}

SpinnerIcon.propTypes = {
  overlay: PropTypes.bool,
  iconMarginBottom: PropTypes.number.isRequired,
  iconMarginRight: PropTypes.number.isRequired,
  iconWidth: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default SpinnerIcon;
