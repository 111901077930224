/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IconButton } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { TogglerNoIcon } from './styled';

function Toggler(props) {
  const { data, depth, isCollapsed, isLeaf, onToggle, pathKeys, styles } = props;

  const togglerStyles = useMemo(() => {
    return {
      icon: {
        fontSize: 28,
        height: 28,
      },
      root: {
        height: 28,
        width: 28,
        ...styles.togglerRoot,
      },
    };
  }, [styles]);

  /* Prevent empty left-space when all nodes are leafs. */
  if (depth === 0) {
    const areAllNodesLeafs = Object.keys(data).every(key => {
      const node = data[key];
      return Object.prototype.hasOwnProperty.call(node, 'value') || key === '__settings__';
    });

    if (areAllNodesLeafs) {
      return null;
    }
  }

  if (isLeaf) {
    return <TogglerNoIcon />;
  }

  return (
    <IconButton
      iconProps={{
        iconName: isCollapsed ? 'RightSmall' : 'DownSmall',
      }}
      onClick={() => onToggle(pathKeys)}
      styles={togglerStyles}
    />
  );
}

Toggler.propTypes = {
  data: PropTypes.object,
  depth: PropTypes.number,
  isCollapsed: PropTypes.bool,
  isLeaf: PropTypes.bool,
  onToggle: PropTypes.func,
  pathKeys: PropTypes.array,
  styles: PropTypes.shape({
    togglerRoot: PropTypes.any,
  }),
};

Toggler.defaultProps = {
  data: null,
  depth: 0,
  isCollapsed: false,
  isLeaf: false,
  onToggle: () => {},
  pathKeys: [],
  styles: {},
};

export default Toggler;
