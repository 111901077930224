/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import defaultTypeValidators from './defaultTypeValidators';
import { runValidatorAndUpdateData } from './utils/runValidatorAndUpdateData';
import updateData from './utils/updateData';
import ErrorBoundary from './ErrorBoundary';
import Table from './Table';

/*
  PropertyTable wraps <Table/> with validation and Unit of Measure validation and conversion.
*/
function PropertyTable(props) {
  const { data: propsData, onFieldUpdate, styles, typeRenderers, typeValidators: propsTypeValidators, onError, handleLink } = props;

  const [data, setData] = useState(propsData);
  const [invalidDataItems, setInvalidDataItems] = useState(new Map());

  const [typeValidators, setTypeValidators] = useState({
    ...defaultTypeValidators,
    ...propsTypeValidators,
  });

  useEffect(() => {
    /*
      TODO: This is probably a hacky way to do check for data changes.

      * This conditional use to check !_.isEqual(oldPropData, newPropData), but an edge case didn't work with it.
        * If you always coerce a property to the same value, then _.isEqual() will say the value didn't change.
        * Consequently, the state wouldn't update with the coerced value.
    */

    setData(propsData);
  }, [propsData]);

  useEffect(() => {
    setTypeValidators({
      ...defaultTypeValidators,
      ...propsTypeValidators,
    });
  }, [propsTypeValidators]);

  /*
   * On failure, revert back to oldDataItem
   * On success, emit new event/trigger callback: onValidUpdate or onSuccessfullChange
   */
  function handleBlur(args) {
    const { oldDataItem, pathKeys } = args;
    let { newData, newDataItem } = args;
    const path = pathKeys.join('.');
    const { type = 'string' } = newDataItem;
    const wasDataItemValidAfterPrevChange = !invalidDataItems.has(path);

    try {
      /* Specific validator in dataItem in props.data. */
      if (newDataItem.validate) {
        ({ newData, newDataItem } = runValidatorAndUpdateData({
          newData,
          newDataItem,
          oldDataItem,
          pathKeys,
          validator: newDataItem.validate,
        }));
      }

      /* General validator for type. */
      const validateType = typeValidators[type];

      if (validateType) {
        ({ newData, newDataItem } = runValidatorAndUpdateData({
          newData,
          newDataItem,
          oldDataItem,
          pathKeys,
          validator: validateType,
        }));
      }

      if (!wasDataItemValidAfterPrevChange) {
        const newInvalidDataItems = new Map(invalidDataItems);

        newInvalidDataItems.delete(path);
        setInvalidDataItems(newInvalidDataItems);
      }

      setData(newData);
    } catch (err) {
      onError();

      /* Revert value/values. */
      if (newDataItem.values) {
        newData = updateData({
          data: newData,
          pathKeys,
          values: oldDataItem.values,
        });
      } else {
        newData = updateData({
          data: newData,
          pathKeys,
          value: oldDataItem.value,
        });
      }

      setData(newData);

      if (wasDataItemValidAfterPrevChange) {
        const newInvalidDataItems = new Map(invalidDataItems);

        newInvalidDataItems.set(path, {
          errorMessage: err.message,
        });

        setInvalidDataItems(newInvalidDataItems);
      }

      return;
    }

    const hasChanged = !isEqual(newDataItem.value, oldDataItem.value) || !isEqual(newDataItem.values, oldDataItem.values);

    if (!hasChanged) return;

    const newArgs = {
      ...args,
      newData,
      newDataItem,
    };

    /* Only reached if validation was successful and the value/values changed. */
    onFieldUpdate(newArgs);
  }

  function handleChange({ newDataItem, oldDataItem, pathKeys, newData }) {
    const isDropdown = Array.isArray(newDataItem.selectOptions);

    if (['boolean', 'date'].includes(newDataItem.type) || isDropdown) {
      handleBlur({
        newDataItem,
        oldDataItem,
        pathKeys,
        newData,
      });
    }
  }

  return (
    /* ErrorBoundary prevents errors from taking down the whole page. */
    <ErrorBoundary>
      <Table
        data={data}
        data-testid="PropertyEditor"
        invalidDataItems={invalidDataItems}
        typeRenderers={typeRenderers}
        onBlur={handleBlur}
        onChange={handleChange}
        styles={styles}
        handleLink={handleLink}
      />
    </ErrorBoundary>
  );
}

PropertyTable.propTypes = {
  data: PropTypes.object.isRequired,
  onError: PropTypes.func,
  onFieldUpdate: PropTypes.func,
  typeRenderers: PropTypes.objectOf(PropTypes.func),
  typeValidators: PropTypes.objectOf(PropTypes.func),
  styles: PropTypes.shape({
    cellRoot: PropTypes.object,
    tableRoot: PropTypes.object,
  }),
  handleLink: PropTypes.func,
};

PropertyTable.defaultProps = {
  onFieldUpdate: () => {},
  styles: {},
  typeRenderers: {},
  typeValidators: {},
};

export default PropertyTable;
