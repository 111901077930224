/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { CarouselProvider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ConfirmDialog } from '../../dialogs/ConfirmDialog';
import EntityIcon from '../../EntityIcon';
import PreviewSlider from './PreviewSlider';
import { EntityRoot, Root } from './styled';

const EMPTY_ARRAY = [];

function Preview({ entity, noAddPreview, noDeletePreview, onAddPreviewClick, onDeletePreviewClick, previews = EMPTY_ARRAY }) {
  const [isDeletePreviewDialogOpen, setIsDeletePreviewDialogOpen] = useState(false);
  const [deletePreview, setDeletePreview] = useState(null);

  const handleDeletePreviewClick = useCallback(preview => {
    setDeletePreview(preview);
    setIsDeletePreviewDialogOpen(true);
  }, []);

  const handleDialogDismiss = useCallback(() => {
    setDeletePreview(null);
    setIsDeletePreviewDialogOpen(false);
  }, []);

  const handleDeletePreview = useCallback(() => {
    onDeletePreviewClick(deletePreview);
    handleDialogDismiss();
  }, [deletePreview, handleDialogDismiss, onDeletePreviewClick]);

  if (previews.length === 0)
    return (
      <EntityRoot data-testid="Preview__Root" className="Preview__Root">
        <EntityIcon entity={entity} noError noPending noProgress noSpinner noVersion width={200} />
      </EntityRoot>
    );

  return (
    <Root data-testid="Preview__Root" className="Preview__Root">
      <CarouselProvider className="Preview__CarouselRoot" totalSlides={previews.length}>
        <PreviewSlider
          previews={previews}
          noAddPreview={noAddPreview}
          noDeletePreview={noDeletePreview}
          onAddPreviewClick={onAddPreviewClick}
          onDeletePreviewClick={handleDeletePreviewClick}
        />
      </CarouselProvider>
      <ConfirmDialog
        body="Are you sure you want to delete this preview?"
        header="Delete Preview"
        isOpen={isDeletePreviewDialogOpen}
        onConfirm={handleDeletePreview}
        onReject={handleDialogDismiss}
        primaryButtonText="Delete"
      />
    </Root>
  );
}

Preview.propTypes = {
  entity: PropTypes.shape({
    system: PropTypes.shape({
      readonly: PropTypes.bool,
    }),
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }),
  noAddPreview: PropTypes.bool,
  noDeletePreview: PropTypes.bool,
  onAddPreviewClick: PropTypes.func,
  onDeletePreviewClick: PropTypes.func,
  previews: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      internal: PropTypes.string,
      preview: PropTypes.string,
      src: PropTypes.string,
      thumbnail: PropTypes.string,
    })
  ),
};

export default Preview;
