import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 24px;
  > .CommentDialog__Title {
    font-size: 16px;
  }

  > .CommentDialog__CommentFailed {
    color: #fa4616;
  }

  > .CommentDialog__Title,
  .CommentDialog__CommentInput,
  .CommentDialog__CommentFailed,
  .CommentDialog__CommentSent {
    margin-bottom: 8px;
  }

  .CommentDialog__CommentFailed,
  .CommentDialog__CommentSent {
    font-size: 14px;
    text-align: center;
  }
`;

export { Root };
