/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';

import LoadingIcon from './LoadingIcon';
import Logo from './logo_Altair_color.svg';

function LoadingScreen({ isLoading = true, message }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '1 0 auto',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          top: -80,
        }}
      >
        {document.documentURI.includes('altair') && <img src={Logo} width={400} alt="Brand" />}
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          {message && <div dangerouslySetInnerHTML={{ __html: message }} />}
          {isLoading && <LoadingIcon />}
        </div>
      </div>
    </div>
  );
}

LoadingScreen.propTypes = {
  isLoading: PropTypes.bool,
  message: PropTypes.string,
};

export default LoadingScreen;
