/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { useSocketCallback } from '../../../../../react-hooks/hooks';
import { useMutation, useQuery } from '@apollo/react-hooks';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import ErrorMessage from './ErrorMessage';
import PropertyEditor from '../../../../components/PropertyEditor';
import { NotificationType, notify } from '../../../../components/NotificationAlert';
import { GET_ENTITY_QUERY, UPDATE_CONTENT_TYPE_MUTATION, UPDATE_ENTITY_MUTATION } from './graphql';
import createData from './utils/createData';
import { createDefaultSchema } from './utils/createDefaultSchema';
import { createRefetchPolicy } from './utils/createRefetchPolicy';
import updateEntity from './utils/updateEntity';

function PropertyEditorContainer(props) {
  const { collectionName, id, schema, styles, type, typeRenderers, typeValidators, onError, api } = props;
  const isMountedRef = useRef(true);
  const [childData, setChildData] = useState(null);
  const [updateContentTypeMutation] = useMutation(UPDATE_CONTENT_TYPE_MUTATION);
  const [updateEntityMutation] = useMutation(UPDATE_ENTITY_MUTATION);

  const { data, refetch } = useQuery(GET_ENTITY_QUERY, {
    // TODO: Review fetchPolicy
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    variables: {
      collectionName,
      id,
    },
  });

  const entity = get(data, 'entity');
  const refetchPolicy = createRefetchPolicy(data);

  useSocketCallback(refetch, refetchPolicy);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!entity) return;

    let newChildData = createData({
      entity,
      schema: schema || createDefaultSchema(entity),
    });

    if (type === 'info') {
      newChildData = newChildData.info;
    } else {
      newChildData = newChildData.attributes;
    }

    setChildData(newChildData);
  }, [entity, schema, type]);

  const handleFieldUpdate = useCallback(
    async ({ newData: newChildData }) => {
      const newEntity = updateEntity(newChildData, entity);
      try {
        if (newEntity.contentType !== entity.contentType) {
          await updateContentTypeMutation({
            variables: {
              updateContentTypeInput: {
                id: entity.id,
                contentType: entity.system.contentType,
                newContentType: newEntity.contentType,
                runTransformer: false,
              },
            },
            update(proxy) {
              proxy.writeQuery({
                query: GET_ENTITY_QUERY,
                data: { entity: newEntity },
                variables: { collectionName: entity.system.collectionName, id: entity.id },
              });
            },
          });
          return;
        }

        const didEntityUpdate = !isEqual(entity, newEntity);

        if (didEntityUpdate) {
          if (entity.attributes.name !== newEntity.attributes.name) {
            // using the correct rename api to rename file / Directory
            // this avoid name duplication within same parent directory
            let renameApi;
            if (entity.system.collectionName === 'Directory') renameApi = api.dir.rename;
            else if (entity.system.collectionName === 'FileEntity') renameApi = api.file.rename;
            try {
              if (renameApi) await renameApi(entity.id, newEntity.attributes.name);
            } catch (e) {
              const errorMsg = get(e, 'response.data.message', 'Name already exists.');
              setChildData(prevChildData => ({ ...prevChildData }));
              notify({ text: errorMsg, type: NotificationType.ERROR });
              return;
            }
          }

          await updateEntityMutation({
            variables: {
              entityInput: {
                attributes: newEntity.attributes,
                contentType: newEntity.system.contentType,
                id: newEntity.id,
              },
            },
          });
        }
      } catch (e) {
        setChildData(prevChildData => ({ ...prevChildData }));
        notify({ text: 'Some error happend while updating the record.', type: NotificationType.ERROR });
      }
    },
    [entity, updateContentTypeMutation, updateEntityMutation, api]
  );

  // if (error) {
  //   console.error(error.message);
  //   // return <ErrorMessage />;
  // }

  if (!childData) {
    return null;
  }

  return (
    <PropertyEditor
      onError={onError}
      data={childData}
      onFieldUpdate={handleFieldUpdate}
      styles={styles}
      typeRenderers={typeRenderers}
      typeValidators={typeValidators}
    />
  );
}

PropertyEditorContainer.propTypes = {
  collectionName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onError: PropTypes.func,
  type: PropTypes.string.isRequired,
  schema: PropTypes.object,
  styles: PropTypes.shape({
    cellLabel: PropTypes.object,
    cellRoot: PropTypes.object,
    tableRoot: PropTypes.object,
    togglerRoot: PropTypes.object,
  }),
  typeRenderers: PropTypes.objectOf(PropTypes.func),
  typeValidators: PropTypes.objectOf(PropTypes.func),
  api: PropTypes.shape({
    dir: PropTypes.object,
    file: PropTypes.object,
  }),
};

PropertyEditorContainer.defaultProps = {
  schema: null,
  styles: {},
  typeRenderers: {},
  typeValidators: {},
};

export { GET_ENTITY_QUERY, UPDATE_ENTITY_MUTATION };

export default PropertyEditorContainer;
