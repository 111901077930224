/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/

import * as React from 'react';
import { browserApi } from '../ui-sdk/browser';
import { usePoller } from '../../react-hooks/hooks';
import { v4 as uuidV4 } from 'uuid';
import axios from 'axios';
import { getApi } from '../../api';

export const LicenseState = {
  CheckingOut: 'CheckingOut',
  CheckoutFailed: 'CheckoutFailed',
  CheckoutSuccess: 'CheckoutSuccess',
  CheckingIn: 'CheckingIn',
  CheckinFailed: 'CheckinFailed',
  CheckinSuccess: 'CheckinSuccess',
  HeartbeatSuccess: 'HeartbeatSuccess',
  HeartbeatFailed: 'HeartbeatFailed',
  Loading: 'Loading',
  Initializing: 'Initializing',
};

const BROWSER_LICENSE_KEY = 'amdc-web-browser-key';
const MAX_HEARTBEAT_RETRIES = 4;
const heartbeatRetryIntervals = [5, 10, 20];

export function useLicense(options) {
  const { loggedInUser, config } = options ?? {};

  const pollerRef = React.useRef({ poll() {}, stop() {} });
  const licenseKeyRef = React.useRef();
  const browserKeyRef = React.useRef();
  const [state, setState] = React.useState();
  const [message, setMessage] = React.useState();
  const [heartbeatFailureCount, setHeartbeatFailureCount] = React.useState(0);
  const [previousFailureCount, setPreviousFailureCount] = React.useState(0);

  const userName = loggedInUser?.username;

  const api = getApi();

  React.useEffect(() => {
    (async () => {
      setState(LicenseState.Initializing);
      axios.defaults.headers.common['skipLicenseCache'] = true;

      let browserLicenseKey = await browserApi.cacheService.getData({ key: BROWSER_LICENSE_KEY, storageType: 'localStorage' });
      if (!browserLicenseKey) {
        browserLicenseKey = uuidV4();
        await browserApi.cacheService.setData({ key: BROWSER_LICENSE_KEY, storageType: 'localStorage', data: browserLicenseKey });
      }
      licenseKeyRef.current = `${userName}-${browserLicenseKey}`;
      axios.defaults.headers.common['licenseKey'] = licenseKeyRef.current;

      browserKeyRef.current = browserLicenseKey;
      setState(LicenseState.Loading);
    })();
  }, [userName]);

  const heartbeat = React.useCallback(async () => {
    if (!licenseKeyRef.current) {
      return;
    }

    try {
      const response = await api.materials.heartbeat();
      if (response?.success) {
        setState(LicenseState.HeartbeatSuccess);
        setHeartbeatFailureCount(0);
      } else {
        axios.defaults.headers.common['skipLicenseCache'] = true;
        setMessage(response.message);
        setHeartbeatFailureCount(heartbeatFailureCount + 1);
      }
    } catch (e) {
      axios.defaults.headers.common['skipLicenseCache'] = true;
      setMessage('Failed to register heartbeat.');
      setHeartbeatFailureCount(heartbeatFailureCount + 1);
    }
  }, [heartbeatFailureCount, api.materials]);

  React.useEffect(() => {
    if (previousFailureCount !== heartbeatFailureCount) {
      setPreviousFailureCount(heartbeatFailureCount);
      if (heartbeatFailureCount > 0 && heartbeatFailureCount < MAX_HEARTBEAT_RETRIES) {
        setTimeout(heartbeat, heartbeatRetryIntervals[heartbeatFailureCount - 1] * 1000);
      } else if (heartbeatFailureCount === MAX_HEARTBEAT_RETRIES) {
        setState(LicenseState.HeartbeatFailed);
        pollerRef.current.stop();
      }
    }
  }, [heartbeatFailureCount, heartbeat, previousFailureCount]);

  pollerRef.current = usePoller(heartbeat, (config?.licenseConfig?.heartbeatInSecs ?? 60) * 1000);

  const checkout = React.useCallback(async () => {
    axios.defaults.headers.common['skipLicenseCache'] = false;
    setState(LicenseState.CheckoutSuccess);
    pollerRef.current.poll();
  }, []);

  return { state, message, checkout };
}
