import React from 'react';
import { DefaultButton } from '@fluentui/react';
import { Margin5 } from '../../../pages/styles';
import PlotTabSample from '../../../pages/plot_tab.png';
import { connect } from 'react-redux';
import * as TextMapping from '../../../utils/textMapping';

const mapStateToProps = state => {
  return {
    roots: state.roots,
  };
};

function PlotMessage({ handleContactUsClick, roots }) {
  const { texts } = roots;
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '12px',
        marginTop: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
        }}
      >
        <img src={PlotTabSample} style={{ filter: 'grayscale(50%)', opacity: '0.5' }} width="600px" alt="Plot Sample" />
        <div
          style={{
            opacity: '0.5',
            fontFamily: 'Unity-Font-Icon-20200218',
            fontSize: '56px',
            color: '#4D4D4D',
            letterSpacing: '0',
          }}
        >
          
        </div>
        <Margin5 />
        {TextMapping.getUIText(TextMapping.UI_TEXT_FREE_VERSION_PLOT_MESSAGE, texts)}
        <Margin5 />
        <DefaultButton
          style={{
            background: '#005879',
            borderRadius: '16px',
            fontSize: '12px',
            color: '#FFFFFF',
            textAlign: 'center',
            lineHeight: '16px',
          }}
          text={TextMapping.getUIText(TextMapping.UI_TEXT_CONTACT_US, texts)}
          onClick={handleContactUsClick}
        />
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(PlotMessage);
