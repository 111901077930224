/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IconButton } from '@fluentui/react';
import PropTypes from 'prop-types';
import React from 'react';

function ExpandButton(props) {
  const { isViewerExpanded, onItemClick } = props;

  return (
    <IconButton
      data-testid="View-Controls-ExpandButton"
      iconProps={{
        iconName: isViewerExpanded ? 'MiniContract' : 'MiniExpand',
      }}
      onClick={() => {
        onItemClick({
          key: isViewerExpanded ? 'COLLAPSE_VIEW' : 'EXPAND_VIEW',
        });
      }}
      styles={{
        root: {
          fontSize: 16,
          height: 30,
          width: 30,
          borderRadius: 2,
          border: '1px solid #000000',
          padding: 0,
          minWidth: 30,
          background: '#FFFFFF',
          marginRight: 8,
          color: '#000000',
        },
      }}
      title={isViewerExpanded ? 'Collapse' : 'Expand'}
    />
  );
}

ExpandButton.propTypes = {
  isViewerExpanded: PropTypes.bool,
  onItemClick: PropTypes.func,
};

ExpandButton.defaultProps = {
  isViewerExpanded: false,
  onItemClick: () => {},
};

export default ExpandButton;
