/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Dialog } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const EMPTY_OBJECT = {};

function BaseDialog({
  children,
  dialogContentProps: propsDialogContentProps = EMPTY_OBJECT,
  modalProps: propsModalProps = EMPTY_OBJECT,
  ...restProps
}) {
  const dialogContentProps = useMemo(() => {
    return {
      showCloseButton: true,
      ...propsDialogContentProps,
    };
  }, [propsDialogContentProps]);

  const modalProps = useMemo(() => {
    return {
      isDarkOverlay: true,
      ...propsModalProps,
    };
  }, [propsModalProps]);

  return (
    <Dialog minWidth={400} maxWidth={400} {...restProps} dialogContentProps={dialogContentProps} modalProps={modalProps}>
      {children}
    </Dialog>
  );
}

BaseDialog.propTypes = {
  children: PropTypes.node.isRequired,
  dialogContentProps: PropTypes.object,
  modalProps: PropTypes.object,
};

export default BaseDialog;
