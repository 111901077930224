/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import has from 'lodash/has';
import unit from 'unitmath';

function validateArgs({ newDataItem, oldDataItem }) {
  if (typeof newDataItem !== 'object') {
    throw new Error(`newDataItem must be an object`);
  }

  if (!has(newDataItem, 'value') && !has(newDataItem, 'values')) {
    throw new Error(`newDataItem must have either a value or values property`);
  }

  if (typeof oldDataItem !== 'object') {
    throw new Error(`oldDataItem must be an object`);
  }

  if (!has(oldDataItem, 'value') && !has(oldDataItem, 'values')) {
    throw new Error(`oldDataItem must have either a value or values property`);
  }
}

/**
 * Converts newValue into same unit as oldValue. Throws error if this is not possible.
 * Assumptions:
 *   - oldValue is always defined
 *   - oldValue has correct unit system, hence converting newValue to this unit system
 * @param oldValue (string)
 * @param newValue (string)
 * @return {string}
 */
function validateUnitScalar({ newDataItem, oldDataItem }) {
  validateArgs({ newDataItem, oldDataItem });

  let { value, values } = newDataItem;

  if (values) {
    values = values.map((elem, index) => {
      const newValue = newDataItem.values[index];
      const oldValue = oldDataItem.values[index];

      return validate({ newValue, oldValue });
    });
  } else {
    const newValue = newDataItem.value;
    const oldValue = oldDataItem.value;

    value = validate({ newValue, oldValue });
  }

  return { value, values };
}

function validate({ newValue, oldValue }) {
  const oldUnit = unit(oldValue);
  const newUnit = unit(newValue);
  const targetUnits = oldUnit.getUnits().toString();
  const result = newUnit.to(targetUnits);

  return result.toString();
}

export default validateUnitScalar;
