/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

async function getHome() {
  const url = '/file/home';

  const { data } = await axios.get(url);

  return data;
}

async function create({ parentDirID, dirName = '', contentType }) {
  const isTrackable = !dirName.startsWith('.');
  const url = `/file/directories?isTrackable=${isTrackable}`;

  const reqBody = {
    name: dirName,
    parentDirID,
    contentType,
  };

  const { data } = await axios.post(url, reqBody);

  return data;
}

async function copyChildren(newParentId, entities) {
  const items = entities.map(entity => {
    const { newName } = entity.pseudo || {};
    const {
      id,
      system: { contentType },
    } = entity;

    return {
      contentType,
      id,
      newName,
    };
  });

  const url = `/file/copy`;

  const reqBody = {
    targetDirectoryID: newParentId,
    entities: items,
  };

  const { data } = await axios.post(url, reqBody);

  return data;
}

async function moveChildren({ targetId, entities, parentDirectoryID, overwrite = false }) {
  const items = entities.map(item => {
    const {
      id,
      system: { contentType },
    } = item;

    return {
      contentType,
      id,
      parentDirectoryID,
    };
  });

  const queryParams = !overwrite ? `?conflict=reject` : '';
  const url = `/file/move${queryParams}`;

  const reqBody = {
    targetDirectoryID: targetId,
    entities: items,
  };

  await axios.post(url, reqBody);

  /* TODO: Response needs to contain moved entities. */
  return {};
}

async function rename(id, newName) {
  const url = `/file/directories/${id}/rename`;

  const reqBody = {
    directoryName: newName,
  };

  const { data } = await axios.post(url, reqBody);

  return data;
}

async function download(entity, index) {
  const { name } = entity.attributes;
  const { id } = entity;
  const url = `/file/directories/${id}/archive-download?byUser=true`;
  const linkElem = document.createElement('a');

  document.body.appendChild(linkElem);
  linkElem.download = name;
  linkElem.href = url;

  /*
    - In Firefox, downloading multiple files doesn't work unless you space the "clicks" out by > 500 ms.
    - Hacky, but it works... :/
    */
  setTimeout(() => {
    linkElem.dispatchEvent(new MouseEvent('click'));
    document.body.removeChild(linkElem);
  }, 500 * index);

  return {};
}

async function getPath(id) {
  const url = `/file/directories/${id}/path`;

  const { data } = await axios.get(url);

  return data;
}

async function getParent(id) {
  const { data } = await axios.get(`/file/directories/${id}/parent`);
  return data;
}

async function getByPath(path) {
  const url = '/file/path';
  const params = {
    path,
    contentType: 'Directory',
  };

  const { data } = await axios.get(url, { params });
  return data;
}

export default {
  copyChildren,
  create,
  download,
  getByPath,
  getHome,
  getParent,
  getPath,
  moveChildren,
  rename,
};
