/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Text = styled.div`
  font-style: italic;
  margin: 2em 1em;
  word-break: break-all;
  text-align: center;
`;

const Wrap = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  > .Controls__Root {
    display: flex;
    align-items: center;
    padding: 5px 10px 15px 0;
  }
  > .ImageViewer__Root,
  .IFrame__Root,
  .Preview__Root {
    height: calc(100% - 40px) !important;
    flex-grow: 1;
  }
`;

export { Text, Wrap };
