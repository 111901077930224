/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import axios from 'axios';

async function getStructures() {
  const url = `/content/search-structures`;
  const reqConfig = {};
  const { data } = await axios.get(url, reqConfig);

  return data;
}

async function query(payload) {
  const url = `/search/`;

  const { data } = await axios.post(url, payload);

  return data;
}

async function search({ filter, limit, offset, returnCounts, searchInView, signal, isLegacy = true }) {
  const queryParams = new URLSearchParams();
  if (limit) {
    queryParams.set('limit', limit);
  }
  if (offset) {
    queryParams.set('offset', offset);
  }
  if (returnCounts) {
    queryParams.set('returnCounts', returnCounts);
  }
  if (searchInView) {
    queryParams.set('searchInView', searchInView);
  }

  queryParams.set('isLegacy', isLegacy);

  const url = `/search/search${queryParams.toString() !== '' ? `?${queryParams.toString()}` : ''}`;

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(filter),
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
  });

  if (!res.ok) {
    return [];
  }

  const data = await res.json();

  return data;
}

async function exportSearch(payload) {
  const url = '/search/export';

  const { data } = await axios.post(url, payload);

  return data;
}

export default {
  search,
  getStructures,
  query,
  exportSearch,
};
