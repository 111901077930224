// eslint-disable-next-line
// 'use strict';


let websocketclient = null;
let apiMaterials = null;
/**
 * The method uses /v1/caecard api to get the material data from AMDC server and respond back via websocket connection.
 * @param {object} data Object defining the parameters for downloading material data.
 */
async function v1caecard(data) {
	let payload = {
		filter: [
			{
				key: 'name',
				value: data.names,
				options: ['clean', 'full'],
			},
		],
		softwareModelVkey: data.software + '/' + data.softwaremodel,
		unitsystem: data.unitsystem,
		startNumber: 1000,
		temperature: data.temperature,
		temperatureUnitSystem: data.temperatureUnitSystem,
		content: data.appContent,
		dest: 'local',
		responseType: data.responseType || 'application/json',
		moist: data.moist,
		batch: data.batch,
		batchCount: data.batchCount
	};
	apiMaterials.downloadMaterialCardV1(payload).then(resp => {
		resp.action = 'caecard';
		resp.batchCount = data.batchCount;
		resp.batch = resp.batch || data.batch;
		delete resp.body;
		websocketclient.send(JSON.stringify(resp));
	}).catch(error => {
		websocketclient.send(JSON.stringify({
			success: false,
			message: error.message || error,
			action: 'caecard',
			batch: data.batch,
			batchCount: data.batchCount
		}
		));
	});
}

async function importJson(data) {
	// websocketclient.send(JSON.stringify({'success': true, action: 'import', 'data': {}}));
	let result = await apiMaterials.uploadMaterialJson(data);
	websocketclient.send(JSON.stringify({ success: result.success, action: 'import', data: result }));
}

export function getWebsocketClient() {
	return websocketclient;
};

/**
 * Creates a new webSocket client by reading the url query parameter or checking if the wsUrl is available in sessionStorage.
 * @param {Object} props object containing appcontent,history and api access
 * @returns websocket client object instance upon successful creation.
 */
export function setupWebSocketConnection(props) {
	const { appContent, history, api } = props;
	apiMaterials = api.materials;
	const urlParams = new URLSearchParams(history.location.search);
	let wsUrl = urlParams.get('webChannelbaseUrl') || sessionStorage.getItem('wsUrl');
	// if not websocket connection url available
	if (!wsUrl) {
		return null;
	}

	try {
		websocketclient = new WebSocket(wsUrl);
		if (!websocketclient) {
			throw Error('Could not connect to web server');
		}

		websocketclient.onopen = function (event) {
			console.log('WS connected');
			sessionStorage.setItem('wsUrl', wsUrl);
			sessionStorage.setItem('websocketclient', websocketclient);
			websocketclient.send(JSON.stringify({ action: 'checkConnection', success: true }));
		};

		websocketclient.onclose = function (event) {
			sessionStorage.setItem('wsUrl', wsUrl);
			console.log('WS disconnected');
			sessionStorage.removeItem('websocketclient');
		};

		websocketclient.onerror = function (event) {
			sessionStorage.setItem('wsUrl', wsUrl);
			console.log('WS encountered error.');
			sessionStorage.removeItem('websocketclient');
		};

		// get all the messages coming from websocket client
		websocketclient.onmessage = async function (event) {
			/**
			 * data struture of the data that should be received, so that appropriate handlers could be called
			 * eventdata = {
			 *  action: import | v1card | ....,
			 *  data: '{}' -> json parse data
			 * }
			 */
			const eventData = JSON.parse(event.data);
			let data = eventData.data;
			eventData.action = eventData.action === 'caeexport' ? 'caecard' : eventData.action;

			switch (eventData.action) {
				case 'caecard':
					// download cae material data
					data.appContent = appContent;
					if (typeof eventData.data === 'string') JSON.parse(eventData.data);
					v1caecard(data);
					break;
				case 'import':
					// importing the material json
					if (typeof eventData.data === 'string') JSON.parse(eventData.data);
					await importJson(data);
					break;
				case 'checkConnection':
					websocketclient.send(JSON.stringify({ action: 'checkConnection', success: true }));
					break;
				case 'responseType':
					const responseType = eventData.responseType && eventData.responseType === 'json' ? 'json' : '';
					sessionStorage.setItem('unity-response', responseType);
					break;
				default:
					const res = { success: false, message: 'no action defined...' };
					websocketclient.send(JSON.stringify(res));
					break;
			}
		};
		return websocketclient;
	} catch (error) {
		console.log('error: failed to connect to websocket server');
	}
}