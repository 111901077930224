/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import defaultState from '../store/initialStates';

const REPLACE_SELECTED_ENTITIES = (state, action) => {
  const { entities } = action;

  return state.map(entity => {
    return entities.get(entity.id) || entity;
  });
};

function selectedEntities(state = [], action) {
  switch (action.type) {
    case 'CLEAR_SELECTED_ENTITIES':
      return [...defaultState.selectedEntities];
    case 'OVERWRITE_SELECTED_ENTITIES':
      return [...action.entities];
    case 'REPLACE_SELECTED_ENTITIES':
      return REPLACE_SELECTED_ENTITIES(state, action);
    default:
      return state;
  }
}

export default selectedEntities;
