/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Root = styled.div`
  > .label {
  }

  > .dropdown {
    position: relative;
    margin: 0px;
    padding: 0px;
    &:focus {
      outline: 0px;
    }
    > .title {
      height: 22px;
      border-radius: 2px;
      border: 1px solid #A2AAAD;
      ${({ disabled, isOpen }) =>
        !disabled &&
        !isOpen &&
        `
        &:hover {
          border: 1px solid #4d4d4d;
        }
      `}
      ${({ isOpen }) =>
        isOpen &&
        `
        border-color: #2DCCD3;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      `}
      
      margin: 0px;
      padding: 0px 28px 0px 8px;
      display: block;
      font-size: 11px;
      line-height: 24px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${({ disabled, theme }) =>
        disabled &&
        `
        background-color: ${theme.semanticColors.disabledBackground}
      `}
    }

    >.prefix~.title {
      padding-left: 20px;
    }
    > .carretDownWrapper {
      position: absolute;
      top: 4px;
      right: 4px;
      ${({ disabled }) =>
        !disabled &&
        `
        cursor: pointer;
      `}
      
    }
    > .prefix {
      position: absolute;
      left: 4px;
      top 4px;
      z-index: 1;
    }
  }
`;

const DropdownItemWrapper = styled.div`
  max-height: ${({ dropDownItemWrapperMaxHeight }) => (dropDownItemWrapperMaxHeight ? `${dropDownItemWrapperMaxHeight}px` : 'auto')};
  > .dropDownSearchWrapper {
    padding: 5px 7px 3px;
  }
  > .dropDownItem {
    width: 100%;
    min-height: 32px;
    height: 32px;
    font-size: 12px;
    padding: 8px 9px;
    &.dropDownItem-selected {
      & .dropdownOptionText {
        font-weight: bold;
      }
    }
    & .dropdownOptionText {
      flex-grow: 1;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #3b3b3b;
    }

    & .dropDownSelectedIcon {
      color: #2dccd3;
    }

    &:hover {
      background: #f4f4f4;
      color: #000000;

      & .dropDownSelectedIcon {
        color: #000000;
      }
    }
  }
  > .dropdownNoOptionText {
    height: 24px;
    font-size: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > .dropdownHiddenItemsText {
    width: 100%;
    min-height: 24px;
    height: 24px;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }
`;

export { DropdownItemWrapper, Root };
