/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { MessageBarType } from '@fluentui/react';

export function showLocalNotification(message, type, timer, url) {
  const notificationId = `localNotify${Math.random(1)}`;
  return dispatch => {
    if (timer) {
      setTimeout(() => {
        dispatch(hideLocalNotification(notificationId));
      }, timer);
    }
    dispatch({
      type: 'SHOW_LOCAL_NOTIFICATION',
      localNotification: {
        message: message,
        url: url,
        visible: true,
        type: MessageBarType[type] || MessageBarType.info,
        id: notificationId,
      },
    });
  };
}

export function hideLocalNotification(notificationId) {
  return {
    type: 'HIDE_LOCAL_NOTIFICATION',
    localNotification: {
      id: notificationId,
      visible: false,
    },
  };
}
