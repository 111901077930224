/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { NotificationColor, NotificationType } from './constants';

export function createIconProps(type) {
  const style = {
    fontSize: '24px',
    color: NotificationColor[type],
  };

  switch (type) {
    case NotificationType.INFO:
      return {
        iconName: 'Callout',
        style,
      };
    case NotificationType.ERROR:
      return {
        iconName: 'WarningTriangle',
        style,
      };
    case NotificationType.SUCCESS:
      return {
        iconName: 'CompletedSolid',
        style,
      };
    case NotificationType.WARNING:
      return {
        iconName: 'WarningTriangle',
        style,
      };
    default:
      return {};
  }
}
