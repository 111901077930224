/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';
import { FontSizes } from '@fluentui/react/lib/Styling';

const DefaultButtonStyle = {
  flexContainer: {
    flexDirection: 'row-reverse',
  },
  root: {
    textAlign: 'left',
    fontSize: '14px',
    width: '100%',
    background: 'transparent',
    border: 'none',
    padding: '0 8px',
    borderRadius: 0,
    height: '24px',
  },
  rootHovered: {
    background: 'transparent',
    border: 'none',
  },
  rootPressed: {
    background: 'transparent',
    border: 'none',
  },
  textContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    fontWeight: 'bold',
    margin: 0,
    height: '16px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

const Divider = styled.div`
  opacity: 0.2;
  background: var(--text-color);
  height: 1px;
`;

const Header = styled.div`
  font-size: 16px;
  padding: 16px 10px 10px 7px;
  font-weight: bold;
`;

const ButtonContent = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const GroupExpanderRoot = styled.div`
  .aone-GroupExpander-reset {
    color: #005776;
    cursor: pointer;
    font-size: ${FontSizes.small};
    padding: 0px 4px;

    &:focus {
      outline: none;
    }
  }
`;

export { ButtonContent, DefaultButtonStyle, Divider, GroupExpanderRoot, Header };
