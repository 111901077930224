/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';

function Tab(props) {
  const { children, isVisible } = props;

  if (!children) return null;

  return (
    <div
      data-testid="content-details-tab-div"
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        visibility: isVisible ? 'visible' : 'hidden',
        overflow: 'auto',
        position: isVisible ? null : 'fixed',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
}

Tab.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isVisible: PropTypes.bool,
};

Tab.defaultProps = {
  children: null,
  isVisible: false,
};

export default Tab;
