/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import validateBoolean from './validateBoolean';
import validateColor from './validateColor';
import validateDate from './validateDate';
import validateInteger from './validateInteger';
import validateNumber from './validateNumber';
import validateString from './validateString';
import validateUnitScalar from './validateUnitScalar';

/*
 * Throw an error to invalidate.
 */
const typeValidators = {
  boolean: validateBoolean,
  color: validateColor,
  date: validateDate,
  integer: validateInteger,
  number: validateNumber,
  string: validateString,
  unitScalar: validateUnitScalar,
};

export default typeValidators;
