import React from 'react';
import { DefaultButton, Dialog, DialogFooter, DialogType } from '@fluentui/react';

import * as TextMapping from '../../../utils/textMapping';
import { useMemo } from 'react';

function LimitSelectionDialog({ hidden, onDismiss, texts, max = 10 }) {
  const dialogContentProps = useMemo(() => {
    return {
      type: DialogType.normal,
      title: TextMapping.getUIText(TextMapping.UI_TEXT_LIMIT_SELECTION_TITLE, texts),
      closeButtonAriaLabel: 'Close',
      subText: TextMapping.getUIText(TextMapping.UI_TEXT_LIMIT_SELECTION_MESSAGE, texts, { max: max }),
    };
  }, [texts, max]);

  return (
    <Dialog
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={dialogContentProps}
      modalProps={{
        isBlocking: true,
      }}
    >
      <DialogFooter>
        <DefaultButton onClick={onDismiss} text={TextMapping.getUIText(TextMapping.UI_TEXT_OK, texts)} />
      </DialogFooter>
    </Dialog>
  );
}

export default LimitSelectionDialog;
