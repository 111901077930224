/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { IconButton } from '@fluentui/react';
import PropTypes from 'prop-types';
import { Image, Slide, Slider, WithStore } from 'pure-react-carousel';
import React, { useCallback, useEffect, useRef } from 'react';
import { useUpdate } from 'react-use';
import { BrokenImage, SliderRoot } from './styled';

const NOOP = () => {};

/*
 * update() is called through out the code to force rerender the component
 * so that buttons, and thumbnil will receive the change event. This is required
 * as the pure-react-carousel doesn't emit any event on slide change
 * */
function PreviewSlider({
  carouselStore,
  noAddPreview = false,
  noDeletePreview = false,
  onAddPreviewClick = NOOP,
  onDeletePreviewClick = NOOP,
  previews,
}) {
  const update = useUpdate();
  const previewsRef = useRef(previews);

  useEffect(() => {
    if (previewsRef.current !== previews && previews.length > 0) {
      previewsRef.current = previews;
      carouselStore.setStoreState({ currentSlide: 0 });
      update();
    }
  }, [carouselStore, previews, update]);

  const handleBackClick = useCallback(() => {
    const { currentSlide, step } = carouselStore.getStoreState();
    const nextCurrentSlide = Math.max(currentSlide - step, 0);
    carouselStore.setStoreState({ currentSlide: nextCurrentSlide });
    update();
  }, [carouselStore, update]);

  const handleNextClick = useCallback(() => {
    const { currentSlide, step, totalSlides, visibleSlides } = carouselStore.getStoreState();
    const maxSlide = totalSlides - visibleSlides;
    const nextCurrentSlide = Math.min(currentSlide + step, maxSlide);
    carouselStore.setStoreState({ currentSlide: nextCurrentSlide });
    update();
  }, [carouselStore, update]);

  const handleThumbnilClick = useCallback(
    index => {
      carouselStore.setStoreState({ currentSlide: index });
      update();
    },
    [carouselStore, update]
  );

  const handleDeleteClick = useCallback(
    (e, preview) => {
      e.stopPropagation();
      onDeletePreviewClick(preview);
    },
    [onDeletePreviewClick]
  );

  const isDisabled = useCallback(
    button => {
      const { currentSlide, totalSlides, visibleSlides } = carouselStore.getStoreState();
      if (button === 'next') {
        return currentSlide >= totalSlides - visibleSlides;
      }
      return currentSlide === 0;
    },
    [carouselStore]
  );

  const { currentSlide } = carouselStore.getStoreState();

  return (
    <SliderRoot>
      <div className="Preview__Slider--Container">
        <Slider trayTag="div" className="Preview__Slider" classNameTray="Preview__SliderlTray" classNameTrayWrap="Preview__SliderTrayWrap">
          {previews.map(({ src, id }, index) => (
            <Slide
              data-testid={`Preview-Slide-${index}`}
              innerClassName="Preview__Slide-Inner"
              className="Preview__Slide"
              key={id}
              index={index}
              style={{ paddingBottom: 0 }}
              tag="div"
            >
              <Image className="Preview__Slide--Image" src={src} renderError={() => <BrokenImage>No Preview Available</BrokenImage>} />
            </Slide>
          ))}
        </Slider>
        {previews.length > 1 && (
          <>
            <IconButton
              className="Preview__SlideButton Preview__SlideButton--Left"
              data-testid="preview-slider-button-left"
              disabled={isDisabled()}
              iconProps={{ iconName: 'ChevronLeft' }}
              onClick={handleBackClick}
            />
            <IconButton
              className="Preview__SlideButton Preview__SlideButton--Right"
              data-testid="preview-slider-button-right"
              disabled={isDisabled('next')}
              iconProps={{ iconName: 'ChevronRight' }}
              onClick={handleNextClick}
            />
          </>
        )}
      </div>

      <div className="Preview__Thumbnail--Container">
        {!noAddPreview && (
          <div className="Preview__Thumbnail" onClick={onAddPreviewClick} onKeyPress={NOOP} role="presentation">
            <div className="Preview__Thumbnail--Action">
              <IconButton className="Preview__Thumbnail--AddButton" iconProps={{ iconName: 'Add' }} />
              <div className="Preview__Thumbnail--Caption">Add Preview</div>
            </div>
          </div>
        )}
        {previews.map((_preview, index) => {
          const { id, src } = _preview;
          return (
            <div
              className={`Preview__Thumbnail${currentSlide === index ? ' active' : ''}`}
              data-testid={`Preview-Thumbnail-${index}`}
              key={id}
              onClick={() => handleThumbnilClick(index)}
              onKeyPress={NOOP}
              role="presentation"
            >
              <Image
                className="Preview__Image Preview__Image--Thumbnail"
                src={src}
                renderError={() => <BrokenImage>No Preview Available</BrokenImage>}
              />
              {!noDeletePreview && (
                <IconButton
                  className="Preview__Thumbnail--Delete"
                  iconProps={{ iconName: 'Cancel' }}
                  onClick={e => handleDeleteClick(e, _preview)}
                  styles={{ root: { width: 20, height: 20 } }}
                />
              )}
            </div>
          );
        })}
      </div>
    </SliderRoot>
  );
}

PreviewSlider.propTypes = {
  carouselStore: PropTypes.object.isRequired,
  noAddPreview: PropTypes.bool,
  noDeletePreview: PropTypes.bool,
  onAddPreviewClick: PropTypes.func,
  onDeletePreviewClick: PropTypes.func,
  previews: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
      internal: PropTypes.string,
      preview: PropTypes.string,
      src: PropTypes.string,
      thumbnail: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};
/*
 * A class based component to satisfy WithStore
 * */
class PreviewSliderContainer extends React.Component {
  render() {
    return <PreviewSlider {...this.props} />;
  }
}

export default WithStore(PreviewSliderContainer);
