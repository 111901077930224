class Tools {
  /**
   * PrÃ¼ft on n ein numerischer Wert ist
   * @param n
   * @returns {boolean}
   */
  static isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /**
   * PrÃ¼ft ob zwei floats identisch sind.
   */
  static areIdentical(val1, val2) {
    let epsilon = val1 / 1e6;
    return Math.abs(val1 - val2) < epsilon;
  }

  static isDefined(v) {
    return !(typeof v === 'undefined' || v === null);
  }

  static isStringValue(v) {
    return !(typeof v === 'undefined' || v === null || v === false || v === true || ('' + v).trim().length === 0);
  }

  /**
   * PrÃ¼ft ob d eine Zehnerpotenz ist
   * @param d
   * @returns {boolean}
   */
  static powerOfTen(d) {
    while (d > 0 && d < 10) {
      d *= 10;
    }
    while (d > 9 && d % 10 === 0) {
      d /= 10;
    }
    return d === 1;
  }

  /**
   * Berechnet die Zehnerpotenz von d
   * @param d
   * @returns {number}
   */
  static pow10(d) {
    return Math.pow(10, d);
  }

  /**
   * Berechnet log10 von d
   * @param x
   * @returns {number}
   */
  static log10(x) {
    if (typeof Math.log10 !== 'undefined') return Math.log10(x);
    else return Math.log(x) / 2.302585092994046;
    // Im IE gibt es die Math.log10 Funktion nicht, bei der
    // Berechnung des Zehnerlogarithmus treten daher
    // Ungenauigkeiten auf, die spÃ¤ter dazu fÃ¼hren, dass die
    // Achsenwerte durch D3 nicht mehr richtig berechnet/
    // angezeigt werden kÃ¶nnen.
    //return Math.round(value*1e10)/1e10;
  }

  static toType(obj) {
    return {}.toString
      .call(obj)
      .match(/\s([a-zA-Z]+)/)[1]
      .toLowerCase();
  }

  static diffDays(d1, d2) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2 - t1) / (24 * 3600 * 1000));
  }

  static diffWeeks(d1, d2) {
    var t2 = d2.getTime();
    var t1 = d1.getTime();

    return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
  }

  static diffMonths(d1, d2) {
    var d1Y = d1.getFullYear();
    var d2Y = d2.getFullYear();
    var d1M = d1.getMonth();
    var d2M = d2.getMonth();

    return d2M + 12 * d2Y - (d1M + 12 * d1Y);
  }

  static diffYears(d1, d2) {
    return d2.getFullYear() - d1.getFullYear();
  }

  static btwn(a, b1, b2) {
    if (a >= b1 && a <= b2) {
      return true;
    }
    if (a >= b2 && a <= b1) {
      return true;
    }
    return false;
  }

  static line_line_intersect(line1, line2) {
    let x1 = line1.x1,
      x2 = line1.x2,
      x3 = line2.x1,
      x4 = line2.x2;
    let y1 = line1.y1,
      y2 = line1.y2,
      y3 = line2.y1,
      y4 = line2.y2;
    let pt_denom = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
    let pt_x_num = (x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4);
    let pt_y_num = (x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4);
    if (pt_denom === 0) {
      return 'parallel';
    } else {
      let pt = { x: pt_x_num / pt_denom, y: pt_y_num / pt_denom };
      if (this.btwn(pt.x, x1, x2) && this.btwn(pt.y, y1, y2) && this.btwn(pt.x, x3, x4) && this.btwn(pt.y, y3, y4)) {
        return pt;
      } else {
        return 'not in range';
      }
    }
  }

  static path_line_intersections(pathEl, line, n_segments) {
    let pts = [];
    let pathLength = pathEl.getTotalLength();
    for (let i = 0; i < n_segments; i++) {
      let pos1 = pathEl.getPointAtLength((pathLength * i) / n_segments);
      let pos2 = pathEl.getPointAtLength((pathLength * (i + 1)) / n_segments);
      let line1 = { x1: pos1.x, x2: pos2.x, y1: pos1.y, y2: pos2.y };
      let line2 = { x1: line.attr('x1'), x2: line.attr('x2'), y1: line.attr('y1'), y2: line.attr('y2') };
      let pt = this.line_line_intersect(line1, line2);
      if (typeof pt != 'string') {
        pts.push(pt);
      }
    }

    return pts;
  }
}

export { Tools };
