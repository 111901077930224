/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React from 'react';
import { Slide, toast, ToastContainer } from 'react-toastify';
import '../notification-alert.css';

function NotificationAlertContainer() {
  return (
    <ToastContainer
      bodyClassName="Notification-body"
      className="NotificationAlert-container"
      toastClassName="Notification"
      closeButton={false}
      closeOnClick={false}
      hideProgressBar
      position={toast.POSITION.BOTTOM_RIGHT}
      transition={Slide}
      containerId="UP-NotificationContainer"
    />
  );
}

export default NotificationAlertContainer;
