/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { v4 as uuidv4 } from 'uuid';


export function addNotificationEntities(entities) {
  return {
    type: 'ADD_NOTIFICATION_ENTITIES',
    entities,
  };
}

export function addNotificationEntity(entity) {
  return {
    type: 'ADD_NOTIFICATION_ENTITY',
    entity,
  };
}

export function addNotificationFailEntity({ id, message, toEntities = [] } = {}) {
  if (typeof message !== 'string') {
    throw new TypeError('message must be a string');
  }

  if (!Array.isArray(toEntities)) {
    throw new TypeError('toEntities must be an array');
  }

  const entity = {
    id: id || uuidv4(),
    attributes: {
      message,
      time: Date.now(),
      type: 'FAIL',
    },
    toEntities,
  };

  return {
    type: 'ADD_NOTIFICATION_ENTITY',
    entity,
  };
}

export function addNotificationInfoEntity({ id, message, toEntities = [] } = {}) {
  if (typeof message !== 'string') {
    throw new TypeError('message must be a string');
  }

  if (!Array.isArray(toEntities)) {
    throw new TypeError('toEntities must be an array');
  }

  const entity = {
    id: id || uuidv4(),
    attributes: {
      message,
      time: Date.now(),
      type: 'INFO',
    },
    toEntities,
  };

  return {
    type: 'ADD_NOTIFICATION_ENTITY',
    entity,
  };
}

export function addNotificationSuccessEntity({ id, message, toEntities = [] } = {}) {
  if (typeof message !== 'string') {
    throw new TypeError('message must be a string');
  }

  if (!Array.isArray(toEntities)) {
    throw new TypeError('toEntities must be an array');
  }

  const entity = {
    id: id || uuidv4(),
    attributes: {
      message,
      time: Date.now(),
      type: 'SUCCESS',
    },
    toEntities,
  };

  return {
    type: 'ADD_NOTIFICATION_ENTITY',
    entity,
  };
}

export function updateNotificationEntity(entity) {
  return {
    type: 'UPDATE_NOTIFICATION_ENTITY',
    entity,
  };
}
