import React, { useCallback } from 'react';
import { TextField } from '@fluentui/react';

function AdvancedSearchTextField({
  child,
  type,
  getPropValue,
  getComponentKey,
  placeholder,
  triggerSearch,
  handleKeyPress,
  onValueChange,
}) {
  const onBlur = useCallback(() => {
    triggerSearch(child, type);
  }, [triggerSearch, child, type]);

  const onKeyPress = useCallback(
    (event, _value) => {
      handleKeyPress(event, child, type);
    },
    [handleKeyPress, child, type]
  );

  const onChange = useCallback(
    (_event, value) => {
      onValueChange(child, type, value);
    },
    [onValueChange, child, type]
  );

  return (
    <TextField
      key={getComponentKey(child, type)}
      value={getPropValue(child, type)}
      placeholder={placeholder}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      onChange={onChange}
    />
  );
}

export default AdvancedSearchTextField;
