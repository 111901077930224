/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import get from 'lodash/get';

export function createRefetchPolicy(data) {
  const entity = get(data, 'entity');

  if (!entity) {
    return {};
  }

  const ids = [entity.id];

  return {
    entityUpdate: ids,
    previewChange: ids,
    mdeStatusChange: ids,
  };
}
