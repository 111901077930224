/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { Dropdown } from '@fluentui/react/lib/Dropdown';
import { TextField } from '@fluentui/react/lib/TextField';
import { Checkbox, MessageBarType, IconButton } from '@fluentui/react';
import { ComboBox } from '@fluentui/react';
import { MessageBar } from '@fluentui/react';
import { Link } from '@fluentui/react';

import { Margin5 } from '../../pages/styles';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { isEmpty, isUndefined } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BBPageComponent from '../BBPageComponent';

import * as actions from '../../actions';

import Plot from '../plot';

import * as TextMapping from '../../utils/textMapping';
import '../buildingBlockStyles.css';
import { setupDiagram, onDoubleClick, onRelayout } from '../processBuildingBlock';
import { usePluginListener } from '../../utils/plugin';
import { convertUnit } from '../../utils/units';
import { setupWebSocketConnection, getWebsocketClient } from '../../websocket';

const mapStateToProps = (state) => {
  return {
    roots: state.roots,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

const resetDownloadStatus = function () {
  return {
    message: undefined,
    status: undefined,
    url: undefined,
    mode: 'local',
  };
};

const DownloadCardForm = (props) => {
  const dropdownStyles = useMemo(() => {
    return { dropdown: { width: 200 }, dropdownOptionText: { fontSize: '10px' } };
  }, []);

  const [reductionErrorMessage, setReductionErrorMessage] = useState();
  const [stressLimitErrorMessage, setStressLimitErrorMessage] = useState();

  const [downloadDisabled, setDownloadDisabled] = useState(true);
  const [solverDisabled, setSolverDisabled] = useState(false);
  const [modelDisabled, setModelDisabled] = useState(true);
  const [unitSystemDisabled, setUnitSystemDisabled] = useState(true);
  const [startNumberDisabled, setStartNumberDisabled] = useState(true);
  const [reductionFactorDisabled, setReductionFactorDisabled] = useState(true);
  const [moistureStateDisabled, setMoistureStateDisabled] = useState(true);
  const [includeGenericDataDisabled, setIncludeGenericDataDisabled] = useState(true);
  const [temperatureDisabled, setTemperatureDisabled] = useState(true);
  const [diagramDisabled, setDiagramDisabled] = useState(true);

  const [engTrueStressStrainDisabled, setEngTrueStressStrainDisabled] = useState(true);
  const [poissonsRatioDisabled, setPoissonsRatioDisabled] = useState(true);
  const [stressLimitDisabled, setStressLimitDisabled] = useState(true);
  const [previewDisabled, setPreviewDisabled] = useState(true);
  const [plotsDisabled, setPlotsDisabled] = useState(true);
  const [encryptDisabled, setEncryptDisabled] = useState(true);
  const [invalidDataDisabled, setInvalidDataDisabled] = useState(true);
  const [previewCollapsible, setPreviewCollapsible] = useState(false);
  const [previewCollapsed, setPreviewCollapsed] = useState(false);

  const [model, setModel] = useState();
  const [solver, setSolver] = useState();
  const [unit, setUnit] = useState();
  const [moistureState, setMoistureState] = useState();
  const [startNumber, setStartNumber] = useState();
  const [reductionFactor, setReductionFactor] = useState();
  const [submittedReductionFactor, setSubmittedReductionFactor] = useState();
  const [engTrueStressStrain, setEngTrueStressStrain] = useState();
  const [temperature, setTemperature] = useState();
  const [diagram, setDiagram] = useState();

  const [stressLimit, setStressLimit] = useState();
  const [poissonsRatio, setPoissonsRatio] = useState();
  const [includeGeneric, setIncludeGeneric] = useState(false);
  const [previousIncludeGeneric, setPreviousIncludeGeneric] = useState(false);
  const [encrypted, setEncrypted] = useState(false);
  const [tempMulti, setTempMulti] = useState(false);
  const [tempPlaceholder, setTempPlaceholder] = useState(null);
  const [temperatureRequired, setTemperatureRequired] = useState(false);
  const [diagramRequired, setDiagramRequired] = useState(false);
  const [diagramPlaceholder, setDiagramPlaceholder] = useState(null);

  const [modelOptions, setModelOptions] = useState([]);
  const [solverOptions, setSolverOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [moistureStateOptions, setMoistureStateOptions] = useState([]);
  const [reductionFactorOptions, setReductionFactorOptions] = useState();
  const [engTrueStressStrainOptions, setEngTrueStressStrainOptions] = useState();
  const [temperatureOptions, setTemperatureOptions] = useState();
  const [diagramOptions, setDiagramOptions] = useState();

  const [allStressLimitOptions, setAllStressLimitOptions] = useState();
  const [stressLimitOptions, setStressLimitOptions] = useState();
  const [previewStyle, setPreviewStyle] = useState();

  const [stressLimitMin, setStressLimitMin] = useState();
  const [stressLimitMax, setStressLimitMax] = useState();

  const [reductionFactorMin, setReductionFactorMin] = useState();
  const [reductionFactorMax, setReductionFactorMax] = useState();

  const includeGenericData = React.createRef();
  const solverDropdown = React.createRef();
  const modelDropdown = React.createRef();
  const unitDropdown = React.createRef();
  const moistureStateDropdown = React.createRef();
  const startNumberTextField = React.createRef();
  const reductionFactorDropdown = React.createRef();
  const engTrueStressStrainDropdown = React.createRef();
  const temperatureDropdown = React.createRef();
  const diagramDropdown = React.createRef();

  const multiTemperatureDropdown = React.createRef();
  const poissonsRatioTextField = React.createRef();
  const stressLimitDropdown = React.createRef();

  const [reductionFactorLabel, setReductionFactorLabel] = useState('Reduction Factor [%]');
  const [engTrueStressStrainLabel, setEngTrueStressStrainLabel] = useState('Eng / True Stress-Strain');
  const [startNumberLabel, setStartNumberLabel] = useState('Material ID');
  const [temperatureLabel, setTemperatureLabel] = useState('Select Temperature');
  const [diagramLabel, setDiagramLabel] = useState('Select Diagram');

  const [componentStyleMap, setComponentStyleMap] = useState(new Map());

  const [poissonsRatioLabel, setPoissonsRatioLabel] = useState("Poisson's Ratio");
  const [stressLimitLabel, setStressLimitLabel] = useState('Stress Limit MPa');
  const [previewLabel, setPreviewLabel] = useState('Preview');

  const [stressStrainPlotData, setStressStrainPlotData] = useState();
  const [trueStressStrainPlotData, setTrueStressStrainPlotData] = useState();
  const [caeStressStrainPlotData, setCAEStressStrainPlotData] = useState();

  const [selectedDiagramPlotData, setSelectedDiagramPlotData] = useState();
  const [selectedDiagramCatalog, setSelectedDiagramCatalog] = useState();

  const [stressStrainCatalog, setStressStrainCatalog] = useState();
  const [trueStressStrainCatalog, setTrueStressStrainCatalog] = useState();
  const [caeStressStrainCatalog, setCAEStressStrainCatalog] = useState();
  const [caeDownloadStatus, setCAEDownloadStatus] = useState(resetDownloadStatus());

  const [permission, setPermission] = useState('ui.caecards');
  const [modelPermission, setModelPermission] = useState('ui.caecards');
  const [softwarePermission, setSoftwarePermission] = useState('ui.caecards');

  const [restrictedPage, setRestrictedPage] = useState('');
  const [modelRestrictedPage, setModelRestrictedPage] = useState('');
  const [softwareRestrictedPage, setSoftwareRestrictedPage] = useState('');

  const [layoutMap, setLayoutMap] = useState(new Map());
  const [pluginMessage, setPluginMessage] = useState();
  const [temperatureUnits, setTemperatureUnits] = useState();

  const {
    onDownloadClick,
    details,
    webSocket,
    roots,
    fetchPlots,
    handleNoDriveLicenseClick,
    clearCaeDownloadStatus,
    resetCaeDownloadPreview,
    getBBPageForComponent,
    getDiagram,
    getNotifications,
  } = props;
  const texts = roots.texts;
  const bbPageForComponent = roots.bbPageForComponent;
  const appContent = roots.appContent;
  const language = roots.language;
  const units = roots.units;

  /*useEffect(() => {
    getSolversForMaterials(materialId);
  }, [materialId, getSolversForMaterials]);
  */
  const [downloadBtnLabel, setDownloadLabel] = useState('Download');

  const findUnit = useCallback((units, baseUnit) => {
    for (let unit of units) {
      if (unit.shortname === baseUnit) {
        return unit.catalog?.unitsystems;
      } else if (unit.children && unit.children.length) {
        let result = findUnit(unit.children, baseUnit);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }, []);

  useEffect(() => {
    if (units) {
      let temperatureUnit = findUnit(units, '°C');
      setTemperatureUnits(temperatureUnit);
    }
  }, [units, findUnit]);

  useEffect(() => {
    if (!roots.caeDownloadStatus) {
      return;
    }
    let notificationType = MessageBarType.info;
    const status = roots.caeDownloadStatus.status;

    if (status === true) {
      notificationType = MessageBarType.success;
    } else if (typeof status !== 'undefined' && status !== 'progress') {
      notificationType = MessageBarType.error;
    }

    setCAEDownloadStatus({
      ...roots.caeDownloadStatus,
      notificationType: notificationType,
    });

    if (roots.caeDownloadStatus.status !== 'progress') {
      getNotifications();
      setTimeout(() => {
        setCAEDownloadStatus(resetDownloadStatus());
      }, 3000);
    }

    if (roots.caeDownloadStatus.status !== 'success') {
      clearCaeDownloadStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roots.caeDownloadStatus, clearCaeDownloadStatus]);

  usePluginListener(appContent, [{ type: 'notification', callback: setPluginMessage }]);

  useEffect(() => {
    if (details.moisturedep) {
      setMoistureStateOptions([
        { key: 'dry', text: 'Dry' },
        { key: 'cond', text: 'Conditioned' },
      ]);
      setMoistureStateDisabled(false);
      setSolverDisabled(true);
      let oldMoistureState = JSON.parse(sessionStorage.getItem('download-card-form-moisture-state') || 'null');
      if (oldMoistureState) {
        setMoistureState(oldMoistureState);
      } else {
        setMoistureState();
      }
    } else {
      setMoistureStateDisabled(true);
      setSolverDisabled(false);
    }
    if (details.hasgenericdata) {
      setIncludeGenericDataDisabled(false);
      let oldIncludeGeneric = JSON.parse(sessionStorage.getItem('download-card-form-include-generic') || 'null');
      if (!isUndefined(oldIncludeGeneric)) {
        setIncludeGeneric(oldIncludeGeneric);
      }
    } else {
      setIncludeGenericDataDisabled(true);
    }
    if (details.restricted) {
      setPermission(details.restricted.permission);
      setRestrictedPage(details.restricted.page);
    }
  }, [details]);

  useEffect(() => {
    if (
      roots.CAEPlots &&
      roots.CAEPlots.success &&
      roots.CAEPlots.StressStrain &&
      roots.CAEPlots.TrueStressStrain &&
      roots.CAEPlots.CAEStressStrain
    ) {
      let stressStrainDiagram = setupDiagram(roots.CAEPlots.StressStrain.catalog, roots.CAEPlots.StressStrain, layoutMap);
      let trueStressStrainDiagram = setupDiagram(roots.CAEPlots.TrueStressStrain.catalog, roots.CAEPlots.TrueStressStrain, layoutMap);
      let caeStressStrainDiagram = setupDiagram(roots.CAEPlots.CAEStressStrain.catalog, roots.CAEPlots.CAEStressStrain, layoutMap);

      setStressStrainPlotData(stressStrainDiagram);
      setTrueStressStrainPlotData(trueStressStrainDiagram);
      setCAEStressStrainPlotData(caeStressStrainDiagram);

      setStressStrainCatalog(roots.CAEPlots.StressStrain.catalog);
      setTrueStressStrainCatalog(roots.CAEPlots.TrueStressStrain.catalog);
      setCAEStressStrainCatalog(roots.CAEPlots.CAEStressStrain.catalog);
      setPlotsDisabled(false);
      setInvalidDataDisabled(true);
    } else if (roots.CAEPlots && !isEmpty(roots.CAEPlots) && !roots.CAEPlots.success) {
      setPlotsDisabled(true);
      setInvalidDataDisabled(false);
      setDownloadDisabled(true);
    }
  }, [roots.CAEPlots, layoutMap]);

  useEffect(() => {
    if (roots.CAESelectedDiagram && roots.CAESelectedDiagram.success) {
      let selectedDiagram = setupDiagram(roots.CAESelectedDiagram.data.catalog, roots.CAESelectedDiagram.data, layoutMap);
      setSelectedDiagramPlotData(selectedDiagram);
      setSelectedDiagramCatalog(roots.CAESelectedDiagram.data.catalog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roots.CAESelectedDiagram]);

  useEffect(() => {
    if (diagram) {
      getDiagram(diagram, roots.unitSystem, roots.language);
    } else {
      setSelectedDiagramPlotData(null);
      setSelectedDiagramCatalog({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diagram, roots.unitSystem, roots.language]);

  useEffect(() => {
    if (engTrueStressStrain && unit && typeof temperature !== 'undefined' && stressLimit && model) {
      if (
        !(submittedReductionFactor < reductionFactorMin || submittedReductionFactor > reductionFactorMax) &&
        !(stressLimit < stressLimitMin || stressLimit > stressLimitMax)
      ) {
        let tempData = null;
        for (let option of temperatureOptions) {
          if (option.key === temperature) {
            tempData = option;
            break;
          }
        }
        fetchPlots(tempData, submittedReductionFactor, stressLimit, unit.key, roots.language);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    engTrueStressStrain,
    temperature,
    stressLimit,
    submittedReductionFactor,
    unit,
    roots.language,
    fetchPlots,
    model,
    reductionFactorMin,
    reductionFactorMax,
    stressLimitMin,
    stressLimitMax,
    temperatureOptions,
  ]);

  // update the download label text
  useEffect(() => {
    let websocketclient = getWebsocketClient();
    if (!webSocket && !websocketclient && typeof websocketclient !== 'object') {
      websocketclient = setupWebSocketConnection(props);
    }
    let inspireApp = window.location.pathname.split('/').pop();
    if (websocketclient && inspireApp) {
      switch (inspireApp.toLowerCase()) {
        case 'otherApp':
          setDownloadLabel(TextMapping.getUIText(TextMapping.UI_TEXT_DOWNLOAD_TO_OTHER_APP, texts));
          break;

        default:
          setDownloadLabel(TextMapping.getUIText(TextMapping.UI_TEXT_SAVE_TO_MY_MATERIALS, texts));
          break;
      }
    } else {
      setDownloadLabel(TextMapping.getUIText(TextMapping.UI_TEXT_DOWNLOAD, texts));
    }
  }, [webSocket, texts, props]);

  useEffect(() => {
    if (!isEmpty(solver)) {
      sessionStorage.setItem('download-card-form-solver', JSON.stringify(solver));
    }
  }, [solver]);

  useEffect(() => {
    if (!isEmpty(model)) {
      sessionStorage.setItem('download-card-form-model', JSON.stringify(model));
    }
  }, [model]);

  useEffect(() => {
    if (!isEmpty(unit)) {
      sessionStorage.setItem('download-card-form-unit', JSON.stringify(unit));
    }
  }, [unit]);

  useEffect(() => {
    if (!isUndefined(moistureState)) {
      sessionStorage.setItem('download-card-form-moisture-state', JSON.stringify(moistureState));
    }
  }, [moistureState]);

  useEffect(() => {
    if (!isUndefined(startNumber)) {
      sessionStorage.setItem('download-card-form-start-number', JSON.stringify(startNumber));
    }
  }, [startNumber]);

  useEffect(() => {
    if (!isUndefined(reductionFactor)) {
      sessionStorage.setItem('download-card-form-reduction-factor', JSON.stringify(reductionFactor));
    }
  }, [reductionFactor]);

  useEffect(() => {
    if (!isUndefined(submitReductionFactor)) {
      sessionStorage.setItem('download-card-form-submitted-reduction-factor', JSON.stringify(submittedReductionFactor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedReductionFactor]);

  useEffect(() => {
    if (!isUndefined(temperature)) {
      sessionStorage.setItem('download-card-form-temperature', JSON.stringify(temperature));
    }
  }, [temperature]);

  useEffect(() => {
    if (!isUndefined(diagram)) {
      sessionStorage.setItem('download-card-form-diagram', JSON.stringify(diagram));
    }
  }, [diagram]);

  useEffect(() => {
    if (!isEmpty(engTrueStressStrain)) {
      sessionStorage.setItem('download-card-form-eng-true-stress-strain', JSON.stringify(engTrueStressStrain));
    }
  }, [engTrueStressStrain]);

  useEffect(() => {
    if (!isUndefined(poissonsRatio)) {
      sessionStorage.setItem('download-card-form-poissons-ratio', JSON.stringify(poissonsRatio));
    }
  }, [poissonsRatio]);

  useEffect(() => {
    if (!isUndefined(stressLimit)) {
      sessionStorage.setItem('download-card-form-stress-limit', JSON.stringify(stressLimit));
    }
  }, [stressLimit]);

  useEffect(() => {
    if (!isUndefined(includeGeneric)) {
      sessionStorage.setItem('download-card-form-include-generic', JSON.stringify(includeGeneric));
    }
  }, [includeGeneric]);

  useEffect(() => {
    if (temperatureUnits) {
      let options = [];
      /*
    if (supportedApplications) {
      supportedApplications.forEach(application => {
        let option = { key: application.key, text: application.text, category: 'Application' };
        options.push(option);
      });
    }

    if (solvers) {
      Object.keys(solvers).forEach(solverKey => {
        let option = { key: solverKey, text: solverKey, category: 'Solver' };
        options.push(option);
      });
    }
    */

      //setSolverOptions(options);

      options = createSolverOptions();
      resetOptions();

      let oldSolver = JSON.parse(sessionStorage.getItem('download-card-form-solver') || '{}');

      if (!isEmpty(oldSolver)) {
        for (let option of options) {
          if (option.key === oldSolver.key) {
            handleSolverDropdownChange(null, option);
            break;
          }
        }
      }

      if (options.length === 1) {
        handleSolverDropdownChange(null, options[0]);
        setSolverDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, moistureState, temperatureUnits]);

  function resetOptions() {
    setSolver();
    setModel();
    setUnit();
    setStartNumber();
    setReductionFactor();
    setSubmittedReductionFactor();
    setReductionFactorOptions([]);
    setEngTrueStressStrain();
    setStressLimit();
    setPoissonsRatio();
    setTemperature();
    setDiagram();
    // setIncludeGeneric(false);
    setEncrypted(false);
    setStartNumberDisabled(true);
    setReductionFactorDisabled(true);
    setEngTrueStressStrainDisabled(true);
    setStressLimitDisabled(true);
    setPoissonsRatioDisabled(true);
    setTemperatureDisabled(true);
    setDiagramDisabled(true);

    setInvalidDataDisabled(true);
    setPreviewDisabled(true);
    setPreviewCollapsed(false);
    setPreviewCollapsible(false);

    setModelOptions([]);
    setUnitOptions([]);
    setReductionFactorOptions([]);
    setEngTrueStressStrainOptions([]);
    setStressLimitOptions([]);
    setTemperatureOptions([]);
    setDiagramOptions([]);
    setAllStressLimitOptions([]);
    setPreviewStyle('');

    setModelDisabled(true);
    setUnitSystemDisabled(true);
    setDownloadDisabled(true);
    setPlotsDisabled(true);

    setTempMulti(false);
    setTempPlaceholder(null);
    setDiagramPlaceholder(null);
  }

  function createSolverOptions() {
    let options = [];

    if (details && details.content) {
      for (let software of details.content) {
        if (!details.moisturedep || (software.data.moisture && moistureState && software.data.moisture.includes(moistureState.key))) {
          if (includeGeneric || !software.linked) {
            let option = {
              key: software.data.name,
              vkey: software.data.vkey,
              text: software.data.name,
              children: software.data.content,
              moisture: software.data.moisture,
              encrypt: software.data.encrypt,
              restricted: software.data.restricted,
              category: 'Solver',
            };
            options.push(option);
          }
        }
      }
    }

    setSolverOptions(options);

    return options;
  }

  useEffect(() => {
    if (includeGeneric !== previousIncludeGeneric) {
      let options = createSolverOptions();
      if (solver) {
        for (let option of options) {
          if (solver.key === option.key) {
            // found the solver, so we don't need to reset it
            let modelOptions = handleSolverDropdownChange(null, solver);

            if (model) {
              for (let modelOption of modelOptions) {
                if (model.key === modelOption.key) {
                  handleModelDropdownChange(null, model);
                  return;
                }
              }
            } else {
              return;
            }
          }
        }
      }

      resetOptions();

      if (options.length === 1) {
        handleSolverDropdownChange(null, options[0]);
        setSolverDisabled(true);
      }

      setPreviousIncludeGeneric(includeGeneric);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeGeneric, previousIncludeGeneric]);

  if (
    downloadDisabled &&
    model &&
    solver &&
    (!diagramRequired || (diagramRequired && typeof diagram !== 'undefined')) &&
    (!temperatureRequired ||
      (temperatureRequired && typeof temperature !== 'undefined' && !Array.isArray(temperature)) ||
      (temperatureRequired && Array.isArray(temperature) && !isEmpty(temperature))) &&
    (unit || unitOptions.length === 0) &&
    roots.perms.includes(permission) &&
    (!(details.type && details.type.moistdep) || (details.type && details.type.moistdep && moistureState)) &&
    (reductionFactorDisabled || (!reductionFactorDisabled && submittedReductionFactor)) &&
    invalidDataDisabled &&
    ((!stressLimitDisabled && !(stressLimit < stressLimitMin || stressLimit > stressLimitMax)) || stressLimitDisabled) &&
    ((!reductionFactorDisabled && !(submittedReductionFactor < reductionFactorMin || submittedReductionFactor > reductionFactorMax)) ||
      reductionFactorDisabled) &&
    ((!reductionFactorDisabled && !(reductionFactor < reductionFactorMin || reductionFactor > reductionFactorMax)) ||
      reductionFactorDisabled)
  ) {
    setDownloadDisabled(false);
  }

  useEffect(() => {
    if (!downloadDisabled && !previewDisabled) {
      onDownloadClick(
        model,
        solver,
        unit,
        startNumber,
        moistureState,
        submittedReductionFactor,
        temperature,
        poissonsRatio,
        engTrueStressStrain,
        stressLimit,
        includeGeneric,
        encrypted,
        diagram,
        'preview'
      );
    } else {
      resetCaeDownloadPreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    downloadDisabled,
    model,
    solver,
    unit,
    startNumber,
    moistureState,
    submittedReductionFactor,
    temperature,
    poissonsRatio,
    engTrueStressStrain,
    stressLimit,
    includeGeneric,
    encrypted,
    onDownloadClick,
    resetCaeDownloadPreview,
    previewDisabled,
    diagram,
  ]);

  //setting the buttonDisable state so rerender can happen and the condition stated above, handles the buttom disability
  useEffect(() => {
    if (
      (!temperatureDisabled && temperatureRequired && (typeof temperature === 'undefined' || isEmpty(temperature))) ||
      (!diagramDisabled && diagramRequired && typeof diagram === 'undefined') ||
      (!stressLimitDisabled && (stressLimit < stressLimitMin || stressLimit > stressLimitMax)) ||
      (!reductionFactorDisabled && (submittedReductionFactor < reductionFactorMin || submittedReductionFactor > reductionFactorMax)) ||
      (!reductionFactorDisabled && (reductionFactor < reductionFactorMin || reductionFactor > reductionFactorMax))
    ) {
      setDownloadDisabled(true);
    }
  }, [
    reductionFactor,
    stressLimitDisabled,
    stressLimit,
    stressLimitMin,
    stressLimitMax,
    reductionFactorDisabled,
    submittedReductionFactor,
    reductionFactorMin,
    reductionFactorMax,
    temperature,
    temperatureRequired,
    temperatureDisabled,
    diagram,
    diagramDisabled,
    diagramRequired,
  ]);
  useEffect(() => {
    let stressLimitOptions = [];

    if (allStressLimitOptions && temperature) {
      for (let v of allStressLimitOptions) {
        if (v.z === temperature) {
          for (let value of v.values) {
            let option = { key: value.key, text: value.name };
            stressLimitOptions.push(option);
          }
        }
      }

      setStressLimitOptions(stressLimitOptions);
    }
  }, [temperature, allStressLimitOptions]);

  function handleModelDropdownChange(e, value) {
    let inputs = value.inputs;
    setStartNumberDisabled(true);
    setReductionFactorDisabled(true);
    setPoissonsRatioDisabled(true);
    setEngTrueStressStrainDisabled(true);
    setTemperatureDisabled(true);
    setDiagramDisabled(true);
    setStressLimitDisabled(true);
    setInvalidDataDisabled(true);
    setPreviewDisabled(true);
    setPreviewCollapsed(false);
    setPreviewCollapsible(false);
    setPlotsDisabled(true);
    setEngTrueStressStrain();
    setTemperature();
    setTemperatureRequired(false);
    setDiagram();
    setDiagramRequired(false);
    setStressLimit();
    setReductionFactor();
    setSubmittedReductionFactor();
    setPoissonsRatio();
    setEncrypted(false);
    setTempMulti(false);
    setTempPlaceholder(null);
    setDiagramPlaceholder(null);
    setCAEDownloadStatus({ downloadMessage: '' });

    if (value.restricted) {
      setModelPermission(value.restricted.permission);
      setModelRestrictedPage(value.restricted.page);
    } else {
      setModelPermission('ui.caecards');
      setModelRestrictedPage('');
    }

    let newComponentStyleMap = new Map();

    if (inputs) {
      for (let input of inputs) {
        if (input.style) {
          newComponentStyleMap.set(input.key, input.style);
        }

        if (input.key === 'startNumber') {
          if (input.label) {
            setStartNumberLabel(input.label);
          }
          setStartNumber(input.default);

          let oldStartNumber = JSON.parse(sessionStorage.getItem('download-card-form-start-number') || 'null');
          if (oldStartNumber) {
            setStartNumber(oldStartNumber);
          }

          setStartNumberDisabled(false);
        } else if (input.key === 'includegenericdata') {
          setIncludeGenericDataDisabled(false);
        } else if (input.key === 'reduction') {
          if (input.label) {
            setReductionFactorLabel(input.label);
          }

          if (input.default) {
            setReductionFactor(input.default);
            setSubmittedReductionFactor(input.default);
          }

          let oldReductionFactor = JSON.parse(sessionStorage.getItem('download-card-form-reduction-factor') || 'null');
          if (oldReductionFactor) {
            setReductionFactor(oldReductionFactor);
          }

          setReductionFactorMin(input.min);
          setReductionFactorMax(input.max);
          setReductionFactorDisabled(false);
        } else if (input.key === 'engTrueStressStrain') {
          if (input.label) {
            setEngTrueStressStrainLabel(input.label);
          }

          let options = [];

          for (let value of input.value) {
            let option = { key: value.vkey, text: value.name };
            options.push(option);

            if (input.default && input.default === value.vkey) {
              setEngTrueStressStrain(option);
            }
          }

          let oldEngTrueStressStrain = JSON.parse(sessionStorage.getItem('download-card-form-eng-true-stress-strain') || '{}');
          if (!isEmpty(oldEngTrueStressStrain)) {
            for (let option of options) {
              if (option.key === oldEngTrueStressStrain.key) {
                setEngTrueStressStrain(option);
                break;
              }
            }
          }

          setEngTrueStressStrainOptions(options);

          setEngTrueStressStrainDisabled(false);
        } else if (input.key === 'selecttemperature') {
          if (input.label) {
            setTemperatureLabel(input.label);
          }

          if (input.multiple) {
            setTempMulti(true);
          }

          if (input.placeholder) {
            setTempPlaceholder(input.placeholder);
          }

          let tempOptions = [];

          for (let value of input.value) {
            let option = { key: value.legend, text: value.legend + ' ' + value.unit, data: value };
            if (value.type === 'original' || includeGeneric) {
              if (!details.moisturedep || value.moisture === moistureState.key) {
                tempOptions.push(option);
              }
            }

            if (input.default && value.legend === input.default) {
              if (input.multiple) {
                setTemperature([option.key]);
              } else {
                setTemperature(option.key);
              }
            }
          }

          let oldTemperature = JSON.parse(sessionStorage.getItem('download-card-form-temperature') || 'null');

          if (Array.isArray(oldTemperature)) {
            let newTemperatures = [];
            for (let temperature of oldTemperature) {
              for (let option of tempOptions) {
                if (option.key === temperature) {
                  newTemperatures.push(option.key);
                  break;
                }
              }
            }

            setTemperature(newTemperatures);
          } else {
            for (let option of tempOptions) {
              if (option.key === oldTemperature) {
                setTemperature(option.key);
              }
            }
          }

          if (tempOptions.length === 1) {
            setTemperature(tempOptions[0].key);
          }

          if (input.required) {
            setTemperatureRequired(true);
          }
          setTemperatureOptions(tempOptions);

          setTemperatureDisabled(false);
        } else if (input.key === 'selectdiagram') {
          if (input.label) {
            setDiagramLabel(input.label);
          }

          if (input.placeholder) {
            setDiagramPlaceholder(input.placeholder);
          }

          let diagramOptions = [];

          for (let value of input.value) {
            let option = { key: value.key, text: value.name };

            diagramOptions.push(option);

            if ((input.default || input.default === 0) && value.key === input.default) {
              setDiagram(option.key);
            }
          }

          let oldDiagram = JSON.parse(sessionStorage.getItem('download-card-form-diagram') || 'null');
          if (oldDiagram) {
            for (let option of diagramOptions) {
              if (option.key === oldDiagram) {
                setDiagram(option.key);
                break;
              }
            }
          }

          if (diagramOptions.length === 1) {
            setDiagram(diagramOptions[0].key);
          }

          if (input.required) {
            setDiagramRequired(true);
          }
          setDiagramOptions(diagramOptions);

          setDiagramDisabled(false);
        } else if (input.key === 'poissonsratio') {
          if (input.label) {
            setPoissonsRatioLabel(input.label);
            if (input.value && input.value.length) {
              setPoissonsRatio(input.value[0].value);
            }
            setPoissonsRatioDisabled(false);
          }
        } else if (input.key === 'stresslimit') {
          if (input.label) {
            setStressLimitLabel(input.label);
          }

          setStressLimitMin(input.min);
          setStressLimitMax(input.max);

          if (input.default) {
            setStressLimit(input.default.toString());
          }

          let oldStressLimit = JSON.parse(sessionStorage.getItem('download-card-form-stress-limit') || 'null');
          if (oldStressLimit) {
            setStressLimit(oldStressLimit);
          }

          setAllStressLimitOptions(input.value);

          setStressLimitDisabled(false);
        } else if (input.type === 'preview') {
          setPreviewDisabled(false);
          setPreviewStyle(input.style);
          setPreviewLabel(input.label);
          if (input.collapsible) {
            setPreviewCollapsible(true);
          }
          if (input.collapsed) {
            setPreviewCollapsed(true);
          }
        }
      }
    }

    setComponentStyleMap(newComponentStyleMap);
    setModel(value);
  }

  function handleMoistureStateDropdownChange(e, value) {
    setMoistureState(value);
    setSolverDisabled(false);
  }

  function handleSolverDropdownChange(e, value) {
    setSolver(value);
    setModel();
    setUnit();
    setEngTrueStressStrain();
    setTemperature();
    setDiagram();
    setStressLimit();
    setReductionFactor();
    setSubmittedReductionFactor();
    setPoissonsRatio();
    setStartNumber();
    setReductionFactorOptions([]);
    setStartNumberDisabled(true);
    setReductionFactorDisabled(true);
    setEngTrueStressStrainDisabled(true);
    setTemperatureDisabled(true);
    setTemperatureRequired(false);
    setDiagramDisabled(true);
    setDiagramRequired(false);
    setPoissonsRatioDisabled(true);
    setStressLimitDisabled(true);
    setInvalidDataDisabled(true);
    setPlotsDisabled(true);
    setPreviewDisabled(true);
    setPreviewCollapsed(false);
    setPreviewCollapsible(false);
    setEncrypted(false);
    setTempMulti(false);
    setTempPlaceholder(null);
    setDiagramPlaceholder(null);

    if (value.encrypt) {
      setEncryptDisabled(false);
    } else {
      setEncryptDisabled(true);
    }

    if (value.restricted) {
      setSoftwarePermission(value.restricted.permission);
      setSoftwareRestrictedPage(value.restricted.page);
    } else {
      setSoftwarePermission('ui.caecards');
      setSoftwareRestrictedPage('');
    }

    let options = [];
    let unitSystemOptions = [];

    if (value.children && value.children.length > 0) {
      for (let child of value.children) {
        if (
          !details.moisturedep ||
          (child.data.moisture && moistureState && child.data.moisture.includes(moistureState.key)) ||
          (!child.data.moisture && value.moisture && moistureState && value.moisture.includes(moistureState.key))
        ) {
          if (child.type === 'solver') {
            for (let model of child.data.content) {
              if (includeGeneric || !child.linked) {
                options.push({ key: model.data.vkey, text: child.data.name, inputs: model.data.inputs, restricted: model.data.restricted });
              }

              for (let unitSystem of model.data.content) {
                let option = { key: unitSystem.vkey, text: unitSystem.name };
                if (unitSystemOptions.filter((e) => e.key === unitSystem.vkey).length === 0) {
                  unitSystemOptions.push(option);
                  if (unitSystem.usemask && unitSystem.usemask.includes(212)) {
                    // Set default
                    setUnit(option);
                  }
                }
              }
            }
          } else {
            options.push({ key: child.data.vkey, text: child.data.name, inputs: child.data.inputs, children: child.data.content });
            for (let unitSystem of child.data.content) {
              if (unitSystem.vtype === 'Unitsystem') {
                let option = { key: unitSystem.vkey, text: unitSystem.name };
                if (unitSystemOptions.filter((e) => e.key === unitSystem.vkey).length === 0) {
                  unitSystemOptions.push(option);
                  if (unitSystem.usemask && unitSystem.usemask.includes(212)) {
                    // Set default
                    setUnit(option);
                  }
                }
              }
            }
          }
        }
      }
    } else {
      options.push({ key: value.vkey, text: value.name });
    }

    setModelDisabled(false);
    if (unitSystemOptions.length > 0) {
      setUnitSystemDisabled(false);
    } else {
      setUnitSystemDisabled(true);
    }
    setModelOptions(options);
    setUnitOptions(unitSystemOptions);

    let oldModel = JSON.parse(sessionStorage.getItem('download-card-form-model') || '{}');

    if (!isEmpty(oldModel)) {
      for (let option of options) {
        if (option.key === oldModel.key) {
          handleModelDropdownChange(null, option);
          break;
        }
      }
    }

    let oldUnit = JSON.parse(sessionStorage.getItem('download-card-form-unit') || '{}');

    if (!isEmpty(oldUnit)) {
      for (let option of unitSystemOptions) {
        if (option.key === oldUnit.key) {
          setUnit(option);
        }
      }
    }

    if (options.length === 1) {
      handleModelDropdownChange(e, options[0]);
      setModelDisabled(true);
    }

    if (unitSystemOptions.length === 1) {
      setUnit(unitSystemOptions[0]);
      setUnitSystemDisabled(true);
    }
    setDownloadDisabled(true);

    return options;
  }

  function closeNotification() {
    setCAEDownloadStatus(resetDownloadStatus());
  }

  const handleUnitDropdownChange = useCallback(
    (e, value) => {
      let newTempOptions = [];

      if (temperatureUnits) {
        let newUnit = temperatureUnits[value.key]?.name;

        if (temperatureOptions && temperatureOptions.length > 0 && temperatureOptions[0].data.unit !== newUnit) {
          let error = false;

          for (let option of temperatureOptions) {
            const newTemp = convertUnit(option.data.legend, option.data.unit, newUnit);
            if (!newTemp) {
              error = true;
              break;
            }
            const newOption = {
              data: { diaKey: option.data.diaKey, legend: newTemp, unit: newUnit, vkey: option.data.vkey, type: option.data.type },
              key: newTemp,
              text: newTemp + ' ' + newUnit,
            };

            newTempOptions.push(newOption);
            if (temperature === option.key) {
              setTemperature(newTemp);
            }
          }

          if (!error) {
            setTemperatureOptions(newTempOptions);
          }
        }
      }

      setUnit(value);
    },
    [temperature, temperatureOptions, temperatureUnits]
  );

  useEffect(() => {
    if (unit) {
      handleUnitDropdownChange(null, unit);
    }
  }, [unit, temperatureOptions, handleUnitDropdownChange]);

  function handleStartNumberChange(e, value) {
    setStartNumber(value);
  }

  function handleReductionFactorChange(e, value) {
    setReductionFactor(value);

    if (value < reductionFactorMin || value > reductionFactorMax) {
      setReductionErrorMessage('Please supply a value between ' + reductionFactorMin + ' and ' + reductionFactorMax);
    } else {
      setReductionErrorMessage();
    }
  }

  function handleToggler() {
    if (previewCollapsible) {
      setPreviewCollapsed((previewCollapsed) => !previewCollapsed);
    }
  }

  function submitReductionFactor() {
    setSubmittedReductionFactor(reductionFactor);
  }

  function handleReductionFactorKeyPress(e) {
    if (e.key === 'Enter') {
      submitReductionFactor();
    }
  }

  function handlePoissonsRatioChange(e, value) {
    setPoissonsRatio(value);
  }

  function handleEngTrueStressStrainChange(e, value) {
    setEngTrueStressStrain(value);
  }

  function handleTemperatureChange(e, value) {
    if (tempMulti) {
      if (temperature && temperature.length) {
        if (value.selected) {
          setTemperature([...temperature, value.key]);
        } else {
          setTemperature(
            temperature.filter((temp) => {
              return temp !== value.key;
            })
          );
        }
      } else {
        if (value.selected) {
          setTemperature([value.key]);
        }
      }
    } else {
      setTemperature(value.key);
    }
  }

  function handleDiagramChange(e, value) {
    setDiagram(value.key);
  }

  function handleStressLimitChange(e, option, index, value) {
    let v = value;

    if (option) {
      v = option.key;
    }

    if (v < stressLimitMin || v > stressLimitMax) {
      setStressLimitErrorMessage('Please supply a value between ' + stressLimitMin + ' and ' + stressLimitMax);
    } else {
      setStressLimitErrorMessage();
    }

    setStressLimit(v);
  }

  function handleIncludeGenericChange(e, value) {
    setIncludeGeneric(value);
  }

  function handleEncryptedChange(e, value) {
    setEncrypted(value);
  }

  let moistureStateComponent = !moistureStateDisabled ? (
    <div style={{ paddingRight: '30px' }}>
      <Dropdown
        styles={dropdownStyles}
        data-testid="caecard-moisture-state-dropdown"
        placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_MOISTURE_STATE, texts)}
        label={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_MOISTURE_STATE, texts)}
        id="moisturestateDropdown"
        ariaLabel={TextMapping.getUIText(TextMapping.UI_TEXT_MOISTURE_STATE, texts)}
        options={moistureStateOptions}
        onChange={handleMoistureStateDropdownChange}
        componentRef={moistureStateDropdown}
        disabled={moistureStateDisabled}
        defaultSelectedKey={moistureState ? moistureState.key : ''}
      />
    </div>
  ) : (
    ''
  );

  let startNumberComponent = !startNumberDisabled ? (
    <div>
      <TextField
        style={componentStyleMap.get('startNumber') || { width: '200px' }}
        data-testid="caecard-start-number"
        value={startNumber}
        label={startNumberLabel}
        id="startNumberTextField"
        ariaLabel="Start Number"
        onChange={handleStartNumberChange}
        componentRef={startNumberTextField}
        type="number"
        disabled={startNumberDisabled}
      />
    </div>
  ) : (
    ''
  );

  let reductionFactorComponent = !reductionFactorDisabled ? (
    <div>
      <TextField
        style={componentStyleMap.get('reduction') || { width: '200px' }}
        data-testid="caecard-reduction-factor-dropdown"
        //placeHolder={TextMapping.getUIText(TextMapping.UI_TEXT_REDUCTION_FACTOR_PLACEHOLDER, texts)}
        label={reductionFactorLabel}
        id="reductionFactorDropdown"
        ariaLabel={reductionFactorLabel}
        options={reductionFactorOptions}
        onChange={handleReductionFactorChange}
        onBlur={submitReductionFactor}
        onKeyPress={handleReductionFactorKeyPress}
        componentRef={reductionFactorDropdown}
        errorMessage={reductionErrorMessage}
        disabled={reductionFactorDisabled}
        value={reductionFactor}
        type="number"
        //defaultSelectedKey={reductionFactor ? reductionFactor.key : ''}
      />
      <Margin5 />
    </div>
  ) : (
    ''
  );

  let engTrueStressStrainComponent = !engTrueStressStrainDisabled ? (
    <div>
      <Dropdown
        styles={componentStyleMap.get('engTrueStressStrain') || dropdownStyles}
        data-testid="caecard-eng-true-stress-strain-dropdown"
        placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_ENG_TRUE_STRESS_STRAIN_PLACEHOLDER, texts)}
        label={engTrueStressStrainLabel}
        id="engTrueStressStrainDropdown"
        ariaLabel={engTrueStressStrainLabel}
        options={engTrueStressStrainOptions}
        onChange={handleEngTrueStressStrainChange}
        componentRef={engTrueStressStrainDropdown}
        disabled={engTrueStressStrainDisabled}
        defaultSelectedKey={engTrueStressStrain ? engTrueStressStrain.key : ''}
      />
      <Margin5 />
    </div>
  ) : (
    ''
  );

  let temperatureComponent = '';
  let diagramComponent = '';
  let multiTemperatureComponent = '';

  if (!diagramDisabled) {
    diagramComponent = (
      <div>
        <Dropdown
          styles={componentStyleMap.get('selectdiagram') || dropdownStyles}
          data-testid="caecard-diagram-dropdown"
          placeholder={diagramPlaceholder ? diagramPlaceholder : diagramLabel}
          label={diagramLabel}
          id="diagramDropdown"
          ariaLabel={diagramLabel}
          options={diagramOptions}
          onChange={handleDiagramChange}
          componentRef={diagramDropdown}
          disabled={diagramDisabled}
          defaultSelectedKey={diagram ? diagram : ''}
        />
        <Margin5 />
      </div>
    );
  }

  if (!temperatureDisabled && !tempMulti) {
    temperatureComponent = (
      <div>
        <Dropdown
          styles={componentStyleMap.get('selecttemperature') || dropdownStyles}
          data-testid="caecard-temperature-dropdown"
          placeholder={tempPlaceholder ? tempPlaceholder : temperatureLabel}
          label={temperatureLabel}
          id="temperatureDropdown"
          ariaLabel={temperatureLabel}
          options={temperatureOptions}
          onChange={handleTemperatureChange}
          componentRef={temperatureDropdown}
          disabled={temperatureDisabled}
          defaultSelectedKey={temperature ? temperature : ''}
        />
        <Margin5 />
      </div>
    );
  } else if (!temperatureDisabled && tempMulti) {
    multiTemperatureComponent = (
      <div>
        <Dropdown
          styles={componentStyleMap.get('selecttemperature') ? componentStyleMap.get('selecttemperature') : dropdownStyles}
          data-testid="caecard-multi-temperature-dropdown"
          placeholder={tempPlaceholder ? tempPlaceholder : temperatureLabel}
          label={temperatureLabel}
          id="multiTemperatureDropdown"
          ariaLabel={temperatureLabel}
          options={temperatureOptions}
          onChange={handleTemperatureChange}
          componentRef={multiTemperatureDropdown}
          disabled={temperatureDisabled}
          multiSelect={tempMulti}
          defaultSelectedKeys={temperature && !isEmpty(temperature) ? temperature : ''}
        />
        <Margin5 />
      </div>
    );
  }

  let poissonsRatioComponent = !poissonsRatioDisabled ? (
    <div>
      <TextField
        data-testid="caecard-poissons-ratio"
        style={componentStyleMap.get('poissonsratio') || { width: '200px' }}
        value={poissonsRatio}
        label={poissonsRatioLabel}
        id="poissonsRatioTextField"
        ariaLabel={poissonsRatioLabel}
        onChange={handlePoissonsRatioChange}
        componentRef={poissonsRatioTextField}
        type="number"
        disabled={poissonsRatioDisabled}
      />
      <Margin5 />
    </div>
  ) : (
    ''
  );

  let stressLimitComponent = !stressLimitDisabled ? (
    <div>
      <ComboBox
        style={componentStyleMap.get('stresslimit') || { width: '200px' }}
        data-testid="caecard-stress-limit-dropdown"
        placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_STRESS_LIMIT_PLACEHOLDER, texts)}
        label={stressLimitLabel}
        id="stressLimitDropdown"
        allowFreeform={true}
        ariaLabel={stressLimitLabel}
        options={stressLimitOptions}
        onChange={handleStressLimitChange}
        componentRef={stressLimitDropdown}
        disabled={stressLimitDisabled}
        text={stressLimit}
        errorMessage={stressLimitErrorMessage}
        // defaultSelectedKey={stressLimit ? stressLimit : ''}
      />
      <Margin5 />
    </div>
  ) : (
    ''
  );

  let modelComponent = (!solver || modelOptions.length > 0) && (
    <Dropdown
      styles={dropdownStyles}
      data-testid="caecard-model-dropdown"
      placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_MODEL, texts)}
      label={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_MODEL, texts)}
      id="modelDropdown"
      ariaLabel={TextMapping.getUIText(TextMapping.UI_TEXT_MODEL, texts)}
      options={modelOptions}
      onChange={handleModelDropdownChange}
      componentRef={modelDropdown}
      defaultSelectedKey={model ? model.key : ''}
      disabled={modelDisabled}
    />
  );

  let includeGenericCheckboxComponent = !includeGenericDataDisabled ? (
    <div style={{ padding: '5px' }}>
      <Checkbox
        label={TextMapping.getUIText(TextMapping.UI_TEXT_INCLUDE_GENERIC_DATA, texts)}
        value={includeGeneric}
        onChange={handleIncludeGenericChange}
        componentRef={includeGenericData}
      />
    </div>
  ) : (
    ''
  );

  let diagramCatalog = engTrueStressStrain && engTrueStressStrain.key === 1 ? stressStrainCatalog : trueStressStrainCatalog;
  let previewDisplay = previewDisabled || previewCollapsed || isEmpty(roots.caeDownloadPreview) ? 'none' : 'block';

  return (
    <div style={{ height: '100%' }}>
      <div
        className="download-card-form"
        data-testid="download-card-form-scrollable-div"
        style={{
          height:
            roots.perms.includes(permission) && roots.perms.includes(modelPermission) && roots.perms.includes(softwarePermission)
              ? 'calc(100% - 45px)'
              : '100%',
          overflow: 'auto',
        }}
      >
        {pluginMessage}
        <Margin5 />
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          {moistureStateComponent}
          {includeGenericCheckboxComponent}
        </div>
        <Margin5 />
        <div style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' }}>
          <Dropdown
            styles={dropdownStyles}
            data-testid="caecard-solver-dropdown"
            placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_SOFTWARE, texts)}
            label={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_SOFTWARE, texts)}
            id="solverDropdown"
            ariaLabel={TextMapping.getUIText(TextMapping.UI_TEXT_SOFTWARE, texts)}
            options={solverOptions}
            onChange={handleSolverDropdownChange}
            componentRef={solverDropdown}
            disabled={solverDisabled || solverOptions.length === 0}
            defaultSelectedKey={solver ? solver.key : ''}
          />
          <Margin5 />
          {modelComponent}
          <Margin5 />
          {roots.perms.includes(permission) && roots.perms.includes(modelPermission) && roots.perms.includes(softwarePermission) && (
            <>
              <Dropdown
                styles={dropdownStyles}
                data-testid="caecard-unit-dropdown"
                placeholder={TextMapping.getUIText(TextMapping.UI_TEXT_UNIT, texts)}
                label={TextMapping.getUIText(TextMapping.UI_TEXT_SELECT_UNIT, texts)}
                id="unitDropdown"
                ariaLabel={TextMapping.getUIText(TextMapping.UI_TEXT_UNIT, texts)}
                options={unitOptions}
                onChange={handleUnitDropdownChange}
                componentRef={unitDropdown}
                defaultSelectedKey={unit ? unit.key : ''}
                disabled={unitSystemDisabled}
              />
              <Margin5 />
              {startNumberComponent}
            </>
          )}
        </div>
        {roots.perms.includes(permission) && roots.perms.includes(modelPermission) && roots.perms.includes(softwarePermission) && (
          <>
            <Margin5 />
            {diagramComponent}
            <Margin5 />
            {temperatureComponent}
            {multiTemperatureComponent}
            <Margin5 />
            <div style={{ display: 'flex', alignItems: 'flex-end', flexWrap: 'wrap' }}>
              {engTrueStressStrainComponent}
              <Margin5 />
              {poissonsRatioComponent}
              <Margin5 />
              {reductionFactorComponent}
              <Margin5 />
              {stressLimitComponent}
            </div>
            <Margin5 />
          </>
        )}
        <div style={{ display: invalidDataDisabled ? 'none' : 'flex', width: '100%', flexWrap: 'wrap' }}>
          {TextMapping.getUIText(TextMapping.UI_TEXT_INVALID_DATA, texts)}
        </div>
        {roots.perms.includes(permission) && roots.perms.includes(modelPermission) && roots.perms.includes(softwarePermission) && (
          <div style={{ display: !selectedDiagramPlotData ? 'none' : 'flex', width: '100%', flexWrap: 'wrap' }}>
            <div style={{ width: '100%', minWidth: '350px', height: '450px' }}>
              <Plot
                id="selecteddiagram"
                name="selecteddiagram"
                data-testid="selecteddiagram"
                plotData={selectedDiagramPlotData}
                onRelayout={(event) =>
                  onRelayout(
                    diagram,
                    event,
                    selectedDiagramCatalog.options.scale.x === 'log',
                    selectedDiagramCatalog.options.scale.y === 'log',
                    layoutMap,
                    setLayoutMap
                  )
                }
                onDoubleClick={() => onDoubleClick(diagram, layoutMap, setLayoutMap)}
              />
            </div>
          </div>
        )}
        {roots.perms.includes(permission) && roots.perms.includes(modelPermission) && roots.perms.includes(softwarePermission) && (
          <div style={{ display: plotsDisabled ? 'none' : 'flex', width: '100%', flexWrap: 'wrap' }}>
            <div style={{ width: '50%', minWidth: '350px', height: '450px' }}>
              <Plot
                id="designcorrection"
                name="designcorrection"
                data-testid="diagram-designcorrection"
                plotData={engTrueStressStrain && engTrueStressStrain.key === 1 ? stressStrainPlotData : trueStressStrainPlotData}
                onRelayout={(event) =>
                  onRelayout(
                    diagramCatalog._key,
                    event,
                    diagramCatalog.options.scale.x === 'log',
                    diagramCatalog.options.scale.y === 'log',
                    layoutMap,
                    setLayoutMap
                  )
                }
                onDoubleClick={() => onDoubleClick(diagramCatalog._key, layoutMap, setLayoutMap)}
              />
            </div>
            <div style={{ width: '50%', minWidth: '350px' }}>
              <Plot
                id="caeexport"
                name="caeexport"
                data-testid="diagram-caeexport"
                plotData={caeStressStrainPlotData}
                onRelayout={(event) =>
                  onRelayout(
                    caeStressStrainCatalog._key,
                    event,
                    caeStressStrainCatalog.options.scale.x === 'log',
                    caeStressStrainCatalog.options.scale.y === 'log',
                    layoutMap,
                    setLayoutMap
                  )
                }
                onDoubleClick={() => onDoubleClick(caeStressStrainCatalog._key, layoutMap, setLayoutMap)}
              />
            </div>
          </div>
        )}
        {roots.perms.includes(permission) && roots.perms.includes(modelPermission) && roots.perms.includes(softwarePermission) && (
          <>
            <div style={{ display: 'flex' }}>
              {previewCollapsible && (
                <IconButton
                  style={{ width: 'auto', height: 'auto' }}
                  iconProps={{ iconName: previewCollapsed ? 'RightSmall' : 'DownSmall' }}
                  onClick={handleToggler}
                  title="Close"
                />
              )}
              <button
                className="bodyText"
                style={{
                  background: 'none',
                  border: 'none',
                  margin: 0,
                  padding: 0,
                  display: previewDisabled || isEmpty(roots.caeDownloadPreview) ? 'none' : 'block',
                  fontSize: '14px',
                }}
                onClick={handleToggler}
              >
                {previewLabel}
              </button>
            </div>
            <pre className={`cae-download-preview ${previewStyle} bodyText`} style={{ display: previewDisplay }}>
              {roots.caeDownloadPreview}
            </pre>
          </>
        )}
        {caeDownloadStatus && caeDownloadStatus.message && (
          <div style={{ position: 'absolute', top: '20px', right: '20px', width: 'fit-content', zIndex: '1' }}>
            <MessageBar onDismiss={closeNotification} messageBarType={caeDownloadStatus.notificationType}>
              {caeDownloadStatus.message}
              {caeDownloadStatus.url && (
                <Link href={`${caeDownloadStatus.url}&sso=okta`} target="_blank">
                  Open file location.
                </Link>
              )}
            </MessageBar>
          </div>
        )}
        {solver &&
          model &&
          (!roots.perms.includes(permission) || !roots.perms.includes(modelPermission) || !roots.perms.includes(softwarePermission)) && (
            <div style={{ paddingTop: '10px' }}>
              <BBPageComponent
                page={
                  !isEmpty(modelRestrictedPage)
                    ? modelRestrictedPage
                    : !isEmpty(softwareRestrictedPage)
                    ? softwareRestrictedPage
                    : restrictedPage
                }
                bbPageForComponent={bbPageForComponent}
                getBBPageForComponent={getBBPageForComponent}
                appContent={appContent}
                language={language}
                texts={texts}
              />
            </div>
          )}
      </div>
      {roots.perms.includes(permission) && roots.perms.includes(modelPermission) && roots.perms.includes(softwarePermission) && (
        <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', paddingTop: '10px' }}>
          {!encryptDisabled && (
            <div style={{ padding: '5px' }}>
              {' '}
              <Checkbox
                label={TextMapping.getUIText(TextMapping.UI_TEXT_ENCRYPTED, texts)}
                value={encrypted}
                onChange={handleEncryptedChange}
              />
            </div>
          )}
          <Margin5 />
          <PrimaryButton
            disabled={downloadDisabled || (caeDownloadStatus.mode === 'local' && caeDownloadStatus.status === 'progress')}
            onClick={() => {
              if (window.opener) {
                window.opener.focus();
              }
              onDownloadClick(
                model,
                solver,
                unit,
                startNumber,
                moistureState,
                submittedReductionFactor,
                temperature,
                poissonsRatio,
                engTrueStressStrain,
                stressLimit,
                includeGeneric,
                encrypted,
                diagram
              );
              setPluginMessage('');
            }}
            iconProps={{ iconName: 'Download' }}
          >
            {downloadBtnLabel}
          </PrimaryButton>
          <Margin5 />
          {roots.config.driveconfig && roots.config.driveconfig.caesavetomydrive && (
            <PrimaryButton
              disabled={downloadDisabled || (caeDownloadStatus.mode === 'drive' && caeDownloadStatus.status === 'progress')}
              onClick={() => {
                if (roots.appsPerms.Drive) {
                  onDownloadClick(
                    model,
                    solver,
                    unit,
                    startNumber,
                    moistureState,
                    submittedReductionFactor,
                    temperature,
                    poissonsRatio,
                    engTrueStressStrain,
                    stressLimit,
                    includeGeneric,
                    encrypted,
                    diagram,
                    'drive'
                  );
                } else {
                  handleNoDriveLicenseClick();
                }
              }}
              iconProps={{ iconName: 'CloudDownload' }}
            >
              Save To Drive
            </PrimaryButton>
          )}
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCardForm);
