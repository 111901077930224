import { MessageBar } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';

function LocalNotifications(props) {
  const { notifications, hideLocalNotification } = props;

  return (
    <div
      style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        width: 'fit-content',
        zIndex: '50',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {notifications.map(
        notify =>
          notify.visible && (
            <div key={notify.id} style={{ padding: '2px' }}>
              <MessageBar onDismiss={() => hideLocalNotification(notify.id)} messageBarType={notify.type}>
                {notify.message}
                {notify.url && (
                  <Link href={notify.url} target="_blank">
                    Open file location.
                  </Link>
                )}
              </MessageBar>
            </div>
          )
      )}
    </div>
  );
}

export default LocalNotifications;
