/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
export function changeActiveDialog(id) {
  return {
    type: 'CHANGE_ACTIVE_DIALOG',
    id,
  };
}

export function clearActiveDialog() {
  return {
    type: 'CLEAR_ACTIVE_DIALOG',
  };
}
