/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { Checkbox } from '@fluentui/react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

function DetailListCheckbox({ checked }) {
  const handleClick = useCallback(e => {
    e.preventDefault();
  }, []);

  return (
    <div onClick={handleClick} role="presentation">
      <Checkbox checked={checked} styles={{ checkbox: { width: '14px', height: '14px' } }} />
    </div>
  );
}

DetailListCheckbox.propTypes = {
  checked: PropTypes.bool,
};

export default DetailListCheckbox;
