/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import styled from 'styled-components';

const Title = styled.div`
  flex-grow: 1;
  padding-left: 0.5rem;
  font-weight: bold;
`;

const Wrap = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

export { Title, Wrap };
