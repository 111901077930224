/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import { GET_APPS } from './graphql';

export function useApplications({ appId, fetchPolicy, username }) {
  const client = useApolloClient();
  const [appData, setAppData] = useState(null);
  const [appError, setAppError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchApps = useCallback(async () => {
    setIsLoading(true);

    const { data, error } = await client.query({
      errorPolicy: 'all',
      fetchPolicy,
      query: GET_APPS,
      variables: { appId },
      suspend: false,
    });

    setAppData(data);
    setAppError(error);
    setIsLoading(false);
  }, [appId, client, fetchPolicy]);

  useEffect(() => {
    if (username && appId) {
      //fetchApps();
    }
  }, [appId, fetchApps, username]);

  return {
    data: appData,
    error: appError,
    isLoading,
  };
}

export default useApplications;
