import React, { useCallback, useState } from 'react';
import { Image, ImageLoadState, ImageFit } from '@fluentui/react';
import PropTypes from 'prop-types';

const NOOP = () => {};

function ImageViewer({ id, onLoad = NOOP }) {
  const [isImageFailedToLoad, setIsImageFailedToLoad] = useState(false);

  const handleLoadingStateChange = useCallback(
    state => {
      if (state === ImageLoadState.error) {
        setIsImageFailedToLoad(true);
      } else {
        onLoad(state);
      }
    },
    [onLoad]
  );

  if (!id) {
    console.error('Id is mandatory');
    return <sapn />;
  }

  if (isImageFailedToLoad) {
    return <span>Unable to load the image</span>;
  }

  return (
    <Image
      className="ImageViewer__Root"
      src={`/file/files/${id}/download`}
      imageFit={ImageFit.centerContain}
      maximizeFrame
      shouldFadeIn
      onLoadingStateChange={handleLoadingStateChange}
    />
  );
}

ImageViewer.propTypes = {
  id: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

export default ImageViewer;
