/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import cloneDeep from 'lodash/cloneDeep';
import updateData from './updateData';

export function runValidatorAndUpdateData({ newData, newDataItem, oldDataItem, pathKeys, validator }) {
  const { value: newValue, values: newValues } = validator({
    newData,
    newDataItem,
    oldDataItem,
  });

  let internalNewData = cloneDeep(newData);
  const internalNewDataItem = cloneDeep(newDataItem);

  if (newValues) {
    internalNewDataItem.values = newValues;

    internalNewData = updateData({
      data: newData,
      pathKeys,
      values: newValues,
    });
  } else {
    internalNewDataItem.value = newValue;

    internalNewData = updateData({
      data: newData,
      pathKeys,
      value: newValue,
    });
  }

  return {
    newData: internalNewData,
    newDataItem: internalNewDataItem,
  };
}
