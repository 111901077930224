/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { combineReducers } from 'redux';

import loggedInUser from './loggedInUser';
import panelId from './panelId';
import initialStates from '../store/initialStates';

const appReducer = combineReducers({
  loggedInUser,
  panelId,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'LOGOUT') {
    newState = { ...initialStates };
  }

  return appReducer(newState, action);
};

export default rootReducer;
