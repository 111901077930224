export const CAE_MODEL = 219;
export const PLOTS = 220;
export const PROPERTIES = 221;
export const INFO = 222;
export const PRODUCER = 203;
export const CO2_FOOTPRINT = 32;
export const GWP = 33;
export const POWER_CONSUMPTION = 34;
export const POWER_MIX_CO2 = 35;
export const POWER_MIX_GWP = 36;
