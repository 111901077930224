/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import cloneDeep from 'lodash/cloneDeep';
import setWith from 'lodash/setWith';

const updateEntity = (data, entity) => {
  let newEntity = cloneDeep(entity);

  Object.values(data).forEach(value => {
    if (!value || typeof value !== 'object') return;

    const { sourcePath, value: newValue } = value;

    if (sourcePath) {
      /* Will create properties missing in sourcePath. */
      setWith(newEntity, sourcePath, newValue, Object);
    }

    newEntity = updateEntity(value, newEntity);
  });

  return newEntity;
};

export default updateEntity;
