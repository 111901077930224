/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import account from './account';
import action from './action';
import application from './application';
import auth from './auth';
import config from './config';
import content from './content';
import dir from './dir';
import entity from './entity';
import job from './job';
import notification from './notification';
import previews from './previews';
import role from './role';
import search from './search';
import share from './share';
import team from './team';
import trash from './trash';
import user from './user';
import versioning from './versioning';

const bindMethods = (obj, self) => {
  if (typeof obj !== 'object') {
    throw new Error('First argument must be an object.');
  }

  if (typeof self !== 'object') {
    throw new Error('Second argument must be an object.');
  }

  const newObj = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === 'function') {
      newObj[key] = value.bind(self);
    } else {
      newObj[key] = value;
    }
  });

  return newObj;
};

class Api {
  constructor() {
    this.bindResource(account, 'account');
    this.bindResource(action, 'action');
    this.bindResource(application, 'application');
    this.bindResource(auth, 'auth');
    this.bindResource(config, 'config');
    this.bindResource(content, 'content');
    this.bindResource(dir, 'dir');
    this.bindResource(entity, 'entity');
    this.bindResource(job, 'job');
    this.bindResource(notification, 'notification');
    this.bindResource(previews, 'previews');
    this.bindResource(role, 'role');
    this.bindResource(search, 'search');
    this.bindResource(share, 'share');
    this.bindResource(trash, 'trash');
    this.bindResource(user, 'user');
    this.bindResource(team, 'team');
    this.bindResource(versioning, 'versioning');
  }

  bindResource(resource, name) {
    this[name] = bindMethods(resource, this);
  }

  getApiUrl() {
    return this._apiUrl;
  }

  clearAuth() {
    return this.setAuth(null, null);
  }

  getAuth() {
    return { ...this._auth };
  }

  setAuth(userId) {
    this._auth = {
      isLoggedIn: ![null, undefined].includes(userId),
      userId,
    };

    return this.getAuth();
  }
}

export { bindMethods };

export default Api;
