import { Tools } from './tools.js';

class NiceScale {
  constructor() {
    this.maxPoint = undefined;
    this.maxTicks = 6;
    this.tickSpacing = undefined;
    this.numTicks = undefined;
    this.range = undefined;
    this.niceMin = undefined;
    this.niceMax = undefined;
    this.minExponent = false;
    this.isLogarithmic = false;
  }

  /**
   * Calculate and update values for tick spacing and nice
   * minimum and maximum data points on the axis.
   */
  calculate() {
    this.range = this.niceNum(this.maxPoint - this.minPoint, false);
    this.tickSpacing = this.niceNum(this.range / (this.maxTicks - 1), true);
    this.tickSpacing = this.isLogarithmic ? Math.ceil(this.tickSpacing) : this.tickSpacing;
    this.niceMin = Math.floor(this.minPoint / this.tickSpacing) * this.tickSpacing;
    this.niceMax = Math.ceil(this.maxPoint / this.tickSpacing) * this.tickSpacing;
    this.numTicks = Math.round((this.niceMax - this.niceMin) / this.tickSpacing) + 1;
    if (this.isLogarithmic) {
      this.niceMin = Tools.pow10(this.niceMin);
      this.niceMax = Tools.pow10(this.niceMax);
    }
  }

  /**
   * Returns a "nice" number approximately equal to range. Rounds
   * the number if round = true. Takes the ceiling if round = false.
   *
   * @param range the data range
   * @param round whether to round the result
   * @return a "nice" number to be used for the data range
   */
  niceNum(range, round) {
    var exponent; /** exponent of range */
    var fraction; /** fractional part of range */
    var niceFraction; /** nice, rounded fraction */

    exponent = Math.floor(Tools.log10(range));

    if (this.minExponent !== false && exponent < this.minExponent) exponent = this.minExponent;

    fraction = range / Tools.pow10(exponent);
    if (round) {
      if (fraction < 1.5) niceFraction = 1;
      else if (fraction < 3) niceFraction = 2;
      else if (fraction < 7) niceFraction = 5;
      else niceFraction = 10;
    } else {
      if (fraction <= 1) niceFraction = 1;
      else if (fraction <= 2) niceFraction = 2;
      else if (fraction <= 5) niceFraction = 5;
      else niceFraction = 10;
    }
    return niceFraction * Tools.pow10(exponent);
  }

  /**
   * Instantiates a new instance of the NiceScale class.
   *
   * @param min the minimum data point on the axis
   * @param max the maximum data point on the axis
   * @param isLog (optional) if true, the scale is logarithmic
   */
  init(min, max, isLog) {
    if (typeof isLog !== 'undefined') this.isLogarithmic = isLog;
    this.setMinMaxPoints(min, max);
    return this;
  }

  /**
   * Sets the minimum and maximum data points for the axis.
   *
   * @param minPoint the minimum data point on the axis
   * @param maxPoint the maximum data point on the axis
   */
  setMinMaxPoints(minPt, maxPt) {
    if (this.isLogarithmic) {
      this.minPoint = Tools.log10(minPt);
      this.maxPoint = Tools.log10(maxPt);
    } else {
      this.minPoint = minPt;
      this.maxPoint = maxPt;
    }
    this.calculate();
  }

  /**
   * Sets maximum number of tick marks we're comfortable with
   * @param maxTicks the maximum number of tick marks for the axis
   */
  setMaxTicks(maxTks) {
    this.maxTicks = maxTks;
    this.calculate();
    return this;
  }

  setMinExponent(minExp) {
    this.minExponent = minExp;
    this.calculate();
    return this;
  }

  get() {
    return {
      min: this.isLogarithmic ? Math.log10(this.niceMin) : this.niceMin,
      max: this.isLogarithmic ? Math.log10(this.niceMax) : this.niceMax,
      spacing: this.tickSpacing,
      ticks: this.numTicks,
    };
  }

  /**
   * Gets the tick spacing.
   * @return the tick spacing
   */
  getTickSpacing() {
    return this.tickSpacing;
  }

  getNumTicks() {
    return this.numTicks;
  }

  /**
   * Gets the "nice" minimum data point.
   * @return the new minimum data point for the axis scale
   */
  getNiceMin() {
    return this.niceMin;
  }

  /**
   * Gets the "nice" maximum data point.
   * @return the new maximum data point for the axis scale
   */
  getNiceMax() {
    return this.niceMax;
  }
}

export { NiceScale };
