/* --------------------------------------------------------------------------------
 * Copyright: Altair Engineering, Inc., 2020.  All rights reserved.
 * Contains trade secrets of Altair Engineering, Inc.
 * Copyright notice does not imply publication.
 * Decompilation or disassembly of this software is strictly prohibited.
 * --------------------------------------------------------------------------------*/
import { mergeStyleSets } from '@fluentui/style-utilities';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Controls from './Controls';
import EntityViewer from './EntityViewer';
import LoadingSpinner from './EntityViewer/LoadingSpinner';
import { Text, Wrap } from './styled';

const EMPTY_OBJECT = {};

const STORAGE_KEY = 'aone-active-viewer-name';

function setActiveViewerIdInLocalStorage(activeViewerId) {
  if (activeViewerId) {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(activeViewerId));
  } else {
    sessionStorage.removeItem(STORAGE_KEY);
  }
}

function getActiveViewerName() {
  const viewerIdString = sessionStorage.getItem(STORAGE_KEY);
  if (!viewerIdString) {
    return undefined;
  }
  return JSON.parse(viewerIdString);
}

const NOOP = () => {};

function View({
  activeViewerName: propsActiveViewerName,
  api,
  entity,
  loading,
  onActiveViewerChange = NOOP,
  onAddPreviewClick,
  onDeletePreviewClick,
  styles = EMPTY_OBJECT,
  viewers,
}) {
  const [activeViewerName, setActiveViewerName] = useState(propsActiveViewerName);
  const [isViewerExpanded, setIsViewerExpanded] = useState(false);
  const [showComments, setShowComments] = useState(false);

  useEffect(() => {
    if (!viewers || loading) {
      return;
    }

    let nextActiveViewerName = propsActiveViewerName ?? getActiveViewerName();
    const prevViewerIndexInCurrentViewers = viewers.findIndex(v => v.name === nextActiveViewerName);

    if (!nextActiveViewerName || prevViewerIndexInCurrentViewers === -1) {
      nextActiveViewerName = viewers[0]?.name;
      onActiveViewerChange(nextActiveViewerName);
    }
    setActiveViewerIdInLocalStorage(nextActiveViewerName);
    setActiveViewerName(nextActiveViewerName);
  }, [loading, onActiveViewerChange, propsActiveViewerName, viewers]);

  useEffect(() => {
    setActiveViewerName(propsActiveViewerName);
  }, [propsActiveViewerName]);

  const classNames = useMemo(() => {
    return mergeStyleSets({
      controls: ['Controls__Root', styles.controls, isViewerExpanded && { position: 'initial' }],
    });
  }, [isViewerExpanded, styles]);

  const { isGeneratingPreview, previewError } = get(entity, 'attributes', EMPTY_OBJECT);

  const handleControlItemClick = useCallback(
    item => {
      const { key, viewerName, defaultShowComments } = item;

      switch (key) {
        case 'CHANGE_VIEW':
          setActiveViewerIdInLocalStorage(viewerName);
          onActiveViewerChange(viewerName);
          setActiveViewerName(viewerName);
          setShowComments(defaultShowComments);
          break;
        case 'COLLAPSE_VIEW':
          setIsViewerExpanded(false);
          break;
        case 'EXPAND_VIEW':
          setIsViewerExpanded(true);
          break;
        default:
          break;
      }

      const viewerIframe = document.querySelector('[title="Viewer"]');
      if (viewerIframe) {
        viewerIframe.contentWindow.postMessage(key, window.location.origin);
      }
    },
    [onActiveViewerChange]
  );

  // Allow distant components to control view
  useEffect(() => {
    const changeView = ({ data }) => {
      if (data.key === 'CHANGE_VIEW' || data.key === 'COLLAPSE_VIEW' || data.key === 'EXPAND_VIEW') {
        handleControlItemClick(data);
      }
    };

    window.addEventListener('message', changeView);

    return () => {
      window.removeEventListener('message', changeView);
    };
  }, [handleControlItemClick]);

  if (loading) {
    return (
      <Wrap data-testid="View" className="aone-View">
        <LoadingSpinner />
      </Wrap>
    );
  }

  if (!entity || !viewers || typeof activeViewerName === 'undefined') return null;

  const style = (() => {
    if (!isViewerExpanded) return {};

    return {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      zIndex: 12,
    };
  })();

  if (viewers.length === 0) {
    if (isGeneratingPreview) {
      return <Text>Generating preview...</Text>;
    }

    if (previewError && typeof previewError === 'string') {
      return <Text>{previewError}</Text>;
    }

    return <Text>No viewers</Text>;
  }

  const activeViewer = viewers.find(viewer => viewer.name === activeViewerName);

  return (
    <Wrap data-testid="View" style={style} className="aone-View">
      <Controls
        activeViewerName={activeViewerName}
        className={classNames.controls}
        entity={entity}
        isViewerExpanded={isViewerExpanded}
        onItemClick={handleControlItemClick}
        viewers={viewers}
      />

      {isGeneratingPreview && <Text>Generating preview...</Text>}

      {previewError && typeof previewError === 'string' && <Text>{previewError}</Text>}

      <EntityViewer
        api={api}
        entity={entity}
        onAddPreviewClick={onAddPreviewClick}
        onDeletePreviewClick={onDeletePreviewClick}
        viewer={activeViewer}
        showComments={showComments}
      />
    </Wrap>
  );
}

View.propTypes = {
  activeViewerName: PropTypes.string,
  api: PropTypes.object,
  entity: PropTypes.shape({
    attributes: PropTypes.shape({
      isGeneratingPreview: PropTypes.bool,
      name: PropTypes.string,
      previewError: PropTypes.string,
    }).isRequired,
    system: PropTypes.shape({
      contentType: PropTypes.string,
    }).isRequired,
  }),
  loading: PropTypes.bool,
  onActiveViewerChange: PropTypes.func,
  onAddPreviewClick: PropTypes.func,
  onDeletePreviewClick: PropTypes.func,
  styles: PropTypes.shape({
    controls: PropTypes.object,
  }),
  viewers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      isPreview: PropTypes.bool,
      url: PropTypes.string,
      previews: PropTypes.arrayOf(
        PropTypes.shape({
          group: PropTypes.string,
          internal: PropTypes.string,
          preview: PropTypes.string,
          src: PropTypes.string,
          thumbnail: PropTypes.string,
        })
      ),
    })
  ),
};

export default View;
